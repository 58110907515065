/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import SectionHeadingStyle from '../elementsPreview/sectionHeadingStyle';
import SelectBox from '../formComponents/selectBox';
import ColorPicker from '../common/colorPicker';
import ImageUploader from '../../services/upload/imageUploader';

/*Assets*/
import Img3 from '../../assets/images/sample/new3.png';
import Img4 from '../../assets/images/sample/new4.png';
import Img5 from '../../assets/images/sample/new5.png';

class NewListSelectionModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      colorOne: "",
      colorTwo: "",
      pickerOneID: "colorPick1",
      pickerTwoID: "colorPick2",
      category: null,
      subcategoryOptions: [],
      type: null,
      listType: null,
      images: [],
      imageLoader: [],
      label: [],
      listStyle: {},
      error: ""
    }
  }

  componentDidMount() {
    if(this.props.data){
      this.setState({subcategoryOptions: this.props.data});
    }
  }

  setList(i){
    let count = 3;
    if(i===2||i===3) count = 4;
    let temp1 = [];
    let temp2 = [];
    let temp3 = [];
    let type = 0;
    for(let j=0; j<count; j++){
        temp1.push("");
        temp3.push("");
        temp2.push(false);
    }
    this.setState({images: temp1, imageLoader: temp2, listType: i, label: temp3, type:i});
  }

  removeImage(i){
    let temp = this.state.images;
    temp[i] = "";
    this.setState({images: temp});
  }

  setImage(i, url){
    console.log(url)
    let temp1 = this.state.images;
    let temp = this.state.imageLoader;
    temp1[i] = url;
    temp[i] = false; 
    this.setState({images: temp1, imageLoader: temp});
  }

  startLoading(i){
    let temp = this.state.imageLoader;
    temp[i] = true; 
    this.setState({imageLoader: temp});
  } 

  setLabel(i, val){
    let temp = this.state.label;
    temp[i] = val;
    this.setState({label: temp});
  }

  // selectListType(tempType, tempClass){
  //   $(".e-demo-list-wrap").removeClass("e-active");
  //   $(tempClass).addClass("e-active");

  //   this.setState({listType: tempType});
  // }

  pickerOneColorChange(tempColor){
    this.setState({colorOne: tempColor});
  }

  pickerTwoColorChange(tempColor){
    this.setState({colorTwo: tempColor});
  }

  // triggerClick(tempItem, tempLoader){
  //   $(tempItem).trigger('click');
  //   this.setState({showUploadLoader: tempLoader});
  // }

  // selectChange = async (subcategory) => {
  //   await this.setState({subcategory: subcategory});
  // }

  selectStyleChange = async (item) => {
    await this.setState({listStyle: item});
  }

  // selectContentOneChange = async (item) => {
  //   await this.setState({subcategoryOne: item});
  // }

  // selectContentTwoChange = async (item) => {
  //   await this.setState({subcategoryTwo: item});
  // }

  // selectContentThreeChange = async (item) => {
  //   await this.setState({subcategoryThree: item});
  // }

  // selectContentFourChange = async (item) => {
  //   await this.setState({subcategoryFour: item});
  // }

  // selectContent2OneChange = async (item) => {
  //   await this.setState({subcategory2One: item});
  // }

  // selectContent2TwoChange = async (item) => {
  //   await this.setState({subcategory2Two: item});
  // }

  // selectContent2ThreeChange = async (item) => {
  //   await this.setState({subcategory2Three: item});
  // }

  // selectContent2FourChange = async (item) => {
  //   await this.setState({subcategory2Four: item});
  // }

  // selectContent2FiveChange = async (item) => {
  //   await this.setState({subcategory2Five: item});
  // }

  // selectContent2SixChange = async (item) => {
  //   await this.setState({subcategory2Six: item});
  // }


  

  submitClick(){
    let tempContent = [];
    this.state.images.forEach((image, i)=>{
      tempContent.push({img: image, title: this.state.label[i].label, id: this.state.label[i].value});
    });
    var tempArry = { 
      "content": tempContent, 
      "type": this.state.type, 
      "style": this.state.listStyle,
    };
    console.log(tempArry)
    this.props.success(tempArry);
  }

  // imageUploadBtnClick(tempItem){
  //   $(tempItem).trigger('click');
  // }

  render() {


    var tempIDSelectOne = "#"+this.state.pickerOneID;
    var tempIDSelectTwo = "#"+this.state.pickerTwoID;
    var tempColorOne = { background: this.state.colorOne };
    var tempColorTwo = { background: this.state.colorTwo };


    return (
      <div className="modal fade" id="newListModal" tabIndex="-1" role="dialog" aria-labelledby="newListModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/*<div className="modal-header">
              <h5 className="modal-title">Choose list type</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>*/}
            <div className="modal-body mt-3">
              <div>
                <h6>Choose the list type</h6>
              </div>
              <div className="e-demo-list-style">
              <div className="row">
                  <div className="col-lg-4">
                    <div className={this.state.listType==1? "e-card-transition e-demo-two-list text-center e-demo-list-wrap e-active": "e-card-transition e-demo-two-list text-center e-demo-list-wrap"} onClick={()=>this.setList(1)}>
                      <img className="mr-2 w-100" src={Img3} alt="Demo slider image"/>
                      <p className="mb-0">Three items list</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className={this.state.listType==2? "e-card-transition e-demo-three-list text-center e-demo-list-wrap e-active": "e-card-transition e-demo-three-list text-center e-demo-list-wrap"} onClick={()=>this.setList(2)}>
                      <img className="mr-2 w-100" src={Img4} alt="Demo slider image"/>
                      <p className="mb-0">Four items list-1</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className={this.state.listType==3?"e-card-transition e-demo-four-list text-center e-demo-list-wrap e-active" : "e-card-transition e-demo-four-list text-center e-demo-list-wrap"} onClick={()=>this.setList(3)}>
                      <img className="mr-2 w-100" src={Img5} alt="Demo slider image"/>
                      <p className="mb-0">Four items list-2</p>
                    </div>
                  </div>  
                </div>
              </div>

              {
                this.state.listType ?
                  <div>
                    <div className="mt-4">
                      <h6>Upload content</h6>
                    </div>
                    <div className="row">
                      {
                        this.state.images.map((image, i)=>(
                          <div className="col-lg-3">
                            <div className="e-subcategory-content text-center">
                              {
                                this.state.imageLoader[i] ?
                                  <div className="e-upload-loader"></div>
                                :
                                  <div>
                                    {
                                      image.length ?
                                        <div className="e-img-upload-content e-img-edit">
                                          <span className="e-close" onClick={() => this.removeImage(i)}></span>
                                          <img src={image} alt="banner"/>
                                        </div>
                                      :
                                        <span className="e-upload-btn mb-3" onClick={()=>$(`#cat-list-image-uploader-${i}`).trigger("click")}>Upload image</span>
                                    }
                                  </div>

                              }
                              <SelectBox options={this.props.data? this.props.data: this.state.subcategoryOptions} selectchange={(val)=>{let temp = this.state.label; temp[i] = val; this.setState({label:temp})}} placeholder="Category"/>
                            </div>

                            <ImageUploader id={`cat-list-image-uploader-${i}`} accept={".jpg,.png,.pdf"} index={i} tempKey={`list-image-${i}`} uploadSuccess={(tempUrl, tempData) => this.setImage(i, tempUrl)} uploadStart={() => this.startLoading(i)}/>
                          </div>
                        ))
                      }
                    </div>

                  </div>
                :
                  null
              }
              
              

            </div>
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              {
                ((this.state.listType===1 && this.state.images[0] && this.state.images[1] && this.state.images[2] && this.state.label[0] && this.state.label[1] && this.state.label[2])||
                ((this.state.listType===2 || this.state.listType===3) && this.state.images[0] && this.state.images[1] && this.state.images[2] && this.state.images[3] && this.state.label[0] && this.state.label[1] && this.state.label[2] && this.state.label[3]))?
                  <button type="submit" className="e-s-btn" value="Submit" data-dismiss="modal" onClick={this.submitClick.bind(this)}>Submit</button>
                :
                  <button type="submit" className="e-s-btn e-inactive" value="Submit" >Submit</button>
              }
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewListSelectionModal;
