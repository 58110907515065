
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

/*Components*/
import DetailInvoice from '../../../components/modals/invoiceDetailModal';
import SelectBox from '../../../components/formComponents/selectBox';
import DateRange from '../../../components/common/dateRangePicker';
import ViewAdContentModal from '../../../components/modals/viewAdContentModal';
import APILoader from '../../../components/other/api-loader';
import NoDataFound from '../../../components/other/nothing';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';

/*Assets*/




class ScheduledAds extends Component {
  constructor(props){
    super(props);
    this.state = {
      category: null,
      dateFilter: false,
      initalDate: moment().format(),
      startDate: moment().startOf('month'),
      toDate: moment().format("DD-MM-YYYY"),
      fromDate: moment().startOf('month').format("DD-MM-YYYY"),
      endDate: moment(),
      data: [],
      pageNum: 1,
      adContent: [],
      status: {value: undefined},
      adLocation: {value: undefined},
      adLocations: []
    }
  }

  componentDidMount() {
    this.getData();
    this.getAdLocations();
  }

  statusChange = async (status) => {
    await this.setState({data: [], status: status});
    this.getData()
  }

  adLocationChange = async (adLocation) => {
    await this.setState({data: [], adLocation: adLocation});
    this.getData()
  }

  getAdLocations(){
    var url = devconfig.adminV1+ '/ads/location';
    // var data = JSON.stringify({type: 2});
    APIGet(url).then(function(response){
      if(response.status == "ok"){
        console.log(response.data);
        var data = response.data;
        var adLocations = []
        for (var i = data.length - 1; i >= 0; i--) {
          adLocations.push({value: data[i].id, label: data[i].ads_location})
        }
        this.setState({ adLocations: adLocations});
      }
    }.bind(this))
  }

  onDatesChange = async ({ startDate, endDate }) => {
    console.log(startDate, endDate)
    if(startDate && endDate){
      var fromDate = startDate.format("DD-MM-YYYY");
      var toDate = endDate.format("DD-MM-YYYY");

      await this.setState({ 
        startDate, 
        endDate,
        fromDate: fromDate, 
        toDate: toDate, 
        data: [],
        pageNum: 1,
        loading: true,
        dateFilter: true
      });
      
      await this.getData();
    }
  }

  getData(){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1+"/ads/list";
    var data = JSON.stringify({
      "page_num" : this.state.pageNum,
      "status": this.state.status.value,
      "ads_location_id": this.state.adLocation.value,
      "start_date": this.state.fromDate,
      "end_date": this.state.toDate
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        if(response.data.length <= 39){
          this.setState({ pageNumActive: false});
        }
        else{
          this.setState({ pageNumActive: true});
        }

        if(this.state.data){
          var tempData = [...this.state.data, ...response.data];
          // tempData.push(response.data);
          this.setState({ data: tempData, apiLoading: false, pageNumLoader: false});
        }
        else{
          this.setState({ data: response.data, apiLoading: false, pageNumLoader: false});
        }
        
      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }

  viewAdContent(ad){
    this.setState({adContent: ad.content? ad.content : []})
  }

  render() {
    
    const filterBy = [
      { value: undefined, label: 'All' },
      { value: 1, label: 'Active' },
      { value: 2, label: 'Ongoing' },
      { value: 3, label: 'Completed' },
    ]

    const filterByAds = this.state.adLocations;


    return (
      <div className="e-main-content e-vendor-table e-scheduled-ads-wrap">
        <h2 className="e-page-head">Scheduled Ad's</h2>

        <div className="row">
          <div className="col-lg-3">
            <h6 cl>Filter by</h6>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-3">
            <DateRange onDatesChange={this.onDatesChange}/>
          </div>
          <div className="col-lg-2">
            <SelectBox defaultValue={this.state.filterDefault} options={filterBy} selectchange={this.statusChange} placeholder="Filter by status"/>
          </div>
          <div className="col-lg-2">
            <SelectBox defaultValue={this.state.filterDefault} options={filterByAds} selectchange={this.adLocationChange} placeholder="Ad's type"/>
          </div>
        </div>
        
        <div className="row">
        {
          this.state.apiLoading? <APILoader />
          : 
            this.state.data.length ?
              this.state.data.map((ad, index)=>{
                return(
                  <div className="col-lg-6 mb-4">
                    <div className="e-vendor-category-list">
                      <div className="row">
                        <div className="col-lg-6">
                          <h6>Ad Type:</h6>
                          <p>{ad.ads_location}</p>
                        </div>
                        <div className="col-lg-6">
                          <h6>Vendor:</h6>
                          <Link to={"/vendor-detail?id="+ad.vendor_id}>{ad.vendor_name}</Link>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <h6>Company:</h6>
                          <p>{ad.company}</p>
                        </div>
                        <div className="col-lg-6">
                          <h6>Status:</h6>
                          <p className="text-success">{ad.status}</p>
                          <span className="e-view-add-content-text" data-toggle="modal" onClick={this.viewAdContent.bind(this, ad)} data-target="#adDetailModal">View content</span>
                        </div>
                      </div>

                      <div className="row e-scheduled-ads-slots-wrap pt-3">
                        <div className="col-lg-12 mb-3">
                          <h5>Ad Schedules:</h5>
                        </div>
                        {
                          ad.schedules ?
                            ad.schedules.map((schedule, index3)=>{
                              return(
                                <div className="col-lg-12 mb-4">
                                  <p className="mb-0">{schedule.date}</p>
                                  <div className="e-scheduled-ads-slots">
                                  {
                                    schedule.slot_data.length!=24 ?
                                      schedule.slot_data.map((slot, index2)=>{
                                        return(
                                          <span>{slot.slot}</span>
                                        )
                                      })
                                    :
                                      null
                                  }
                                  </div>
                                </div>
                              )
                            })
                          :
                            <div className="col-lg-12 mb-4">
                              <p className="mb-1">{ad.booking_date}</p>
                              <div className="e-scheduled-ads-slots">
                              {
                                ad.slot_data.length!=24 ?
                                  ad.slot_data.map((slot, index2)=>{
                                    return(
                                      <span>{slot.slot}</span>
                                    )
                                  })
                                : null
                              }
                              </div>
                            </div>
                        }
                        
                      </div>
                    </div>
                  </div>
                )
              })
            :
              <NoDataFound head="No data found"/>
        }
        </div>

      {/*Modals*/}
      <ViewAdContentModal data={this.state.adContent} />



      </div>
      
    );
  }
}

export default ScheduledAds;
