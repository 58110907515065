
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import NothingFound from '../../../components/other/nothing';
import APILoader from '../../../components/other/api-loader';
import AddLocationAdmin from '../../../components/modals/addLocationAdminModal';
import ActiveAdmin from './active-admin-list';
import BlockedAdmin from './blocekd-list';
import Toast from '../../../components/common/toast';
import PendingAdmin from './pending-admin-list';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
/*Assets*/


class LocationAdminList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 1,
      activeData: [],
      data: null,
      activeLoading: false,
      error: "",
      modalOn: false
    }
  }

  componentDidMount() {
    this.getActiveData();
  }

  getActiveData() {
    this.setState({ activeLoading: true, error: "" });
    let url = devconfig.localAdmin + "/locationadmin/active-list";
    let data = JSON.stringify({
      "location_id": null
    });
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ activeData: response.data, activeLoading: false });
      }
      else {
        this.setState({ activeLoading: false, error: response.message });
      }
    }.bind(this));
  }


  triggerToast(temp, type) {
    this.setState({ toast: temp, toastType: type })
    setTimeout(function () { this.setState({ toast: null }); }.bind(this), 4000);
  }

  // async getLocations(){
  //   // const url = "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=w&location=12.976750,77.575279&radius=50000&type=(regions)&key=AIzaSyC18Txpu7uQPawM25nnOu_k8M9eEw6ly_s";
  //   const url = "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=w&location=12.976750,77.575279&radius=50000&type=(regions)&key=AIzaSyCTyoFWYEaMSYMzSo2U_FYdpY9PiNSDfFs"
  //   const response = fetch(url)
  //   if (response.ok) {
  //     const test = await response.json();
  //     console.log("test");
  //   } else {
  //     console.log("error");
  //   }

  // }

  tabItemClick(tempItem) {
    this.setState({ tabIndex: tempItem });
  }

  render() {

    return (
      <>
        <div className="e-main-content e-vendor-table">
          <h2 className="e-page-head">Manage Location Admins</h2>
          <div className="row mb-3">
            <div className="col-lg-6 e-vendor-tab-link-wrap pt-4">

              {
                this.state.tabIndex === 1 ?
                  <span className="e-active">Active Admin</span>
                  :
                  <span onClick={this.tabItemClick.bind(this, 1)}>Active Admin</span>
              }

              {
                this.state.tabIndex === 2 ?
                  <span className="e-active">Blocked Admin</span>
                  :
                  <span onClick={this.tabItemClick.bind(this, 2)}>Blocked Admin</span>
              }

              {
                this.state.tabIndex === 3 ?
                  <span className="e-active">Pending Admin Invitations</span>
                  :
                  <span onClick={this.tabItemClick.bind(this, 3)}>Pending Admin Invitations</span>
              }
            </div>
            <div className="col-lg-6 text-right">
              <button className="e-s-btn" data-toggle="modal" data-target="#addAdminModal" onClick={() => this.setState({ modalOn: true })}>Add New Location & Admin</button>
            </div>
          </div>

          <div className="row pt-3">
            {
              this.state.tabIndex === 1 ?
                <div className="col-lg-12">
                  {
                    this.state.activeLoading ?
                      <div className="container pt-5 mt-5 text-center">
                        <APILoader />
                      </div>
                      :
                      <ActiveAdmin data={this.state.activeData} toast={(message, type) => { this.triggerToast(message, type); if (type == "success") { this.getActiveData(); } }} success={() => { this.triggerToast("Location Admin Updated successfully", "success"); this.getActiveData(); }} />
                  }
                </div>
                :
                null
            }
            {
              this.state.tabIndex === 2 ?
                <div className="col-lg-12">
                  <BlockedAdmin/>
                </div>
                :
                null
            }
            {
              this.state.tabIndex === 3 ?
                <div className="col-lg-12">
                  <PendingAdmin />
                </div>
                :
                null
            }
          </div>
        </div>

        {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType} />
            :
            null
        }

        <AddLocationAdmin modalOn={this.state.modalOn} modalClose={() => this.setState({ modalOn: false })} success={() => { this.triggerToast("Location Admin invited successfully", "success"); this.getActiveData(); }} />
      </>

    );
  }
}

export default LocationAdminList;
