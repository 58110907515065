/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
// import Inputs from '../../components/formComponents/inputs.js';
import SelectBox from '../../components/formComponents/selectBox';
import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/textarea';



/*Assets*/
import sampleBanner from '../../assets/images/sample-banner.jpg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';


class AddMenu extends Component {
  constructor(props){
    super(props);
    this.state = {
      category: "",
      apiLoading: false,
      selected: null
    }
  }

  async componentDidMount() {



    this.getData();

    if(this.props.data){
      var tempArry = [];
      this.props.data.map(function(item, index){
        var tempItem = {value: item.id, label: "Other: "+item.name}
        tempArry.push(tempItem);
      });
      await this.setState({selected: tempArry});
    }
    else{
      await this.setState({selected: []});
    }
  }

  getData(){
    var url = devconfig.admin+ '/local/page/list';
    if(this.props.online) url = devconfig.admin+ "/online/page/list"
    APIGet(url).then(async function(response){
      if(response.status == "ok"){
        var tempArry = [];

        var tempArry2 = [];
        if(!this.props.online){
          response.data.category_data.map(function(item, index){
            var tempItem = {value: item.id, label:"Department: "+item.name}
            let tempItem2 = {value: item.wholesale_data.id, label: item.wholesale_data.name}
            let superValueStore = {value: item.super_value_store_data.id, label: item.super_value_store_data.name}
            tempArry.push(tempItem);
            tempArry.push(tempItem2);
            tempArry.push(superValueStore);
  
            item.categorydata.map(function(item, index){
              var tempItem = {value: item.id, label:"Category: "+item.name}
              tempArry.push(tempItem);

              item.subcategory_data.map(function(item, index){
                var tempItem = {value: item.id, label:"Subcategory: "+item.name}
                tempArry.push(tempItem);
              });
            });
          });
          response.data.custom_page_layouts.map(item=>{
              var tempItem = {value: item.id, label:"Custom: "+item.name}
              tempArry.push(tempItem);
          })
        }
        else{
          response.data.category_data.map(function(item, index){
            var tempItem = {value: item.id, label:"Category: "+item.name}
            tempArry.push(tempItem);
  
            item.subcategory_data.map(function(item, index){
              var tempItem = {value: item.id, label:"Subcategory: "+item.name}
              tempArry.push(tempItem);
            });
  
          });
        }
        

        var tempArry1 = [];
        response.data.home_data.map(function(item, index){
          var tempItem = {value: item.id, label: "Other: "+item.name}
          tempArry.push(tempItem);
        });

        await this.setState({option: tempArry});
      }
    }.bind(this))
  }
   
  createMenu(e){
    e.preventDefault();

    var tempArry = [];
    this.state.selected.map(function(item, index){
      tempArry.push(item.value);
    });


   
    this.setState({apiLoading: true});
    var url = devconfig.admin+ '/local/topmenu/update';
    if(this.props.online) url = devconfig.admin + "/online/topmenu/update"
    var data = JSON.stringify({
      "main_page_id" : this.props.id,
      "sub_page_ids" : tempArry
    })
    APIPost(url,data).then(function(response){
      if(response.status == "ok"){
        window.location.reload();
      }
    }.bind(this))
  }


  render() {

     const categoryBy = [
      { value: 1, label: 'Last 1 week' },
      { value: 2, label: 'Last 1 month' },
      { value: 3, label: 'Last 3 months' },
    ]
    return (
      <div className="modal fade e-ad-detail-wrap e-ofer-modal e-menu-add-wrap" id="addMenuModal" tabIndex="-1" role="dialog" aria-labelledby="addMenuModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content e-career-modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h6 className="e-modal-header e-add-career-heading">Add Menu Item</h6>
              </div>
            </div>
             <div className="row mt-3">
                <div className="col-lg-12">
                </div>
                <div className="col-lg-12" style={{zIndex: 2}}>
                  {
                    this.state.selected ?
                      <SelectBox options={this.state.option} defaultValue={this.state.selected} isSearchable={true} selectType={true} selectchange={(tempVal) => {
                        this.setState({selected: tempVal}) 
                      }} placeholder="Select Category"/>
                    :
                     null
                  }
                  
                </div>
              </div>
            <div className="row">
              <div className="col-lg-9">
                <a href="#" className="float-right pt-4 e-ofer-cancel" data-dismiss="modal">Cancel</a>
              </div>
              {
                this.state.selected && !this.state.apiLoading ?
                  <button className="e-s-btn e-new-ofer float-right mt-3 ml-5" type="submit" value="Submit" onClick={this.createMenu.bind(this)}>Update</button>
                :
                  <button className="e-s-btn e-inactive e-new-ofer float-right mt-3 ml-5" type="submit" value="Submit">{this.state.apiLoading ? "Loading.." : "Update"}</button>
              }

            </div>

            <div className="modal-footer">


            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default AddMenu;
