
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import NothingFound from '../../../components/other/nothing';
import APILoader from '../../../components/other/api-loader';
import SelectBox from '../../../components/formComponents/selectBox';
/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';

/*Assets*/


class ActiveVendor extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: false,
      pageNum: 1,
      data: [],
      pageNumActive: false,
      pageNumLoader: false,
      locationAdmin: false,
      vendorLocation: null,
      locationOptions: [],
    }
  }

  componentDidMount() {
    if(localStorage.getItem("truekhoj_locationadmin")){
      this.setState({locationAdmin: true});
    }
    this.getLocations();
    this.getData(this.state.pageNum);
  }

  getData(tempPageNum){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1+"/vendor/list";
    if(localStorage.getItem("truekhoj_locationadmin")){
      url = devconfig.locationAdmin+"/vendor/list";
    }
    var data = JSON.stringify({
      "page_num" : tempPageNum,
      "online" : this.props.online
    })
    if(!this.props.online){
      var data = JSON.stringify({
        "page_num" : tempPageNum,
        "online" : this.props.online,
        "location_id": this.state.vendorLocation? this.state.vendorLocation.value : null
      })
    }
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({ data: response.data,  baseData: [...response.data], apiLoading: false, pageNumLoader: false});
        // if(response.data.length <= 39){
        //   this.setState({ pageNumActive: false});
        // }
        // else{
        //   this.setState({ pageNumActive: true});
        // }


        // if(this.state.data.length && response.data.length){
        //   var tempData = this.state.data;
        //   response.data.forEach(vendor=>{
        //     tempData.push(vendor);
        //   })
        //   // tempData.push(response.data);
        //   this.setState({ data: tempData,  baseData: [...response.data], apiLoading: false, pageNumLoader: false});
        // }
        // else{
        //   this.setState({ data: response.data,  baseData: [...response.data], apiLoading: false, pageNumLoader: false});
        // }
      }
      else{
        this.setState({ apiLoading: false, data: []});
      }
    }.bind(this))
  }

  getLocations(){
    let url = devconfig.adminV1+"/location/list";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        let locationOptions = [{label: "All", value: null}];
        response.data.forEach(item=>{
          locationOptions.push({value: item.id, label: item.name});
        });
        this.setState({locationOptions});
      }
    }.bind(this))
  }

  tabItemClick(tempItem){
    this.setState({tabIndex: tempItem});
  }  

  loadMore(){
    this.getData(this.state.pageNum + 1);
    this.setState({pageNum: this.state.pageNum + 1, pageNumLoader: true});
  }

  keyDownHandler(e) {
    if (e.keyCode == 13) this.submitSearch();
  }

  submitSearch() {
    this.setState({ apiLoading: true });
    if (this.state.query && this.state.baseData && this.state.baseData.length) {
      let query = this.state.query.toLowerCase();
      let base = this.state.baseData;
      let out = [];
      base.forEach(item => {
        if (item.name && item.name.toLowerCase().includes(query)) out.push(item);
      });
      this.setState({ data: out });
    }
    else if (this.state.baseData && this.state.baseData.length) {
      let out = [...this.state.baseData];
      this.setState({ data: out });
    }
    this.setState({ apiLoading: false });
  }

  render() {
    
      return (
        <div className="container-fluid p-0">
          {!this.props.online? <div className="row pb-4 pt-3 e-vendor-filters">
            <div className="col-lg-3">
            <h6 className="e-career-header">Filter by</h6>
              <SelectBox
              options={this.state.locationOptions}
              placeholder="Location"
              value={this.state.vendorLocation}
              selectchange={async (val) => this.setState({vendorLocation: val}, ()=>this.getData(1))}/>
            </div>
          </div>: 
          <div className="row mb-4 pt-3">
          <div className="col-lg-4 col-md-6">
            <div className="e-search-wrap e-category-search w-100 position-relative">
              <input className="e-search-input w-100" placeholder="Search with vendor name" value={this.state.query} type="text" onChange={(e) => this.setState({ query: e.target.value })} onKeyDown={this.keyDownHandler.bind(this)} required />
              {
                this.state.query && this.state.query.length ?
                  <div className="e-search-btn position-absolute" onClick={this.submitSearch.bind(this)}></div>
                  :
                  null
              }
            </div>
          </div>
          </div>
          }
          {
            this.state.apiLoading?
            <div>
              <APILoader/>
            </div>
            : this.state.data && this.state.data.length?
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-lg-12">
                  <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Company</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Location</th>
                      <th>Seller type</th>
                      <th></th>
                      {/*<th>Total Amount</th>
                      <th>Pending Payment</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.data.map((item, index) => {
                        return(
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.company}</td>
                            <td>{item.mobile}</td>
                            <td>{item.email}</td>
                            <td>{item.location}</td>
                            <td>{item.seller_type}</td>
                            <td><Link to={`/vendor-detail?id=${item.id}&local=${!this.props.online}`}>View detail</Link></td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                {
                  this.state.pageNumActive ?
                    <div className="text-center">
                      {
                        this.state.pageNumLoader ?
                          <APILoader/>
                        :
                          <button className="e-s-btn-2" onClick={this.loadMore.bind(this)}>Load More</button>
                      }
                    </div>
                  :
                    null
                }
                  </div>
                </div>
              </div>
            :
              <NothingFound data="No vendor details found"/>
          }
          
        </div>
      );
    
  }
}

export default ActiveVendor;
