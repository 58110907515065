/*Package importing*/
import React, { Component } from 'react';
import Chart from "react-apexcharts";

/*Import custom components and assets*/

class LineChart extends Component {
  constructor(props){
    super(props);
    this.state = {
      series: [{ name: 'Revenue',  data: [40, 5500, 4100, 6400], type: 'area', }],
      options: {
        stroke: {curve: 'smooth'},
        legend: {fontSize: '13px', fontFamily: 'Nunito', fontWeight: "20px"},
        chart: { type: 'area', height: 730},
        plotOptions: { bar: { horizontal: false, dataLabels: {position: 'top'}} },
        dataLabels: { enabled: false, offsetX: -6, style: { fontSize: '12px', colors: ['#fff'] } },
        stroke: { show: true, width: 2, colors: ['#002A5C'] },
        markers: {
          size: 0
        },
        labels: ["Week 1","Week 2","Week 3","Week 4"],
        fill: {
          colors: ['#FFB606'],
          type:'solid',
          opacity: [0.4, 1],
        },
        tooltip: {
          shared: true,
          colors: ['#FFB606'],
        }
      },
    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <Chart options={this.state.options} series={this.state.series} type="bar" height="400px" width="100%"/>
    );
  }
}

export default LineChart;
