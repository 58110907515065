/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../components/formComponents/input';
import LocationAdmin from './locationAdmin';

/*Config*/
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url-var';
import APIPost from '../../services/apiCall/post';

/*Assets*/
import logo from '../../assets/images/logo.svg';
import loginHero from '../../assets/images/login-hero.svg';


class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      username: "",
      password: "",
      errorMessage: "",
      apiLoading: false,
      locationAdmin: false,
      signup: false,
      key: "",
      email: ""
    }
  }

  componentDidMount(){
    GetURLVar().then(function(query){
      if(query.type && query.type=="1" && query.key){
        this.setState({locationAdmin: true, signup: true, key: query.key, email: query.email});
      }
      if(query.type && query.type=="2" && query.key){
        this.setState({locationAdmin: true, signup: true, key: query.key, email: query.email, reset: true});
      }
    }.bind(this))
  }

  buttonClick(e){
    e.preventDefault();

    this.setState({apiLoading: true});
    if(!this.state.resetPassword){
      var tempEncodeData = this.state.username.concat(":").concat(this.state.password);
    var encodedData = btoa(tempEncodeData);
    $.ajax({
      type: "GET",
      url: devconfig.localAdmin + '/auth/login',
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Basic " + encodedData,
      }
    })
    .then(function (response) {
      if(response.status === "ok"){
        localStorage.setItem("trueKhoj_data", JSON.stringify(response.data));
        localStorage.setItem("trueKhoj_token", response.access_token);
        localStorage.setItem("truekhoj_admin", true);
        if(response.data.type &&  response.data.type===2){
          window.location.href = '/local-layouts';
        }
        else{
          window.location.href = '/online-layouts';
        }
      }
    }.bind(this))
    .catch(function(response){ 
      this.setState({errorMessage: response.responseJSON.message, apiLoading: false});
    }.bind(this))
    }
    else{
      var url = devconfig.localAdmin + '/auth/forgot-password';
            let data = JSON.stringify({
                "email": this.state.username
            })
            APIPost(url, data).then(async function(response){
                if(response.status=="ok"){
                    this.setState({resetPassword: false});
                }
            }.bind(this))
    } 
  }

  render() {
    return (
      <section className="e-login-section">
        <div className="container-fluid">
          <div className="row e-login-logo-wrap">
            <div className="col-lg-12">
              <img src={logo} alt="AliceBlue Tradeschool logo"/>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5 pt-5">
            <div className="offset-lg-2 col-lg-2  e-login-img">
              <img src={loginHero} alt="Login hero"/>
            </div>
            <div className="col-lg-6 e-login-wrap">
              <h2>{this.state.reset? "Reset Password" :"Login to continue"}</h2>

              {
                this.state.locationAdmin?
                <LocationAdmin admin={()=>this.setState({locationAdmin: false})} signup={this.state.signup} signupData={[this.state.email, this.state.key]} login={()=>this.setState({signup: false, locationAdmin: false, reset: false})} reset={this.state.reset}/>
                :
                <form id="e-login-form">
                <div className="pt-4">
                  <Input label="Username" id="u-name" type="email" defaultValue={this.state.username} valueChange={(tempVal) => this.setState({username: tempVal, errorMessage: ""})} />
                  {
                    !this.state.resetPassword?
                    <Input label="Password" id="u-name" type="password" defaultValue={this.state.password} valueChange={(tempVal) => this.setState({password: tempVal, errorMessage: ""})} />
                    :
                    null
                  }
                </div>
                <span className="text-danger">{this.state.errorMessage}</span>
                <div className="d-flex pt-3 justify-content-end align-items-center">
                  <a className="pt-1 e-login-admin" onClick={()=>this.setState({resetPassword: !this.state.resetPassword, errorMessage: ""})}>{this.state.resetPassword?  "Back to Login":"Forgot Password?"}</a>
                  {
                    this.state.username.length && (this.state.password || this.state.resetPassword) && !this.state.apiLoading ?
                      <button className="e-s-btn ml-4" type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>{this.state.resetPassword?  "Reset Password":"Login"}</button>
                    :
                      <button className="e-inactive e-s-btn ml-4" type="submit" form="e-login-form" value="Submit" >{this.state.resetPassword?  "Reset Password":"Login"}</button>
                  }
                </div>
              </form>
               }
            </div>
          </div>
        </div>
      </section>
      
    );
  }
}

export default Login;
