/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import SectionHeadingStyle from '../elementsPreview/sectionHeadingStyle';
import SelectBox from '../formComponents/selectBox';
import ColorPicker from '../common/colorPicker';
import Input from '../../components/formComponents/input';

/*Assets*/

class HeadingSelectionModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      colorOne: "",
      colorTwo: "",
      pickerOneID: "colorPick1",
      pickerTwoID: "colorPick2",
      category: null,
      styleType: null,
      categoryOptions: [],
      textHeading: ""
    }
  }


  componentDidMount() {
    console.log("this.props.data");
    console.log(this.props.data);

    
    if(this.props.data){
      this.setState({categoryOptions: this.props.data});
    }
  }

  selectheading(tempType, tempClass){
    $(".e-demo-heading-wrap").removeClass("e-active");
    $(tempClass).addClass("e-active");

    this.setState({styleType: tempType});
  }

  pickerOneColorChange(tempColor){
    this.setState({colorOne: tempColor});
  }

  pickerTwoColorChange(tempColor){
    this.setState({colorTwo: tempColor});
  }

  triggerClick(tempItem, tempLoader){
    $(tempItem).trigger('click');
    this.setState({showUploadLoader: tempLoader});
  }

  selectChange = async (category) => {
    await this.setState({category: category});
  }

  submit(){
    var tempContent = "";
    var tempCategory= null;
    if(this.state.category){
      tempContent = this.state.category.label;
      tempCategory = this.state.category.value;
    }
    else{
      tempContent = this.state.textHeading;
    }
    var tempArry = { 
      "content": tempContent, 
      "type": this.state.styleType, 
      "font_color": this.state.colorOne, 
      "bg_color": this.state.colorTwo, 
      "category": tempCategory
    };
    this.props.success(tempArry);
    this.setState({ 
      colorOne: "",
      colorTwo: "",
      pickerOneID: "colorPick1",
      pickerTwoID: "colorPick2",
      category: null,
      styleType: null,
      textHeading: ""}, ()=>this.setState({refreshModal: false}));
  }

  render() {

    var tempHeading=[
      { "type": 1, "content": "Heading style - 1", font_color: "#000", bg_color: "#1200DE"},
      { "type": 2, "content": "Heading style - 2", font_color: "#000", bg_color: "#F9DB3F"},
      { "type": 3, "content": "Heading style - 3", font_color: "#000", bg_color: "#1CD6A9"},
      { "type": 4, "content": "Heading style - 4", font_color: "#000", bg_color: "#F64A7C"},
      { "type": 5, "content": "Heading style - 5", font_color: "#000", bg_color: "#EA4747"},
      { "type": 6, "content": "Heading style - 6", font_color: "#000", bg_color: "#ABB6DF"},
      { "type": 7, "content": "Heading style - 7", font_color: "#000", bg_color: "#F64A7C"},
      { "type": 8, "content": "Heading style - 8", font_color: "#000", bg_color: "#20B7EC"},
      { "type": 9, "content": "Heading style - 9", font_color: "#000", bg_color: "#FF9B21"},
      { "type": 10, "content": "Heading style - 10", font_color: "#000", bg_color: "#5FE70E"},
      { "type": 11, "content": "Heading style - 11", font_color: "#000", bg_color: "#1200DE"},
      { "type": 12, "content": "Heading style - 12", font_color: "#000", bg_color: "#F0CA42"},
      { "type": 13, "content": "Heading style - 13", font_color: "#000", bg_color: "#FF9CBE"},
      { "type": 14, "content": "Heading style - 14", font_color: "#000", bg_color: "#EBF4B5"},
      { "type": 15, "content": "Heading style - 15", font_color: "#000", bg_color: "#D9D9D9"},
      { "type": 16, "content": "Heading style - 16", font_color: "#000", bg_color: "#FF9B21"},
      { "type": 17, "content": "Heading style - 17", font_color: "#000", bg_color: "#E9CA2B"},
      { "type": 18, "content": "Heading style - 18", font_color: "#000", bg_color: "#D53578"},
      { "type": 19, "content": "Heading style - 19", font_color: "#000", bg_color: "#C38448"},
      { "type": 20, "content": "Heading style - 20", font_color: "#000", bg_color: "#E9CA2B"},

      { "type": 21, "content": "Heading style - 21", font_color: "#000", bg_color: "#A78210"},
      { "type": 22, "content": "Heading style - 22", font_color: "#000", bg_color: "#FF4A31"},
      { "type": 23, "content": "Heading style - 23", font_color: "#000", bg_color: "#F64A7C"},
      { "type": 24, "content": "Heading style - 24", font_color: "#000", bg_color: "#1CD6A9"},
      { "type": 25, "content": "Heading style - 25", font_color: "#000", bg_color: "#530EDF"},
      { "type": 26, "content": "Heading style - 26", font_color: "#000", bg_color: "#296240"},
      { "type": 27, "content": "Heading style - 27", font_color: "#000", bg_color: "#FF6031"},
      { "type": 28, "content": "Heading style - 28", font_color: "#000", bg_color: "#FFB8B8"},
      { "type": 29, "content": "Heading style - 29", font_color: "#000", bg_color: "#2E23AB"},
      { "type": 30, "content": "Heading style - 30", font_color: "#000", bg_color: "#20B7EC"},
    ]

    const filterBy = [
      { value: 1, label: 'Clothing' },
      { value: 2, label: 'Fashion' },
      { value: 3, label: 'Beauty & personal care' },
      { value: 4, label: 'Wedding special' },
      { value: 5, label: 'Footwear' },
      { value: 6, label: 'Jewellery' },
      { value: 7, label: 'Electronics' },
      { value: 8, label: 'Travel' },
      { value: 9, label: 'Food & beverages' },
      { value: 10, label: 'Automobiles' },
      { value: 11, label: 'Mobiles & laptops' },
      { value: 12, label: 'Luxury shopping' },
    ]


    var tempIDSelectOne = "#"+this.state.pickerOneID;
    var tempIDSelectTwo = "#"+this.state.pickerTwoID;
    var tempColorOne = { background: this.state.colorOne };
    var tempColorTwo = { background: this.state.colorTwo };


    return (
      <div className="modal fade" id="headerModal" tabIndex="-1" role="dialog" aria-labelledby="headerModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          {
            !this.state.refreshModal?
            <div className="modal-content">
            {/*<div className="modal-header">
              <h5 className="modal-title">Choose header style & content</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>*/}
            <div className="modal-body mt-3">
              <div>
                <h6>Choose the heading style</h6>
              </div>
              <div className="e-demo-heading-continer">
              { 
                tempHeading.map( (item, index) => {
                  var tempWrapClass = "mb-3 e-demo-heading-wrap e-card-transition e-heading-type-"+item.type;
                  var tempUniqClass = ".e-heading-type-"+item.type;
                  return( 
                    <div key={index} className={tempWrapClass} onClick={this.selectheading.bind(this, item.type, tempUniqClass)}>
                      <SectionHeadingStyle data={item}/>
                    </div>
                  );
                })
              }
              </div>

              <div className="mt-4">
                <h6>Choose the category</h6>
              </div>
              <div>
                {
                  this.props.type && this.props.type != 3 ?
                    <div className="e-filter-wrap">
                      <SelectBox defaultValue={this.state.filterDefault} options={this.state.categoryOptions} selectchange={this.selectChange} placeholder="Select Category"/>
                    </div>
                  :
                    null

                }
                
                {
                  this.props.type && this.props.type == 2 ?
                    <div className="mt-3">
                      <h6 className="text-center">OR</h6>

                      <div>
                        <Input label="Add text heading" id="e-txt-heading" type="text" defaultValue={this.state.textHeading} valueChange={(tempVal) => this.setState({textHeading: tempVal})} />
                      </div>
                    </div>
                  :
                    null
                }

                {
                  this.props.type && this.props.type == 3 ?
                    <div className="mt-3">
                      <div>
                        <Input label="Add text heading" id="e-txt-heading" type="text" defaultValue={this.state.textHeading} valueChange={(tempVal) => this.setState({textHeading: tempVal})} />
                      </div>
                    </div>
                  :
                    null
                }
                
              </div>

              <div className="mt-4">
                <h6>Choose the colors</h6>
              </div>
              <div className="row e-upload-wrap">
                <div className="col-lg-4">
                  <div className="e-color-picker-wrap e-card-transition" onClick={this.triggerClick.bind(this, tempIDSelectOne)}>
                    {
                      this.state.colorOne.length ?
                        <span className="e-color-preview-wrap">
                          <span className="e-color-preview" style={tempColorOne}></span><br/>
                          {this.state.colorOne}
                        </span>
                      :
                        <span>Choose heading font color</span>
                    }
                    <ColorPicker id={this.state.pickerOneID} colorChange={this.pickerOneColorChange.bind(this)}/>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="e-color-picker-wrap e-card-transition" onClick={this.triggerClick.bind(this, tempIDSelectTwo)}>
                    {
                      
                      this.state.colorTwo.length ?
                        <span className="e-color-preview-wrap">
                          <span className="e-color-preview" style={tempColorTwo}></span><br/>
                          {this.state.colorTwo}
                        </span>
                      :
                        <span>Choose heading bg color</span>
                    }
                    <ColorPicker id={this.state.pickerTwoID} colorChange={this.pickerTwoColorChange.bind(this)}/>
                  </div>
                </div>
              </div>
              

            </div>
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              {
                (this.state.category || this.state.textHeading.length ) && this.state.styleType && this.state.colorOne.length && this.state.colorTwo.length  ?
                  <button type="submit" className="e-s-btn" value="Submit" data-dismiss="modal" onClick={()=>this.setState({refreshModal: true},()=>this.submit())}>Submit</button>
                :
                  <button type="submit" className="e-s-btn e-inactive" value="Submit" >Submit</button>
              }
              
            </div>
          </div>
          :
          null
          }
        </div>
      </div>
    );
  }
}

export default HeadingSelectionModal;
