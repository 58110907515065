
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*Components*/
import HeadingSelectionModal from '../../../components/modals/headingSelectionModal';
import ListSelectionModal from '../../../components/modals/listSelectionModal';
import EditHeadingModal from '../../../components/modals/editHeadingModal';

import SectionHeadingStyle from '../../../components/elementsPreview/sectionHeadingStyle';
import Input from '../../../components/formComponents/input';
import CardFourColumn from '../../../components/cards/column-4.js';
import CardSixColumn from '../../../components/cards/column-6.js';
import UploadDummyAdsModal from '../../../components/modals/uploadDummyAdsModal';
import UploadDummyCatBannerModal from '../../../components/modals/uploadDummyCategorybanner';
import AddMenu from '../../../components/modals/addMenu';
import AddBg from '../../../components/modals/addBg';
import VideoPlayer from '../../../components/common/video';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';
import CardTwoColumn from '../../../components/cards/column-2';
import CardThreeColumn from '../../../components/cards/column-3';

/*Assets*/


class OnlineDealsPagesLayout extends Component {
  notify = e => toast(e);
  constructor(props) {
    super(props);
    this.state = {
      layout: [],
      ads: {
        hero: { id: 1, page_id: 1, data: [{ content: null, link: null }] },
        logo_slider: {
          id: 1, page_id: 1, data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
          ]
        },
        deals_banner: { id: 1, page_id: 1, data: [
          { content: null, link: null },
          { content: null, link: null },
          { content: null, link: null },
          { content: null, link: null },
         ] },
        deals: {
          header: "Live deals",
          id: 1,
          page_id: 1,
          banner: [{ content: null, link: null }],
          data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
          ]
        },
        ad_section_1: {
          header: "Hot & Trending Deals",
          id: 1,
          page_id: 1,
          data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null }
          ]
        },
        ad_section_2: {
          header: "Best Deals",
          id: 1,
          page_id: 1,
          data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null }
          ]
        },
        ad_section_3: {
          header: "Top Deals",
          id: 1,
          page_id: 1,
          data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
          ]
        },
        ad_section_4: {
          header: "Other Deals",
          id: 1,
          page_id: 1,
          data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null }
          ]
        }


      },
      explore_more:{
        "header": "Explore More",
        "data": { "content": null, "image": null, "stlye": null },
        "page_id": 1,
      },
      id: null,
      selectItemIndex: null,
      categoryData: null,
      selectedSubCategories: null,
      data: null,
      name: null,
      adDummyType: null,
      swapIndex: "",
      catBannerAdIndex: null,
      categoryId: null,
      sectionHeadingEditSelect: null,
      adIndex: 0,
      menu: null,
      menuApiLoading: true
    }
  }

  componentDidMount() {
    this.getCategoryData();
    GetURLVar().then(function (query) {
      if (query) {
        this.getData(query.id);
        this.getMenu(query.id);
        this.setState({ id: query.id });

        console.log("query.id cfghjbnkhgvhjhgfhjkhg");
        console.log(query.id);


        if (query.id == 52) {
          this.setState({
            page_id: 6,
            hero_id: 16,
            logo_id: 17,
            deals_banner_id: 73,
            deals_id: 18,
            ad_section_1_id: 19,
            ad_section_2_id: 20,
            ad_section_3_id: 21,
            ad_section_4_id: 22,
            brand_section_1_id: 23,
            brand_section_2_id: 24,
            brand_section_3_id: 25,
          });
        }
        else if (query.id == 53) {
          this.setState({
            page_id: 8,
            hero_id: 36,
            logo_id: 37,
            deals_banner_id: 75,
            deals_id: 38,
            ad_section_1_id: 39,
            ad_section_2_id: 40,
            ad_section_3_id: 41,
            ad_section_4_id: 42,
            brand_section_1_id: 43,
            brand_section_2_id: 44,
            brand_section_3_id: 45,
          });
        }
        else if (query.id == 54) {
          this.setState({
            page_id: 7,
            hero_id: 26,
            logo_id: 27,
            deals_banner_id: 74,
            deals_id: 28,
            ad_section_1_id: 29,
            ad_section_2_id: 30,
            ad_section_3_id: 31,
            ad_section_4_id: 32,
            brand_section_1_id: 33,
            brand_section_2_id: 34,
            brand_section_3_id: 35,
          });
        }
        else {
          this.setState({
            page_id: 9,
            hero_id: 46,
            logo_id: 47,
            deals_banner_id: 76,
            deals_id: 48,
            ad_section_1_id: 49,
            ad_section_2_id: 50,
            ad_section_3_id: 51,
            ad_section_4_id: 52,
            brand_section_1_id: 53,
            brand_section_2_id: 54,
            brand_section_3_id: 55,
          });
        }

      }
    }.bind(this))
  }

  handleAds(data) {
    let ads = data;
    if (ads.deals_banner && ads.deals_banner && ads.deals_banner.data.length == 1) ads.deals_banner.data = [...ads.deals_banner.data, { content: null, link: null }, { content: null, link: null }, { content: null, link: null }];
    if (ads.deals && ads.deals.data && ads.deals.data.length == 6) ads.deals.data.splice(2, 3);
    if (ads.ad_section_2 && ads.ad_section_2 && ads.ad_section_2.data.length == 6) ads.ad_section_2.data = [...ads.ad_section_2.data, { content: null, link: null }, { content: null, link: null }];
    if (ads.ad_section_3 && ads.ad_section_3 && ads.ad_section_3.data.length == 6) ads.ad_section_3.data.splice(3, 2);
    this.setState({ ads: ads });
  }

  getData(tempId) {
    var url = devconfig.adminV1+"/online/page/detail";
    var data = JSON.stringify({
      "layout_id": tempId
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        if (response.data.page_data.layout) {
          this.setState({ layout: response.data.page_data.layout });

          if (response.data.page_data.layout.length) {
            console.log(response.data.page_data.layout[response.data.page_data.layout.length - 1])
            var lastItem = response.data.page_data.layout[response.data.page_data.layout.length - 1];
            this.getChildCategory();
          }
        }
        if (response.data.page_data.ads) {
          this.handleAds(response.data.page_data.ads);
        }
        if(response.data.page_data.explore_more) this.setState({explore_more: response.data.page_data.explore_more})
        this.setState({ name: response.data.name });

        if (response.data.category_id) {
          this.setState({ categoryId: response.data.category_id });
          this.getSubCategoryData(response.data.category_id);
        }
      }
    }.bind(this))
  }

  getMenu(tempId) {
    this.setState({ menuApiLoading: true });
    var url = devconfig.adminV1+"/online/topmenu/list";
    var data = JSON.stringify({
      "layout_id": Number(tempId)
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        if (response.data.length) {
          this.setState({ menu: response.data[0], menuApiLoading: false });
        }
        else {
          this.setState({ menu: [], menuApiLoading: false });
        }

      }
    }.bind(this))
  }

  addSection() {
    var tempId = this.state.brand_section_1_id;
    if (this.state.layout.length == 1) {
      tempId = this.state.brand_section_2_id;
    }
    else if (this.state.layout.length == 2) {
      tempId = this.state.brand_section_3_id;
    }
    else {
      tempId = this.state.brand_section_1_id;
    }
    var tempArry = {
      "header": { "content": null, "type": null, "font_color": null, "bg_color": null, category: null },
      "ads": { "content": null, "link": null },
      "data": { "content": null, "image": null, "stlye": null },
      "page_id": this.state.page_id,
      "id": tempId
    }

    var tempLayout = this.state.layout;
    tempLayout.push(tempArry);
    this.setState({ layout: tempLayout });
  }

  removeSection(tempIndex) {
    var tempLayout = this.state.layout;
    tempLayout.splice(tempIndex, 1);
    this.setState({ layout: tempLayout, selectedSubCategories: null });
  }

  headingSelectSuccess(tempItem) {
    var tempArry = this.state.layout;
    tempArry[this.state.selectItemIndex].header = tempItem;
    this.setState({ layout: tempArry });

    if (tempItem.category) {
      this.getChildCategory(tempItem.category);
    }
    else {
      this.getChildCategory();
    }
  }

  bgColorSuccess(tempColor) {
    var tempArry = this.state.layout;
    tempArry[this.state.selectItemIndex].bg_color = tempColor;
    this.setState({ layout: tempArry });
  }

  getChildCategory(tempItem) {
    var url = devconfig.adminV2+"/product/list";
    var tempSubCat = [];
    if (tempItem) {
      tempSubCat = [tempItem];
    }

    var data = JSON.stringify({
      "sub_category_ids": tempSubCat,
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        var tempArry = [];
        response.data.map(function (item, index) {
          var tempItem = { value: item.id, label: item.product }
          tempArry.push(tempItem);
        });

        this.setState({ selectedSubCategories: tempArry, categoryData: response.data });
      }
    }.bind(this))
  }

  listSelectSuccess(tempItem) {
    if(this.state.explore){
      var tempArry = this.state.explore_more;
      tempArry.data = tempItem;
      this.setState({ explore_more: tempArry });
    }
    else{
      var tempArry = this.state.layout;
      if (tempArry[this.state.selectItemIndex].data && tempArry[this.state.selectItemIndex].data.content) {
        var temp = tempArry[this.state.selectItemIndex].data.content.concat(tempItem.content);
        tempArry[this.state.selectItemIndex].data.content = temp;
        tempArry[this.state.selectItemIndex].data.style = tempItem.style;
        tempArry[this.state.selectItemIndex].data.type = tempItem.type;
      }
      else {
        tempArry[this.state.selectItemIndex].data = tempItem;
      }

      this.setState({ layout: tempArry });
    }
  }

  selectedIndex(tempindex, explore_more) {
    this.setState({ selectItemIndex: tempindex });
    if(explore_more) this.setState({explore: true});
    else this.setState({explore: false});
  }


  sectionSwapInputChange(tempClass, e) {
    $(".e-section-swap-btn").removeClass("e-active");
    $(tempClass).addClass("e-active");
  }

  sectionSwapInputReset() {
    $(".e-section-swap-btn").removeClass("e-active");
  }

  getCategoryData() {
    var url = devconfig.adminV2+"/category/list";
    APIGet(url).then(function (response) {
      if (response.status === "ok") {
        var tempArry = [];
        response.data.map(function (item, index) {
          var tempItem = { value: item.id, label: item.category }
          tempArry.push(tempItem);
        });

        this.setState({ categoryList: tempArry, categoryData: response.data });
      }
    }.bind(this))
  }

  getSubCategoryData(tempId) {
    var url = devconfig.adminV2+"/subcategory/list";
    var data = JSON.stringify({
      "category_ids": [tempId],
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        var tempArry = [];
        response.data.map(function (item, index) {
          var tempItem = { value: item.id, label: item.sub_category }
          tempArry.push(tempItem);
        });

        this.setState({ categoryList: tempArry, categoryData: response.data });
      }
    }.bind(this))
  }

  updatePageLayout() {
    this.setState({ updateApiLoading: true });
    setTimeout(function () { this.setState({ updateApiLoading: false }); }.bind(this), 1000);

    var tempArry = {
      layout: this.state.layout,
      ads: this.state.ads,
      explore_more: this.state.explore_more
    }
    var url = devconfig.adminV1+"/online/page/update";
    var data = JSON.stringify({
      "layout_id": this.state.id,
      "page_data": tempArry
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {

      }
    }.bind(this))
  }

  async adsDummySuccess(tempUrl, tempLink) {
    var tempArry = this.state.ads;

    await this.setState({ modalRefresh: true });

    switch (this.state.adDummyType) {
      case 1: {
        tempArry.hero.data[0] = { content: tempUrl, link: tempLink };
        tempArry.hero.page_id = this.state.page_id;
        tempArry.hero.id = this.state.hero_id;
        break;
      }
      case 2: {
        tempArry.logo_slider.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        tempArry.logo_slider.id = this.state.logo_id;
        tempArry.logo_slider.page_id = this.state.page_id;
        break;
      }
      case 3: {
        tempArry.deals_banner.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        tempArry.deals_banner.id = this.state.deals_banner_id;
        tempArry.deals_banner.page_id = this.state.page_id;
        break;
      }
      case 4: {
        tempArry.deals.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        tempArry.deals.id = this.state.deals_id;
        tempArry.deals.page_id = this.state.page_id;
        break;
      }
      case 5: {
        tempArry.ad_section_1.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        tempArry.ad_section_1.id = this.state.ad_section_1_id;
        tempArry.ad_section_1.page_id = this.state.page_id;
        break;
      }
      case 6: {
        tempArry.ad_section_2.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        tempArry.ad_section_2.id = this.state.ad_section_2_id;
        tempArry.ad_section_2.page_id = this.state.page_id;
        break;
      }
      case 7: {
        tempArry.ad_section_3.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        tempArry.ad_section_3.id = this.state.ad_section_3_id;
        tempArry.ad_section_3.page_id = this.state.page_id;
        break;
      }
      case 8: {
        tempArry.ad_section_4.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        tempArry.ad_section_4.id = this.state.ad_section_4_id;
        tempArry.ad_section_4.page_id = this.state.page_id;
        break;
      }
    }

    this.setState({ ads: tempArry, modalRefresh: false });
  }

  adDummyTypeClick(tempItem, tempIndex) {
    this.setState({ adDummyType: tempItem, adIndex: tempIndex });
  }

  removeDummyAds(tempItem, tempIndex) {
    var tempArry = this.state.ads;

    switch (tempItem) {
      case 1: {
        tempArry.hero.data[0] = { content: null, link: null };
        break;
      }
      case 2: {
        tempArry.logo_slider.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 3: {
        tempArry.deals_banner.data[0] = { content: null, link: null };
        break;
      }
      case 4: {
        tempArry.deals.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 5: {
        tempArry.ad_section_1.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 6: {
        tempArry.ad_section_2.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 7: {
        tempArry.ad_section_3.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 8: {
        tempArry.ad_section_4.data[tempIndex] = { content: null, link: null };
        break;
      }
    }

    this.setState({ ads: tempArry });
  }

  sectionSwap(tempCurrectIndex, tempClass) {
    var tempArry = this.state.layout;
    var tempSwapValue = $(tempClass).val();

    if (tempSwapValue > tempArry.length || tempSwapValue <= 0) {
      this.notify("Swap index must be between 0 to " + tempArry.length)
    }
    else {
      if (tempArry[tempSwapValue - 1]) {
        var b = tempArry[tempCurrectIndex - 1];
        tempArry[tempCurrectIndex - 1] = tempArry[tempSwapValue - 1];
        tempArry[tempSwapValue - 1] = b;
        $(".e-section-swap-btn").removeClass("e-active");
        $(tempClass).val("")
      }
      else {
        this.notify("Please verify your input data")
      }
      this.setState({ layout: tempArry });
    }
  }

  catBannerIndexClick(tempIndex) {
    this.setState({ catBannerAdIndex: tempIndex });
  }

  catAdsBannerSuccess(tempImg, tempLink) {
    var tempArry = this.state.layout;
    var tempItem = { "content": tempImg, "link": tempLink };

    tempArry[this.state.catBannerAdIndex].ads = { "content": tempImg, "link": tempLink };
    this.setState({ layout: tempArry });
  }

  removeCatAds(tempIndex) {
    var tempArry = this.state.layout;
    tempArry[tempIndex].ads = { "content": null, "link": null };
    this.setState({ layout: tempArry });
  }

  sectionHeadingChange(tempContent) {
    var tempArry = this.state.ads;

    switch (this.state.sectionHeadingEditSelect) {
      case 1: {
        tempArry.deals.header = tempContent;
        break;
      }
      case 2: {
        tempArry.ad_section_1.header = tempContent;
        break;
      }
      case 3: {
        tempArry.ad_section_2.header = tempContent;
        break;
      }
      case 4: {
        tempArry.ad_section_3.header = tempContent;
        break;
      }
      case 5: {
        tempArry.ad_section_4.header = tempContent;
        break;
      }
      case 6: {
        let explore = this.state.explore_more;
        explore.header = tempContent;
        this.setState({explore_more: explore});
        break;
      }
    }

    this.setState({ ads: tempArry });
  }

  sectionHeadingEditClick(tempItem) {
    this.setState({ sectionHeadingEditSelect: tempItem });
  }

  render() {

    console.log("page_id");
    console.log(this.state.page_id)



    return (
      <div className="e-main-content e-driver-detail-wrap e-dash-wrap">
        <h2 className="e-page-head">{this.state.name} Page Layout</h2>

        {
          this.state.updateApiLoading ?
            <button className="e-p-btn e-nav-button e-inactive e-loading e-page-layout-btn"></button>
            :
            <span>
              {
                this.state.layout && this.state.layout.length ?
                  <button className="e-p-btn e-nav-button" onClick={this.updatePageLayout.bind(this)}>Update page</button>
                  :
                  <button className="e-p-btn e-nav-button e-inactive">Update page</button>
              }
            </span>
        }


        <div className="row e-menu-setting-wrap">
          <div className="col-lg-12">
            <h6>Menu Settings {
              !this.state.menu || (this.state.menu.sub_page_data && this.state.menu.sub_page_data.length) || !this.state.menu.sub_page_data <= 7 ?
                <span data-toggle="modal" data-target="#addMenuModal">Edit/Add</span>
                :
                null
            }</h6>
          </div>


          <div className="col-lg-12">
            {
              this.state.menuApiLoading ?
                null
                :
                <React.Fragment>
                  {
                    this.state.menu ?
                      <AddMenu data={this.state.menu.sub_page_data} id={this.state.id} online={true}/>
                    :
                      <AddMenu id={this.state.id} online={true}/>
                  }
                </React.Fragment>
            }

          </div>

          <div className="col-lg-12">
            {
              this.state.menu && this.state.menu.sub_page_data ?
                <React.Fragment>
                  {
                    this.state.menu.sub_page_data.map((data, index) => {
                      return (
                        <span className="e-menu-item-tag mr-3">{data.name}</span>
                      );
                    })
                  }

                </React.Fragment>
                :
                <React.Fragment>
                  {
                    this.state.menuApiLoading ?
                      null
                      :
                      <span className='e-no-menu-item'>No menu item found. Click Edit/Add to update menu.</span>
                  }
                </React.Fragment>
            }
          </div>
          {/*
            this.state.menu && this.state.menu.sub_page_data ?
              <AddMenu data={this.state.menu.sub_page_data} id={this.state.id}/>
            :
              <AddMenu id={this.state.id}/>
          */}

        </div>

        <div className="row mt-5">
          <div className="col-lg-12">
            <div className="e-layout-wrap mb-3">

              <div className="row">
                <div className="col-lg-12">
                  <div className="e-layout-row  h-100  e-ad-row">
                    {
                      this.state.ads && this.state.ads.hero.data[0] && this.state.ads.hero.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 1)}></span>
                          <a href={this.state.ads.hero.data[0].link} target="_blank">
                            {
                              this.state.ads.hero.data[0].content && this.state.ads.hero.data[0].content.indexOf(".mp4") > -1 ?
                                <VideoPlayer url={this.state.ads.hero.data[0].content} />
                                :
                                <img className="e-hero-dummy-img" src={this.state.ads.hero.data[0].content} alt="Truekhoj hero" />
                            }

                            {/*<img className="e-hero-dummy-img" src={this.state.ads.hero.data[0].content} alt="Truekhoj hero"/>*/}
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 1)}></span>
                          Hero banner advertisement section
                        </div>
                    }
                  </div>
                </div>
              </div>

              {
                this.state.ads && this.state.ads.logo_slider.data && this.state.ads.logo_slider.data.length ?
                  <div className="row mt-4">
                    {
                      this.state.ads.logo_slider.data.map((item, index) => {
                        return (
                          <div className="col-lg-2">
                            <div className="e-layout-row  h-100  e-ad-row">
                              {
                                this.state.ads && item && item.content ?
                                  <div className="position-relative e-hero-dummy-img-wrap">
                                    <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 2, index)}></span>
                                    <a href={item.link} target="_blank">
                                      <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                    </a>
                                  </div>
                                  :
                                  <div className="e-ad-full-placeholder">
                                    <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 2, index)}></span>
                                    Logo {index + 1}
                                  </div>
                              }
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                  :
                  null
              }



            </div>

            <div className="e-layout-wrap mb-3">

              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center mb-3 e-section-head-edit">
                    <h6>{this.state.ads.deals.header}</h6>
                    <span className="e-edit" data-toggle="modal" data-target="#editHeadingModal" onClick={this.sectionHeadingEditClick.bind(this, 1)}></span>
                  </div>
                </div>
                <div className="col-lg-12">
                  {

                    <div className="row">
                      <div className="col-lg-8 mt-4">
                        <div className="e-layout-row  h-100  e-ad-row h-100">
                          {
                            this.state.ads.deals_banner.data[0] && this.state.ads.deals_banner.data[0].content ?
                              <div className="position-relative e-hero-dummy-img-wrap h-100">
                                <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 3, 0)}></span>
                                <a href={this.state.ads.deals_banner.data[0].link} target="_blank">
                                  <img className="e-hero-dummy-img" style={{ maxHeight: "calc(900px + 2rem)", height: "100%", objectFit: "cover" }} src={this.state.ads.deals_banner.data[0].content} alt="Truekhoj highlight" />
                                </a>
                              </div>
                              :
                              <div className="e-ad-full-placeholder">
                                <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 3, 0)}></span>
                                Buy of the moment special banner 1
                              </div>
                          }
                        </div>
                      </div>
                      <div className="col-lg-4 py-2 mt-4">
                        <div className="row my-n3">
                          {
                            this.state.ads.deals_banner.data.map((item, index) => {
                              if (index > 0) {
                                return (
                                  <div className="col-lg-12 py-2">
                                    <div className="e-layout-row  h-100  e-ad-row">
                                      {
                                        item && item.content ?
                                          <div className="position-relative e-hero-dummy-img-wrap">
                                            <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 3, index)}></span>
                                            <a href={item.link} target="_blank">
                                              <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                            </a>
                                          </div>
                                          :
                                          <div className="e-ad-full-placeholder">
                                            <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 3, index)}></span>
                                            Buy of the moment special banner {index+1}
                                          </div>
                                      }
                                    </div>
                                  </div>
                                );
                              }
                            })
                          }
                        </div>
                      </div>
                    </div>

                  }
                  {/* <div className="e-layout-row  h-100  e-ad-row">
                    {
                      this.state.ads && this.state.ads.deals_banner.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 3)}></span>
                          <a href={this.state.ads.deals_banner.data[0].link} target="_blank">
                            {
                              this.state.ads.deals_banner.data[0].content && this.state.ads.deals_banner.data[0].content.indexOf(".mp4") > -1 ?
                                <VideoPlayer url={this.state.ads.deals_banner.data[0].content} />
                                :
                                <img className="e-hero-dummy-img" src={this.state.ads.deals_banner.data[0].content} alt="Truekhoj hero" />
                            }
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder position-relative">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 3)}></span>
                          Buy of the moment special banner
                        </div>
                    }
                  </div> */}
                </div>


                {
                  this.state.ads && this.state.ads.deals.data && this.state.ads.deals.data.length ?
                    <React.Fragment>
                      {
                        this.state.ads.deals.data.map((item, index) => {
                          return (
                            <div className="col-lg-4 mt-4">
                              <div className="e-layout-row  h-100  e-ad-row">
                                {
                                  item && item.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 4, index)}></span>
                                      <a href={item.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                      </a>
                                    </div>
                                    :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 4, index)}></span>
                                      Sub banner
                                    </div>
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </React.Fragment>
                    :
                    null
                }
              </div>

            </div>
            <div className="e-layout-wrap mb-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center e-section-head-edit">
                    <span className="e-edit" data-toggle="modal" data-target="#editHeadingModal" onClick={this.sectionHeadingEditClick.bind(this, 2)}></span>
                    <h6>{this.state.ads.ad_section_1.header}</h6>
                  </div>
                </div>
              </div>

              <div className="row">

                {
                  this.state.ads && this.state.ads.ad_section_1.data && this.state.ads.ad_section_1.data.length ?
                    <React.Fragment>
                      {
                        this.state.ads.ad_section_1.data.map((item, index) => {
                          return (
                            <div className="col-lg-4 mt-4">
                              <div className="e-layout-row  h-100  e-ad-row position-relative">
                                {
                                  item && item.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 5, index)}></span>
                                      <a href={item.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                      </a>
                                    </div>
                                    :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 5, index)}></span>
                                      Banner {index + 1}
                                    </div>
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </React.Fragment>
                    :
                    null
                }
              </div>
            </div>

            <div className="e-layout-wrap">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center e-section-head-edit">
                    <span className="e-edit" data-toggle="modal" data-target="#editHeadingModal" onClick={this.sectionHeadingEditClick.bind(this, 3)}></span>
                    <h6>{this.state.ads.ad_section_2.header}</h6>
                  </div>
                </div>
              </div>

              <div className="row">

                {
                  this.state.ads && this.state.ads.ad_section_2.data && this.state.ads.ad_section_2.data.length ?
                    <React.Fragment>
                      {
                        this.state.ads.ad_section_2.data.map((item, index) => {
                          return (
                            <div className="col-lg-3 mt-4">
                              <div className="e-layout-row  h-100  e-ad-row position-relative">
                                {
                                  item && item.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 6, index)}></span>
                                      <a href={item.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                      </a>
                                    </div>
                                    :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 6, index)}></span>
                                      Banner {index + 1}
                                    </div>
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </React.Fragment>
                    :
                    null
                }
              </div>

            </div>

            <div className="e-layout-wrap mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center e-section-head-edit">
                    <span className="e-edit" data-toggle="modal" data-target="#editHeadingModal" onClick={this.sectionHeadingEditClick.bind(this, 4)}></span>
                    <h6>{this.state.ads.ad_section_3.header}</h6>
                  </div>
                </div>
              </div>

              <div className="row ">

                {
                  this.state.ads && this.state.ads.ad_section_3.data && this.state.ads.ad_section_3.data.length ?
                    <React.Fragment>
                      {
                        this.state.ads.ad_section_3.data.map((item, index) => {
                          return (
                            <div className="col-lg-3 mt-4">
                              <div className="e-layout-row  h-100  e-ad-row position-relative">
                                {
                                  item && item.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 7, index)}></span>
                                      <a href={item.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                      </a>
                                    </div>
                                    :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 7, index)}></span>
                                      Banner {index + 1}
                                    </div>
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </React.Fragment>
                    :
                    null
                }
              </div>

            </div>

            <div className="e-layout-wrap mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center e-section-head-edit">
                    <span className="e-edit" data-toggle="modal" data-target="#editHeadingModal" onClick={this.sectionHeadingEditClick.bind(this, 5)}></span>
                    <h6>{this.state.ads.ad_section_4.header}</h6>
                  </div>
                </div>
              </div>

              <div className="row">

                {
                  this.state.ads && this.state.ads.ad_section_4.data && this.state.ads.ad_section_4.data.length ?
                    <React.Fragment>
                      {
                        this.state.ads.ad_section_4.data.map((item, index) => {
                          return (
                            <div className="col-lg-4 mt-4">
                              <div className="e-layout-row  h-100  e-ad-row position-relative">
                                {
                                  item && item.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 8, index)}></span>
                                      <a href={item.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                      </a>
                                    </div>
                                    :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 8, index)}></span>
                                      Banner {index + 1}
                                    </div>
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </React.Fragment>
                    :
                    null
                }
              </div>

            </div>

            <div className="e-layout-wrap my-3">
              {
                this.state.layout && this.state.layout.map((data, index) => {
                  var tempClass = "e-section-swap-btn e-section-swap-" + index;
                  var tempClassSelect = ".e-section-swap-" + index;

                  var tempInputClass = "e-swap-input" + index;
                  var tempInputClassSelect = ".e-swap-input" + index;

                  var tempStyle = null;
                  if (data.bg_color) {
                    var tempStyle = { background: data.bg_color };
                  }


                  return (
                    <div className="row mt-4" key={index} style={tempStyle}>
                      <div className="col-lg-12">
                        <div className="e-row-input-wrap">
                          <label className="label">Section - {index + 1}: </label>
                          <input className={tempInputClass} onChange={this.sectionSwapInputChange.bind(this, tempClassSelect)} type="text" required />
                          <span className={tempClass} onClick={this.sectionSwap.bind(this, index + 1, tempInputClassSelect)}></span>
                        </div>
                        <div className="e-layout-row  h-100  e-edit-section">
                          <span className="e-close-icon" onClick={this.removeSection.bind(this, index)}></span>
                          {
                            data.header.content ?
                              <div className="bg-white e-demo-element-wrap">
                                <SectionHeadingStyle data={data.header} />
                              </div>
                              :
                              <div className="e-section-head-placeholder" onClick={()=>this.selectedIndex(index)} data-toggle="modal" data-target="#headerModal">
                                + Add Section Header
                              </div>
                          }


                          {
                            data.ads && data.ads.content ?
                              <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index)}></span>
                                <img className="e-hero-dummy-img" src={data.ads.content} alt="Truekhoj highlight" />
                              </div>
                              :
                              <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index)}></span>
                                Section banner advertisement
                              </div>
                          }

                          {
                            data.data.content ?
                              <div>
                                <div className="mt-3 e-no-p">
                                  {
                                    data.data.content && data.data.type === 1 ?
                                      <CardFourColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color} />
                                      : data.data.content && data.data.type === 2 ?
                                        <CardSixColumn data={data.data.content} type={data.data.style.value} />
                                        : data.data.content && data.data.type === 3 ?
                                          <CardTwoColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color} />
                                          : data.data.content && data.data.type === 4 ?
                                            <CardThreeColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color} />
                                            :
                                            null
                                  }
                                </div>

                                {
                                  this.state.layout.length == index + 1 && data.data.content.length != 8 && data.data.content.length != 12 && data.data.content.length != 10 ?
                                    <div className="e-section-head-placeholder mt-3" onClick={()=>this.selectedIndex(index)} data-toggle="modal" data-target="#listModal">
                                      + Add Category List
                                    </div>
                                    :
                                    null

                                }

                              </div>
                              :
                              <div className="e-section-head-placeholder mt-3" onClick={()=>this.selectedIndex(index)} data-toggle="modal" data-target="#listModal">
                                + Add Category List
                              </div>
                          }
                          <div className="text-right">
                            <span className="e-link" data-toggle="modal" data-target="#addBg" onClick={()=>this.selectedIndex(index)}>+ Add bg color</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              }

              <div className="row my-4">
                {
                  this.state.layout.length <= 3 ?
                    <div className="col-lg-12 text-center">
                      <button className="e-s-btn" type="submit" value="Submit" onClick={this.addSection.bind(this)}>+ Add New Section & Elements</button>
                    </div>
                    :
                    null
                }
              </div>
              
            </div>
            {
                this.state.explore_more && this.state.explore_more.data?
                <div className="row my-4">
                  <div className="col-12">
                  <div className="e-layout-wrap">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-center e-section-head-edit">
                        <span className="e-edit" data-toggle="modal" data-target="#editHeadingModal" onClick={this.sectionHeadingEditClick.bind(this, 6)}></span>
                        <h6>{this.state.explore_more.header}</h6>
                      </div>
                    </div>
                    <div className="col-12">
                    {
                            this.state.explore_more.data.content ?
                              <div>
                                <div className="mt-3 e-no-p">
                                  {
                                    this.state.explore_more.data.content && this.state.explore_more.data.type === 1 ?
                                      <CardFourColumn data={this.state.explore_more.data.content} type={this.state.explore_more.data.style.value} />
                                      : this.state.explore_more.data.content && this.state.explore_more.data.type === 2 ?
                                        <CardSixColumn data={this.state.explore_more.data.content} type={this.state.explore_more.data.style.value} />
                                        : this.state.explore_more.data.content && this.state.explore_more.data.type === 3 ?
                                          <CardTwoColumn data={this.state.explore_more.data.content} type={this.state.explore_more.data.style.value} />
                                          : this.state.explore_more.data.content && this.state.explore_more.data.type === 4 ?
                                            <CardThreeColumn data={this.state.explore_more.data.content} type={this.state.explore_more.data.style.value} />
                                            :
                                            null
                                  }
                                </div>

                              </div>
                              :
                              <div className="e-section-head-placeholder mt-3" onClick={()=>this.selectedIndex(2, true)} data-toggle="modal" data-target="#listModal">
                                + Add Category List
                              </div>
                          }
                      </div>
                  </div>
                  </div>
                </div>
                </div>
                :
                null
              }

          </div>
        </div>

        {/*Modal*/}
        {
          this.state.categoryList ?
            <HeadingSelectionModal success={this.headingSelectSuccess.bind(this)} data={this.state.categoryList} type={2} />
            :
            null
        }

        {
          this.state.selectedSubCategories ?
            <ListSelectionModal success={this.listSelectSuccess.bind(this)} data={this.state.explore?this.state.categoryList :this.state.selectedSubCategories} explore={this.state.explore}/>
            :
            null
        }

        {
          !this.state.modalRefresh ?
            <UploadDummyAdsModal success={this.adsDummySuccess.bind(this)} />
            :
            null
        }

        <EditHeadingModal success={this.sectionHeadingChange.bind(this)} />

        <UploadDummyCatBannerModal success={this.catAdsBannerSuccess.bind(this)} />


        <AddBg success={this.bgColorSuccess.bind(this)} />

      </div>

    );
  }
}

export default OnlineDealsPagesLayout;
