
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


/*Components*/
import HeadingSelectionModal from '../../../components/modals/headingSelectionModal';
import ListSelectionModal from '../../../components/modals/listSelectionModal';
import SectionHeadingStyle from '../../../components/elementsPreview/sectionHeadingStyle';
import Input from '../../../components/formComponents/input';
import CardFourColumn from '../../../components/cards/column-4.js';
import CardSixColumn from '../../../components/cards/column-6.js';
import CardTwoColumn from '../../../components/cards/column-2';
import CardThreeColumn from '../../../components/cards/column-3';
import UploadDummyAdsModal from '../../../components/modals/uploadDummyAdsModal';
import UploadDummyCatBannerModal from '../../../components/modals/uploadDummyCategorybanner';
import VideoPlayer from '../../../components/common/video';
import AddBg from '../../../components/modals/addBg';
import AddMenu from '../../../components/modals/addMenu';
import EditHeadingModal from '../../../components/modals/editHeadingModal';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';
import LazyLoaderGif from '../../../components/common/lazyloader';

/*Assets*/

var tempAdItem = { content: null, link: null };

class LocalHomeLayout extends Component {
  notify = e => toast(e);
  constructor(props) {
    super(props);
    this.state = {
      layout: [],
      ads: {
        hero: { id: 77, page_id: 1, data: [{ content: null, link: null }] },
        ad1: { id: 78, page_id: 1, data: [{ content: null, link: null }, { content: null, link: null }, { content: null, link: null }, { content: null, link: null }] },
        summerCollection: { id: 79, page_id: 1, header: "Summer Collection", data: [{ content: null, link: null }] },
        ad2: { id: 80, page_id: 1, data: [{ content: null, link: null }, { content: null, link: null }, { content: null, link: null }, { content: null, link: null }] },
      },
      id: null,
      selectItemIndex: null,
      categoryData: null,
      selectedSubCategories: null,
      data: null,
      name: null,
      adDummyType: null,
      swapIndex: "",
      catBannerAdIndex: null,
      updateApiLoading: false,
    }
  }

  componentDidMount() {
    this.getCategoryData(); /* Get department list for section Headings*/
    GetURLVar().then(function (query) {
      if (query) {
        this.getData(query.id);
        this.setState({ id: query.id });
        this.getMenu(query.id)
      }
    }.bind(this))
  }

  addAdIds(response, type) {
    let data = response;
    if (type === "ad") {
      if (data.hero) data.hero.id = 77;
      if (data.ad1) data.ad1.id = 78;
      if (data.summerCollection) data.summerCollection.id = 79;
      if (data.ad2) data.ad2.id = 80;
      this.setState({ ads: data });
    }
    if (type === "layout") {
      if (data && data.length) {
        data.forEach(item => {
          item.id = 81;
          item.page_id = 1;
        });
      }
      else data = [];
      this.setState({ layout: data });
    }
  }

  getData(tempId) {
    this.setState({ loader: true});
    var url = devconfig.adminV1 + "/local/page/detail";
    var data = JSON.stringify({
      "layout_id": tempId
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        if (response.data.page_data.layout) {
          this.addAdIds(response.data.page_data.layout, "layout");
 
          this.addAdIds(response.data.page_data.layout, "layout");  /* Fn to correct IDs of existing layouts in page data*/

          // this.setState({ layout: response.data.page_data.layout });
        }
        if (response.data.page_data.ads) {
          this.addAdIds(response.data.page_data.ads, "ad"); /* Fn to correct IDs of existing ads in page data*/
          // this.setState({ ads: response.data.page_data.ads });
        }
        this.setState({ name: response.data.name, loader: false });
      }
      else{
        this.setState({ loader: false})
      }
    }.bind(this))
  }

  removeDummyHeader() {
    var tempArry = this.state.ads;
    tempArry.summerCollection.header = "";
    this.setState({ ads: tempArry });
  }

  sectionHeadingChange(tempContent, tempLink) {
    var tempArry = this.state.ads;
    if (this.state.headerImage) {
      tempArry.summerCollection.header = { content: tempContent, link: tempLink };
    }
    else {
      tempArry.summerCollection.header = tempContent;
    }
    this.setState({ ads: tempArry, sectionHeadingEditSelect: false });
  }

  addSection() {
    var tempArry = {
      "header": { "content": null, "type": null, "font_color": null, "bg_color": null, category: null },
      "ads": { "content": null, "link": null },
      "data": { "content": null, "image": null, "stlye": null },
      "page_id": 1,
      "favStore":  { },
      "id": 81
    }

    var tempLayout = this.state.layout;
    tempLayout.push(tempArry);
    this.setState({ layout: tempLayout });

    this.setDefaultOptions();
  }

  getMenu(tempId) {
    this.setState({ menuApiLoading: true });
    var url = devconfig.localAdmin + "/local/topmenu/list";
    var data = JSON.stringify({
      "layout_id": Number(tempId)
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        if (response.data.length) {
          this.setState({ menu: response.data[0], menuApiLoading: false });
        }
        else {
          this.setState({ menu: [], menuApiLoading: false });
        }

      }
    }.bind(this))
  }

  removeSection(tempIndex) {
    var tempLayout = this.state.layout;
    tempLayout.splice(tempIndex, 1);
    this.setDefaultOptions();
    this.setState({ layout: tempLayout });
  }

  async setDefaultOptions() {
    var tempArry = [];
    this.state.categoryData.forEach(element => {
      element.category.map(function (item, index) {
        var tempItem = { value: item.id, label: item.name }
        tempArry.push(tempItem);
      });
    })
    await this.setState({ selectedSubCategories: tempArry });
  }

  async headingSelectSuccess(tempItem) {
    var tempArry = this.state.layout;
    tempArry[this.state.selectItemIndex].header = tempItem;
    this.setState({ layout: tempArry });

    var temp = this.state.categoryData.filter(function (obj) {
      return obj.id === tempItem.category
    });

    var tempArry = [];
    if (temp.length) {
      temp[0].category.map(function (item, index) {
        var tempItem = { value: item.id, label: item.name }
        tempArry.push(tempItem);
      });
    }
    else {
      this.state.categoryData.forEach(element => {
        element.category.map(function (item, index) {
          var tempItem = { value: item.id, label: item.name }
          tempArry.push(tempItem);
        });
      })
    }

    await this.setState({ selectedSubCategories: tempArry });
  }

  bgColorSuccess(tempColor) {
    if (this.state.adBackground) {
      let ad = this.state.ads;
      if (ad.summerCollection.bg_color) ad.summerCollection.bg_color = tempColor;
      else ad.summerCollection = { ...this.state.ads.summerCollection, bg_color: tempColor };
      this.setState({ ads: ad });
    }
    else {
      var tempArry = this.state.layout;
      tempArry[this.state.selectItemIndex].bg_color = tempColor;
      this.setState({ layout: tempArry });
    }
  }

  listSelectSuccess(tempItem, tempType) {
    var tempArry = this.state.layout;
    if(tempType){
      if(tempArry[this.state.selectItemIndex].favStore && tempArry[this.state.selectItemIndex].favStore.data){
        var temp = tempArry[this.state.selectItemIndex].favStore.data.concat(tempItem.vendors.data);
        var tVendor =  tempArry[this.state.selectItemIndex].favStore.vendors.concat(tempItem.vendors.vendors);
        tempArry[this.state.selectItemIndex].favStore.data = temp;
        tempArry[this.state.selectItemIndex].favStore.vendors = tVendor;
        console.log('temp data ->', temp);
        console.log('temp vendor-->', tVendor);
        console.log('favStoer-->', tempArry);
      }else{
        tempArry[this.state.selectItemIndex].favStore = tempItem.vendors ;
      }
    }
    else{
      if (tempArry[this.state.selectItemIndex].data && tempArry[this.state.selectItemIndex].data.content) {
        var temp = tempArry[this.state.selectItemIndex].data.content.concat(tempItem.content);
        tempArry[this.state.selectItemIndex].data.content = temp;
        tempArry[this.state.selectItemIndex].data.style = tempItem.style;
        tempArry[this.state.selectItemIndex].data.type = tempItem.type;
      }
      else {
        tempArry[this.state.selectItemIndex].data = tempItem;
      }
    }
    
    // tempArry[this.state.selectItemIndex].data = tempItem;
    this.setState({ layout: tempArry });
  }

  selectedIndex(tempindex) {
    this.setState({ selectItemIndex: tempindex, adBackground: false });
  }

  sectionSwapInputChange(tempClass, e) {
    $(".e-section-swap-btn").removeClass("e-active");
    $(tempClass).addClass("e-active");
  }

  sectionSwapInputReset() {
    $(".e-section-swap-btn").removeClass("e-active");
  }

  getCategoryData() {
    var url = devconfig.adminV1 + "/department/list";
    APIGet(url).then(async function (response) {
      if (response.status === "ok") {
        var cat = [];
        var subcat = [];
        response.data.forEach(function (item, index) {
          var tempItem = { value: item.id, label: item.name }
          cat.push(tempItem);
          item.category.forEach(function (sub, index) {
            var tempItem = { value: sub.id, label: sub.name }
            subcat.push(tempItem);
          });
        });

        await this.setState({ categoryList: cat, categoryData: response.data, selectedSubCategories: subcat });
      }
    }.bind(this))
  }

  updatePageLayout() {
    this.setState({ updateApiLoading: true });
    setTimeout(function () { this.setState({ updateApiLoading: false }); }.bind(this), 1000);

    let category_ids = [];
    let layout_ad_location_id = null;
    if (this.state.layout && this.state.layout.length) {
      this.state.layout.forEach(section => {
        if (section.header && section.header.category) category_ids.push(section.header.category);
      });
      layout_ad_location_id = this.state.layout[0].id;
    }
    var tempArry = {
      layout: this.state.layout,
      ads: this.state.ads
    }
    var url = devconfig.adminV1 + "/local/page/update";
    var data = JSON.stringify({
      "layout_id": this.state.id,
      "page_data": tempArry,
      "layout_ad_location_id": layout_ad_location_id,
      "department_ids": category_ids
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {

      }
    }.bind(this))
  }

    /* ---------------------------------
  Fn to add banner based on key 
  (state.adDummyType, which is set on adDummyTypeClick() fn).
  ----------------------------------------*/ 
  adsDummySuccess(tempUrl, tempLink) {
    let tempArry = this.state.ads;
    let layout = this.state.layout;

    console.log(this.state.adDummyType);

    switch (this.state.adDummyType) {
      case 1: {
        tempArry.hero.data = [{ content: tempUrl, link: tempLink }];
        break;
      }
      case 2: {
        tempArry.ad1.data[0] = { content: tempUrl, link: tempLink };
        break;
      }
      case 3: {
        // tempArry.highlightTwo = {content: tempUrl, link: tempLink};
        tempArry.ad1.data[1] = { content: tempUrl, link: tempLink };
        // tempArry.ad1.page_id = 1;
        // tempArry.ad1.id = 2;
        break;
      }
      case 4: {
        // tempArry.highlightThree = {content: tempUrl, link: tempLink};
        tempArry.ad1.data[2] = { content: tempUrl, link: tempLink };
        // tempArry.ad1.page_id = 1;
        // tempArry.ad1.id = 2;
        break;
      }
      case 5: {
        // tempArry.highlightFour = {content: tempUrl, link: tempLink};
        tempArry.ad1.data[3] = { content: tempUrl, link: tempLink };
        // tempArry.ad1.page_id = 1;
        // tempArry.ad1.id = 2;
        break;
      }
      case 6: {
        tempArry.summerCollection.data = [{ content: tempUrl, link: tempLink }];
        // tempArry.summerCollection.page_id = 1;
        // tempArry.summerCollection.id = 3;
        break;
      }
      case 7: {
        tempArry.ad2.data[0] = { content: tempUrl, link: tempLink };
        // tempArry.ad2.page_id = 1;
        // tempArry.ad2.id = 4;
        break;
      }
      case 8: {
        // tempArry.highlightTwo = {content: tempUrl, link: tempLink};
        tempArry.ad2.data[1] = { content: tempUrl, link: tempLink };
        // tempArry.ad2.page_id = 1;
        // tempArry.ad2.id = 4;
        break;
      }
      case 9: {
        // tempArry.highlightThree = {content: tempUrl, link: tempLink};
        tempArry.ad2.data[2] = { content: tempUrl, link: tempLink };
        // tempArry.ad2.page_id = 1;
        // tempArry.ad2.id = 4;
        break;
      }
      case 10: {
        // tempArry.highlightFour = {content: tempUrl, link: tempLink};
        tempArry.ad2.data[3] = { content: tempUrl, link: tempLink };
        // tempArry.ad2.page_id = 1;
        // tempArry.ad2.id = 4;
        break;
      }
      case 11: {
        layout[this.state.selectItemIndex].header = { content: tempUrl, link: tempLink, category: null };
        this.setState({ layout: layout });
        break;
      }
    }

    this.setState({ ads: tempArry });
  }

  async adDummyTypeClick(tempItem) {
    await   this.setState({ adDummyType: tempItem });
    await console.log(this.state.adDummyType);
  }

  /* ---------------------------------
  Fn to remove banner based on key 
  (tempItem, eg: tempItem is 1 for hero banner).
  tempIndex (optional) is used in case of arrays
  ----------------------------------------*/ 
  removeDummyAds(tempItem, tempIndex) {
    let tempArry = this.state.ads;
    let layout = this.state.layout;
    switch (tempItem) {
      case 1: {
        tempArry.hero.data = { content: null, link: null };
        break;
      }
      case 2: {
        // tempArry.highlightOne = {content: null, link: null};
        tempArry.ad1.data[0] = { content: null, link: null };
        break;
      }
      case 3: {
        // tempArry.highlightTwo = {content: null, link: null};
        tempArry.ad1.data[1] = { content: null, link: null };
        break;
      }
      case 4: {
        // tempArry.highlightThree = {content: null, link: null};
        tempArry.ad1.data[2] = { content: null, link: null };
        break;
      }
      case 5: {
        // tempArry.highlightFour = {content: null, link: null};
        tempArry.ad1.data[3] = { content: null, link: null };
        break;
      }
      case 6: {
        tempArry.summerCollection.data = { content: null, link: null };
        break;
      }
      case 7: {
        // tempArry.highlightOne = {content: null, link: null};
        tempArry.ad2.data[0] = { content: null, link: null };
        break;
      }
      case 8: {
        // tempArry.highlightTwo = {content: null, link: null};
        tempArry.ad2.data[1] = { content: null, link: null };
        break;
      }
      case 9: {
        // tempArry.highlightThree = {content: null, link: null};
        tempArry.ad2.data[2] = { content: null, link: null };
        break;
      }
      case 10: {
        // tempArry.highlightFour = {content: null, link: null};
        tempArry.ad2.data[3] = { content: null, link: null };
        break;
      }
      case 11: {
        layout[tempIndex].header = { "content": null, "type": null, "font_color": null, "bg_color": null, category: null };
        this.setState({ layout: layout });
        break;
      }
    }

    this.setState({ ads: tempArry });
  }

  sectionSwap(tempCurrectIndex, tempClass) {
    var tempArry = this.state.layout;
    var tempSwapValue = $(tempClass).val();

    if (tempSwapValue > tempArry.length || tempSwapValue <= 0) {
      this.notify("Swap index must be between 0 to " + tempArry.length)
    }
    else {
      if (tempArry[tempSwapValue - 1]) {
        var b = tempArry[tempCurrectIndex - 1];
        tempArry[tempCurrectIndex - 1] = tempArry[tempSwapValue - 1];
        tempArry[tempSwapValue - 1] = b;
        $(".e-section-swap-btn").removeClass("e-active");
        $(tempClass).val("")
      }
      else {
        this.notify("Please verify your input data")
      }
      this.setState({ layout: tempArry });
    }
  }

  catBannerIndexClick(tempIndex) {
    this.setState({ catBannerAdIndex: tempIndex });
  }

  catAdsBannerSuccess(tempImg, tempLink) {
    var tempArry = this.state.layout;
    var tempItem = { "content": tempImg, "link": tempLink };

    tempArry[this.state.catBannerAdIndex].ads = { "content": tempImg, "link": tempLink };
    this.setState({ layout: tempArry });
  }

  removeCatAds(tempIndex) {
    var tempArry = this.state.layout;
    tempArry[tempIndex].ads = { "content": null, "link": null };
    this.setState({ layout: tempArry });
  }

  render() {


    return (
      <div className="e-main-content e-driver-detail-wrap e-dash-wrap">
        <h2 className="e-page-head">{this.state.name} Page Layout</h2>

        {
          this.state.updateApiLoading ?
            <button className="e-p-btn e-nav-button e-inactive e-loading e-page-layout-btn"></button>
            :
            <span>
              {
                this.state.layout && this.state.layout.length ?
                  <button className="e-p-btn e-nav-button" onClick={this.updatePageLayout.bind(this)}>Update page</button>
                  :
                  <button className="e-p-btn e-nav-button e-inactive">Update page</button>
              }
            </span>
        }

        <div className="row e-menu-setting-wrap">
          <div className="col-lg-12">
            <h6>Menu Settings {
              !this.state.menu || (this.state.menu.sub_page_data && this.state.menu.sub_page_data.length) || !this.state.menu.sub_page_data <= 7 ?
                <span data-toggle="modal" data-target="#addMenuModal">Edit/Add</span>
                :
                null
            }</h6>
          </div>
          <div className="col-lg-12">
            {
              this.state.menuApiLoading ?
                null
                :
                <React.Fragment>
                  {
                    this.state.menu ?
                      <AddMenu data={this.state.menu.sub_page_data} id={this.state.id} />
                      :
                      <AddMenu id={this.state.id} />
                  }
                </React.Fragment>
            }
          </div>

          <div className="col-lg-12">
            {
              this.state.menu && this.state.menu.sub_page_data ?
                <React.Fragment>
                  {
                    this.state.menu.sub_page_data.map((data, index) => {
                      return (
                        <span className="e-menu-item-tag mr-3">{data.name}</span>
                      );
                    })
                  }
                </React.Fragment>
                :
                <React.Fragment>
                  {
                    this.state.menuApiLoading ?
                      null
                      :
                      <span className='e-no-menu-item'>No menu item found. Click Edit/Add to update menu.</span>
                  }
                </React.Fragment>
            }
          </div>
          {/*
            this.state.menu && this.state.menu.sub_page_data ?
              <AddMenu data={this.state.menu.sub_page_data} id={this.state.id}/>
            :
              null
          */}

        </div>
        {
          this.state.loader ?
          <div className='col-lg-12 text-center mt-5 pt-5'>
             < LazyLoaderGif/>
          </div>
         :
        <>
        <div className="row mt-5">
          <div className="col-lg-12">
            <h6>Layout Settings</h6>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="e-layout-wrap">

              <div className="row">
                <div className="col-lg-12">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads.hero && this.state.ads.hero.data.length && this.state.ads.hero.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 1)}></span>
                          <a href={this.state.ads.hero.data[0].link} target="_blank">
                            {
                              this.state.ads.hero.data[0].content.indexOf(".mp4") > -1 ?
                                <VideoPlayer url={this.state.ads.hero.data[0].content} />
                                :
                                <img className="e-hero-dummy-img" src={this.state.ads.hero.data[0].content} alt="Truekhoj hero" />
                            }
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 1)}></span>
                          Hero banner advertisement section
                        </div>
                    }

                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-6 my-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.ad1.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 2)}></span>
                          <a href={this.state.ads.ad1.data[0].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.ad1.data[0].content} alt="Truekhoj highlight" />
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 2)}></span>
                          Wholesale Products
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-6 my-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.ad1.data[1].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 3)}></span>
                          <a href={this.state.ads.ad1.data[1].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.ad1.data[1].content} alt="Truekhoj highlight" />
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 3)}></span>
                          Supervalue Shop
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-6 my-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.ad1.data[2].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 4)}></span>
                          <a href={this.state.ads.ad1.data[2].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.ad1.data[2].content} alt="Truekhoj highlight" />
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 4)}></span>
                          Special Offers
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-6 my-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.ad1.data[3].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 5)}></span>
                          <a href={this.state.ads.ad1.data[3].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.ad1.data[3].content} alt="Truekhoj highlight" />
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 5)}></span>
                          Today's Deals
                        </div>
                    }
                  </div>
                </div>
              </div>

              <div className="row py-3" style={{ backgroundColor: this.state.ads.summerCollection && this.state.ads.summerCollection.bg_color ? this.state.ads.summerCollection.bg_color : "#fff0" }}>
                <div className="col-lg-12">
                  {/* <h6>Summer Collection</h6> */}
                  <div className="text-center mb-3 e-section-head-edit">
                    {
                      this.state.ads.summerCollection.header.content && !this.state.sectionHeadingEditSelect ?
                        <div className="row">
                          <div className="col-lg-5"></div>
                          <div className="col-lg-2">
                            <div className="position-relative e-hero-dummy-img-wrap">
                              <span className="e-close-icon" onClick={this.removeDummyHeader.bind(this)}></span>
                              <a href={this.state.ads.summerCollection.header.link} target="_blank">
                                <img className="e-hero-dummy-img" src={this.state.ads.summerCollection.header.content} alt="Truekhoj highlight" />
                              </a>
                            </div>
                          </div>
                          <div className="col-lg-5"></div>
                        </div>
                        : !this.state.sectionHeadingEditSelect ?
                          <>
                            <h6>{this.state.ads.summerCollection.header}</h6>
                            <span className="e-edit" onClick={() => this.setState({ sectionHeadingEditSelect: true })}></span>
                          </>
                          :
                          <div className="row">
                            <div className="col-lg-5">
                              <div className="e-upload-layout-header h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6", cursor: "pointer" }} data-toggle="modal" data-target="#editHeadingModal">
                                Edit Header content
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <span className="e-upload-layout-header border-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6" }}>OR</span>
                            </div>
                            <div className="col-lg-5">
                              <div className="e-upload-layout-header">
                                <div className="e-ad-full-placeholder position-relative">
                                  <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={() => this.setState({ headerImage: true })}></span>
                                  Upload Header Image
                                </div>
                              </div>
                            </div>
                          </div>
                    }
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads.summerCollection && this.state.ads.summerCollection.data.length && this.state.ads.summerCollection.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 6)}></span>
                          <a href={this.state.ads.summerCollection.data[0].link} target="_blank">
                            {
                              this.state.ads.summerCollection.data[0].content.indexOf(".mp4") > -1 ?
                                <VideoPlayer url={this.state.ads.summerCollection.data[0].content} />
                                :
                                <img className="e-hero-dummy-img" src={this.state.ads.summerCollection.data[0].content} alt="Truekhoj hero" />
                            }
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={()=> {this.adDummyTypeClick(6); this.setState({ headerImage: false}); }}></span>
                          Summer Collection Advertisement banner
                        </div>
                    }

                  </div>
                </div>
                <div className="col-lg-12 text-right py-3">
                  <span className="e-link" data-toggle="modal" data-target="#addBg" onClick={() => this.setState({ adBackground: true })}>+ Add bg color</span>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-6 my-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.ad2.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 7)}></span>
                          <a href={this.state.ads.ad2.data[0].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.ad2.data[0].content} alt="Truekhoj highlight" />
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 7)}></span>
                          What's New
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-6 my-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.ad2.data[1].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 8)}></span>
                          <a href={this.state.ads.ad2.data[1].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.ad2.data[1].content} alt="Truekhoj highlight" />
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 8)}></span>
                          Shop of the Day
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-6 my-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.ad2.data[2].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 9)}></span>
                          <a href={this.state.ads.ad2.data[2].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.ad2.data[2].content} alt="Truekhoj highlight" />
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 9)}></span>
                          Exclusive brands
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-6 my-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.ad2.data[3].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 10)}></span>
                          <a href={this.state.ads.ad2.data[3].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.ad2.data[3].content} alt="Truekhoj highlight" />
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 10)}></span>
                          Weekly Deals
                        </div>
                    }
                  </div>
                </div>
              </div>

              {/*------------------------------------------------------------
                                      LAYOUT SECTION
              --------------------------------------------------------------*/}

              {
                this.state.layout && this.state.layout.map((data, index) => {
                  var tempClass = "e-section-swap-btn e-section-swap-" + index;
                  var tempClassSelect = ".e-section-swap-" + index;

                  var tempInputClass = "e-swap-input" + index;
                  var tempInputClassSelect = ".e-swap-input" + index;

                  var tempStyle = null;
                  if (data.bg_color) {
                    var tempStyle = { background: data.bg_color };
                  }

                  return (
                    <div className="row mt-4" key={index} style={tempStyle}>
                      <div className="col-lg-12">
                        <div className="e-row-input-wrap">
                          <label className="label">Section - {index + 1}: </label>
                          <input className={tempInputClass} onChange={this.sectionSwapInputChange.bind(this, tempClassSelect)} type="text" required />
                          <span className={tempClass} onClick={this.sectionSwap.bind(this, index + 1, tempInputClassSelect)}></span>
                        </div>
                        <div className="e-layout-row e-edit-section">
                          <span className="e-close-icon" onClick={this.removeSection.bind(this, index)}></span>
                          {
                            data.header.content && !data.header.link ?
                              <div className="bg-white e-demo-element-wrap">
                                <SectionHeadingStyle data={data.header} />
                              </div>
                              : data.header.content && data.header.link ?
                                <div className="row">
                                  <div className="col-lg-5"></div>
                                  <div className="col-lg-2">
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 11, index)}></span>
                                      <a href={data.header.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={data.header.content} alt="Truekhoj highlight" />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-lg-5"></div>
                                </div>
                                : this.state.selectItemIndex === index ?
                                  <div className="row">
                                    <div className="col-lg-5">
                                      <div className="e-upload-layout-header h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6", cursor: "pointer" }} data-toggle="modal" data-target="#headerModal">
                                        Choose Header style and content
                                      </div>
                                    </div>
                                    <div className="col-lg-2">
                                      <span className="e-upload-layout-header border-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6" }}>OR</span>
                                    </div>
                                    <div className="col-lg-5">
                                      <div className="e-upload-layout-header">
                                        <div className="e-ad-full-placeholder position-relative">
                                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 11)}></span>
                                          Upload Header Image
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  :
                                  <div className="e-section-head-placeholder" onClick={this.selectedIndex.bind(this, index)} >
                                    + Add Section Header
                                  </div>
                          }

                          {
                            data.ads && data.ads.content ?
                              <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index)}></span>
                                {
                                  data.ads.content.indexOf(".mp4") > -1 ?
                                    <VideoPlayer url={data.ads.content} />
                                    :
                                    <img className="e-hero-dummy-img" src={data.ads.content} alt="Truekhoj highlight" />
                                }

                              </div>
                              :
                              <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index)}></span>
                                Section banner advertisement
                              </div>
                          }
                          {
                              data.favStore && data.favStore.data && data.favStore.data.length?
                              <div className='row'>
                              
                              {
                                data.favStore.data.map((item, key)=>{
                                  
                                return(
                                  <div className='col-lg-6 mt-3'>
                                    <div className='card e-favitem-card'>
                                        <img  src={item.content}/>
                                        <p className='mt-3 text-center '>Vendor - {item.vendor.label}</p>
                                    </div>
                                  </div>
                                  )
                              })
                            }
                              </div>
                              :
                              null
                            }

                          {
                            data.data.content ?
                              <React.Fragment>
                                <div className="mt-3 e-no-p mb-4">
                                  {
                                    data.data.content && data.data.type === 1 ?
                                      <CardFourColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color} />
                                      : data.data.content && data.data.type === 2 ?
                                        <CardSixColumn data={data.data.content} type={data.data.style.value} />
                                        : data.data.content && data.data.type === 3 ?
                                          <CardTwoColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color} />
                                          : data.data.content && data.data.type === 4 ?
                                            <CardThreeColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color} />
                                            :
                                            null
                                  }
                                </div>
                                {
                                  this.state.layout.length == index + 1 && data.data.content.length != 8 && data.data.content.length != 12 && data.data.content.length != 10 && this.state.selectedSubCategories ?
                                    <div className="e-section-head-placeholder" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#listModal">
                                      + Add Category List
                                    </div>
                                    :
                                    null
                                }
                              </React.Fragment>

                              :
                              <div className="e-section-head-placeholder mt-3" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#listModal">
                                + Add Category List
                              </div>
                          }

                          <div className="text-right">
                            <span className="e-link" data-toggle="modal" data-target="#addBg" onClick={this.selectedIndex.bind(this, index)}>+ Add bg color</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              }

              <div className="row my-4">
                <div className="col-lg-12 text-center">
                  <button className="e-s-btn" type="submit" value="Submit" onClick={this.addSection.bind(this)}>+ Add New Section & Elements</button>
                </div>
              </div>


            </div>
          </div>
        </div>
        </>
  }
        {/*Modal*/}
        {
          this.state.categoryList ?
            <HeadingSelectionModal success={this.headingSelectSuccess.bind(this)} data={this.state.categoryList} type={2} />
            :
            null
        }

         {
          this.state.selectedSubCategories  && this.state.categoryList ?
            <ListSelectionModal success={this.listSelectSuccess.bind(this)} catList={this.state.categoryList} include_category={true}  data={this.state.selectedSubCategories} type={1} />
            :
            null
        }
        <EditHeadingModal success={this.sectionHeadingChange.bind(this)} />

        <UploadDummyAdsModal success={this.state.headerImage ? this.sectionHeadingChange.bind(this) : this.adsDummySuccess.bind(this)} />

        <UploadDummyCatBannerModal success={this.catAdsBannerSuccess.bind(this)} />

        <AddBg success={this.bgColorSuccess.bind(this)} />

      </div>

    );
  }
}

export default LocalHomeLayout;
