/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/



/*Assets*/

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';


class ConfirmModal extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }


  componentDidMount() {

  }

  confirm() {
    this.props.success();
  }

  render() {


    return (
      <div className="modal fade e-ad-detail-wrap e-ofer-modal" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content e-career-modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h6 className="e-modal-header e-add-career-heading">Confirmation!</h6>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-12">
               <p>{this.props.data}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9">
                <a href="#" className="float-right pt-2 e-ofer-cancel" data-dismiss="modal">Cancel</a>
              </div>

              <button className="e-s-btn e-new-ofer float-right" type="submit" value="Submit" data-dismiss="modal" onClick={this.confirm.bind(this)}>Confirm</button>
            </div>

            <div className="modal-footer">

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmModal;
