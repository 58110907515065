
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import NothingFound from '../../../components/other/nothing';
import APILoader from '../../../components/other/api-loader';
import ConfirmModal from '../../../components/modals/confirmModal';
import AddLocationAdmin from '../../../components/modals/addLocationAdminModal';
/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
/*Assets*/


class PendingVendor extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: false,
      pageNum: 1,
      data: [],
      pageNumActive: true,
      pageNumLoader: false,
      locationAdmin: false
    }
  }

  componentDidMount() {
    if(localStorage.getItem("truekhoj_locationadmin")){
      this.setState({locationAdmin: true});
    }
    this.getData(this.state.pageNum);
  }

  async getData(tempPageNum){
    this.setState({ apiLoading: true});
    if(this.props.adminType>1 && !localStorage.getItem("truekhoj_locationadmin")){
      let url = devconfig.adminV1 + "/vendor/pending";
      await APIGet(url).then(function(response){
      if(response.status === "ok"){
        if(response.data.length <= 39){
          this.setState({ pageNumActive: false});
        }
        else{
          this.setState({ pageNumActive: true});
        }


        if(this.state.data.length && this.state.pageNumLoader){
          var tempData = this.state.data;
          tempData.push(response.data);
          this.setState({ data: tempData, apiLoading: false, pageNumLoader: false});
        }
        else{
          this.setState({ data: response.data, apiLoading: false, pageNumLoader: false});
        }
        
      }
      else{
        this.setState({ apiLoading: false});
      }
    }.bind(this))
    }
    else{
      let url = devconfig.adminV1+"/vendor/list";
    if(this.props.adminType>1) url = devconfig.locationAdmin + "/vendor/pending";
    if(localStorage.getItem("truekhoj_locationadmin")){
      url = devconfig.locationAdmin + "/vendor/list";
    }
    let data = JSON.stringify({
      "page_num" : tempPageNum,
      "filter_type" : 2
    })
    await APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        if(response.data.length <= 39){
          this.setState({ pageNumActive: false});
        }
        else{
          this.setState({ pageNumActive: true});
        }


        if(this.state.data.length && this.state.pageNumLoader){
          var tempData = this.state.data;
          tempData.push(response.data);
          this.setState({ data: tempData, apiLoading: false, pageNumLoader: false});
        }
        else{
          this.setState({ data: response.data, apiLoading: false, pageNumLoader: false});
        }
        
      }
      else{
        this.setState({ apiLoading: false});
      }
    }.bind(this))
    }
  }

  tabItemClick(tempItem){
    this.setState({tabIndex: tempItem});
  }  

  loadMore(){
    this.getData(this.state.pageNum + 1);
    this.setState({pageNum: this.state.pageNum + 1, pageNumLoader: true});
  }

  async verify(){
    let url = devconfig.adminV1+"/vendor/verify-request";
    let data = JSON.stringify({
      "id" : this.state.selectedId,
      "accept" : this.state.type
    })
    if(this.state.locationAdmin){
      url = devconfig.locationAdmin + "/vendor/verify-request";
    }
    await APIPost(url, data).then(function(response){
      this.getData(this.state.pageNum);
    }.bind(this))
  }

  verifyClick(tempId, tempType){
    this.setState({selectedId:tempId, type: tempType});
  }

  render() {
    if(!this.state.data && this.state.apiLoading){
      return (
        <div>
          <APILoader/>
        </div>
      );
    }
    else{
      return (
        <div>
          {
            this.state.data.length ?
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Company</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Location</th>
                      <th>Seller type</th>
                      <th>Action</th>
                      {/*<th>Total Amount</th>
                      <th>Pending Payment</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.data.map((item, index) => {
                        return(
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.company}</td>
                            <td>{item.mobile}</td>
                            <td>{item.email}</td>
                            <td>{item.location}</td>
                            <td>{item.seller_type}</td>
                            {
                              this.props.adminType>1 && !localStorage.getItem("truekhoj_locationadmin")?
                              <td>
                                <span className="e-accept" data-toggle="modal" data-target="#addAdminModal" onClick={() => this.setState({ modalOn: true, location:item.location_data})}>Assign Location Admin</span>
                              </td>
                              :
                              <td>
                              <span className="e-accept"  data-toggle="modal" data-target="#e-accept-Modal" onClick={this.verifyClick.bind(this, item.id, true)}>Accept</span>
                              <span className="e-reject"  data-toggle="modal" data-target="#e-reject-Modal" onClick={this.verifyClick.bind(this, item.id, false)}>Reject</span>
                            </td>
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                {
                  this.state.pageNumActive ?
                    <div className="text-center">
                      {
                        this.state.pageNumLoader ?
                          <APILoader/>
                        :
                          <button className="e-s-btn-2" onClick={this.loadMore.bind(this)}>Load More</button>
                      }
                    </div>
                  :
                    null
                }
              </div>
            :
              <NothingFound data="No vednor details found"/>
          }

          <ConfirmModal data={"Are you sure want to reject this request!"} success={this.verify.bind(this)} id={"e-reject-Modal"}/>

          <ConfirmModal data={"Are you sure want to accept this request!"} success={this.verify.bind(this)} id={"e-accept-Modal"}/>
          <AddLocationAdmin modalOn={this.state.modalOn} modalClose={() => this.setState({ modalOn: false })} success={() => { this.triggerToast("Location Admin invited successfully", "success"); this.getActiveData(); }} location={this.state.location} locationSelected={true}/>
        </div>
      );
    }
    
  }
}

export default PendingVendor;
