
 /*Import Packages*/
import React, { Component } from 'react';
import {BrowserRouter, Route, Redirect} from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*Import pages*/
import Login from './container/auth/login.js';
import Dashboard from './container/home/dashboard.js';
import OnlineLayoutsList from './container/online/layouts/layouts-list.js';
import OnlineHomeLayout from './container/online/layouts/home.js';
import OnlineCategoryLayout from './container/online/layouts/category.js';
import OnlineSubCategoryLayout from './container/online/layouts/subcategory.js';
import OnlineDealsPagesLayout from './container/online/layouts/deals-pages.js';
import OnlineProductListPagesLayout from './container/online/layouts/product-listing.js';

import LocalLayoutsList from './container/local/layouts/layouts-list.js';
import LocalHomeLayout from './container/local/layouts/home.js';
import LocalCategoryLayout from './container/local/layouts/category.js';
import LocalSubCategoryLayout from './container/local/layouts/subcategory.js';
import LocalDealsPagesLayout from './container/local/layouts/deals-pages.js';
import LocalProductListPagesLayout from './container/local/layouts/product-listing.js';
import LocationAdminDetail from './container/online/locationAdmin/adminDetail.js';

import VendorsList from './container/online/vendors/list.js';
import VendorDetail from './container/online/vendors/detail.js';
import UsersList from './container/online/users/list.js';
import InvoiceList from './container/online/invoice/list.js';
import SendNotification from './container/online/notification/send.js';
import ScheduledAds from './container/online/scheduledAds/adsList.js';
import NewPlan from './container/online/membership/newPlan.js';
import EditPlan from './container/online/membership/editPlan.js';
import AdPlansList from './container/online/adPlans/list.js';
import NewAdPlan from './container/online/adPlans/newPlan.js';
import Quotations from './container/local/quotation.js';

import CategorySettings from './container/online/category/list.js';
import LocalCategorySettings from './container/local/category/list.js';
import LocationAdminList from './container/online/locationAdmin/adminList.js';
import CareerList from './container/online/career/list.js';
import OnlineAdminList from './container/online/onlineAdmin/adminList.js';
import JoinedUsers from './container/users/users-list';

/*Import global components like header, footers*/
import Sidebar from './components/navigations/sidebar.js';
import Topbar from './components/navigations/topbar.js';
import OnlineAdminDetail from './container/online/onlineAdmin/adminDetail.js';
import PageBuilder from './container/local/pagebuilder/pageBuilder.js';
import Megamenu from './container/local/megamenu.js';
import OnlineOtherAds from './container/online/otherAds/otherAds.js';
import MembershipListLocal from './container/online/membership/localList.js';
import MembershipListOnline from './container/online/membership/onlineList.js';
import LocalPopups from './container/local/popups.js';
import OnlinePopups from './container/online/otherAds/popups.js';
import SuperValueStoreLayout from './container/local/layouts/super-value-store.js';
import Support from './container/supportDesk/index';


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      adminType: 1
    }
  }

  componentDidMount(){
    let temp = JSON.parse(localStorage.getItem("trueKhoj_data"))
    this.setState({adminType: temp && temp.type? temp.type: 1});
  }

  render() {
    if(localStorage.getItem("truekhoj_admin")){
      return(
        <section>
          <BrowserRouter>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-2 e-sidebar-wrap p-0">
                <Sidebar/>
              </div>
              <div className="col-lg-10 p-0">
                <Topbar/>
                <div>
                  <Route path="/" exact render={() => (<Redirect to="/dashboard" />)} />
                  {
                    this.state.adminType===2?
                    <>
                    <Route path="/dashboard" component={LocalLayoutsList}/>
                    <Route path="/category-settings" component={LocalCategorySettings}/>
                    </>
                    :
                    <>
                    {/* <Route path="/dashboard" component={OnlineLayoutsList}/>
                    <Route path="/category-settings" component={CategorySettings}/> */}
                    </>
                  }
                  <Route path="/online-layouts" component={OnlineLayoutsList}/>
                  <Route path="/online-layout-home" component={OnlineHomeLayout}/>
                  <Route path="/online-layout-category" component={OnlineCategoryLayout}/>
                  <Route path="/online-layout-subcategory" component={OnlineSubCategoryLayout}/>
                  <Route path="/online-deals" component={OnlineDealsPagesLayout}/>
                  <Route path="/product-listing" component={OnlineProductListPagesLayout}/>
                  <Route path="/quotations" component={Quotations}/>
                  <Route path="/local-layouts" component={LocalLayoutsList}/>
                  <Route path="/local-layout-home" component={LocalHomeLayout}/>
                  <Route path="/local-layout-category" component={LocalCategoryLayout}/>
                  <Route path="/local-layout-subcategory" component={LocalSubCategoryLayout}/>
                  <Route path="/local-deals" component={LocalDealsPagesLayout}/>
                  <Route path="/local-product" component={LocalProductListPagesLayout}/>

                  <Route path="/user-list" component={JoinedUsers}/>
                  <Route path="/local-vendors" component={VendorsList}/>
                  <Route path="/online-vendors" render={()=><VendorsList online={true}/>}/>
                  <Route path="/vendor-detail" component={VendorDetail}/>
                  <Route path="/invoices" component={InvoiceList}/>
                  <Route path="/push-notification" component={SendNotification}/>
                  <Route path="/scheduled-ads" component={ScheduledAds}/>
                  <Route path="/local-membership-plans" component={MembershipListLocal}/>
                  <Route path="/online-membership-plans" component={MembershipListOnline}/>
                  <Route path="/new-plan" component={NewPlan}/>
                  <Route path="/edit-plan" component={EditPlan}/>
                  <Route path="/ad-plans" component={AdPlansList}/>
                  <Route path="/new-ad-plan" component={NewAdPlan}/>
                  <Route path="/career" component={CareerList}/>
                  <Route path="/location-admins" exact component={LocationAdminList}/>
                  <Route path="/online-admins" exact component={OnlineAdminList}/>
                  <Route path="/location-admin/detail" component={LocationAdminDetail}/>
                  <Route path="/online/admin-detail" component={OnlineAdminDetail}/>
                  <Route path="/page-builder" component={PageBuilder}/>
                  <Route path="/local-ads" component={Megamenu}/>
                  {/* <Route path="/other-ads" component={OnlineOtherAds}/> */}
                  <Route path="/local-popup" component={LocalPopups}/>
                  <Route path="/super-value-store" component={SuperValueStoreLayout}/>
                  <Route path="/online-popup" component={OnlinePopups}/>
                  <Route path="/support" component={Support}/>
                </div>
              </div>
            </div>
          </div>
          </BrowserRouter>
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover
          />
        </section>
      )
    }
    else if(localStorage.getItem("truekhoj_locationadmin")){
      return(
        <section>
          <BrowserRouter>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-2 e-sidebar-wrap p-0">
                <Sidebar/>
              </div>
              <div className="col-lg-10 p-0">
                <Topbar/>
                <div>
                  <Route path="/dashboard" component={LocalLayoutsList}/>
                  <Route path="/category-settings" component={LocalCategorySettings}/>
                  <Route path="/local-layouts" component={LocalLayoutsList}/>
                  <Route path="/local-layout-home" component={LocalHomeLayout}/>
                  <Route path="/local-layout-category" component={LocalCategoryLayout}/>
                  <Route path="/local-layout-subcategory" component={LocalSubCategoryLayout}/>
                  <Route path="/local-deals" component={LocalDealsPagesLayout}/>
                  <Route path="/product-listing" component={LocalProductListPagesLayout}/>

                  <Route path="/quotations" component={Quotations}/>
                  <Route path="/users" component={UsersList}/>
                  <Route path="/vendors" component={VendorsList}/>
                  <Route path="/vendor-detail" component={VendorDetail}/>
                  <Route path="/invoices" component={InvoiceList}/>
                  <Route path="/push-notification" component={SendNotification}/>
                  <Route path="/scheduled-ads" component={ScheduledAds}/>
                  {/* <Route path="/membership-plans" component={MembershipList}/> */}
                  <Route path="/new-plan" component={NewPlan}/>
                  <Route path="/edit-plan" component={EditPlan}/>
                  <Route path="/ad-plans" component={AdPlansList}/>
                  <Route path="/new-ad-plan" component={NewAdPlan}/>
                  <Route path="/career" component={CareerList}/>
                  {/* <Route path="/location-admins" component={LocationAdminList}/> */}


                </div>
              </div>
            </div>
          </div>
          </BrowserRouter>
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover
          />
        </section>
      )
    }
    else{
      return(
        <section>
          <BrowserRouter>
          <Route path="/" component={Login}/>
          </BrowserRouter>
        </section>
      )
    }

  }
}

export default App;
