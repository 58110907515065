
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import NothingFound from '../../../components/other/nothing';
import APILoader from '../../../components/other/api-loader';

/*Services*/
import devconfig, { locationAdmin } from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';

/*Assets*/


class BlockedVendor extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: false,
      pageNum: 1,
      data: [],
      pageNumActive: true,
      pageNumLoader: false
    }
  }

  componentDidMount() {
    if(localStorage.getItem("truekhoj_locationadmin")){
      this.setState({locationAdmin:true});
    }
    this.getData(this.state.pageNum);
  }

  getData(tempPageNum){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1+"/vendor/list";
    if(localStorage.getItem("truekhoj_locationadmin")){
      url = devconfig.locationAdmin+"/vendor/list";
    }
    var data = JSON.stringify({
      "page_num" : tempPageNum,
      "filter_type" : 3
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){

        if(response.data.length <= 39){
          this.setState({ pageNumActive: false});
        }
        else{
          this.setState({ pageNumActive: true});
        }


        if(this.state.data.length){
          var tempData = this.state.data;
          tempData .push(response.data);
          this.setState({ data: tempData , apiLoading: false, pageNumLoader: false});
        }
        else{
          this.setState({ data: response.data, apiLoading: false, pageNumLoader: false});
        }


        
      }
      else{
        this.setState({ apiLoading: false});
      }
    }.bind(this))
  }

  tabItemClick(tempItem){
    this.setState({tabIndex: tempItem});
  }  

  loadMore(){
    this.getData(this.state.pageNum + 1);
    this.setState({pageNum: this.state.pageNum + 1, pageNumLoader: true});
  }

  render() {
    if(!this.state.data && this.state.apiLoading){
      return (
        <div>
          <APILoader/>
        </div>
      );
    }
    else{
      return (
        <div>
          {
            this.state.data.length ?
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Company</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Location</th>
                      <th>Seller type</th>
                      {/*<th>Total Amount</th>
                      <th>Pending Payment</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.data.map((item, index) => {
                        return(
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.company}</td>
                            <td>{item.mobile}</td>
                            <td>{item.email}</td>
                            <td>{item.location}</td>
                            <td>{item.seller_type}</td>
                            <td><Link to={"/vendor-detail?id="+item.id}>View detail</Link></td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                {
                  this.state.pageNumActive ?
                    <div className="text-center">
                      {
                        this.state.pageNumLoader ?
                          <APILoader/>
                        :
                          <button className="e-s-btn-2" onClick={this.loadMore.bind(this)}>Load More</button>
                      }
                    </div>
                  :
                    null
                }
              </div>
            :
              <NothingFound data="No vednor details found"/>
          }
          
        </div>
      );
    }
    
  }
}

export default BlockedVendor;
