
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


/*Components*/
// import DetailInvoice from '../../../components/modals/invoiceDetailModal';
// import CareerDetail from '../../../components/modals/careerDetailModal';
// import APILoader from '../../../components/other/api-loader';
// import NothingFound from '../../../components/other/nothing';
// import ConfirmationModal from '../../../components/modals/confirmationModal';
// import CareerModal from '../../../components/modals/newCareerModal';
import PlanList from '../../../components/plan/planList.js';


/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import NoDataFound from '../../../components/other/nothing.js';
import APILoader from '../../../components/other/api-loader.js';

/*Assets*/



class MembershipListOnline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiLoading: true,
      data: [],
      pageNumActive: true,
      pageNumLoader: false,
      pageNum: 1,
      tabIndex: 1,
    }
  }

  getPlans() {
    this.setState({ apiLoading: true });
    var url = devconfig.adminV1 + "/online/plan/list";
    var data = JSON.stringify({
      type: this.state.tabIndex
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ data: response.data, baseData: [...response.data], apiLoading: false });
      }
      else {
        this.setState({ apiLoading: false, data: [] });
      }
    }.bind(this))
  }

  componentDidMount() {
    this.getPlans();
  }

  keyDownHandler(e) {
    if (e.keyCode == 13) this.submitSearch();
  }

  submitSearch() {
    this.setState({ apiLoading: true });
    if (this.state.query && this.state.baseData && this.state.baseData.length) {
      let query = this.state.query.toLowerCase();
      let base = this.state.baseData;
      let out = [];
      base.forEach(item => {
        if (item.name && item.name.toLowerCase().includes(query)) out.push(item);
      });
      this.setState({ data: out });
    }
    else if (this.state.baseData && this.state.baseData.length) {
      let out = [...this.state.baseData];
      this.setState({ data: out });
    }
    this.setState({ apiLoading: false });
  }


  tabItemClick(tempItem) {
    if (tempItem != this.state.tabIndex) this.setState({ tabIndex: tempItem, query: "" }, () => this.getPlans());
  }

  render() {

    return (
      <div className="e-main-content e-vendor-table">
        <h2 className="e-page-head">Online Plans</h2>
        <div className="row mb-4">
          <div className="col-lg-6 e-vendor-tab-link-wrap pt-4">
            {
              this.state.tabIndex === 1 ?
                <span className="e-active">Membership Plans</span>
                :
                <span onClick={this.tabItemClick.bind(this, 1)}>Membership Plans</span>
            }
            {
              this.state.tabIndex === 2 ?
                <span className="e-active">Advertisement Plans</span>
                :
                <span onClick={this.tabItemClick.bind(this, 2)}>Advertisement Plans</span>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 pt-3">
            <div className="e-search-wrap e-category-search w-100 position-relative">
              <input className="e-search-input w-100" placeholder="Search with plan name" value={this.state.query} type="text" onChange={(e) => this.setState({ query: e.target.value })} onKeyDown={this.keyDownHandler.bind(this)} required />
              {
                this.state.query && this.state.query.length ?
                  <div className="e-search-btn position-absolute" onClick={this.submitSearch.bind(this)}></div>
                  :
                  null
              }
            </div>
          </div>
          {
            this.state.apiLoading ?
              <div className="col-12 pt-4">
                <APILoader />
              </div>
              :
              this.state.data && this.state.data.length ?

                <div className="col-12 pt-4">
                  <PlanList data={this.state.data} type={this.state.tabIndex} />
                </div>
                :
                <div className="col-12 pt-4">
                  <NoDataFound data="No plans found" />
                </div>
          }
        </div>
      </div>

    );
  }
}

export default MembershipListOnline;
