
/*Package importing*/
import React, { Component } from 'react';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

/*Components*/


/*Assets*/


class DateRange extends Component {
  constructor(props){
    super(props);
    this.state = {
      initalDate: moment().format(),
      startDate: moment().startOf('month'),
      endDate: moment(),
    }
  }

  componentDidMount() {

  }


  onDatesChange = async ({ startDate, endDate }) => {
    this.setState({startDate, endDate})
    this.props.onDatesChange({ startDate, endDate })
    // if(startDate && endDate){
    //   var fromDate = startDate.format("YYYY-MM-DD");
    //   var toDate = endDate.format("YYYY-MM-DD");

    //   await this.setState({ 
    //     startDate, 
    //     endDate,
    //     fromDate: fromDate, 
    //     toDate: toDate, 
    //     data: [],
    //     pageNum: 1,
    //     loading: true,
    //     dateFilter: true
    //   });
      
    //   await this.getData();
    // }
  }

  render() {
    return (
      <div className="">
        <DateRangePicker
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
          small={true}
        />
      </div>
      
    );
  }
}

export default DateRange;
