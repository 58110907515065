
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*Components*/
import HeadingSelectionModal from '../../../components/modals/headingSelectionModal';
import ListSelectionModal from '../../../components/modals/listSelectionModal';
import SectionHeadingStyle from '../../../components/elementsPreview/sectionHeadingStyle';
import Input from '../../../components/formComponents/input';
import CardFourColumn from '../../../components/cards/column-4.js';
import CardSixColumn from '../../../components/cards/column-6.js';
import CardTwoColumn from '../../../components/cards/column-2';
import CardThreeColumn from '../../../components/cards/column-3';
import UploadDummyAdsModal from '../../../components/modals/uploadDummyAdsModal';
import UploadDummyCatBannerModal from '../../../components/modals/uploadDummyCategorybanner';
import AddMenu from '../../../components/modals/addMenu';
import VideoPlayer from '../../../components/common/video';
import AddBg from '../../../components/modals/addBg';
import SelectBox from '../../../components/formComponents/selectBox';
import AddButtonLink from '../../../components/modals/addButtonLink';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';

/*Assets*/


class LocalSubCategoryLayout extends Component {
  notify = e => toast(e);
  constructor(props) {
    super(props);
    this.state = {
      layout: [],
      custom_link: null,
      ads: {
        hero: { id: 89, page_id: 3,   data: [{ content: null, link: null }] },
        favStores: {
          id: 90, page_id: 3,link: null, data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
          ],
          vendors: [null, null, null, null]
        },
        storeSale: {
          id: 91, page_id: 3, data: [{ content: null, link: null }, { content: null, link: null }],
        },
        brandAdsBanner: { id: 92, page_id: 3, data: [{ content: null, link: null }] },
        popularBrands: {
          id: 93, page_id: 3, data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
          ],
        },
        moreBrands: {
          id: 94, page_id: 3, data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
          ],
        },
      },
      id: null,
      selectItemIndex: null,
      categoryData: null,
      selectedSubCategories: null,
      data: null,
      name: null,
      adDummyType: null,
      swapIndex: "",
      catBannerAdIndex: null,
      categoryId: null,
      updateApiLoading: false,
      modalRefresh: false,
      menu: null,
      menuApiLoading: true,
      adIndex: 0,
      vendorOptions: []
    }
  }

  componentDidMount() {
    GetURLVar().then(function (query) {
      if (query) {
        this.getData(query.id);
        this.getMenu(query.id);
        this.setState({ id: query.id });
        // this.getCategoryData();
        this.getVendorList();
      }
    }.bind(this))
  }

  addAdIds(response, type){
    let data = response;
    if(type==="ad"){
      if(data.hero) data.hero.id = 89;
      if(data.favStores) data.favStores.id = 90;
      if(data.storeSale) data.storeSale.id = 91;
      if(data.brandAdsBanner) data.brandAdsBanner.id = 92;
      if(data.popularBrands) data.popularBrands.id = 93;
      if(data.moreBrands) data.moreBrands.id = 94;
      this.setState({ads: data});
    }
  }

  getVendorList(){
    var url = devconfig.localAdmin+"/vendor/names";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        if(response.data && response.data.length){
          let temp = []
          const vendors = response.data;
          vendors.forEach(vendor=>{
            temp.push({value: vendor.id, label: vendor.name});
          });
          this.setState({vendorOptions: temp});
        }
      }
    }.bind(this))
  }

  setVendor(vendor, i){
    let temp = this.state.ads;
    if(temp.favStores.vendors){
      temp.favStores.vendors[i] = vendor;
    }
    this.setState({ads: temp});
  }

  addMoreStores(){
    let temp = this.state.ads;
    temp.favStores.vendors = [...temp.favStores.vendors, null, null];
    temp.favStores.data = [...temp.favStores.data, {content: null, link: null}, {content: null, link: null}];
    this.setState({ads: temp});
  }

  getMenu(tempId) {
    this.setState({ menuApiLoading: true });
    var url = devconfig.adminV1 + "/local/topmenu/list";
    var data = JSON.stringify({
      "layout_id": Number(tempId)
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        if (response.data.length) {
          this.setState({ menu: response.data[0], menuApiLoading: false });
        }
        else {
          this.setState({ menu: [], menuApiLoading: false });
        }
      }
    }.bind(this))
  }

  getData(tempId) {
    var url = devconfig.adminV1 + "/local/page/detail";
    var data = JSON.stringify({
      "layout_id": tempId
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        if (response.data.page_data.layout) {
          this.setState({ layout: response.data.page_data.layout });
        }
        if (response.data.page_data.ads) {
          this.addAdIds(response.data.page_data.ads, "ad");
          // this.setState({ads: response.data.page_data.ads});
        }
        this.setState({ name: response.data.name, categoryId: response.data.category_id });
        this.getChildCategory(response.data.category_id);
      }
    }.bind(this))
  }

  addSection() {
    var tempArry = {
      "header": { "content": null, "type": null, "font_color": null, "bg_color": null, category: null },
      "ads": { "content": null, "link": null },
      "data": { "content": null, "image": null, "stlye": null },
      "id": 12,
      "favStore" : {},
      "page_id": 3,
    }

    var tempLayout = this.state.layout;
    tempLayout.push(tempArry);
    this.setState({ layout: tempLayout });
  }

  removeSection(tempIndex) {
    var tempLayout = this.state.layout;
    tempLayout.splice(tempIndex, 1);
    this.setState({ layout: tempLayout, selectedSubCategories: null });
  }

  headingSelectSuccess(tempItem) {
    var tempArry = this.state.layout;
    tempArry[this.state.selectItemIndex].header = tempItem;
    this.setState({ layout: tempArry });

    // if (tempItem.category) {
    //   this.getChildCategory(tempItem.category);
    // }
    // else {
    //   this.getChildCategory();
    // }
  }

  bgColorSuccess(tempColor) {
    var tempArry = this.state.layout;
    tempArry[this.state.selectItemIndex].bg_color = tempColor;
    this.setState({ layout: tempArry });
  }

  getChildCategory(tempItem) {

    var url = devconfig.localAdmin + "/subcategory/list";
    var tempSubCat = [];
    if (tempItem) {
      tempSubCat = [tempItem];
    }

    var data = JSON.stringify({
      "category_ids": tempSubCat,
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        var tempArry = [];
        response.data.forEach((item, index) => {
          var tempItem = { value: item.id, label: item.sub_category }
          tempArry.push(tempItem);
        });

        this.setState({ categoryList: tempArry });
      }
    }.bind(this));
  }

  listSelectSuccess(tempItem, tempType) {
    console.log('cate', tempItem)
    var tempArry = this.state.layout;
    if(tempType){
      if(tempArry[this.state.selectItemIndex].favStore && tempArry[this.state.selectItemIndex].favStore.data){
        var temp = tempArry[this.state.selectItemIndex].favStore.data.concat(tempItem.vendors.data);
        var tVendor =  tempArry[this.state.selectItemIndex].favStore.vendors.concat(tempItem.vendors.vendors);
        tempArry[this.state.selectItemIndex].favStore.data = temp;
        tempArry[this.state.selectItemIndex].favStore.vendors = tVendor;
        console.log('temp data ->', temp);
        console.log('temp vendor-->', tVendor);
        console.log('favStoer-->', tempArry);
      }else{
        tempArry[this.state.selectItemIndex].favStore = tempItem.vendors ;
      }
    }
    else{
      if (tempArry[this.state.selectItemIndex].data && tempArry[this.state.selectItemIndex].data.content) {
        var temp = tempArry[this.state.selectItemIndex].data.content.concat(tempItem.content);
        tempArry[this.state.selectItemIndex].data.content = temp;
        tempArry[this.state.selectItemIndex].data.style = tempItem.style;
        tempArry[this.state.selectItemIndex].data.type = tempItem.type;
      }
      else {
        tempArry[this.state.selectItemIndex].data = tempItem;
      }
  
    }
   
    this.setState({ layout: tempArry });
  }

  selectedIndex(tempindex) {
    this.setState({ selectItemIndex: tempindex });
  }

  sectionSwapInputChange(tempClass, e) {
    $(".e-section-swap-btn").removeClass("e-active");
    $(tempClass).addClass("e-active");
  }

  sectionSwapInputReset() {
    $(".e-section-swap-btn").removeClass("e-active");
  }

  // getCategoryData(){
  //   var url = devconfig.localAdmin + "/category/list";
  //   APIGet(url).then(function (response) {
  //     if (response.status === "ok") {
  //       var tempArry = [];
  //       response.data.map(function (item, index) {
  //         var tempItem = { value: item.id, label: item.name }
  //         tempArry.push(tempItem);
  //       });

  //       this.setState({ categoryList: tempArry, categoryData: response.data });
  //     }
  //   }.bind(this))
  // }

  // getSubCategoryData(tempId){
  //   var url = devconfig.adminV1+"/subcategory/list";
  //   var data = JSON.stringify({
  //     "category_ids" : [tempId],
  //   })
  //   APIPost(url, data).then(function(response){
  //     if(response.status === "ok"){
  //       var tempArry = [];
  //       response.data.map(function(item, index){
  //         var tempItem = {value: item.id, label: item.sub_category}
  //         tempArry.push(tempItem);
  //       });

  //       this.setState({categoryList: tempArry, categoryData: response.data});
  //     }
  //   }.bind(this))
  // }

  updatePageLayout() {
    this.setState({ updateApiLoading: true });
    setTimeout(function () { this.setState({ updateApiLoading: false }); }.bind(this), 1000);
    var tempArry = {
      layout: this.state.layout,
      ads: this.state.ads
    }
    var url = devconfig.adminV1 + "/local/page/update";
    var data = JSON.stringify({
      "layout_id": this.state.id,
      "page_data": tempArry
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {

      }
    }.bind(this))
  }

  async adsDummySuccess(tempImg, tempLink, tempIndex) {
    var tempArry = this.state.ads;
    let layout = this.state.layout;

    await this.setState({ modalRefresh: true });

    switch (this.state.adDummyType) {
      case 1: {
        tempArry.hero.data[0] = { content: tempImg, link: tempLink };
        // tempArry.hero.page_id = 3;
        // tempArry.hero.id = 15;
        break;
      }
      case 2: {
        tempArry.favStores.data[this.state.adIndex] = { content: tempImg, link: tempLink };
        // tempArry.favStores.id = 16;
        // tempArry.favStores.page_id = 3;
        break;
      }
      case 3: {
        tempArry.storeSale.data[this.state.adIndex] = { content: tempImg, link: tempLink };
        // tempArry.storeSale.id = 17;
        // tempArry.storeSale.page_id = 3;
        break;
      }
      case 4: {
        tempArry.brandAdsBanner.data[0] = { content: tempImg, link: tempLink };
        // tempArry.brandAdsBanner.id = 18;
        // tempArry.brandAdsBanner.page_id = 3;
        break;
      }
      case 5: {
        tempArry.popularBrands.data[this.state.adIndex] = { content: tempImg, link: tempLink };
        // tempArry.popularBrands.id = 19;
        // tempArry.popularBrands.page_id = 3;
        break;
      }
      case 6: {
        tempArry.moreBrands.data[this.state.adIndex] = { content: tempImg, link: tempLink };
        // tempArry.moreBrands.id = 20;
        // tempArry.moreBrands.page_id = 3;
        break;
      }
      case 7: {
        layout[this.state.selectItemIndex].header = { content: tempImg, link: tempLink , categoty: null };
        this.setState({ layout: layout });
        break;
      }

    }

    this.setState({ ads: tempArry, modalRefresh: false });
  }

  adDummyTypeClick(tempItem, tempIndex) {
    this.setState({ adDummyType: tempItem, adIndex: tempIndex });
  }

  removeDummyAds(tempItem, tempIndex) {
    let tempArry = this.state.ads;
    let layout = this.state.layout;

    switch (tempItem) {
      case 1: {
        tempArry.hero.data[0] = { content: null, link: null };
        break;
      }
      case 2: {
        tempArry.favStores.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 3: {
        tempArry.storeSale.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 4: {
        tempArry.brandAdsBanner.data[0] = { content: null, link: null };
        break;
      }
      case 5: {
        tempArry.popularBrands.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 6: {
        tempArry.moreBrands.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 7:{
        layout[tempIndex].header = { "content": null, "type": null, "font_color": null, "bg_color": null, category: null };
        this.setState({ layout: layout });
        break;
      }
    }

    this.setState({ ads: tempArry });
  }

  sectionSwap(tempCurrectIndex, tempClass) {
    var tempArry = this.state.layout;
    var tempSwapValue = $(tempClass).val();

    if (tempSwapValue > tempArry.length || tempSwapValue <= 0) {
      this.notify("Swap index must be between 0 to " + tempArry.length)
    }
    else {
      if (tempArry[tempSwapValue - 1]) {
        var b = tempArry[tempCurrectIndex - 1];
        tempArry[tempCurrectIndex - 1] = tempArry[tempSwapValue - 1];
        tempArry[tempSwapValue - 1] = b;
        $(".e-section-swap-btn").removeClass("e-active");
        $(tempClass).val("")
      }
      else {
        this.notify("Please verify your input data")
      }
      this.setState({ layout: tempArry });
    }
  }

  catBannerIndexClick(tempIndex) {
    this.setState({ catBannerAdIndex: tempIndex });
  }

  catAdsBannerSuccess(tempImg, tempLink) {
    var tempArry = this.state.layout;
    var tempItem = { "content": tempImg, "link": tempLink };

    tempArry[this.state.catBannerAdIndex].ads = { "content": tempImg, "link": tempLink };
    this.setState({ layout: tempArry });
  }

  removeCatAds(tempIndex) {
    var tempArry = this.state.layout;
    tempArry[tempIndex].ads = { "content": null, "link": null };
    this.setState({ layout: tempArry });
  }

  render() {
    return (
      <>
        <div className="e-main-content e-driver-detail-wrap e-dash-wrap">
          <h2 className="e-page-head">{this.state.name} Page Layout</h2>

          {
            this.state.updateApiLoading ?
              <button className="e-p-btn e-nav-button e-inactive e-loading e-page-layout-btn"></button>
              :
              <span>
                {
                  this.state.layout && this.state.layout.length ?
                    <button className="e-p-btn e-nav-button" onClick={this.updatePageLayout.bind(this)}>Update page</button>
                    :
                    <button className="e-p-btn e-nav-button e-inactive">Update page</button>
                }
              </span>
          }

          <div className="row e-menu-setting-wrap">
            <div className="col-lg-12">
              <h6>Menu Settings {
                !this.state.menu || (this.state.menu.sub_page_data && this.state.menu.sub_page_data.length) || !this.state.menu.sub_page_data <= 7 ?
                  <span data-toggle="modal" data-target="#addMenuModal">Edit/Add</span>
                  :
                  null
              }</h6>
            </div>
            <div className="col-lg-12">
              {
                this.state.menuApiLoading ?
                  null
                  :
                  <React.Fragment>
                    {
                      this.state.menu ?
                        <AddMenu data={this.state.menu.sub_page_data} id={this.state.id} />
                        :
                        <AddMenu id={this.state.id} />
                    }
                  </React.Fragment>
              }

            </div>

            <div className="col-lg-12">
              {
                this.state.menu && this.state.menu.sub_page_data ?
                  <React.Fragment>
                    {
                      this.state.menu.sub_page_data.map((data, index) => {
                        return (
                          <span className="e-menu-item-tag mr-3">{data.name}</span>
                        );
                      })
                    }
                  </React.Fragment>
                  :
                  <React.Fragment>
                    {
                      this.state.menuApiLoading ?
                        null
                        :
                        <span className='e-no-menu-item'>No menu item found. Click Edit/Add to update menu.</span>
                    }
                  </React.Fragment>
              }
            </div>
            {/*
            this.state.menu && this.state.menu.sub_page_data ?
              <AddMenu data={this.state.menu.sub_page_data} id={this.state.id}/>
            :
              <AddMenu id={this.state.id}/>
          */}

          </div>

          <div className="row mt-5">
            <div className="col-lg-12">
              <div className="e-layout-wrap mb-3">

                <div className="row">
                  <div className="col-lg-12">
                    <div className="e-layout-row e-ad-row">
                      {
                        this.state.ads && this.state.ads.hero.data[0] && this.state.ads.hero.data[0].content ?
                          <div className="position-relative e-hero-dummy-img-wrap">
                            <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 1)}></span>
                            <a href={this.state.ads.hero.data[0].link} target="_blank">
                              {
                                this.state.ads.hero.data[0].content && this.state.ads.hero.data[0].content.indexOf(".mp4") > -1 ?
                                  <VideoPlayer url={this.state.ads.hero.data[0].content} />
                                  :
                                  <img className="e-hero-dummy-img" src={this.state.ads.hero.data[0].content} alt="Truekhoj hero" />
                              }
                            </a>
                          </div>
                          :
                          <div className="e-ad-full-placeholder">
                            <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 1)}></span>
                          Hero banner advertisement section
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="e-layout-wrap mb-3">

                <div className="row mt-2">
                  <div className="col-lg-12 mb-4">
                    <h6 className="text-center">Favorite Stores</h6>
                  </div>
                  {
                    this.state.ads && this.state.ads.favStores.data && this.state.ads.favStores.data.length ?
                      <React.Fragment>
                        {
                          this.state.ads.favStores.data.map((item, index) => {
                            return (
                              <div className={this.state.ads.favStores.data.length===6? "col-lg-2 mb-4":"col-lg-3 mb-4"}>
                                <div className="e-layout-row e-ad-row">
                                  {
                                    this.state.ads && item && item.content ?
                                      <div className="position-relative e-hero-dummy-img-wrap">
                                        <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 2, index)}></span>
                                        <a href={item.link} target="_blank">
                                          {
                                            item.content && item.content.indexOf(".mp4") > -1 ?
                                              <VideoPlayer url={item.content} />
                                              :
                                              <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                          }
                                        </a>
                                      </div>
                                      :
                                      <div className="e-ad-full-placeholder">
                                        <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 2, index)}></span>
                                      Your favorite {index + 1}
                                      </div>
                                  }
                                  {
                                   item.content?
                                   <div className="w-100 pt-2 e-vendor-selection">
                                   <SelectBox options={this.state.vendorOptions} defaultValue={this.state.ads.favStores.vendors? this.state.ads.favStores.vendors[index]: null} selectchange={(value)=>this.setVendor(value, index)}/>
                                 </div>
                                 :
                                 null
                                  }
                                </div>
                              </div>
                            );
                          })
                        }
                        {
                          this.state.ads.favStores.data.length<8?
                          <div className="col-lg-12 py-4">
                    <div className="e-section-head-placeholder mt-3 mb-4 shadow-sm" onClick={this.addMoreStores.bind(this)}>
                      Add more stores
                    </div>
                  </div>
                  :
                  null
                        }
                        <div className='col-lg-12 text-center my-3 py-4' style={{backgroundColor: '#b2b2f333'}}>
                          <button onClick={()=> window.open(this.state.ads.favStores.link, '_blank')}>View more</button>
                          <span className="e-link e-add-link" data-toggle="modal" data-target="#addLink" >+ Add button link</span>
                        </div>
                        
                      </React.Fragment>
                      :
                      null
                  }

                  {
                    this.state.ads && this.state.ads.storeSale.data && this.state.ads.storeSale.data.length ?
                      <React.Fragment>
                        {
                          this.state.ads.storeSale.data.map((item, index) => {
                            return (
                              <div className="col-lg-6 mb-4">
                                <div className="e-layout-row e-ad-row">
                                  {
                                    this.state.ads && item && item.content ?
                                      <div className="position-relative e-hero-dummy-img-wrap">
                                        <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 3, index)}></span>
                                        <a href={item.link} target="_blank">
                                          {
                                            item.content && item.content.indexOf(".mp4") > -1 ?
                                              <VideoPlayer url={item.content} />
                                              :
                                              <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                          }
                                        </a>
                                      </div>
                                      :
                                      <div className="e-ad-full-placeholder">
                                        <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 3, index)}></span>
                                      Store Sale {index + 1}
                                      </div>
                                  }
                                </div>
                              </div>
                            );
                          })
                        }
                      </React.Fragment>
                      :
                      null
                  }


                </div>
              </div>

              <div className="e-layout-wrap mb-3">

                <div className="col-lg-12">
                  <div className="e-layout-row e-ad-row mb-4">
                    {
                      this.state.ads && this.state.ads.brandAdsBanner && this.state.ads.brandAdsBanner.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 4)}></span>
                          <a href={this.state.ads.brandAdsBanner.data[0].link} target="_blank">
                            {
                              this.state.ads.brandAdsBanner.data[0].content && this.state.ads.brandAdsBanner.data[0].content.indexOf(".mp4") > -1 ?
                                <VideoPlayer url={this.state.ads.brandAdsBanner.data[0].content} />
                                :
                                <img className="e-hero-dummy-img" src={this.state.ads.brandAdsBanner.data[0].content} alt="Truekhoj highlight" />
                            }
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder position-relative">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 4)}></span>
                          Brand Ads banner
                        </div>
                    }
                  </div>
                </div>
    
              </div>
              
              <div className="e-layout-wrap mb-3">

                <div className="row mt-2">
                  <div className="col-lg-12 mb-4">
                    <h6 className="text-center">Popular Brands</h6>
                  </div>
                  {
                    this.state.ads && this.state.ads.popularBrands.data && this.state.ads.popularBrands.data.length ?
                      <React.Fragment>
                        {
                          this.state.ads.popularBrands.data.map((item, index) => {
                            return (
                              <div className="col-lg-4 mb-4">
                                <div className="e-layout-row e-ad-row">
                                  {
                                    this.state.ads && item && item.content ?
                                      <div className="position-relative e-hero-dummy-img-wrap">
                                        <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 5, index)}></span>
                                        <a href={item.link} target="_blank">
                                          {
                                            item.content && item.content.indexOf(".mp4") > -1 ?
                                              <VideoPlayer url={item.content} />
                                              :
                                              <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                          }
                                        </a>
                                      </div>
                                      :
                                      <div className="e-ad-full-placeholder">
                                        <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 5, index)}></span>
                                      Popular brand {index + 1}
                                      </div>
                                  }
                                </div>
                              </div>
                            );
                          })
                        }
                      </React.Fragment>
                      :
                      null
                  }
                </div>
              </div>
              <div className="e-layout-wrap mb-3">

                <div className="row mt-2">
                  <div className="col-lg-12 mb-4">
                    <h6 className="text-center">More Brands</h6>
                  </div>
                  {
                    this.state.ads && this.state.ads.moreBrands.data && this.state.ads.moreBrands.data.length ?
                      <React.Fragment>
                        {
                          this.state.ads.moreBrands.data.map((item, index) => {
                            return (
                              <div className="col-lg-2 mb-4">
                                <div className="e-layout-row e-ad-row">
                                  {
                                    this.state.ads && item && item.content ?
                                      <div className="position-relative e-hero-dummy-img-wrap">
                                        <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 6, index)}></span>
                                        <a href={item.link} target="_blank">
                                          {
                                            item.content && item.content.indexOf(".mp4") > -1 ?
                                              <VideoPlayer url={item.content} />
                                              :
                                              <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                          }
                                        </a>
                                      </div>
                                      :
                                      <div className="e-ad-full-placeholder">
                                        <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 6, index)}></span>
                                      Brand {index + 1}
                                      </div>
                                  }
                                </div>
                              </div>
                            );
                          })
                        }
                      </React.Fragment>
                      :
                      null
                  }

                </div>
              </div>
              <div className="e-layout-wrap mb-3">
              {
                this.state.layout && this.state.layout.map((data, index) => {
                  var tempClass = "e-section-swap-btn e-section-swap-" + index;
                  var tempClassSelect = ".e-section-swap-" + index;

                  var tempInputClass = "e-swap-input" + index;
                  var tempInputClassSelect = ".e-swap-input" + index;

                  var tempStyle = null;
                  if (data.bg_color) {
                    var tempStyle = { background: data.bg_color };
                  }

                  return (
                    <div className="row mt-4" key={index} style={tempStyle}>
                      <div className="col-lg-12">
                        <div className="e-row-input-wrap">
                          <label className="label">Section - {index + 1}: </label>
                          <input className={tempInputClass} onChange={this.sectionSwapInputChange.bind(this, tempClassSelect)} type="text" required />
                          <span className={tempClass} onClick={this.sectionSwap.bind(this, index + 1, tempInputClassSelect)}></span>
                        </div>
                        <div className="e-layout-row e-edit-section">
                          <span className="e-close-icon" onClick={this.removeSection.bind(this, index)}></span>
                          {
                            data.header.content && !data.header.link ?
                              <div className="bg-white e-demo-element-wrap">
                                <SectionHeadingStyle data={data.header} />
                              </div>
                              : data.header.content && data.header.link ?
                              <div className="row">
                                <div className="col-lg-5"></div>
                                <div className="col-lg-2">
                                  <div className="position-relative e-hero-dummy-img-wrap">
                                    <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 7, index)}></span>
                                    <a href={data.header.link} target="_blank">
                                      <img className="e-hero-dummy-img" src={data.header.content} alt="Truekhoj highlight" />
                                    </a>
                                  </div>
                                </div>
                                <div className="col-lg-5"></div>
                              </div>
                              : this.state.selectItemIndex === index ?
                              <div className="row">
                                <div className="col-lg-5">
                                  <div className="e-upload-layout-header h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6", cursor: "pointer" }} data-toggle="modal" data-target="#headerModal">
                                    Choose Header style and content
                                  </div>
                                </div>
                                <div className="col-lg-2">
                                  <span className="e-upload-layout-header border-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6" }}>OR</span>
                                </div>
                                <div className="col-lg-5">
                                  <div className="e-upload-layout-header">
                                    <div className="e-ad-full-placeholder position-relative">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 7, index)}></span>
                                      Upload Header Image
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              <div className="e-section-head-placeholder" onClick={this.selectedIndex.bind(this, index)}>
                                + Add Section Header
                              </div>
                          }


                          {
                            data.ads && data.ads.content ?
                              <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index)}></span>
                                {
                                  data.ads.content && data.ads.content.indexOf(".mp4") > -1 ?
                                    <VideoPlayer url={data.ads.content} />
                                  :
                                    <img className="e-hero-dummy-img" src={data.ads.content} alt="Truekhoj highlight"/>
                                }
                              </div>
                            :
                              <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index)}></span>
                                Section banner advertisement
                              </div>
                        }
                           {
                              data.favStore && data.favStore.data && data.favStore.data.length?
                              <div className='row'>
                              
                              {
                                data.favStore.data.map((item, key)=>{
                                  
                                return(
                                  <div className='col-lg-6 mt-3'>
                                    <div className='card e-favitem-card'>
                                        <img  src={item.content}/>
                                        <p className='mt-3 text-center '>Vendor - {item.vendor.label}</p>
                                    </div>
                                  </div>
                                  )
                              })
                            }
                              </div>
                              :
                              null
                            }

                          {
                            data.data.content ?
                              <div>
                                <div className=" row mt-3 e-no-p">
                                {
                                    data.data.content && data.data.type === 1?
                                      <CardFourColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color}/>
                                    : data.data.content && data.data.type === 2?
                                      <CardSixColumn data={data.data.content} type={data.data.style.value}/>
                                    : data.data.content && data.data.type === 3?
                                      <CardTwoColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color}/>
                                    : data.data.content && data.data.type === 4?
                                      <CardThreeColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color}/>
                                    :
                                    null
                                  }
                                </div>

                                {
                                  this.state.layout.length == index + 1 && data.data.content.length != 8 && data.data.content.length != 12 && data.data.content.length != 10 ?
                                    <div className="e-section-head-placeholder mt-3" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#listModal">
                                      + Add Category List
                                    </div>
                                    :
                                    null

                                }

                              </div>
                              :
                              <div className="e-section-head-placeholder mt-3" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#listModal">
                                + Add Category List
                              </div>
                          }

                          <div className="text-right mt-2">
                            <span className="e-link" data-toggle="modal" data-target="#addBg" onClick={this.selectedIndex.bind(this, index)}>+ Add bg color</span>
                          </div>


                        </div>
                      </div>
                    </div>
                  );
                })
              }

              <div className="row my-4">
                <div className="col-lg-12 text-center">
                  <button className="e-s-btn" type="submit" value="Submit" onClick={this.addSection.bind(this)}>+ Add New Section & Elements</button>
                </div>
              </div>


            </div>
          
            </div>

            
          </div>
        </div>


        <HeadingSelectionModal success={this.headingSelectSuccess.bind(this)} data={this.state.categoryList} type={3} />

        {
          this.state.categoryList ?
            <ListSelectionModal success={this.listSelectSuccess.bind(this)} data={this.state.categoryList} />
            :
            null
        }

        {
          !this.state.modalRefresh ?
            <UploadDummyAdsModal success={this.adsDummySuccess.bind(this)} />
            :
            null
        }

        <UploadDummyCatBannerModal success={this.catAdsBannerSuccess.bind(this)} />

        <AddBg success={this.bgColorSuccess.bind(this)} />
        <AddButtonLink success={(temp)=> { var tempAry = this.state.ads;  tempAry.favStores.link = temp; this.setState({ ads: tempAry})  }}/>

      </>

    );
  }
}

export default LocalSubCategoryLayout;
