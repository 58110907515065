/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import ReactToPdf from "react-to-pdf";

/*Components*/
import SectionHeadingStyle from '../elementsPreview/sectionHeadingStyle';
import SelectBox from '../formComponents/selectBox';
import ColorPicker from '../common/colorPicker';
import APILoader from '../../components/other/api-loader';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';

/*Assets*/
import logo from '../../assets/images/logo.svg';

const ref = React.createRef();

class DetailInvoice extends Component {
  constructor(props){
    super(props);
    this.state = {
      colorOne: "",
      colorTwo: "",
      pickerOneID: "colorPick1",
      pickerTwoID: "colorPick2",
      category: null,
      styleType: null
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData(){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1+"/invoice/detail";
    var data = JSON.stringify({
      "id" : parseInt(this.props.id),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        if(response.data){
          this.setState({ data: response.data, apiLoading: false});
        }
        else{
          this.setState({ apiLoading: false});
        }
        
      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }


  render() {
    var data = this.state.data;

    return (
      <div className="modal fade e-detail-invoice-wrap" id={this.props.selector} tabIndex="-1" role="dialog" aria-labelledby="invoiceDetailModalLabel" aria-hidden="true">
        {
          this.state.apiLoading ?
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body pb-5" ref={ref}>
                  <APILoader/>
                </div> 
              </div>
            </div>
          :

            <div className="modal-dialog"  role="document">
              {
                this.state.data ?
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body pb-5" ref={ref}>
                      <div className="row mb-4">
                        <div className="col-lg-8">
                          <img src={logo} alt="truekhoj logo"/>
                        </div>
                        <div className="col-lg-4">
                          <h5 className="mb-3">Paid Invoice</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h6 className="mb-2">{data.admin_data.company}</h6>
                          <p className="mb-1 ">{data.admin_data.address}</p>
                          <p>GST: {data.admin_data.gst_no}</p>
                        </div>
                        <div className="col-lg-4 offset-lg-2">
                          <p className="mb-0"><b>Invoice no:</b> {data.invoice_no}</p>
                          <p><b>Invoice date:</b> {data.purchased_on}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <h4>Billing to:</h4>
                          <h6>{data.vendor_data.company}</h6>
                          <p className="mb-1">{data.vendor_data.address}</p>
                          <p>GST: {data.vendor_data.gst_no}</p>
                        </div>
                      </div>

                      <div className="row mt-5 pb-2 e-price-table-header">
                        <div className="col-lg-6">
                          <h4 className="m-0">Plan</h4>
                        </div>
                        <div className="col-lg-2">
                          <h4 className="m-0">Quantity</h4>
                        </div>
                        <div className="col-lg-2">
                          <h4 className="m-0">Price</h4>
                        </div>
                        <div className="col-lg-2">
                          <h4 className="m-0">Amount</h4>
                        </div>
                      </div>

                      <div className="row mt-4 e-price-table-item">
                        <div className="col-lg-6">
                          <p>{data.type} - {data.plan_name}</p>
                        </div>
                        <div className="col-lg-2">
                          <p>1</p>
                        </div>
                        <div className="col-lg-2">
                          <p>{data.amount}</p>
                        </div>
                        <div className="col-lg-2">
                          <p>{data.amount}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="offset-lg-8 col-lg-4 mt-3">
                          <div className="row">
                            <div className="col-lg-6">
                              <h6>Subtotal:</h6>
                            </div>
                            <div className="col-lg-6">
                              <p>{data.amount}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <h6>IGST:</h6>
                            </div>
                            <div className="col-lg-6">
                              <p>{data.gst}</p>
                            </div>
                          </div>
                          <div className="row e-price-table-total pt-3 pb-5">
                            <div className="col-lg-6">
                              <h6>Total</h6>
                            </div>
                            <div className="col-lg-6">
                              <p>{data.total}</p>
                            </div>
                          </div>
                          <span className="e-price-table-fully-paid">Fully Paid</span>
                        </div> 
                      </div>

                    </div>
                    <div className="modal-footer">
                    {/*<ReactToPdf targetRef={ref} filename="Invoice.pdf">
                      {({ toPdf }) => <button className="e-s-btn" onClick={toPdf}>Download as pdf</button>}
                    </ReactToPdf>*/}
                      
                      {/*

                      <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
                      {
                        this.state.category && this.state.styleType && this.state.colorOne.length && this.state.colorTwo.length  ?
                          <button type="submit" className="e-s-btn" value="Submit" data-dismiss="modal" onClick={this.submit.bind(this)}>Submit</button>
                        :
                          <button type="submit" className="e-s-btn e-inactive" value="Submit" >Submit</button>
                      }

                    */}
                      
                    </div>
                  </div>
                :
                  null
              }
              
            </div>
        }
      </div>
    );
  }
}

export default DetailInvoice;
