/*Package importing*/
import React, { Component } from 'react';
import Chart from "react-apexcharts";

/*Import custom components and assets*/

class GroupedBarChart extends Component {
  constructor(props){
    super(props);
    this.state = {
      series: [{ name: 'Visitors', data: [4400, 5500, 4100, 6400] }, { name: 'Ads Click', data: [1300, 1200, 1500, 1200] }],
      options: {
        legend: {fontSize: '13px', fontFamily: 'Nunito', fontWeight: "20px"},
        chart: { type: 'bar', height: 730},
        plotOptions: { bar: { horizontal: false, dataLabels: {position: 'top'}} },
        dataLabels: { enabled: false, offsetX: -6, style: { fontSize: '12px', colors: ['#fff'] } },
        stroke: { show: true, width: 1, colors: ['#fff'] },
        xaxis: { categories: ["Week 1","Week 2","Week 3","Week 4"] },
        fill: {colors: ['#002A5C', '#FFB606']}
      },
    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <Chart options={this.state.options} series={this.state.series} type="bar" height="400px" width="100%"/>
    );
  }
}

export default GroupedBarChart;
