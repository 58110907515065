
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*Components*/
import HeadingSelectionModal from '../../../components/modals/headingSelectionModal';
import ListSelectionModal from '../../../components/modals/listSelectionModal';
import SectionHeadingStyle from '../../../components/elementsPreview/sectionHeadingStyle';
import SelectBox from '../../../components/formComponents/selectBox';
import Input from '../../../components/formComponents/input';
import CardFourColumn from '../../../components/cards/column-4.js';
import CardSixColumn from '../../../components/cards/column-6.js';
import UploadDummyAdsModal from '../../../components/modals/uploadDummyAdsModal';
import UploadDummyCatBannerModal from '../../../components/modals/uploadDummyCategorybanner';
import AddMenu from '../../../components/modals/addMenu';
import VideoPlayer from '../../../components/common/video';
import AddBg from '../../../components/modals/addBg';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';

/*Assets*/


class LocalProductListPagesLayout extends Component {
  notify = e => toast(e);
  constructor(props) {
    super(props);
    this.state = {
      layout: [],
      ads: {
        hero: { page_id: 1, data: [{ content: null, link: null }] },
        intermediate: { page_id: 1, data: [{ content: null, link: null }] }
      },
      mapped_categories: [],
      id: null,
      selectItemIndex: null,
      categoryData: null,
      selectedSubCategories: null,
      data: null,
      name: null,
      adDummyType: null,
      swapIndex: "",
      catBannerAdIndex: null,
      categoryId: null,
      updateApiLoading: false,
      modalRefresh: false,
      menu: null,
      menuApiLoading: true,
      categories: [{ label: "Common", value: "*" }],
      selectedValue: { label: "Common", value: "*" }
    }
  }

  componentDidMount() {
    GetURLVar().then(function (query) {
      if (query) {
        this.getData(query.id);
        this.getMenu(query.id);
        this.setState({ id: query.id, page_id: 1});
        this.getCategoryData();
      }
    }.bind(this))
  }

  addIntermediate() {
    let ads = this.state.ads;
    if (!ads.intermediate) {
      ads = { ...ads, intermediate: { page_id: 1, data: [{ content: null, link: null }] } }
      this.setState({ ads });
    }
    return null;
  }

  insertLayout(selected, type) {
    console.log(selected, "Selected");
    let layout = this.state.layout? this.state.layout: [];
    let mapped = this.state.mapped_categories;
    if (mapped.indexOf(`${selected.value}-${type===1? '185': '186'}`) === -1) {
      mapped.push(`${selected.value}-${type===1? '185': '186'}`);
      layout.push({
        "header": { "content": selected.label, "type": null, "font_color": null, "bg_color": null, category: selected.value },
        "ads": { "content": null, "link": null },
        "page_id": 1,
        "id": type===1? 185: 186
      });
      this.setState({ mapped_categories: mapped, layout: layout });
    }
    return null
  }

  getCategoryData() {
    var url = devconfig.adminV1 + "/department/list";
    APIGet(url).then(function (response) {
      if (response && response.status === "ok") {
        let categories = [{ label: "Common", value: "*" }];
        if (response.data.length) {
          response.data.map(item => {
            var category = { value: item.id, label: item.name }
            categories.push(category);
          });
        }
        this.setState({ categories });
      }
    }.bind(this))
  }

  getMenu(tempId) {
    this.setState({ menuApiLoading: true });
    var url = devconfig.adminV1 + "/local/topmenu/list";
    var data = JSON.stringify({
      "layout_id": Number(tempId)
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        if (response.data.length) {
          this.setState({ menu: response.data[0], menuApiLoading: false });
        }
        else {
          this.setState({ menu: [], menuApiLoading: false });
        }
      }
    }.bind(this))
  }

  getData(tempId) {
    var url = devconfig.adminV1 + "/local/page/detail";
    var data = JSON.stringify({
      "layout_id": tempId
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        if (response.data.page_data.layout) {
          this.setState({ layout: response.data.page_data.layout });
        }
        if (response.data.page_data.ads) {
          this.setState({ ads: response.data.page_data.ads });
        }
        if (response.data && response.data.page_data.mapped_categories) this.setState({ mapped_categories: response.data.page_data.mapped_categories});
        this.setState({ name: response.data.name });

        if (response.data.sub_category_id) {
          this.setState({ categoryId: response.data.category_id });
          this.getChildCategory(response.data.category_id);
        }
      }
    }.bind(this))
  }

  addSection() {
    var tempArry = {
      "header": { "content": null, "type": null, "font_color": null, "bg_color": null, category: null },
      "ads": { "content": null, "link": null },
    }

    var tempLayout = this.state.layout;
    tempLayout.push(tempArry);
    this.setState({ layout: tempLayout });
  }

  removeSection(tempIndex) {
    var tempLayout = this.state.layout;
    tempLayout.splice(tempIndex, 1);
    this.setState({ layout: tempLayout, selectedSubCategories: null });
  }

  headingSelectSuccess(tempItem) {
    var tempArry = this.state.layout;
    tempArry[this.state.selectItemIndex].header = tempItem;
    this.setState({ layout: tempArry });

    if (tempItem.category) {
      this.getChildCategory(tempItem.category);
    }
    else {
      this.getChildCategory();
    }
  }

  bgColorSuccess(tempColor) {
    var tempArry = this.state.layout;
    tempArry[this.state.selectItemIndex].bg_color = tempColor;
    this.setState({ layout: tempArry });
  }

  getChildCategory(tempItem) {
    // var url = devconfig.adminV1 + "/subcategory/list";
    // var tempSubCat = [];
    // if (tempItem) {
    //   tempSubCat = [tempItem];
    // }

    // var data = JSON.stringify({
    //   "sub_category_ids": tempSubCat,
    // })
    // APIPost(url, data).then(function (response) {
    //   if (response.status === "ok") {
    //     var tempArry = [];
    //     response.data.map(function (item, index) {
    //       var tempItem = { value: item.id, label: item.product }
    //       tempArry.push(tempItem);
    //     });

    //     this.setState({ categoryList: tempArry, categoryData: response.data });
    //   }
    // }.bind(this))
  }

  listSelectSuccess(tempItem) {
    var tempArry = this.state.layout;
    if (tempArry[this.state.selectItemIndex].data && tempArry[this.state.selectItemIndex].data.content) {
      var temp = tempArry[this.state.selectItemIndex].data.content.concat(tempItem.content);
      tempArry[this.state.selectItemIndex].data.content = temp;
      tempArry[this.state.selectItemIndex].data.style = tempItem.style;
      tempArry[this.state.selectItemIndex].data.type = tempItem.type;
    }
    else {
      tempArry[this.state.selectItemIndex].data = tempItem;
    }

    this.setState({ layout: tempArry });
  }

  selectedIndex(tempindex) {
    this.setState({ selectItemIndex: tempindex });
  }

  sectionSwapInputChange(tempClass, e) {
    $(".e-section-swap-btn").removeClass("e-active");
    $(tempClass).addClass("e-active");
  }

  sectionSwapInputReset() {
    $(".e-section-swap-btn").removeClass("e-active");
  }

  // getCategoryData(){
  //   var url = devconfig.adminV1+"/subcategory/list";
  //   APIGet(url).then(function(response){
  //     if(response.status === "ok"){
  //       var tempArry = [];
  //       response.data.map(function(item, index){
  //         var tempItem = {value: item.id, label: item.category}
  //         tempArry.push(tempItem);
  //       });

  //       this.setState({categoryList: tempArry, categoryData: response.data});
  //     }
  //   }.bind(this))
  // }

  // getSubCategoryData(tempId){
  //   var url = devconfig.adminV1+"/subcategory/list";
  //   var data = JSON.stringify({
  //     "category_ids" : [tempId],
  //   })
  //   APIPost(url, data).then(function(response){
  //     if(response.status === "ok"){
  //       var tempArry = [];
  //       response.data.map(function(item, index){
  //         var tempItem = {value: item.id, label: item.sub_category}
  //         tempArry.push(tempItem);
  //       });

  //       this.setState({categoryList: tempArry, categoryData: response.data});
  //     }
  //   }.bind(this))
  // }

  updatePageLayout() {
    this.setState({ updateApiLoading: true });

    let layout = this.state.layout;
    let categories = this.state.categories;
    let mapped = this.state.mapped_categories;

    categories.forEach(category => {
      if (category.value!="*"&& mapped.indexOf(`${category.value}-185`) === -1 ) {
        mapped.push(`${category.value}-185`);
        layout.push({
          "header": { "content": category.label, "type": null, "font_color": null, "bg_color": null, category: category.value },
          "ads": { "content": null, "link": null },
          "page_id": 1,
          "id": 185
        });
      }
      if (category.value!="*"&& mapped.indexOf(`${category.value}-186`) === -1 ) {
        mapped.push(`${category.value}-186`);
        layout.push({
          "header": { "content": category.label, "type": null, "font_color": null, "bg_color": null, category: category.value },
          "ads": { "content": null, "link": null },
          "page_id": 1,
          "id": 186
        });
      }
    })

    var tempArry = {
      layout: layout,
      ads: this.state.ads,
      mapped_categories: mapped
    }
    var url = devconfig.adminV1 + "/local/page/update";
    var data = JSON.stringify({
      "layout_id": this.state.id,
      "page_data": tempArry
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ updateApiLoading: false });
      }
    }.bind(this))
  }

  async adsDummySuccess(tempUrl, tempLink) {
    var tempArry = this.state.ads;

    await this.setState({ modalRefresh: true });

    switch (this.state.adDummyType) {
      case 1: {
        tempArry.hero.data = [{ content: tempUrl, link: tempLink }];
        break;
      }
      case 2: {
        tempArry.intermediate.data = [{ content: tempUrl, link: tempLink }];
        break;
      }
      case 3:{
        let layout = this.state.layout;
        layout[this.state.layoutIndex].ads = { content: tempUrl, link: tempLink };
        this.setState({layout});
        break;
      }
    }

    this.setState({ ads: tempArry, modalRefresh: false });
  }

  adDummyTypeClick(tempItem, tempIndex) {
    this.setState({ adDummyType: tempItem, layoutIndex: tempIndex });
  }

  removeDummyAds(tempItem, tempIndex) {
    var tempArry = this.state.ads;

    switch (tempItem) {
      case 1: {
        tempArry.hero.data = { content: null, link: null };
        break;
      }
      case 2: {
        tempArry.intermediate.data = { content: null, link: null };
        break;
      }
      case 3: {
        let layout = this.state.layout;
        layout[tempIndex].ads = {content: null, link: null};
        this.setState({layout});
        break;
      }
    }

    this.setState({ ads: tempArry });
  }

  sectionSwap(tempCurrectIndex, tempClass) {
    var tempArry = this.state.layout;
    var tempSwapValue = $(tempClass).val();

    if (tempSwapValue > tempArry.length || tempSwapValue <= 0) {
      this.notify("Swap index must be between 0 to " + tempArry.length)
    }
    else {
      if (tempArry[tempSwapValue - 1]) {
        var b = tempArry[tempCurrectIndex - 1];
        tempArry[tempCurrectIndex - 1] = tempArry[tempSwapValue - 1];
        tempArry[tempSwapValue - 1] = b;
        $(".e-section-swap-btn").removeClass("e-active");
        $(tempClass).val("")
      }
      else {
        this.notify("Please verify your input data")
      }
      this.setState({ layout: tempArry });
    }
  }

  catBannerIndexClick(tempIndex) {
    this.setState({ catBannerAdIndex: tempIndex });
  }

  catAdsBannerSuccess(tempImg, tempLink) {
    var tempArry = this.state.layout;
    var tempItem = { "content": tempImg, "link": tempLink };

    tempArry[this.state.catBannerAdIndex].ads = { "content": tempImg, "link": tempLink };
    this.setState({ layout: tempArry });
  }

  removeCatAds(tempIndex) {
    var tempArry = this.state.layout;
    tempArry[tempIndex].ads = { "content": null, "link": null };
    this.setState({ layout: tempArry });
  }

  selectChange(item) {
    this.setState({ selectedValue: item },()=> console.log(this.state.selectedValue));
  }

  render() {
    console.log("this.state.ads");
    console.log(this.state.ads);

    return (
      <div className="e-main-content e-driver-detail-wrap e-dash-wrap">
        <h2 className="e-page-head">{this.state.name} Layout</h2>

        {
          this.state.updateApiLoading ?
            <button className="e-p-btn e-nav-button e-inactive e-loading e-page-layout-btn"></button>
            :
            <span>
              {
                this.state.ads.hero ?
                  <button className="e-p-btn e-nav-button" onClick={this.updatePageLayout.bind(this)}>Update page</button>
                  :
                  <button className="e-p-btn e-nav-button e-inactive">Update page</button>
              }
            </span>
        }

        <div className="row e-menu-setting-wrap">
          <div className="col-lg-12">
            <h6>Menu Settings {
              !this.state.menu || (this.state.menu.sub_page_data && this.state.menu.sub_page_data.length) || !this.state.menu.sub_page_data <= 7 ?
                <span data-toggle="modal" data-target="#addMenuModal">Edit/Add</span>
                :
                null
            }</h6>
          </div>
          <div className="col-lg-12">
            {
              this.state.menuApiLoading ?
                null
                :
                <React.Fragment>
                  {
                    this.state.menu ?
                      <AddMenu data={this.state.menu.sub_page_data} id={this.state.id}/>
                      :
                      <AddMenu id={this.state.id}/>
                  }
                </React.Fragment>
            }
          </div>

          <div className="col-lg-12">
            {
              this.state.menu && this.state.menu.sub_page_data ?
                <React.Fragment>
                  {
                    this.state.menu.sub_page_data.map((data, index) => {
                      return (
                        <span className="e-menu-item-tag mr-3">{data.name}</span>
                      );
                    })
                  }
                </React.Fragment>
                :
                <React.Fragment>
                  {
                    this.state.menuApiLoading ?
                      null
                      :
                      <span className='e-no-menu-item'>No menu item found. Click Edit/Add to update menu.</span>
                  }
                </React.Fragment>
            }
          </div>
          {/*
            this.state.menu && this.state.menu.sub_page_data ?
              <AddMenu data={this.state.menu.sub_page_data} id={this.state.id}/>
            :
              <AddMenu id={this.state.id}/>
          */}

        </div>

        <div className="row mt-5">
          <div className="col-12">
            <h6>Category</h6>
          </div>
          <div className="col-lg-2 pb-5">
            <SelectBox isValuePresent={true} selectedValue={this.state.selectedValue} options={this.state.categories} selectchange={this.selectChange.bind(this)} placeholder="Category" />
          </div>
          <div className="col-lg-12">
            <div className="e-layout-wrap mb-3">
              <h4 className="pb-3 mb-0 font-weight-bold">Hero Banner</h4>
              <div className="row">
                {
                  this.state.selectedValue && this.state.selectedValue.value === "*" ?
                    <div className="col-lg-12">
                      <div className="e-layout-row e-ad-row">
                        {
                          this.state.ads.hero && this.state.ads.hero.data.length && this.state.ads.hero.data[0].content ?
                            <div className="position-relative e-hero-dummy-img-wrap">
                              <span className="e-close-icon" onClick={() => this.removeDummyAds(1)}></span>
                              <a href={this.state.ads.hero.data[0].link} target="_blank">
                                {
                                  this.state.ads.hero.data[0].content.indexOf(".mp4") > -1 ?
                                    <VideoPlayer url={this.state.ads.hero.data[0].content} />
                                    :
                                    <img className="e-hero-dummy-img" src={this.state.ads.hero.data[0].content} alt="Truekhoj hero" />
                                }
                              </a>
                            </div>
                            :
                            <div className="e-ad-full-placeholder">
                              <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={() => this.adDummyTypeClick(1)}></span>
                              Hero banner advertisement section
                            </div>
                        }
                      </div>
                    </div>
                    : this.state.selectedValue ?
                      <div className="col-lg-12">
                        {
                          this.state.layout && this.state.layout.length?
                          <>
                          {
                            this.state.layout.map((item, i) => {
                              if (item && item.header && item.header.category && item.header.category === this.state.selectedValue.value && item.id===185) {
                                return (
                                  <div className="e-layout-row e-ad-row">
                                    {
                                      item && item.ads.content ?
                                        <div className="position-relative e-hero-dummy-img-wrap">
                                          <span className="e-close-icon" onClick={() => this.removeDummyAds(3, i)}></span>
                                          <a href={item.ads.link} target="_blank">
                                            {
                                              item.ads.content.indexOf(".mp4") > -1 ?
                                                <VideoPlayer url={item.ads.content} />
                                                :
                                                <img className="e-hero-dummy-img" src={item.ads.content} alt="Truekhoj hero" />
                                            }
                                          </a>
                                        </div>
                                        :
                                        <div className="e-ad-full-placeholder">
                                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={() => this.adDummyTypeClick(3, i)}></span>
                                          Hero banner advertisement section
                                        </div>
                                    }
                                  </div>
                                )
                              }
                              else if (i === (this.state.layout.length - 1) && (!item.header.category ||item.header.category != this.state.selectedValue.value || item.id!=185)) {
                                this.insertLayout(this.state.selectedValue, 1)
                              }
                            })
                          }
                          </>
                          :
                          this.insertLayout(this.state.selectedValue, 1)
                        }
                      </div>
                      :
                      null
                }
              </div>
            </div>
          </div>
          <div className="col-lg-12 pt-4">
            <div className="e-layout-wrap mb-3">
              <h4 className="pb-3 mb-0 font-weight-bold">Intermediate Banner</h4>
              <div className="row">
                {
                  this.state.selectedValue && this.state.selectedValue.value === "*" ?
                    <div className="col-lg-12">
                      {
                        this.state.ads.intermediate && this.state.ads.intermediate.data ?
                          <div className="e-layout-row e-ad-row">
                            {
                              this.state.ads.intermediate.data.length && this.state.ads.intermediate.data[0].content ?
                                <div className="position-relative e-hero-dummy-img-wrap">
                                  <span className="e-close-icon" onClick={() => this.removeDummyAds(2)}></span>
                                  <a href={this.state.ads.intermediate.data[0].link} target="_blank">
                                    {
                                      this.state.ads.intermediate.data[0].content.indexOf(".mp4") > -1 ?
                                        <VideoPlayer url={this.state.ads.intermediate.data[0].content} />
                                        :
                                        <img className="e-hero-dummy-img" src={this.state.ads.intermediate.data[0].content} alt="Truekhoj hero" />
                                    }
                                  </a>
                                </div>
                                :
                                <div className="e-ad-full-placeholder">
                                  <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={() => this.adDummyTypeClick(2)}></span>
                                  Intermediate banner advertisement section
                                </div>
                            }
                          </div>
                          :
                          this.addIntermediate()
                      }
                    </div>
                    :
                    this.state.selectedValue ?
                    <div className="col-lg-12">
                    {
                      this.state.layout && this.state.layout.length?
                      <>
                      {
                        this.state.layout.map((item, i) => {
                          if (item && item.header && item.header.category && item.header.category === this.state.selectedValue.value && item.id===186) {
                            return (
                              <div className="e-layout-row e-ad-row">
                                {
                                  item && item.ads.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={() => this.removeDummyAds(3, i)}></span>
                                      <a href={item.ads.link} target="_blank">
                                        {
                                          item.ads.content.indexOf(".mp4") > -1 ?
                                            <VideoPlayer url={item.ads.content} />
                                            :
                                            <img className="e-hero-dummy-img" src={item.ads.content} alt="Truekhoj hero" />
                                        }
                                      </a>
                                    </div>
                                    :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={() => this.adDummyTypeClick(3, i)}></span>
                                      Hero banner advertisement section
                                    </div>
                                }
                              </div>
                            )
                          }
                          else if (i === (this.state.layout.length - 1) && (!item.header.category ||item.header.category != this.state.selectedValue.value || item.id!=186)) {
                            this.insertLayout(this.state.selectedValue, 2)
                          }
                        })
                      }
                      </>
                      :
                      this.insertLayout(this.state.selectedValue, 2)
                    }
                  </div>
                      :
                      null
                }
              </div>
            </div>
          </div>
        </div>

        {/*Modal*/}
        <HeadingSelectionModal success={this.headingSelectSuccess.bind(this)} data={this.state.categoryList} type={3} />

        {
          this.state.categoryList ?
            <ListSelectionModal success={this.listSelectSuccess.bind(this)} data={this.state.categoryList} />
            :
            null
        }

        {
          !this.state.modalRefresh ?
            <UploadDummyAdsModal success={this.adsDummySuccess.bind(this)} />
            :
            null
        }

        <UploadDummyCatBannerModal success={this.catAdsBannerSuccess.bind(this)} />

        <AddBg success={this.bgColorSuccess.bind(this)} />

      </div>

    );
  }
}

export default LocalProductListPagesLayout;
