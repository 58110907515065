
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/

/*Services*/
import devconfig from '../../../config/config.js';
import APIGet from '../../../services/apiCall/get';

/*Assets*/

class OnlineLayoutsList extends Component {
  constructor(props){
    super(props);
    this.state = {
      mainData: null,
      categoryData: null,
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData(){
    var url = devconfig.adminV1+"/online/page/list";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({mainData: response.data.home_data, categoryData: response.data.category_data});
      }
    }.bind(this))
  }

  render() {
    return (
      <div className="e-main-content e-driver-detail-wrap e-layout-list e-dash-wrap">
        <h2 className="e-page-head">Online Page Layouts</h2>

        {
          this.state.mainData ?
            <div className="row pb-4">
              <div className="col-lg-6">
                <h3 className="mb-4">Main pages</h3>
                {
                  this.state.mainData.map((item, index) => {
                    if(item.name == "home" ){
                      return(
                        <Link className="e-list-card e-card-transition mb-3" to={"/online-layout-home?id="+item.id} key={index}>
                          {item.name}
                        </Link>
                      );
                    }
                  })
                }

                {
                  this.state.mainData.map((item, index) => {
                    if(item.name == "Product listing page" ){
                      return(
                        <Link className="e-list-card e-card-transition mb-3" to={"/product-listing?id="+item.id} key={index}>
                          {"Product listing"}
                        </Link>
                      );
                    }
                  })
                }

                {
                  this.state.mainData.map((item, index) => {
                    if(item.name != "Product listing page" && item.name != "home"){
                      return(
                        <Link className="e-list-card e-card-transition mb-3" to={"/online-deals?id="+item.id} key={index}>
                          {item.name}
                        </Link>
                      );
                    }
                  })
                }


              </div>
            </div>
          :
            null
        }
        
        {
          this.state.categoryData ?
            <div>
              <div className="row">
                <div className="col-lg-6">
                  <h3 className="mb-4">Category</h3>
                </div>
                <div className="col-lg-6">
                  <h3 className="mb-4">Sub category</h3>
                </div>
              </div>

              {
                this.state.categoryData.map((item, index) => {
                  return(
                    <div className="row e-cat-layout-list-wrap">
                      <div className="col-lg-6">
                        <Link className="e-list-card e-card-transition mb-3" to={"/online-layout-category?id="+item.id}key={index}>
                          {item.name}
                        </Link>
                      </div>

                      <div className="col-lg-6">
                        <div className="row">
                          {
                            item.subcategory_data.map((subItem, index) => {
                              return(
                                <div className="col-lg-6" key={index}>
                                  <Link className="e-list-card e-card-transition mb-3" to={"/online-layout-subcategory?id="+subItem.id} >
                                    {subItem.name}
                                  </Link>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                  );
                })
              }

            </div>
          :
            null
        }
      </div>
      
    );
  }
}

export default OnlineLayoutsList;
