
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import APILoader from '../../../components/other/api-loader';
import NothingFound from '../../../components/other/nothing';


/*Assets*/

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';


class UsersList extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: true,
      data: null,
      pageNumActive: true,
      pageNumLoader: false,
      pageNum: 1
    }
  }

 componentDidMount() {
    this.getData(this.state.pageNum);
  }
   getData(tempPageNum){
    this.setState({apiLoading: true});
    var url = devconfig.adminV1+'/user/list';
    var data = JSON.stringify({
      "page_num": tempPageNum
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        if(response.data.length <= 39){
          this.setState({ pageNumActive: false});
        }
        else{
          this.setState({ pageNumActive: true});
        }

        if(this.state.data){
          var tempData = this.state.data;
          tempData.push(response.data);
          this.setState({ data: data, apiLoading: false, pageNumLoader: false});
        }
        else{
          this.setState({ data: response.data, apiLoading: false, pageNumLoader: false});
        }

      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }

  loadMore(){
    this.getData(this.state.pageNum + 1);
    this.setState({pageNum: this.state.pageNum + 1, pageNumLoader: true});
  }
  render() {
    return (
      <div>
        {
          this.state.apiLoading ?
            <APILoader/>
            :
              <div className="e-main-content e-vendor-table">
                <h2 className="e-page-head">Users</h2>
                <div className="row">
                  {
                    this.state.data && this.state.data.length ?
                      <div className="col-lg-8">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Phone</th>
                              <th>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.data.map((item,index) => {
                                return(
                                  <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.mobile}</td>
                                    <td>{item.email}</td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                        {
                          this.state.pageNumActive ?
                            <div className="text-center">
                              {
                                this.state.pageNumLoader ?
                                  <APILoader/>
                                    :
                                      <button className="e-s-btn-2" onClick={this.loadMore.bind(this)}>Load More</button>
                              }
                            </div>
                          :
                            null
                        }
                      </div>
                    :
                      <NothingFound data="No users found"/>
                  }
                </div>
              </div>
        }
      </div>
    );
  }
}

export default UsersList;
