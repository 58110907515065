/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/



/*Assets*/

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';


class ConfirmationModal extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }
   selectChange = async (category) => {
    await this.setState({category: category});
  }


  componentDidMount() {

  }

  removeList() {
    this.setState({apiLoading: true});
    var url = devconfig.adminV2+ '/career/delete';
    var data = JSON.stringify({
      "id": this.props.id
    })
    APIPost(url,data).then(function(response){
      if(response.status == "ok"){
        // console.log(response.data);
        this.props.getData();
        $("#confirmModal").modal('hide');
        // this.setState({ data: response.data,apiLoading: false});
      }
    }.bind(this))
  }

  render() {


    return (
      <div className="modal fade e-ad-detail-wrap e-ofer-modal" id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content e-career-modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h6 className="e-modal-header e-add-career-heading">Remove career</h6>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-12">
               <p>Do you want to remove this career? Click confirm to remove.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9">
                <a href="#" className="float-right pt-2 e-ofer-cancel" data-dismiss="modal">Cancel</a>
              </div>

              <button className="e-s-btn e-new-ofer float-right" type="submit" value="Submit" onClick={this.removeList.bind(this)}>Confirm</button>
            </div>

            <div className="modal-footer">

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationModal;
