/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Pdf from "react-to-pdf";

/*Components*/
import SectionHeadingStyle from '../elementsPreview/sectionHeadingStyle';
import SelectBox from '../formComponents/selectBox';
import ColorPicker from '../common/colorPicker';


/*Assets*/
import logo from '../../assets/images/logo.svg';

class CareerDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
       data: [],
       description: ""

    }
  }

  componentDidMount() {

  }

  render() {

    return (
      <div className="modal fade e-detail-invoice-wrap" id="careerDetailModal" tabIndex="-1" role="dialog" aria-labelledby="careerDetailModalLabel" aria-hidden="true">
        <div className="modal-dialog"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" >
              <div className="row">
                <div className="col-lg-6">
                  <h5 className="mb-3">{this.props.designation}</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <p>{this.props.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CareerDetail;
