/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
// import Inputs from '../../components/formComponents/inputs.js';
import SelectBox from '../../components/formComponents/selectBox';
import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/textarea';



/*Assets*/
import sampleBanner from '../../assets/images/sample-banner.jpg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import PhoneValidation from '../../services/validation/phone';
import EmailValidation from '../../services/validation/email';



class EditLocationAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      number: "",
      location: null,
      apiLoading: false,
      error: ""
    }
  }

  async addAdmin() {
    this.setState({ apiLoading: true, error: "" });
    let verifyNumber = await PhoneValidation(this.props.data.mobile);
    let verifyEmail = await EmailValidation(this.props.data.email);
    if (verifyEmail && verifyNumber) {
      let url = devconfig.localAdmin + "/locationadmin/update";
      let data = JSON.stringify({
        "admin_id": this.props.data.id,
        "name": this.props.data.name,
        "email": this.props.data.email,
        "mobile": this.props.data.mobile,
        "active": true,
        "location": {
          "description": this.state.location? this.state.location.label: this.props.data.location,
          "place_id": this.state.location? this.state.location.value : this.props.data.place_id,
          "reference": this.state.location? this.state.location.value : this.props.data.place_id
        }
      });
      APIPost(url, data).then(function (response) {
        if (response.status == "ok") {
          this.modalClose();
          this.props.success();
        }
        else {
          this.setState({ apiLoading: false, error: response.message })
        }
      }.bind(this))
    }
    else {
      if (!verifyNumber && !verifyEmail) {
        this.setState({ apiLoading: false, error: "Enter valid Number and E-mail" });
      }
      else if (verifyEmail) {
        this.setState({ apiLoading: false, error: "Enter a valid Number" });
      }
      else {
        this.setState({ apiLoading: false, error: "Enter a valid E-mail" });
      }
    }
  }

  modalClose() {
    this.setState({
      name: "",
      email: "",
      number: "",
      location: null,
      apiLoading: false,
      error: ""
    });
    this.props.modalClose();
    $("#editAdminModal").modal("hide");
  }

  async search(query){
    this.setState({locationSearch: query});
    if(query && query.length){
      let url = devconfig.localAdmin + "/location/search";
      let data = JSON.stringify({
        "text" : query
      })
      APIPost(url, data).then(function(response){
        if(response.status==="ok"){
          this.setState({locationOptions: response.data});
        }
        else{
          this.setState({ error: response.message});
        }
      }.bind(this));
    }
    else{
      this.setState({locationOptions: []});
    }
  }


  render() {
    return (
      <div className="modal fade e-ofer-modal" id="editAdminModal" tabIndex="-1" role="dialog" aria-labelledby="addCategoryModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content e-career-modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.modalClose()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {
              this.props.modalOn ?
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <h6 className="e-modal-header e-add-career-heading">Edit Location Admin</h6>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <h6 className="e-career-header">Name</h6>
                      <div className="e-input-wrap">
                        <input
                          id="e-name"
                          value={this.props.data.name}
                          type="text"
                          onChange={(e)=>this.props.updateData('name', e.target.value)}
                          className="e-add-career"
                          autoComplete={false}
                          disabled={false}
                          required
                        // {...this.props}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <h6 className="e-career-header">Email ID</h6>
                      <div className="e-input-wrap">
                        <input
                          id="e-emai"
                          value={this.props.data.email}
                          type="email"
                          onChange={(e)=>this.props.updateData('email', e.target.value)}
                          className="e-add-career"
                          autoComplete={false}
                          disabled={false}
                          required
                        // {...this.props}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <h6 className="e-career-header">Phone Number</h6>
                      <div className="e-input-wrap">
                        <input
                          id="e-number"
                          value={this.props.data.mobile}
                          type="number"
                          onChange={(e)=>this.props.updateData('mobile', e.target.value)}
                          className="e-add-career"
                          autoComplete={false}
                          disabled={false}
                          required
                        // {...this.props}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 e-location-search">
                      <h6 className="e-career-header">Location</h6>
                      <SelectBox options={this.state.locationOptions}
                       isSearchable={true}
                       inputValue={this.state.locationSearch}
                       onInputChange={(e) => this.search(e)}
                       placeholder="Search for location"
                       value={this.state.location? this.state.location: {value: this.props.data.place_id, label: this.props.data.location}}
                      selectchange={(val) => this.setState({location: val})}/>
                    </div>
                  </div>
                </>
                :
                null
            }
            <div className="row py-2">
              {
                this.state.error.length ?
                  <div className="col-lg-12 py-2">
                    <span className="text-danger">{this.state.error}</span>
                  </div>
                  :
                  null
              }
              <div className="col-lg-12">
                <div className="d-flex align-items-center float-right">
                  <a href="#" className="pt-1 e-ofer-cancel" data-dismiss="modal" onClick={() => this.modalClose()}>Cancel</a>
                  {
                    this.props.data && this.props.data.name && this.props.data.mobile && this.props.data.email && (this.props.data.location || this.state.location) && !this.state.apiLoading ?
                      <button className="e-s-btn ml-5" type="submit" value="Submit" onClick={() => this.addAdmin()}>Update</button>
                      :
                      <button className="e-s-btn ml-5 e-inactive" type="submit" value="Submit">Update</button>
                  }
                </div>
              </div>
            </div>

            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    );
  }
}

export default EditLocationAdmin;
