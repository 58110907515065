/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class ColorPicker extends Component {
  constructor(props){
    super(props);
    this.state = {
      color: "#e66465",
    }
  }

  componentDidMount(){

  }

  colorChange(e){
    this.setState({color: e.target.value});
    this.props.colorChange(e.target.value);
  }

  render() {
    return (
      <input id={this.props.id} type="color" value="" className="e-colore-picker" onChange={this.colorChange.bind(this)} value={this.state.color} />
    );
  }
}

export default ColorPicker;
