
const url= "https://58gmeu3tb4.execute-api.ap-south-1.amazonaws.com/staging/api/v1";
const devUrl= "https://iigqgbxnqj.execute-api.ap-south-1.amazonaws.com/dev/api/v1";
const liveUrl = "https://iwu5u49n5d.execute-api.ap-south-1.amazonaws.com/prod/api/v1"

var devconfig = {
   adminV1: liveUrl + "/admin",
   adminV2: liveUrl + "/admin",
   admin: liveUrl + "/admin",
   localAdmin: liveUrl+ "/superadmin",
   locationAdmin: liveUrl+ "/local/locationadmin",

   onlineAdminAppUrl: 'https://online-admin.truekhoj.com',
   locationAdminAppUrl: 'https://location-admin.truekhoj.com',
   vendorAppUrl: 'https://vendor-admin.truekhoj.com',
   // onlineAdminAppUrl: 'https://truekhoj-online-admin-dev.netlify.app',
   // locationAdminAppUrl: 'https://truekhoj-location-admin-dev.netlify.app',
   // vendorAppUrl: 'https://truekhoj-vendor-admin-dev.netlify.app',
}

if(localStorage.getItem("trueKhoj_data") && JSON.parse(localStorage.getItem("trueKhoj_data")).type ===2){
   devconfig.adminV1= liveUrl + "/superadmin";
   devconfig.admin= liveUrl + "/superadmin";
}
module.exports = devconfig;