
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*Components*/
import HeadingSelectionModal from '../../../components/modals/headingSelectionModal';
import ListSelectionModal from '../../../components/modals/listSelectionModal';
import EditHeadingModal from '../../../components/modals/editHeadingModal';

import SectionHeadingStyle from '../../../components/elementsPreview/sectionHeadingStyle';
import Input from '../../../components/formComponents/input';
import CardFourColumn from '../../../components/cards/column-4.js';
import CardSixColumn from '../../../components/cards/column-6.js';
import CardTwoColumn from '../../../components/cards/column-2';
import CardThreeColumn from '../../../components/cards/column-3';
import UploadDummyAdsModal from '../../../components/modals/uploadDummyAdsModal';
import UploadDummyCatBannerModal from '../../../components/modals/uploadDummyCategorybanner';
import AddMenu from '../../../components/modals/addMenu';
import AddBg from '../../../components/modals/addBg';
import VideoPlayer from '../../../components/common/video';
import SelectBox from '../../../components/formComponents/selectBox';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';

/*Assets*/


class LocalDealsPagesLayout extends Component {
  notify = e => toast(e);
  constructor(props) {
    super(props);
    this.state = {
      layout: [],
      ads: {
        hero: { id: 1, page_id: 1, data: [{ content: null, link: null }] },
        logo_slider: {
          id: 1, page_id: 1, data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null }
          ]
        },
        deals_banner: { id: 1, page_id: 1, data: [{ content: null, link: null }] },
        deals: {
          header: "Live deals",
          id: 1,
          page_id: 1,
          banner: [{ content: null, link: null }],
          data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null }
          ]
        },
        ad_section_1: {
          header: "Hot & Trending Deals",
          id: 1,
          page_id: 1,
          data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null }
          ]
        },
        ad_section_2: {
          header: "Best Deals",
          id: 1,
          page_id: 1,
          data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null }
          ]
        },
        ad_section_3: {
          header: "Top Deals",
          id: 1,
          page_id: 1,
          data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null }
          ]
        },
        ad_section_4: {
          header: "Other Deals",
          id: 1,
          page_id: 1,
          data: [
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null },
            { content: null, link: null }
          ]
        }

      },
      id: null,
      selectItemIndex: null,
      categoryData: null,
      selectedSubCategories: null,
      data: null,
      name: null,
      adDummyType: null,
      swapIndex: "",
      catBannerAdIndex: null,
      categoryId: null,
      sectionHeadingEditSelect: null,
      adIndex: 0,
      menu: null,
      menuApiLoading: true,
      vendorOptions: []
    }
  }

  addAdIds(response, type) {
    let data = response;
    if (type === "wholesale") {
      if (data.hero) data.hero.id = 172;
      if (data.logo_slider) data.logo_slider.id = 173;
      if (data.deals_banner) data.deals_banner.id = 174;
      if (data.deals) data.deals.id = 175;
      if (data.ad_section_1) data.ad_section_1.id = 176;
      if (data.ad_section_2) data.ad_section_2.id = 177;
      if (data.ad_section_3) data.ad_section_3.id = 183;
      if (data.ad_section_4) data.ad_section_4.id = 184;
      if (data.wholesalePrice) data.wholesalePrice.id = 178;
      this.setState({
        ads: data,
        brand_section_1_id: 179,
        brand_section_2_id: 180,
        brand_section_3_id: 181
      });
    }
    /* Luxuary Offers Page */
    else if (type === 13) {
      if (data.hero) data.hero.id = 95;
      if (data.logo_slider) data.logo_slider.id = 96;
      if (data.deals_banner) data.deals_banner.id = 97;
      if (data.deals) data.deals.id = 98;
      if (data.ad_section_1) data.ad_section_1.id = 99;
      if (data.ad_section_2) data.ad_section_2.id = 100;
      if (data.ad_section_3) data.ad_section_3.id = 101;
      if (data.ad_section_4) data.ad_section_4.id = 102;
      this.setState({
        ads: data,
        brand_section_1_id: 103,
        brand_section_2_id: 104,
        brand_section_3_id: 105
      });
    }
    /* Special Offers Page */
    else if (type === 12) {
      if (data.hero) data.hero.id = 106;
      if (data.logo_slider) data.logo_slider.id = 107;
      if (data.deals_banner) data.deals_banner.id = 108;
      if (data.deals) data.deals.id = 109;
      if (data.ad_section_1) data.ad_section_1.id = 110;
      if (data.ad_section_2) data.ad_section_2.id = 111;
      if (data.ad_section_3) data.ad_section_3.id = 112;
      if (data.ad_section_4) data.ad_section_4.id = 113;
      this.setState({
        ads: data,
        brand_section_1_id: 114,
        brand_section_2_id: 115,
        brand_section_3_id: 116
      });
    }
    /* What's New Page */
    else if (type === 30) {
      if (data.hero) data.hero.id = 117;
      if (data.logo_slider) data.logo_slider.id = 118;
      if (data.deals_banner) data.deals_banner.id = 119;
      if (data.deals) data.deals.id = 120;
      if (data.ad_section_1) data.ad_section_1.id = 121;
      if (data.ad_section_2) data.ad_section_2.id = 122;
      if (data.ad_section_3) data.ad_section_3.id = 123;
      if (data.ad_section_4) data.ad_section_4.id = 124;
      this.setState({
        ads: data,
        brand_section_1_id: 125,
        brand_section_2_id: 126,
        brand_section_3_id: 127
      });
    }
    /* Today's Deal Page */
    else if (type === 31) {
      if (data.hero) data.hero.id = 128;
      if (data.logo_slider) data.logo_slider.id = 129;
      if (data.deals_banner) data.deals_banner.id = 130;
      if (data.deals) data.deals.id = 131;
      if (data.ad_section_1) data.ad_section_1.id = 132;
      if (data.ad_section_2) data.ad_section_2.id = 133;
      if (data.ad_section_3) data.ad_section_3.id = 134;
      if (data.ad_section_4) data.ad_section_4.id = 135;
      this.setState({
        ads: data,
        brand_section_1_id: 136,
        brand_section_2_id: 137,
        brand_section_3_id: 138
      });
    }
    /* Exclusive Brands Page */
    else if (type === 32) {
      if (data.hero) data.hero.id = 139;
      if (data.logo_slider) data.logo_slider.id = 140;
      if (data.deals_banner) data.deals_banner.id = 141;
      if (data.deals) data.deals.id = 142;
      if (data.ad_section_1) data.ad_section_1.id = 143;
      if (data.ad_section_2) data.ad_section_2.id = 144;
      if (data.ad_section_3) data.ad_section_3.id = 145;
      if (data.ad_section_4) data.ad_section_4.id = 146;
      this.setState({
        ads: data,
        brand_section_1_id: 147,
        brand_section_2_id: 148,
        brand_section_3_id: 149
      });
    }
    /* Shop of the day Page */
    else if (type === 33) {
      if (data.hero) data.hero.id = 150;
      if (data.logo_slider) data.logo_slider.id = 151;
      if (data.deals_banner) data.deals_banner.id = 152;
      if (data.deals) data.deals.id = 153;
      if (data.ad_section_1) data.ad_section_1.id = 154;
      if (data.ad_section_2) data.ad_section_2.id = 155;
      if (data.ad_section_3) data.ad_section_3.id = 156;
      if (data.ad_section_4) data.ad_section_4.id = 157;
      this.setState({
        ads: data,
        brand_section_1_id: 158,
        brand_section_2_id: 159,
        brand_section_3_id: 160
      });
    }
    /* Luxury hotels Page */
    else if (type === 34) {
      if (data.hero) data.hero.id = 161;
      if (data.logo_slider) data.logo_slider.id = 162;
      if (data.deals_banner) data.deals_banner.id = 163;
      if (data.deals) data.deals.id = 164;
      if (data.ad_section_1) data.ad_section_1.id = 165;
      if (data.ad_section_2) data.ad_section_2.id = 166;
      if (data.ad_section_3) data.ad_section_3.id = 167;
      if (data.ad_section_4) data.ad_section_4.id = 168;
      this.setState({
        ads: data,
        brand_section_1_id: 169,
        brand_section_2_id: 170,
        brand_section_3_id: 171
      });
    }
  }

  componentDidMount() {
    GetURLVar().then(function (query) {
      if (query && query.id) {
        let layout_id = Number(query.id);
        if (query.wholesale && query.wholesale == "true") {
          this.setState({
            wholesale: true,
            ads: {
              hero: { id: 172, page_id: 1, data: [{ content: null, link: null }] },
              logo_slider: {
                id: 173, page_id: 1, data: [
                  { content: null, link: null },
                  { content: null, link: null },
                  { content: null, link: null },
                  { content: null, link: null },
                  { content: null, link: null },
                  { content: null, link: null }
                ]
              },
              deals_banner: { id: 174, page_id: 1, data: [{ content: null, link: null }] },
              deals: {
                header: "Live deals",
                id: 175,
                page_id: 1,
                banner: [{ content: null, link: null }],
                data: [
                  { content: null, link: null },
                  { content: null, link: null },
                  { content: null, link: null },
                  { content: null, link: null },
                ]
              },
              ad_section_1: {
                header: "Wholesale stores",
                id: 1,
                page_id: 176,
                data: [
                  { content: null, link: null },
                  { content: null, link: null },
                  { content: null, link: null },
                  { content: null, link: null },
                ],
                vendors: [null, null, null, null]
              },
              ad_section_2: {
                header: "Best Deals",
                id: 177,
                page_id: 1,
                data: [
                  { content: null, link: null },
                  { content: null, link: null },
                ]
              },
              ad_section_3: {
                id: 183,
                page_id: 1,
                data: [
                  { content: null, link: null },
                ]
              },
              ad_section_4: {
                id: 184,
                page_id: 1,
                data: [
                  { content: null, link: null },
                  { content: null, link: null },
                ]
              },
              wholesalePrice: {
                id: 178,
                page_id: 1,
                data: [
                  { content: null, link: null },
                ]
              }

            },
            brand_section_1_id: 179,
            brand_section_2_id: 180,
            brand_section_3_id: 181
          });
          this.getVendorList();
        }
        else {
          let data = this.state.ads;
          this.addAdIds(data, layout_id);
          this.getCategoryData();
        }
        this.getData(query.id);
        this.getMenu(query.id);
        this.setState({ id: query.id });

      }
    }.bind(this))
  }

  getData(tempId) {
    var url = devconfig.adminV1 + "/local/page/detail";
    var data = JSON.stringify({
      "layout_id": Number(tempId)
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        if (response.data.page_data.layout) {
          this.setState({ layout: response.data.page_data.layout });

          if (response.data.page_data.layout.length) {
            // console.log(response.data.page_data.layout[response.data.page_data.layout.length-1])
            var lastItem = response.data.page_data.layout[response.data.page_data.layout.length - 1];
            this.getChildCategory(lastItem.header.category);
          }
        }
        if (response.data.page_data.ads) {
          this.addAdIds(response.data.page_data.ads, this.state.wholesale ? "wholesale" : Number(tempId));
          // this.setState({ads: response.data.page_data.ads});
        }
        this.setState({ name: response.data.name });

        if (this.state.wholesale) {
          this.setState({ categoryId: response.data.wholesale_department_id });
          this.getSubCategoryData(response?.data?.wholesale_department_id);
        }
      }
    }.bind(this))
  }

  getVendorList() {
    var url = devconfig.localAdmin + "/vendor/names";
    APIGet(url).then(function (response) {
      if (response.status === "ok") {
        if (response.data && response.data.length) {
          let temp = []
          const vendors = response.data;
          vendors.forEach(vendor => {
            temp.push({ value: vendor.id, label: vendor.name });
          });
          this.setState({ vendorOptions: temp });
        }
      }
    }.bind(this))
  }

  setVendor(vendor, i) {
    let temp = this.state.ads;
    if (temp.ad_section_1.vendors) {
      temp.ad_section_1.vendors[i] = vendor;
    }
    else {
      temp.ad_section_1 = { ...temp.ad_section_1, vendors: [null, null, null, null] };
      temp.ad_section_1.vendors[i] = vendor;
    }
    this.setState({ ads: temp });
  }
  
  addMoreStores(){
    let temp = this.state.ads;
    temp.ad_section_1.vendors = [...temp.ad_section_1.vendors, null, null];
    temp.ad_section_1.data = [...temp.ad_section_1.data, {content: null, link: null}, {content: null, link: null}];
    this.setState({ads: temp});
  }

  getMenu(tempId) {
    this.setState({ menuApiLoading: true });
    var url = devconfig.adminV1 + "/local/topmenu/list";
    var data = JSON.stringify({
      "layout_id": Number(tempId)
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        if (response.data.length) {
          this.setState({ menu: response.data[0], menuApiLoading: false });
        }
        else {
          this.setState({ menu: [], menuApiLoading: false });
        }

      }
    }.bind(this))
  }

  addSection() {
    var tempId = this.state.brand_section_1_id;
    if (this.state.layout.length == 1) {
      tempId = this.state.brand_section_2_id;
    }
    else if (this.state.layout.length == 2) {
      tempId = this.state.brand_section_3_id;
    }
    var tempArry = {
      "header": { "content": null, "type": null, "font_color": null, "bg_color": null, category: null },
      "ads": { "content": null, "link": null },
      "data": { "content": null, "image": null, "stlye": null },
      "page_id": this.state.page_id,
      "favStore":  { },
      "id": tempId
    }

    var tempLayout = this.state.layout;
    tempLayout.push(tempArry);
    this.setState({ layout: tempLayout });
  }

  removeSection(tempIndex) {
    var tempLayout = this.state.layout;
    tempLayout.splice(tempIndex, 1);
    this.setState({ layout: tempLayout, selectedSubCategories: null });
  }

  headingSelectSuccess(tempItem) {
    var tempArry = this.state.layout;
    tempArry[this.state.selectItemIndex].header = tempItem;
    this.setState({ layout: tempArry });

    if (tempItem.category) {
      this.getChildCategory(tempItem.category);
    }
    else {
      this.getChildCategory();
    }
  }

  bgColorSuccess(tempColor) {
    var tempArry = this.state.layout;
    tempArry[this.state.selectItemIndex].bg_color = tempColor;
    this.setState({ layout: tempArry });
  }

  getChildCategory(tempItem) {
    var tempSubCat = [];
    if (tempItem) {
      tempSubCat = [tempItem];
    }

    var url = devconfig.localAdmin + "/subcategory/list";
    var data = JSON.stringify({
      "category_ids": tempSubCat,
    })
    if(!this.state.wholesale){
      url = devconfig.localAdmin + "/category/list";
      data = JSON.stringify({
        "department_ids": tempSubCat,
      });
    }
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        var tempArry = [];
        response.data.forEach( (item, index)=> {
          var tempItem = { value: item.id, label: this.state.wholesale?  item.sub_category: item.name }
          tempArry.push(tempItem);
        });

        this.setState({ selectedSubCategories: tempArry, categoryData: response.data });
      }
    }.bind(this))
  }

  listSelectSuccess(tempItem, tempType) {
    var tempArry = this.state.layout;
    if(tempType){
      if(tempArry[this.state.selectItemIndex].favStore && tempArry[this.state.selectItemIndex].favStore.data){
        var temp = tempArry[this.state.selectItemIndex].favStore.data.concat(tempItem.vendors.data);
        var tVendor =  tempArry[this.state.selectItemIndex].favStore.vendors.concat(tempItem.vendors.vendors);
        tempArry[this.state.selectItemIndex].favStore.data = temp;
        tempArry[this.state.selectItemIndex].favStore.vendors = tVendor;
        console.log('temp data ->', temp);
        console.log('temp vendor-->', tVendor);
        console.log('favStoer-->', tempArry);
      }else{
        tempArry[this.state.selectItemIndex].favStore = tempItem.vendors ;
      }
    }
    else{
      if (tempArry[this.state.selectItemIndex].data && tempArry[this.state.selectItemIndex].data.content) {
        var temp = tempArry[this.state.selectItemIndex].data.content.concat(tempItem.content);
        tempArry[this.state.selectItemIndex].data.content = temp;
        tempArry[this.state.selectItemIndex].data.style = tempItem.style;
        tempArry[this.state.selectItemIndex].data.type = tempItem.type;
      }
      else {
        tempArry[this.state.selectItemIndex].data = tempItem;
      }
    }
   

    this.setState({ layout: tempArry });
  }

  selectedIndex(tempindex) {
    this.setState({ selectItemIndex: tempindex });
    console.log("Selected", this.state.selectedSubCategories)
  }


  sectionSwapInputChange(tempClass, e) {
    $(".e-section-swap-btn").removeClass("e-active");
    $(tempClass).addClass("e-active");
  }

  sectionSwapInputReset() {
    $(".e-section-swap-btn").removeClass("e-active");
  }

  getCategoryData() {
    var url = devconfig.adminV1 + "/department/list";
    APIGet(url).then(function (response) {
      if (response.status === "ok") {
        var tempArry = [];
        response.data.forEach(function (item, index) {
          var tempItem = { value: item.id, label: item.name }
          tempArry.push(tempItem);
        });

        this.setState({ categoryList: tempArry, categoryData: response.data });
      }
    }.bind(this))
  }

  getSubCategoryData(tempId) {
    var url = devconfig.localAdmin + "/category/list";
    let data = JSON.stringify({
      "department_ids": tempId? [tempId]: [],
    });
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        var tempArry = [];
        response.data.forEach(function (item, index) {
          var tempItem = { value: item.id, label: item.name }
          tempArry.push(tempItem);
        });

        this.setState({ categoryList: tempArry, categoryData: response.data });
      }
    }.bind(this))
  }

  updatePageLayout() {
    this.setState({ updateApiLoading: true });
    setTimeout(function () { this.setState({ updateApiLoading: false }); }.bind(this), 1000);

    var tempArry = {
      layout: this.state.layout,
      ads: this.state.ads
    }
    var url = devconfig.adminV1 + "/local/page/update";
    var data = JSON.stringify({
      "layout_id": this.state.id,
      "page_data": tempArry
    })
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {

      }
    }.bind(this))
  }

  async adsDummySuccess(tempUrl, tempLink) {
    var tempArry = this.state.ads;
    let layout = this.state.layout;

    await this.setState({ modalRefresh: true });

    switch (this.state.adDummyType) {
      case 1: {
        tempArry.hero.data[0] = { content: tempUrl, link: tempLink };
        // tempArry.hero.page_id = this.state.page_id;
        // tempArry.hero.id = this.state.hero_id;
        break;
      }
      case 2: {
        tempArry.logo_slider.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        // tempArry.logo_slider.id = this.state.logo_id;
        // tempArry.logo_slider.page_id = this.state.page_id;
        break;
      }
      case 3: {
        tempArry.deals_banner.data[0] = { content: tempUrl, link: tempLink };
        // tempArry.deals_banner.id = this.state.deals_banner_id;
        // tempArry.deals_banner.page_id = this.state.page_id;
        break;
      }
      case 4: {
        tempArry.deals.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        // tempArry.deals.id = this.state.deals_id;
        // tempArry.deals.page_id = this.state.page_id;
        break;
      }
      case 5: {
        tempArry.ad_section_1.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        // tempArry.ad_section_1.id = this.state.ad_section_1_id;
        // tempArry.ad_section_1.page_id = this.state.page_id;
        break;
      }
      case 6: {
        tempArry.ad_section_2.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        // tempArry.ad_section_2.id = this.state.ad_section_2_id;
        // tempArry.ad_section_2.page_id = this.state.page_id;
        break;
      }
      case 7: {
        tempArry.ad_section_3.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        // tempArry.ad_section_3.id = this.state.ad_section_3_id;
        // tempArry.ad_section_3.page_id = this.state.page_id;
        break;
      }
      case 8: {
        tempArry.ad_section_4.data[this.state.adIndex] = { content: tempUrl, link: tempLink };
        // tempArry.ad_section_4.id = this.state.ad_section_4_id;
        // tempArry.ad_section_4.page_id = this.state.page_id;
        break;
      }
      case 9: {
        tempArry.wholesalePrice.data[0] = { content: tempUrl, link: tempLink };
        // tempArry.wholesalePrice.id = this.state.wholesalePrice_id;
        // tempArry.wholesalePrice.page_id = this.state.page_id;
        break;
      }
      case 10: {
        layout[this.state.selectItemIndex].header = { content: tempUrl, link: tempLink, categoty: null };
        this.setState({ layout: layout });
        break;
      }
      case 10: {
        layout[this.state.selectItemIndex].header = { content: tempUrl, link: tempLink };
        this.setState({ layout: layout });
        break;
      }
    }

    this.setState({ ads: tempArry, modalRefresh: false });
  }

  adDummyTypeClick(tempItem, tempIndex) {
    this.setState({ adDummyType: tempItem, adIndex: tempIndex });
  }

  removeDummyAds(tempItem, tempIndex) {
    var tempArry = this.state.ads;
    let layout = this.state.layout;

    switch (tempItem) {
      case 1: {
        tempArry.hero.data[0] = { content: null, link: null };
        break;
      }
      case 2: {
        tempArry.logo_slider.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 3: {
        tempArry.deals.deals_banner.data[0] = { content: null, link: null };
        break;
      }
      case 4: {
        tempArry.deals.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 5: {
        tempArry.ad_section_1.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 6: {
        tempArry.ad_section_2.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 7: {
        tempArry.ad_section_3.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 8: {
        tempArry.ad_section_3.data[tempIndex] = { content: null, link: null };
        break;
      }
      case 9: {
        tempArry.wholesalePrice.data[0] = { content: null, link: null };
      }
      case 10: {
        layout[tempIndex].header = { "content": null, "type": null, "font_color": null, "bg_color": null, category: null };
        this.setState({ layout: layout });
        break;
      }
    }

    this.setState({ ads: tempArry });
  }

  sectionSwap(tempCurrectIndex, tempClass) {
    var tempArry = this.state.layout;
    var tempSwapValue = $(tempClass).val();

    if (tempSwapValue > tempArry.length || tempSwapValue <= 0) {
      this.notify("Swap index must be between 0 to " + tempArry.length)
    }
    else {
      if (tempArry[tempSwapValue - 1]) {
        var b = tempArry[tempCurrectIndex - 1];
        tempArry[tempCurrectIndex - 1] = tempArry[tempSwapValue - 1];
        tempArry[tempSwapValue - 1] = b;
        $(".e-section-swap-btn").removeClass("e-active");
        $(tempClass).val("")
      }
      else {
        this.notify("Please verify your input data")
      }
      this.setState({ layout: tempArry });
    }
  }

  catBannerIndexClick(tempIndex) {
    this.setState({ catBannerAdIndex: tempIndex });
  }

  catAdsBannerSuccess(tempImg, tempLink) {
    var tempArry = this.state.layout;
    var tempItem = { "content": tempImg, "link": tempLink };

    tempArry[this.state.catBannerAdIndex].ads = { "content": tempImg, "link": tempLink };
    this.setState({ layout: tempArry });
  }

  removeCatAds(tempIndex) {
    var tempArry = this.state.layout;
    tempArry[tempIndex].ads = { "content": null, "link": null };
    this.setState({ layout: tempArry });
  }

  async sectionHeadingChange(tempContent, tempLink) {
    var tempArry = this.state.ads;
    if (this.state.headerImage) {
      switch (this.state.sectionHeadingEditSelect) {
        case 1: {
          tempArry.deals.header = { content: tempContent, link: tempLink };
          break;
        }
        case 2: {
          tempArry.ad_section_1.header = { content: tempContent, link: tempLink };
          break;
        }
        case 3: {
          tempArry.ad_section_2.header = { content: tempContent, link: tempLink };
          break;
        }
        case 4: {
          tempArry.ad_section_3.header = { content: tempContent, link: tempLink };
          break;
        }
        case 5: {
          tempArry.ad_section_4.header = { content: tempContent, link: tempLink };
          break;
        }
      }
    }
    else {
      switch (this.state.sectionHeadingEditSelect) {
        case 1: {
          tempArry.deals.header = tempContent;
          break;
        }
        case 2: {
          tempArry.ad_section_1.header = tempContent;
          break;
        }
        case 3: {
          tempArry.ad_section_2.header = tempContent;
          break;
        }
        case 4: {
          tempArry.ad_section_3.header = tempContent;
          break;
        }
        case 5: {
          tempArry.ad_section_4.header = tempContent;
          break;
        }
      }
    }


    await this.setState({ ads: tempArry, headerImage: false, sectionHeadingEditSelect: null });
  }

  removeDummyHeader(tempItem) {
    var tempArry = this.state.ads;
    switch (tempItem) {
      case 1: {
        tempArry.deals.header = "";
        break;
      }
      case 2: {
        tempArry.ad_section_1.header = "";
        break;
      }
      case 3: {
        tempArry.ad_section_2.header = "";
        break;
      }
      case 4: {
        tempArry.ad_section_3.header = "";
        break;
      }
      case 5: {
        tempArry.ad_section_4.header = "";
        break;
      }
    }
    this.setState({ ads: tempArry });
  }

  sectionHeadingEditClick(tempItem) {
    this.setState({ sectionHeadingEditSelect: tempItem });
  }

  async hideLayout(){
    let url = devconfig.admin+"/local/page/hide";
    let data = JSON.stringify({
      "layout_id" : this.state.id
    });
    let response = await APIPost(url, data);
    if(response&& response.status==="ok"){
      window.location.href="/local-layouts"
    }
  }

  render() {

    console.log("page_id");
    console.log(this.state.page_id)



    return (
      <div className="e-main-content e-driver-detail-wrap e-dash-wrap">
        <h2 className="e-page-head">{this.state.name} Page Layout</h2>

        {
          this.state.updateApiLoading ?
            <button className="e-p-btn e-nav-button e-inactive e-loading e-page-layout-btn"></button>
            :
            <span>
              {
                this.state.layout && this.state.layout.length ?
                  <button className="e-p-btn e-nav-button" onClick={this.updatePageLayout.bind(this)}>Update page</button>
                  :
                  <button className="e-p-btn e-nav-button e-inactive">Update page</button>
              }
            </span>
        }


        <div className="row e-menu-setting-wrap">
          <div className="col-lg-8">
            {/* <h6>Menu Settings {
              !this.state.menu || (this.state.menu.sub_page_data && this.state.menu.sub_page_data.length) || !this.state.menu.sub_page_data <= 7 ?
                <span data-toggle="modal" data-target="#addMenuModal">Edit/Add</span>
                :
                null
            }</h6> */}
          </div>
          <div className="col-lg-4 py-3 py-lg-0">
            <h6 className="text-right"><span onClick={()=>this.hideLayout()}>Hide Page</span></h6>
          </div>


          {/* <div className="col-lg-12">
            {
              this.state.menuApiLoading ?
                null
                :
                <React.Fragment>
                  {
                    this.state.menu ?
                      <AddMenu data={this.state.menu.sub_page_data} id={this.state.id} />
                      :
                      <AddMenu id={this.state.id} />
                  }
                </React.Fragment>
            }

          </div>

          <div className="col-lg-12">
            {
              this.state.menu && this.state.menu.sub_page_data ?
                <React.Fragment>
                  {
                    this.state.menu.sub_page_data.map((data, index) => {
                      return (
                        <span className="e-menu-item-tag mr-3">{data.name}</span>
                      );
                    })
                  }

                </React.Fragment>
                :
                <React.Fragment>
                  {
                    this.state.menuApiLoading ?
                      null
                      :
                      <span className='e-no-menu-item'>No menu item found. Click Edit/Add to update menu.</span>
                  }
                </React.Fragment>
            }
          </div> */}
          {/*
            this.state.menu && this.state.menu.sub_page_data ?
              <AddMenu data={this.state.menu.sub_page_data} id={this.state.id}/>
            :
              <AddMenu id={this.state.id}/>
          */}

        </div>

        <div className="row mt-5">
          <div className="col-lg-12">
            <div className="e-layout-wrap mb-3">

              <div className="row">
                <div className="col-lg-12">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.hero.data[0] && this.state.ads.hero.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 1)}></span>
                          <a href={this.state.ads.hero.data[0].link} target="_blank">
                            {
                              this.state.ads.hero.data[0].content && this.state.ads.hero.data[0].content.indexOf(".mp4") > -1 ?
                                <VideoPlayer url={this.state.ads.hero.data[0].content} />
                                :
                                <img className="e-hero-dummy-img" src={this.state.ads.hero.data[0].content} alt="Truekhoj hero" />
                            }

                            {/*<img className="e-hero-dummy-img" src={this.state.ads.hero.data[0].content} alt="Truekhoj hero"/>*/}
                          </a>
                        </div>
                        :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 1)}></span>
                          Hero banner advertisement section
                        </div>
                    }
                  </div>
                </div>
              </div>

              {
                this.state.ads && this.state.ads.logo_slider.data && this.state.ads.logo_slider.data.length ?
                  <div className="row mt-4">
                    {
                      this.state.ads.logo_slider.data.map((item, index) => {
                        return (
                          <div className="col-lg-2">
                            <div className="e-layout-row e-ad-row">
                              {
                                this.state.ads && item && item.content ?
                                  <div className="position-relative e-hero-dummy-img-wrap">
                                    <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 2, index)}></span>
                                    <a href={item.link} target="_blank">
                                      <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                    </a>
                                  </div>
                                  :
                                  <div className="e-ad-full-placeholder">
                                    <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 2, index)}></span>
                                    Logo {index + 1}
                                  </div>
                              }
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                  :
                  null
              }



            </div>

            <div className="e-layout-wrap mb-3">

              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center mb-3 e-section-head-edit">
                    {
                      this.state.ads.deals.header.content && this.state.sectionHeadingEditSelect != 1 ?
                        <div className="row">
                          <div className="col-lg-5"></div>
                          <div className="col-lg-2">
                            <div className="position-relative e-hero-dummy-img-wrap">
                              <span className="e-close-icon" onClick={this.removeDummyHeader.bind(this, 1)}></span>
                              <a href={this.state.ads.deals.header.link} target="_blank">
                                <img className="e-hero-dummy-img" src={this.state.ads.deals.header.content} alt="Truekhoj highlight" />
                              </a>
                            </div>
                          </div>
                          <div className="col-lg-5"></div>
                        </div>
                        : this.state.sectionHeadingEditSelect != 1 ?
                          <>
                            <h6>{this.state.ads.deals.header}</h6>
                            <span className="e-edit" onClick={this.sectionHeadingEditClick.bind(this, 1)}></span>
                          </>
                          :
                          <div className="row">
                            <div className="col-lg-5">
                              <div className="e-upload-layout-header h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6", cursor: "pointer" }} data-toggle="modal" data-target="#editHeadingModal">
                                Edit Header content
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <span className="e-upload-layout-header border-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6" }}>OR</span>
                            </div>
                            <div className="col-lg-5">
                              <div className="e-upload-layout-header">
                                <div className="e-ad-full-placeholder position-relative">
                                  <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={() => this.setState({ headerImage: true })}></span>
                                  Upload Header Image
                                </div>
                              </div>
                            </div>
                          </div>
                    }
                  </div>
                </div>
                {
                  !this.state.wholesale && this.state.id != 34 ?
                    <div className="col-lg-12">
                      <div className="e-layout-row e-ad-row">
                        {
                          this.state.ads && this.state.ads.deals_banner.data[0].content ?
                            <div className="position-relative e-hero-dummy-img-wrap">
                              <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 3)}></span>
                              <a href={this.state.ads.deals_banner.data[0].link} target="_blank">
                                {
                                  this.state.ads.deals_banner.data[0].content && this.state.ads.deals_banner.data[0].content.indexOf(".mp4") > -1 ?
                                    <VideoPlayer url={this.state.ads.deals_banner.data[0].content} />
                                    :
                                    <img className="e-hero-dummy-img" src={this.state.ads.deals_banner.data[0].content} alt="Truekhoj hero" />
                                }
                              </a>
                            </div>
                            :
                            <div className="e-ad-full-placeholder position-relative">
                              <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 3)}></span>
                              Buy of the moment special banner
                            </div>
                        }
                      </div>
                    </div>
                    :
                    null
                }


                {
                  this.state.ads && this.state.ads.deals.data && this.state.ads.deals.data.length ?
                    <React.Fragment>
                      {
                        this.state.ads.deals.data.map((item, index) => {
                          return (
                            <div className={this.state.wholesale || this.state.id == 34 ? "col-lg-6 mt-4" : "col-lg-4 mt-4"}>
                              <div className="e-layout-row e-ad-row">
                                {
                                  item && item.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 4, index)}></span>
                                      <a href={item.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                      </a>
                                    </div>
                                    :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 4, index)}></span>
                                      Sub banner
                                    </div>
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </React.Fragment>
                    :
                    null
                }

                {
                  this.state.wholesale || this.state.id == 34 ?
                    <div className="col-lg-12 mt-3">
                      <div className="e-layout-row e-ad-row">
                        {
                          this.state.ads && this.state.ads.deals_banner.data[0].content ?
                            <div className="position-relative e-hero-dummy-img-wrap">
                              <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 3)}></span>
                              <a href={this.state.ads.deals_banner.data[0].link} target="_blank">
                                {
                                  this.state.ads.deals_banner.data[0].content && this.state.ads.deals_banner.data[0].content.indexOf(".mp4") > -1 ?
                                    <VideoPlayer url={this.state.ads.deals_banner.data[0].content} />
                                    :
                                    <img className="e-hero-dummy-img" src={this.state.ads.deals_banner.data[0].content} alt="Truekhoj hero" />
                                }
                              </a>
                            </div>
                            :
                            <div className="e-ad-full-placeholder position-relative">
                              <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 3)}></span>
                              Buy of the moment special banner
                            </div>
                        }
                      </div>
                    </div>
                    :
                    null
                }
              </div>

            </div>
            <div className="e-layout-wrap mb-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center e-section-head-edit">
                    {
                      this.state.ads.ad_section_1.header.content && this.state.sectionHeadingEditSelect != 2 ?
                        <div className="row">
                          <div className="col-lg-5"></div>
                          <div className="col-lg-2">
                            <div className="position-relative e-hero-dummy-img-wrap">
                              <span className="e-close-icon" onClick={this.removeDummyHeader.bind(this, 2)}></span>
                              <a href={this.state.ads.ad_section_1.header.link} target="_blank">
                                <img className="e-hero-dummy-img" src={this.state.ads.ad_section_1.header.content} alt="Truekhoj highlight" />
                              </a>
                            </div>
                          </div>
                          <div className="col-lg-5"></div>
                        </div>
                        : this.state.sectionHeadingEditSelect != 2 ?
                          <>
                            <span className="e-edit" onClick={this.sectionHeadingEditClick.bind(this, 2)}></span>
                            <h6>{this.state.ads.ad_section_1.header}</h6>
                          </>
                          :
                          <div className="row">
                            <div className="col-lg-5">
                              <div className="e-upload-layout-header h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6", cursor: "pointer" }} data-toggle="modal" data-target="#editHeadingModal">
                                Edit Header content
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <span className="e-upload-layout-header border-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6" }}>OR</span>
                            </div>
                            <div className="col-lg-5">
                              <div className="e-upload-layout-header">
                                <div className="e-ad-full-placeholder position-relative">
                                  <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={() => this.setState({ headerImage: true })}></span>
                                  Upload Header Image
                                </div>
                              </div>
                            </div>
                          </div>
                    }
                  </div>
                </div>
              </div>

              <div className="row">

                {
                  this.state.ads && this.state.ads.ad_section_1.data && this.state.ads.ad_section_1.data.length ?
                    <React.Fragment>
                      {
                        this.state.ads.ad_section_1.data.map((item, index) => {
                          return (
                            <div className={this.state.wholesale && this.state.ads.ad_section_1.data.length === 6 ? "col-lg-2 mb-4" : this.state.wholesale ? "col-lg-3 mt-4" : "col-lg-4 mt-4"}>
                              <div className="e-layout-row e-ad-row position-relative">
                                {
                                  item && item.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 5, index)}></span>
                                      <a href={item.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                      </a>
                                    </div>
                                    :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 5, index)}></span>
                                      Banner {index + 1}
                                    </div>
                                }
                                {
                                  item.content ?
                                    <div className="w-100 pt-2 e-vendor-selection">
                                      <SelectBox options={this.state.vendorOptions} defaultValue={this.state.ads.ad_section_1.vendors ? this.state.ads.ad_section_1.vendors[index] : null} selectchange={(value) => this.setVendor(value, index)} />
                                    </div>
                                    :
                                    null
                                }
                              </div>
                              {
                                this.state.wholesale && item.content ?
                                  <div className="w-100 pt-2 e-vendor-selection">
                                    <SelectBox options={this.state.vendorOptions} defaultValue={this.state.ads.ad_section_1.vendors ? this.state.ads.ad_section_1.vendors[index] : null} selectchange={(value) => this.setVendor(value, index)} />
                                  </div>
                                  :
                                  null
                              }
                            </div>
                          );
                        })
                      }
                      {
                        this.state.wholesale && this.state.ads.ad_section_1.data.length < 8 ?
                          <div className="col-lg-12 py-4">
                            <div className="e-section-head-placeholder mt-3 mb-4 shadow-sm" onClick={this.addMoreStores.bind(this)}>
                              Add more stores
                            </div>
                          </div>
                          :
                          null
                      }
                    </React.Fragment>
                    :
                    null
                }
              </div>
            </div>

            <div className="e-layout-wrap">
              {
                this.state.id != 34 ?
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-center e-section-head-edit">
                        {
                          this.state.ads.ad_section_2.header.content && this.state.sectionHeadingEditSelect != 3 ?
                            <div className="row">
                              <div className="col-lg-5"></div>
                              <div className="col-lg-2">
                                <div className="position-relative e-hero-dummy-img-wrap">
                                  <span className="e-close-icon" onClick={this.removeDummyHeader.bind(this, 3)}></span>
                                  <a href={this.state.ads.ad_section_2.header.link} target="_blank">
                                    <img className="e-hero-dummy-img" src={this.state.ads.ad_section_2.header.content} alt="Truekhoj highlight" />
                                  </a>
                                </div>
                              </div>
                              <div className="col-lg-5"></div>
                            </div>
                            : this.state.sectionHeadingEditSelect != 3 ?
                              <>
                                <span className="e-edit" onClick={this.sectionHeadingEditClick.bind(this, 3)}></span>
                                <h6>{this.state.ads.ad_section_2.header}</h6>
                              </>
                              :
                              <div className="row">
                                <div className="col-lg-5">
                                  <div className="e-upload-layout-header h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6", cursor: "pointer" }} data-toggle="modal" data-target="#editHeadingModal">
                                    Edit Header content
                                  </div>
                                </div>
                                <div className="col-lg-2">
                                  <span className="e-upload-layout-header border-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6" }}>OR</span>
                                </div>
                                <div className="col-lg-5">
                                  <div className="e-upload-layout-header">
                                    <div className="e-ad-full-placeholder position-relative">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={() => this.setState({ headerImage: true })}></span>
                                      Upload Header Image
                                    </div>
                                  </div>
                                </div>
                              </div>
                        }
                      </div>
                    </div>
                  </div>
                  :
                  null
              }

              <div className="row">

                {
                  this.state.ads && this.state.ads.ad_section_2.data && this.state.ads.ad_section_2.data.length ?
                    <React.Fragment>
                      {
                        this.state.ads.ad_section_2.data.map((item, index) => {
                          return (
                            <div className={this.state.wholesale ? "col-lg-6 mt-4" : this.state.id == 34 ? "col-lg-12 mt-4" : "col-lg-4 mt-4"}>
                              <div className="e-layout-row e-ad-row position-relative">
                                {
                                  item && item.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 6, index)}></span>
                                      <a href={item.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                      </a>
                                    </div>
                                    :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 6, index)}></span>
                                      Banner {index + 1}
                                    </div>
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </React.Fragment>
                    :
                    null
                }
              </div>

            </div>

            <div className={this.state.wholesale ? "e-layout-wrap" : "e-layout-wrap mt-3"}>
              {
                !this.state.wholesale ?
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-center e-section-head-edit">
                        {
                          this.state.ads.ad_section_3.header && this.state.ads.ad_section_3.header.content && this.state.sectionHeadingEditSelect != 4 ?
                            <div className="row">
                              <div className="col-lg-5"></div>
                              <div className="col-lg-2">
                                <div className="position-relative e-hero-dummy-img-wrap">
                                  <span className="e-close-icon" onClick={this.removeDummyHeader.bind(this, 4)}></span>
                                  <a href={this.state.ads.ad_section_3.header.link} target="_blank">
                                    <img className="e-hero-dummy-img" src={this.state.ads.ad_section_3.header.content} alt="Truekhoj highlight" />
                                  </a>
                                </div>
                              </div>
                              <div className="col-lg-5"></div>
                            </div>
                            : this.state.sectionHeadingEditSelect != 4 ?
                              <>
                                <span className="e-edit" onClick={this.sectionHeadingEditClick.bind(this, 4)}></span>
                                <h6>{this.state.ads.ad_section_3.header}</h6>
                              </>
                              :
                              <div className="row">
                                <div className="col-lg-5">
                                  <div className="e-upload-layout-header h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6", cursor: "pointer" }} data-toggle="modal" data-target="#editHeadingModal">
                                    Edit Header content
                                  </div>
                                </div>
                                <div className="col-lg-2">
                                  <span className="e-upload-layout-header border-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6" }}>OR</span>
                                </div>
                                <div className="col-lg-5">
                                  <div className="e-upload-layout-header">
                                    <div className="e-ad-full-placeholder position-relative">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={() => this.setState({ headerImage: true })}></span>
                                      Upload Header Image
                                    </div>
                                  </div>
                                </div>
                              </div>
                        }
                      </div>
                    </div>
                  </div>
                  :
                  null
              }

              <div className="row ">

                {
                  this.state.ads && this.state.ads.ad_section_3.data && this.state.ads.ad_section_3.data.length ?
                    <React.Fragment>
                      {
                        this.state.ads.ad_section_3.data.map((item, index) => {
                          return (
                            <div className={this.state.wholesale || (this.state.id == 34 && index == 0) || (this.state.id == 34 && index == 3) ? "col-lg-12 mt-4" : this.state.id == 34 ? "col-lg-6 mt-4" : "col-lg-4 mt-4"}>
                              <div className="e-layout-row e-ad-row position-relative">
                                {
                                  item && item.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 7, index)}></span>
                                      <a href={item.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                      </a>
                                    </div>
                                    :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 7, index)}></span>
                                      Banner {index + 1}
                                    </div>
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </React.Fragment>
                    :
                    null
                }
              </div>

            </div>

            <div className={this.state.wholesale ? "e-layout-wrap" : "e-layout-wrap mt-3"}>
              {
                !this.state.wholesale ?
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-center e-section-head-edit">
                        {
                          this.state.ads.ad_section_4.header && this.state.ads.ad_section_4.header.content && this.state.sectionHeadingEditSelect != 5 ?
                            <div className="row">
                              <div className="col-lg-5"></div>
                              <div className="col-lg-2">
                                <div className="position-relative e-hero-dummy-img-wrap">
                                  <span className="e-close-icon" onClick={this.removeDummyHeader.bind(this, 5)}></span>
                                  <a href={this.state.ads.ad_section_4.header.link} target="_blank">
                                    <img className="e-hero-dummy-img" src={this.state.ads.ad_section_4.header.content} alt="Truekhoj highlight" />
                                  </a>
                                </div>
                              </div>
                              <div className="col-lg-5"></div>
                            </div>
                            : this.state.sectionHeadingEditSelect != 5 ?
                              <>
                                <span className="e-edit" onClick={this.sectionHeadingEditClick.bind(this, 5)}></span>
                                <h6>{this.state.ads.ad_section_4.header}</h6>
                              </>
                              :
                              <div className="row">
                                <div className="col-lg-5">
                                  <div className="e-upload-layout-header h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6", cursor: "pointer" }} data-toggle="modal" data-target="#editHeadingModal">
                                    Edit Header content
                                  </div>
                                </div>
                                <div className="col-lg-2">
                                  <span className="e-upload-layout-header border-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6" }}>OR</span>
                                </div>
                                <div className="col-lg-5">
                                  <div className="e-upload-layout-header">
                                    <div className="e-ad-full-placeholder position-relative">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={() => this.setState({ headerImage: true })}></span>
                                      Upload Header Image
                                    </div>
                                  </div>
                                </div>
                              </div>
                        }
                      </div>
                    </div>
                  </div>
                  :
                  null
              }

              <div className="row">

                {
                  this.state.ads && this.state.ads.ad_section_4.data && this.state.ads.ad_section_4.data.length ?
                    <React.Fragment>
                      {
                        this.state.ads.ad_section_4.data.map((item, index) => {
                          return (
                            <div className={this.state.wholesale ? "col-lg-6 mt-4" : "col-lg-4 mt-4"}>
                              <div className="e-layout-row e-ad-row position-relative">
                                {
                                  item && item.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 8, index)}></span>
                                      <a href={item.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                      </a>
                                    </div>
                                    :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 8, index)}></span>
                                      Banner {index + 1}
                                    </div>
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </React.Fragment>
                    :
                    null
                }
              </div>

            </div>

            {
              this.state.wholesale && this.state.ads && this.state.ads.wholesalePrice && this.state.ads.wholesalePrice.data && this.state.ads.wholesalePrice.data.length ?
                <div className="e-layout-wrap my-3">
                  <div className="row py-3">
                    <div className="col-lg-12 text-center">Wholesale Price</div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 mt-4"></div>
                    {
                      this.state.ads.wholesalePrice.data.map((item, index) => {
                        return (
                          <div className="col-lg-4 mt-4">
                            <div className="e-layout-row e-ad-row position-relative">
                              {
                                item && item.content ?
                                  <div className="position-relative e-hero-dummy-img-wrap">
                                    <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 9, index)}></span>
                                    <a href={item.link} target="_blank">
                                      <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight" />
                                    </a>
                                  </div>
                                  :
                                  <div className="e-ad-full-placeholder">
                                    <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 9, index)}></span>
                                    Banner {index + 1}
                                  </div>
                              }
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
                :
                null
            }

            <div className="e-layout-wrap my-3">
              {
                this.state.layout && this.state.layout.map((data, index) => {
                  var tempClass = "e-section-swap-btn e-section-swap-" + index;
                  var tempClassSelect = ".e-section-swap-" + index;

                  var tempInputClass = "e-swap-input" + index;
                  var tempInputClassSelect = ".e-swap-input" + index;

                  var tempStyle = null;
                  if (data.bg_color) {
                    var tempStyle = { background: data.bg_color };
                  }


                  return (
                    <div className="row mt-4" key={index} style={tempStyle}>
                      <div className="col-lg-12">
                        <div className="e-row-input-wrap">
                          <label className="label">Section - {index + 1}: </label>
                          <input className={tempInputClass} onChange={this.sectionSwapInputChange.bind(this, tempClassSelect)} type="text" required />
                          <span className={tempClass} onClick={this.sectionSwap.bind(this, index + 1, tempInputClassSelect)}></span>
                        </div>
                        <div className="e-layout-row e-edit-section">
                          <span className="e-close-icon" onClick={this.removeSection.bind(this, index)}></span>
                          {
                            data.header.content && !data.header.link ?
                              <div className="bg-white e-demo-element-wrap">
                                <SectionHeadingStyle data={data.header} />
                              </div>
                              : data.header.content && data.header.link ?
                                <div className="row">
                                  <div className="col-lg-5"></div>
                                  <div className="col-lg-2">
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 10, index)}></span>
                                      <a href={data.header.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={data.header.content} alt="Truekhoj highlight" />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-lg-5"></div>
                                </div>
                                : this.state.selectItemIndex === index ?
                                  <div className="row">
                                    <div className="col-lg-5">
                                      <div className="e-upload-layout-header h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6", cursor: "pointer" }} data-toggle="modal" data-target="#headerModal">
                                        Choose Header style and content
                                      </div>
                                    </div>
                                    <div className="col-lg-2">
                                      <span className="e-upload-layout-header border-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6" }}>OR</span>
                                    </div>
                                    <div className="col-lg-5">
                                      <div className="e-upload-layout-header">
                                        <div className="e-ad-full-placeholder position-relative">
                                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 10)}></span>
                                          Upload Header Image
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div className="e-section-head-placeholder" onClick={this.selectedIndex.bind(this, index)} >
                                    + Add Section Header
                                  </div>
                          }


                          {
                            data.ads && data.ads.content ?
                              <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index)}></span>
                                <img className="e-hero-dummy-img" src={data.ads.content} alt="Truekhoj highlight" />
                              </div>
                              :
                              <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index)}></span>
                                Section banner advertisement
                              </div>
                          }
                           {
                              data.favStore && data.favStore.data && data.favStore.data.length?
                              <div className='row'>
                              
                              {
                                data.favStore.data.map((item, key)=>{
                                  
                                return(
                                  <div className='col-lg-6 mt-3'>
                                    <div className='card e-favitem-card'>
                                        <img  src={item.content}/>
                                        <p className='mt-3 text-center '>Vendor - {item.vendor.label}</p>
                                    </div>
                                  </div>
                                  )
                              })
                            }
                              </div>
                              :
                              null
                            }

                          {
                            data.data.content ?
                              <div>
                                <div className="mt-3 e-no-p">
                                  {
                                    data.data.content && data.data.type === 1 ?
                                      <CardFourColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color} />
                                      : data.data.content && data.data.type === 2 ?
                                        <CardSixColumn data={data.data.content} type={data.data.style.value} />
                                        : data.data.content && data.data.type === 3 ?
                                          <CardTwoColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color} />
                                          : data.data.content && data.data.type === 4 ?
                                            <CardThreeColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color} />
                                            :
                                            null
                                  }
                                </div>

                                {
                                  this.state.layout.length == index + 1 && data.data.content.length != 8 && data.data.content.length != 12 && data.data.content.length != 10 ?
                                    <div className="e-section-head-placeholder mt-3" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#listModal">
                                      + Add Category List
                                    </div>
                                    :
                                    null

                                }

                              </div>
                              :
                              <div className="e-section-head-placeholder mt-3" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#listModal">
                                + Add Category List
                              </div>
                          }
                          <div className="text-right">
                            <span className="e-link" data-toggle="modal" data-target="#addBg" onClick={this.selectedIndex.bind(this, index)}>+ Add bg color</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              }

              <div className="row my-4">
                {
                  this.state.layout.length < 3 ?
                    <div className="col-lg-12 text-center">
                      <button className="e-s-btn" type="submit" value="Submit" onClick={this.addSection.bind(this)}>+ Add New Section & Elements</button>
                    </div>
                    :
                    null
                }
              </div>


            </div>
          </div>
        </div>

        {/*Modal*/}
        {
          this.state.categoryList ?
            <HeadingSelectionModal success={this.headingSelectSuccess.bind(this)} data={this.state.categoryList} type={2} />
            :
            null
        }

        {
          this.state.selectedSubCategories ?
            <ListSelectionModal success={this.listSelectSuccess.bind(this)} catList={this.state.categoryList} include_category={true}  data={this.state.selectedSubCategories} />
            :
            null
        }

        {
          !this.state.modalRefresh ?
            <UploadDummyAdsModal success={this.state.headerImage ? this.sectionHeadingChange.bind(this) : this.adsDummySuccess.bind(this)} />
            :
            null
        }

        <EditHeadingModal success={this.sectionHeadingChange.bind(this)} />

        <UploadDummyCatBannerModal success={this.catAdsBannerSuccess.bind(this)} />


        <AddBg success={this.bgColorSuccess.bind(this)} />

      </div>

    );
  }
}

export default LocalDealsPagesLayout;
