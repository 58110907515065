import React from 'react';


/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import NoDataFound from '../../components/other/nothing.js';
import APILoader from '../../components/other/api-loader.js';
import NothingFound from '../../components/common/nothingFound';

export default class  extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount(){
        this.getusers();
    }

    getusers = () =>{
        this.setState({ loader: true})
        let url  = devconfig.admin+'/contactus/list-all';
        let data =JSON.stringify({
            "user"  : false
        })
        APIPost(url, data).then((response)=>{
            if(response.status){
                this.setState({ data: response.data, loader: false})
            }
            else{
                this.setState({ data: null, loader: false})
            }
        })
    }

    render(){
        return(
            <div className="row">
            {
                this.state.loader ? 
                    <div className='col-lg-12 text-center mt-5'>
                        <APILoader/>
                    </div>
                : !this.state.loader && !this.state.data ?
                    <div className='col-lg-12 text-center'>
                            <NothingFound/>
                    </div>
                :
                    <div className='col-lg-12'>
                        <table className="table">
                            <thead>
                                <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Date</th>
                                <th>Subject</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                this.state.data.map((item,index) => {
                                    return(
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>{item.mobile}</td>
                                        <td>{item.email}</td>
                                        <td>{item.date}</td>
                                        <td>{item.subject}</td>
                                    </tr>
                                    );
                                })
                                }
                            </tbody>
                        </table>
                    </div>
            }
            
        </div>

        )
    }
}