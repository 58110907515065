
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


/*Components*/
import HeadingSelectionModal from '../../../components/modals/headingSelectionModal';
import ListSelectionModal from '../../../components/modals/listSelectionModal';
import SectionHeadingStyle from '../../../components/elementsPreview/sectionHeadingStyle';
import Input from '../../../components/formComponents/input';
import CardFourColumn from '../../../components/cards/column-4.js';
import UploadDummyAdsModal from '../../../components/modals/uploadDummyAdsModal';
import UploadDummyCatBannerModal from '../../../components/modals/uploadDummyCategorybanner';
import VideoPlayer from '../../../components/common/video';
import AddBg from '../../../components/modals/addBg';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';
import CardTwoColumn from '../../../components/cards/column-2';
import CardThreeColumn from '../../../components/cards/column-3';
import CardSixColumn from '../../../components/cards/column-6';

/*Assets*/

var tempAdItem = {content: null, link: null};

class OnlineHomeLayout extends Component {
  notify = e => toast(e);
  constructor(props){
    super(props);
    this.state = {
      layout: [],
      ads: {
        hero: { id: 1, page_id: 1, data: [{content: null, link: null}]},
        ad1: { id: 2, page_id: 1, data: [{content: null, link: null},{content: null, link: null}, {content: null, link: null}, {content: null, link: null}] },
      },
      id: null,
      selectItemIndex: null,
      categoryData: null,
      selectedSubCategories: null,
      data: null,
      name: null,
      adDummyType: null,
      swapIndex: "",
      catBannerAdIndex: null,
      updateApiLoading: false,
    }
  }

  componentDidMount() {
    this.getCategoryData();
    GetURLVar().then(function(query){
      if(query){
        this.getData(query.id);
        this.setState({id: query.id});
      }
    }.bind(this))
  }

  getData(tempId){
    var url = devconfig.adminV1+"/online/page/detail";
    var data = JSON.stringify({
      "layout_id" : tempId
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        if(response.data.page_data.layout){
          this.setState({layout: response.data.page_data.layout});
        }
        if(response.data.page_data.ads){
          this.setState({ads: response.data.page_data.ads});
        }
        this.setState({name: response.data.name});
      }
    }.bind(this))
  }

  addSection(){
    var tempArry =  {
      "header": { "content": null, "type": null, "font_color": null, "bg_color": null, category: null},
      "ads": { "content": null, "link": null},
      "data": { "content": null , "image": null, "stlye": null},
      "page_id": 1,
      "id": 3
    }

    var tempLayout = this.state.layout;
    tempLayout.push(tempArry);
    this.setState({layout: tempLayout, selectedSubCategories: null});
  }

  removeSection(tempIndex){
    var tempLayout = this.state.layout;
    tempLayout.splice(tempIndex,1);

    this.setState({layout: tempLayout, selectedSubCategories: null});
  }

  headingSelectSuccess(tempItem){
    var tempArry = this.state.layout;
    tempArry[this.state.selectItemIndex].header = tempItem;
    this.setState({layout: tempArry});

    var tempItem = this.state.categoryData.filter(function(obj) {
      return obj.id === tempItem.category
    });

    var tempArry = [];
    tempItem[0].sub_category.map(function(item, index){
      var tempItem = {value: item.id, label: item.sub_category}
      tempArry.push(tempItem);
    });
    this.setState({selectedSubCategories: tempArry});
  }

  bgColorSuccess(tempColor){
    var tempArry = this.state.layout;
    tempArry[this.state.selectItemIndex].bg_color = tempColor;
    this.setState({layout: tempArry});
  }

  listSelectSuccess(tempItem){
    var tempArry = this.state.layout;
    if(tempArry[this.state.selectItemIndex].data && tempArry[this.state.selectItemIndex].data.content){
      var temp = tempArry[this.state.selectItemIndex].data.content.concat(tempItem.content);
      tempArry[this.state.selectItemIndex].data.content = temp;
      tempArry[this.state.selectItemIndex].data.style = tempItem.style;
      tempArry[this.state.selectItemIndex].data.type = tempItem.type;
    }
    else{
      tempArry[this.state.selectItemIndex].data = tempItem;
    }
    // tempArry[this.state.selectItemIndex].data = tempItem;
    this.setState({layout: tempArry});
  }

  selectedIndex(tempindex){
    this.setState({selectItemIndex: tempindex});
  }

  sectionSwapInputChange(tempClass, e){
    $(".e-section-swap-btn").removeClass("e-active");
    $(tempClass).addClass("e-active");
  }
  
  sectionSwapInputReset(){
    $(".e-section-swap-btn").removeClass("e-active");
  }

  getCategoryData(){
    var url = devconfig.adminV2+"/category/list";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        var tempArry = [];
        response.data.map(function(item, index){
          var tempItem = {value: item.id, label: item.category}
          tempArry.push(tempItem);
        });

        this.setState({categoryList: tempArry, categoryData: response.data});
      }
    }.bind(this))
  }

  updatePageLayout(){
    this.setState({updateApiLoading: true});
    
    let category_ids=[];
    let layout_ad_location_id = null;
    if(this.state.layout && this.state.layout.length){
      this.state.layout.forEach(section=>{
        if(section.header && section.header.category) category_ids.push(section.header.category);
      });
      layout_ad_location_id = this.state.layout[0].id;
    }

    var tempArry = {
      layout: this.state.layout,
      ads: this.state.ads
    }
    var url = devconfig.adminV1+"/online/page/update";
    var data = JSON.stringify({
      "layout_id" : this.state.id,
      "page_data" : tempArry,
      "layout_ad_location_id": layout_ad_location_id,
      "category_ids": category_ids
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({ updateApiLoading: false });
      }
    }.bind(this))
  }

  adsDummySuccess(tempUrl, tempLink){
    var tempArry = this.state.ads;

    switch (this.state.adDummyType) {
      case 1: {
        tempArry.hero.data = [{content: tempUrl, link: tempLink}];
        tempArry.hero.page_id = 1;
        tempArry.hero.id = 1;
        break;
      }
      case 2: {
        tempArry.ad1.data[0] = {content: tempUrl, link: tempLink};
        tempArry.ad1.page_id = 1;
        tempArry.ad1.id = 2;
        break;
      }
      case 3: {
        // tempArry.highlightTwo = {content: tempUrl, link: tempLink};
        tempArry.ad1.data[1] = {content: tempUrl, link: tempLink};
        tempArry.ad1.page_id = 1;
        tempArry.ad1.id = 2;
        break;
      }
      case 4: {
        // tempArry.highlightThree = {content: tempUrl, link: tempLink};
        tempArry.ad1.data[2] = {content: tempUrl, link: tempLink};
        tempArry.ad1.page_id = 1;
        tempArry.ad1.id = 2;
        break;
      }
      case 5: {
        // tempArry.highlightFour = {content: tempUrl, link: tempLink};
        tempArry.ad1.data[3] = {content: tempUrl, link: tempLink};
        tempArry.ad1.page_id = 1;
        tempArry.ad1.id = 2;
        break;
      }
    }

    this.setState({ads: tempArry});
  }

  adDummyTypeClick(tempItem){
    this.setState({adDummyType: tempItem});
  }

  removeDummyAds(tempItem){
    var tempArry = this.state.ads;

    switch (tempItem) {
      case 1: {
        tempArry.hero.data = {content: null, link: null};
        break;
      }
      case 2: {
        // tempArry.highlightOne = {content: null, link: null};
        tempArry.ad1.data[0] = {content: null, link: null};
        break;
      }
      case 3: {
        // tempArry.highlightTwo = {content: null, link: null};
        tempArry.ad1.data[1] = {content: null, link: null};
        break;
      }
      case 4: {
        // tempArry.highlightThree = {content: null, link: null};
        tempArry.ad1.data[2] = {content: null, link: null};
        break;
      }
      case 5: {
        // tempArry.highlightFour = {content: null, link: null};
        tempArry.ad1.data[3] = {content: null, link: null};
        break;
      }
    }

    this.setState({ads: tempArry});
  }

  sectionSwap(tempCurrectIndex, tempClass){
    var tempArry = this.state.layout;
    var tempSwapValue = $(tempClass).val();

    if(tempSwapValue > tempArry.length || tempSwapValue <= 0){
      this.notify("Swap index must be between 0 to "+tempArry.length)
    }
    else{
      if(tempArry[tempSwapValue-1]){
        var b = tempArry[tempCurrectIndex-1];
        tempArry[tempCurrectIndex-1] = tempArry[tempSwapValue-1];
        tempArry[tempSwapValue-1] = b;
        $(".e-section-swap-btn").removeClass("e-active");
        $(tempClass).val("")
      }
      else{
        this.notify("Please verify your input data")
      }
      this.setState({layout: tempArry});
    }
  }

  catBannerIndexClick(tempIndex){
    this.setState({catBannerAdIndex: tempIndex});
  }

  catAdsBannerSuccess(tempImg, tempLink){
    var tempArry = this.state.layout;
    var tempItem = { "content": tempImg, "link": tempLink};

    tempArry[this.state.catBannerAdIndex].ads = { "content": tempImg, "link": tempLink};
    this.setState({layout: tempArry});
  }

  removeCatAds(tempIndex){
    var tempArry = this.state.layout;
    tempArry[tempIndex].ads = { "content": null, "link": null};
    this.setState({layout: tempArry});
  }

  render() {
    console.log("this.state.categoryList");
    console.log(this.state.ads.hero.data);


    return (
      <div className="e-main-content e-driver-detail-wrap e-dash-wrap">
        <h2 className="e-page-head">{this.state.name} Page Layout</h2>

        {
          this.state.updateApiLoading ?
            <button className="e-p-btn e-nav-button e-inactive e-loading e-page-layout-btn"></button>
          :
            <span>
              {
                this.state.layout && this.state.layout.length ?
                  <button className="e-p-btn e-nav-button" onClick={this.updatePageLayout.bind(this)}>Update page</button>
                :
                  <button className="e-p-btn e-nav-button e-inactive">Update page</button>
              }
            </span>
        }

        <div className="row">
          <div className="col-lg-12">
            <div className="e-layout-wrap">

              <div className="row">
                <div className="col-lg-12">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads.hero && this.state.ads.hero.data.length && this.state.ads.hero.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 1)}></span>
                          <a href={this.state.ads.hero.data[0].link} target="_blank">
                            {
                              this.state.ads.hero.data[0].content.indexOf(".mp4") > -1 ?
                                <VideoPlayer url={this.state.ads.hero.data[0].content} />
                              :
                                <img className="e-hero-dummy-img" src={this.state.ads.hero.data[0].content} alt="Truekhoj hero"/>
                            }
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal"  onClick={this.adDummyTypeClick.bind(this, 1)}></span>
                          Hero banner advertisement section
                        </div>
                    } 
                    
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-3">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.ad1.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 2)}></span>
                          <a href={this.state.ads.ad1.data[0].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.ad1.data[0].content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 2)}></span>
                          Deals of the day
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.ad1.data[1].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 3)}></span>
                          <a href={this.state.ads.ad1.data[1].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.ad1.data[1].content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 3)}></span>
                          Sales of the week
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.ad1.data[2].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 4)}></span>
                          <a href={this.state.ads.ad1.data[2].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.ad1.data[2].content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 4)}></span>
                          Brand of the day
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.ad1.data[3].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 5)}></span>
                          <a href={this.state.ads.ad1.data[3].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.ad1.data[3].content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 5)}></span>
                          Exclusive offers
                        </div>
                    }
                  </div>
                </div>
              </div>

              { 
                this.state.layout && this.state.layout.map( (data, index) => {
                  var tempClass = "e-section-swap-btn e-section-swap-"+index;
                  var tempClassSelect = ".e-section-swap-"+index;

                  var tempInputClass = "e-swap-input"+index;
                  var tempInputClassSelect = ".e-swap-input"+index;

                  var tempStyle = null;
                  if(data.bg_color){
                    var tempStyle = {background: data.bg_color};
                  }

                  return( 
                    <div className="row mt-4" key={index} style={tempStyle}>
                      <div className="col-lg-12">
                        <div className="e-row-input-wrap">
                          <label className="label">Section - {index + 1}: </label>
                          <input className={tempInputClass} onChange={this.sectionSwapInputChange.bind(this, tempClassSelect)}  type="text" required/>
                          <span className={tempClass} onClick={this.sectionSwap.bind(this, index + 1, tempInputClassSelect)}></span>
                        </div>
                        <div className="e-layout-row e-edit-section">
                          <span className="e-close-icon" onClick={this.removeSection.bind(this, index)}></span>
                          {
                            data.header.content ?
                              <div className="bg-white e-demo-element-wrap">
                                <SectionHeadingStyle data={data.header}/>
                              </div>
                            :
                              <div className="e-section-head-placeholder" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#headerModal">
                                + Add Section Header
                              </div>
                          }

                          {
                            data.ads && data.ads.content ?
                              <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index)}></span>
                                {
                                  data.ads.content.indexOf(".mp4") > -1 ?
                                    <VideoPlayer url={data.ads.content} />
                                  :
                                    <img className="e-hero-dummy-img" src={data.ads.content} alt="Truekhoj highlight"/>
                                }
                                
                              </div>
                            :
                              <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index)}></span>
                                Section banner advertisement
                              </div>
                          }

                          {
                            data.data.content ?
                              <React.Fragment>
                                <div className="mt-3 e-no-p mb-4">
                                {
                                    data.data.content && data.data.type === 1?
                                      <CardFourColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color}/>
                                    : data.data.content && data.data.type === 2?
                                      <CardSixColumn data={data.data.content} type={data.data.style.value}/>
                                    : data.data.content && data.data.type === 3?
                                      <CardTwoColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color}/>
                                    : data.data.content && data.data.type === 4?
                                      <CardThreeColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color}/>
                                    :
                                    null
                                  }
                                </div>
                                {
                                  this.state.layout.length == index + 1  && data.data.content.length != 8 && data.data.content.length != 12 && data.data.content.length != 10  && this.state.selectedSubCategories?
                                    <div className="e-section-head-placeholder" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#listModal">
                                      + Add Category List
                                    </div>
                                  :
                                    null
                                }
                              </React.Fragment>

                            :
                              <div className="e-section-head-placeholder mt-3" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#listModal">
                                + Add Category List
                              </div>
                          }

                          <div className="text-right">
                            <span className="e-link" data-toggle="modal" data-target="#addBg" onClick={this.selectedIndex.bind(this, index)}>+ Add bg color</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              }

              <div className="row my-4">
                <div className="col-lg-12 text-center">
                  <button className="e-s-btn" type="submit" value="Submit" onClick={this.addSection.bind(this)}>+ Add New Section & Elements</button>
                </div>
              </div>


            </div>
          </div>
        </div>

      {/*Modal*/}
      {
        this.state.categoryList ?
          <HeadingSelectionModal success={this.headingSelectSuccess.bind(this)} data={this.state.categoryList} type={1}/>
        :
          null
      }
      
      {
        this.state.selectedSubCategories ?
          <ListSelectionModal success={this.listSelectSuccess.bind(this)} data={this.state.selectedSubCategories} type={1}/>
        :
          null
      }
      
      <UploadDummyAdsModal success={this.adsDummySuccess.bind(this)}/>

      <UploadDummyCatBannerModal success={this.catAdsBannerSuccess.bind(this)}/>

      <AddBg success={this.bgColorSuccess.bind(this)}/>

      </div>
      
    );
  }
}

export default OnlineHomeLayout;
