
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


/*Components*/


/*Assets*/
import UpArrow from '../../assets/images/sidebar/up-arrow.png';



class PlanList extends Component {
  constructor(props){
    super(props);
    this.state = {
      viewMore: null
    }
  }

  componentDidMount() {


    


  }




  render() {
    var data = this.props.data;

    return (
      <div className="row">
      {
        data.map((plan, index)=>{
          return(
            <div className="col-lg-4">
              <div className={this.state.viewMore == index ? "e-plan-wrap e-large" : "e-plan-wrap"}>
                <div className="e-plan-content e-plan-one">
                  {/* <a className="e-edit" href={"/edit-plan?id="+plan.id}></a> */}
                  <h1 className="py-4 mb-0"><span className="e-symbol">₹</span>{plan.offer_price}<span className="pl-2"><strike>₹{plan.price}</strike></span></h1>
                  {
                    this.props.type==1?
                      <h5  className="py-4 mb-0"><span className="e-symbol">₹</span>{plan.emi_amount}/month</h5>
                    :
                      null
                  }
                  <h2 className="" style={{color: plan.color}}>{plan.name}</h2>
                  <p className="pt-2">{plan.description}</p>

                  <div className="e-plan-ad-list-wrap">
                    <ul>
                      {
                        plan.features.map((item, index2)=>{
                          var validityType = item.validity_type===1? "Days" : item.validity_type===2? "Months" : item.validity_type===3? "Years" : "Days";
                          var contentTypes = "";
                          if(item.ads_content_ids.length==3){
                            contentTypes = "Images, Videos & Gifs"
                          }else{
                            if(item.ads_content_ids.includes(1)){
                              contentTypes = "Images"
                            }
                            if(item.ads_content_ids.includes(2)){
                              if(contentTypes.length){
                                contentTypes = contentTypes + "& Videos"
                              }else{
                                contentTypes = "Videos"
                              }
                            }
                            if(item.ads_content_ids.includes(3)){
                              if(contentTypes.length){
                                contentTypes = contentTypes + "& Gifs"
                              }else{
                                contentTypes = "Gifs"
                              }
                            }
                          } 
                          return(
                            <li><span style={{background: plan.color}}></span>{item.ads_location} - {item.validity} {validityType} ({contentTypes})</li>
                          )
                        })
                      
                      }
                    </ul>
                  </div>


                  <h6 className="pb-3 pt-4">{plan.additional_info && plan.additional_info.map((feature, index3)=>{return(" • " + feature)})}</h6>
                  <div className="text-center">
                    {
                      this.state.viewMore == index ?
                        <div className="e-plan-view-more" onClick={() => this.setState({viewMore: null})}>View Less</div>
                      :
                        <div className="e-plan-view-more" onClick={() => this.setState({viewMore: index})}>View More</div>
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
      </div>

    );
  }
}

export default PlanList;
