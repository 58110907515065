
/*Package importing*/
import React, { Component } from 'react';

/*Components*/
import SelectBox from '../../components/formComponents/selectBox';
import NothingFound from '../../components/common/nothingFound';
import GroupedBarChart from '../../components/charts/groupedBar';
import LineChart from '../../components/charts/line';

/*Assets*/
import profile from '../../assets/images/profile.png';
import profPlaceholder from '../../assets/images/profile-placeholder.jpg';

class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    const filterBy = [
      { value: 1, label: 'Last 1 week' },
      { value: 2, label: 'Last 1 month' },
      { value: 3, label: 'Last 3 months' },
    ]
    const filterByTwo = [
      { value: null, label: 'All'},
      { value: 1, label: 'Ongoing' },
      { value: 2, label: 'Cancelled' },
      { value: 3, label: 'Completed' },
    ]
    const sortBy = [
      { value: 1, label: 'Latest first' },
      { value: 3, label: 'Max. total amount' },
      { value: 4, label: 'Max. commission' },
    ]
    return (
      <div className="e-main-content e-driver-detail-wrap e-dash-wrap">
        <h2 className="e-page-head">Dashboard</h2>
        <div className="row">
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-3">
                <div className="e-summry-card-wrap">
                  <h6>128</h6>
                  <p className="mb-0">Total Vendors</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="e-summry-card-wrap">
                  <h6>160</h6>
                  <p className="mb-0">Ad's Created</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="e-summry-card-wrap">
                  <h6>₹ 1200</h6>
                  <p className="mb-0">Revenue Accrued</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="e-summry-card-wrap">
                  <h6>31200</h6>
                  <p className="mb-0">Users visited</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">

          <div className="col-lg-6">
            <div className="row mb-4">
              <div className="col-lg-8">
                <h2>Visitors & Ad's clicked</h2>
              </div>
              <div className="col-lg-4">
                <div className="e-filter-wrap">
                  <SelectBox defaultValue={this.state.filterDefault} options={filterBy} selectchange={this.filterChange} placeholder="Filter"/>
                </div>
              </div>
            </div>
            <GroupedBarChart/>
          </div>
          <div className="col-lg-6">
            <div className="row mb-4">
              <div className="col-lg-8">
                <h2>Revenue</h2>
              </div>
              <div className="col-lg-4">
                <div className="e-filter-wrap">
                  <SelectBox defaultValue={this.state.filterDefault} options={filterBy} selectchange={this.filterChange} placeholder="Filter"/>
                </div>
              </div>
            </div>
            <LineChart/>
          </div>
        </div>
      </div>
      
    );
  }
}

export default Dashboard;
