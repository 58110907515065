
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import APILoader from '../../../components/other/api-loader';
import NothingFound from '../../../components/other/nothing';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';

/*Assets*/
import vendorLogo from '../../../assets/images/vendor-logo.jpg';

class VendorSelectedOnlineCategories extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: true,
      data: {}
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData(tempId){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1+"/online/vendor/categories";
    var data = JSON.stringify({
      "id" : parseInt(this.props.id)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        console.log("response");
        console.log(response);
        if(response.data){
          this.setState({ data: response.data, apiLoading: false});
        }
        else{
          this.setState({ apiLoading: false});
        }
        
      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }
  

  render() {
    var data = this.state.data;
    console.log(this.state.data);

    if(this.state.apiLoading){
      return(
        <APILoader/>
      );
    }
    else{
      return(
        <div>
          {
            this.state.data && this.state.data.length?
              <React.Fragment>
                {
                  this.state.data.map((item1, index) => {
                    return(
                      <div className="row">
                        <div className="col-lg-6 mt-4">
                          <div className="e-vendor-category-list">
                            <h6>Category:</h6>
                            <p>{item1.category}</p>

                            <h6>Subcategory:</h6>
                            <p>

                            {
                              item1.sub_category.map((item2, index) => {
                                return(
                                  <span>{item2.sub_category}{item1.sub_category.length == index + 1 ? "" : ", "}</span>
                                );
                              })
                            }
                            </p>

                            <h6>Item Selected:</h6>
                            <p>
                            {
                              item1.sub_category.map((item3, index1) => {
                                return(
                                  <span>
                                    {
                                      item3.product.map((item4, index2) => {
                                        return(
                                          <span>{item4.product}{ item1.sub_category.length ==index1 + 1 && item3.product.length == index2 + 1 ? "" : ", "}</span>
                                        );
                                      })
                                    }
                                  </span>
                                );
                              })
                            }

                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </React.Fragment>
            :
              <NothingFound data="No categories found"/>
          }
        </div>
      );
    }
  }
}

export default VendorSelectedOnlineCategories;
