
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import NothingFound from '../../../components/other/nothing';
import APILoader from '../../../components/other/api-loader';
import AddLocationAdmin from '../../../components/modals/addLocationAdminModal';
import Toast from '../../../components/common/toast';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import AddOnlineAdmin from '../../../components/modals/addOnlineAdmin';
/*Assets*/


class OnlineAdminList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 1,
      activeData: [],
      data: null,
      activeLoading: false,
      error: "",
      modalOn: false
    }
  }

  componentDidMount() {
    this.getActiveData();
  }

  getActiveData() {
    this.setState({ activeLoading: true, error: "" });
    let url = devconfig.admin + "/online/admin-list";
    APIGet(url).then(function (response) {
      if (response.status === "ok") {
        this.setState({ activeData: response.data, activeLoading: false });
      }
      else {
        this.setState({ activeLoading: false, error: response.message });
      }
    }.bind(this));
  }


  triggerToast(temp, type) {
    this.setState({ toast: temp, toastType: type })
    setTimeout(function () { this.setState({ toast: null }); }.bind(this), 4000);
  }

  render() {

    return (
      <>
        <div className="e-main-content e-vendor-table">
          <h2 className="e-page-head">Online Admins</h2>

          <div className="row mb-3">
            <div className="col-lg-6 pt-4"></div>
            <div className="col-lg-6 text-right">
              <button className="e-s-btn" data-toggle="modal" data-target="#addOnlineAdmin" onClick={() => this.setState({ modalOn: true })}>Add Online Admin</button>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-lg-12">
                {
                this.state.activeLoading ?
                    <div className="container pt-5 mt-5 text-center">
                    <APILoader />
                    </div>
                :this.state.activeData && this.state.activeData.length?
                <table className="table e-location-admin-table">
                    <thead>
                        <tr>
                        <th>Name</th>
                        <th>Email ID</th>
                        <th>Phone Number</th>
                        {/* <th>Location</th> */}
                        {/* <th>Actions</th> */}
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        this.state.activeData.map(admin=>(
                            <tr>
                            <td className="text-capitalize">{admin.name.toLowerCase()}</td>
                            <td>{admin.email}</td>
                            <td>{admin.mobile}</td>
                            {/* <td>{admin.location.description}</td> */}
                            <td><Link to={`/online/admin-detail?id=${admin.id}`}>View Details</Link></td>
                            </tr>
                        ))
                        }
                    </tbody>
                    </table>
                    : 
                    <div className="pt-5 text-center container">
                      <NothingFound data="No Online Admins found"/>
                    </div>
                }
            </div>
          </div>
        </div>

        {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType} />
            :
            null
        }
        <AddOnlineAdmin modalOn={this.state.modalOn} modalClose={() => this.setState({ modalOn: false })} success={() => { this.triggerToast("Location Admin invited successfully", "success"); this.getActiveData(); }} />
        </>

    );
  }
}

export default OnlineAdminList;
