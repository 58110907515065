/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
// import Inputs from '../../components/formComponents/inputs.js';
import SelectBox from '../../components/formComponents/selectBox';
import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/textarea';



/*Assets*/
import sampleBanner from '../../assets/images/sample-banner.jpg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import ImageUploader from '../../services/upload/imageUploader';


class AddPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      apiLoading: false,
      selected: null
    }
  }

  async componentDidMount() {
    this.getData();
  }

  getData() {
    var url = devconfig.admin + '/local/page/list';
    if (this.props.online) url = devconfig.admin + "/online/page/list"
    APIGet(url).then(async function (response) {
      if (response.status == "ok") {
        var tempArry = [];

        var tempArry2 = [];
        if (!this.props.online) {
          response.data.category_data.map(function (item, index) {
            var tempItem = { value: item.id, label: "Category: " + item.name }
            let tempItem2 = { value: item.wholesale_data.id, label: item.wholesale_data.name }
            let superValueStore = {value: item.super_value_store_data.id, label: item.super_value_store_data.name}
            tempArry.push(tempItem);
            tempArry.push(tempItem2);
            tempArry.push(superValueStore);

            item.categorydata.map(function (item, index) {
              var tempItem = { value: item.id, label: "Subcategory: " + item.name }
              tempArry.push(tempItem);
            });
          });
          response.data.custom_page_layouts.map(item => {
            var tempItem = { value: item.id, label: "Custom: " + item.name }
            tempArry.push(tempItem);
          })
        }
        else {
          response.data.category_data.map(function (item, index) {
            var tempItem = { value: item.id, label: "Category: " + item.name }
            tempArry.push(tempItem);

            item.subcategory_data.map(function (item, index) {
              var tempItem = { value: item.id, label: "Subcategory: " + item.name }
              tempArry.push(tempItem);
            });

          });
        }


        var tempArry1 = [];
        response.data.home_data.map(function (item, index) {
          var tempItem = { value: item.id, label: "Other: " + item.name }
          tempArry.push(tempItem);
        });

        await this.setState({ option: tempArry });
      }
    }.bind(this))
  }

  async addPopup(){
    this.setState({apiLoading: true});
    let url = devconfig.adminV1 + "/popupbanner/update";
    let data = JSON.stringify({
      "image" : this.state.adImg,
      "redirection_url" : this.state.url,
      "local" : this.props.online? false : true,
      "layout_id" : this.state.selected? this.state.selected.value : null
    });
    let response = await APIPost(url, data);
    if(response&& response.status==="ok"){
      this.setState({apiLoading: false, selected: null, url: "", adImg: null});
      this.props.showToast('New Popup added!', 'success');
      this.props.success()
      $('#addPopupModal').modal('hide');
    }
    else{
      this.setState({apiLoading: false});
      this.props.showToast(response.message, "fail");
    }
  }

  closeModal(e){
    if(e.target.classList.contains('e-close-bs-btn')|| e.target.classList.contains('e-ofer-modal')) this.setState({apiLoading: false, selected: null, url: "", adImg: null});
  }

  imageUploadBtnClick(tempItem) {
    $(tempItem).trigger('click');
  }

  render() {
    return (
      <div className="modal fade e-ad-detail-wrap e-ofer-modal e-menu-add-wrap" id="addPopupModal" tabIndex="-1" role="dialog" aria-labelledby="addMenuModalLabel" aria-hidden="true" onClick={e=>this.closeModal(e)}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content e-career-modal">
            <div className="modal-header">
              <button type="button" className="close e-close-bs-btn" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h6 className="e-modal-header e-add-career-heading">Add New Popup</h6>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-8 e-popup-image-wrapper mb-3">
                {
                  this.state.adImgLoading ?
                    <div className="e-upload-loader"></div>
                    :
                    <div>
                      {
                        this.state.adImg ?
                          <div className="e-img-upload-content e-img-edit" >
                            <span className="e-close" onClick={(tempVal) => this.setState({ adImg: null })}></span>
                            {
                              this.state.adImg.indexOf(".mp4") > -1 ?
                                <span className="e-video-upload-success">&#x2713; Video Uploaded Success</span>
                                :
                                <img src={this.state.adImg} alt="banner" />
                            }

                          </div>
                          :
                          <span className="e-upload-btn w-100 d-flex align-items-center justify-content-center mb-3" onClick={this.imageUploadBtnClick.bind(this, "#e-col-4-img-1")}>
                            <span className="pt-4">Upload image</span>
                          </span>
                      }
                    </div>
                }
              </div>
              <ImageUploader id={"e-col-4-img-1"} accept={".jpg,.png,.gif"} uploadSuccess={(tempUrl, tempData) => this.setState({ adImg: tempUrl, adImgLoading: false })} uploadStart={(tempVal) => this.setState({ adImgLoading: true })} />
              <div className="col-lg-12">
                <Input label="Add Redirection URL*" id="banner-url" type="text" value={this.state.url} valueChange={(tempVal) => this.setState({ url: tempVal })} />
              </div>
              <div className="col-lg-12 mb-4" style={{ zIndex: 2 }}>
                <SelectBox options={this.state.option} value={this.state.selected} isSearchable={true} selectchange={(tempVal) => {
                  this.setState({ selected: tempVal })
                }} placeholder="Select Page" />
              </div>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-end">
              {
                this.state.selected && this.state.adImg && this.state.url &&  !this.state.apiLoading ?
                  <button className="e-s-btn position-static e-new-ofer float-right mt-3 ml-5" type="submit" onClick={()=>this.addPopup()}>Update</button>
                  :
                  <button className="e-s-btn position-static e-inactive e-new-ofer float-right mt-3 ml-5" type="submit" value="Submit">{this.state.apiLoading ? "Loading.." : "Update"}</button>
              }

            </div>

            <div className="modal-footer">


            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default AddPopup;
