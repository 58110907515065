/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class RadioButton extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  render() {
    return (
        <div className={`e_radiobutton ${this.props.wrapperClass}`} onClick={(e)=>this.props.onClick(e)} >
        <input id={this.props.id} onClick={(e)=>this.props.onClick(e)} type="radio" name={this.props.name} checked={this.props.checked}/>
        <label for={this.props.id} className={this.props.className} >{this.props.label}</label>
      </div>
    );
  }
}

export default RadioButton;