
/*Package importing*/
import React, { Component } from 'react';
import VendorSelectedLocalCategories from './vendor-selected-local-cat';
import VendorSelectedOnlineCategories from './vendor-selected-online-cat';

class VendorSelectedCategories extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: true,
      data: null
    }
  }

  componentDidMount() {
   
  }

  render() {
    return(
      <>
      {
        this.props.local?
        <VendorSelectedLocalCategories id={this.props.id}/>
        :
        <VendorSelectedOnlineCategories id={this.props.id}/>
      }
      </>
    )
  }
}

export default VendorSelectedCategories;
