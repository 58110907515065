import React, { Component }  from 'react';

class TextButton extends Component{
  constructor(props){
    super(props);
    this.state = {

    }
}
  render() {
  	if(this.props.url){
  		return(
  			<a to={this.props.url} target={this.props.target}>{this.props.name}</a>
  		);
  	}
    else{
    	return(
	      <button type="button"
	      	className="e-t-btn"
	      	disabled={this.props.disabled}
	      	data-toggle={this.props.toggle}
	      	data-target={this.props.target}
          onClick={this.props.handleClick}
	      >{this.props.name}</button>
	    );
    }
  }
}

export default TextButton;

