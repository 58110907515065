
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import NothingFound from '../../../components/other/nothing';
import APILoader from '../../../components/other/api-loader';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import { timers } from 'jquery';

/*Assets*/


class BlockedAdmin extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.getBlockedData();
  }


  getBlockedData() {
    this.setState({ blockedLoading: true, error: "" });
    let url = devconfig.localAdmin + "/locationadmin/blocked-list";
    let data = JSON.stringify({
      "location_id": null
    });
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ blockedData: response.data, blockedLoading: false });
      }
      else {
        this.setState({ blockedLoading: false, error: response.message });
      }
    }.bind(this));
  }

  render() {

    return (
      <>
      <div className="w-100">
        {
          this.state.blockedData && this.state.blockedData.length && !this.state.blockedLoading?
          <table className="table e-location-admin-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email ID</th>
              <th>Phone Number</th>
              <th>Location</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.blockedData.map(admin=>(
                <tr>
                  <td className="text-capitalize">{admin.name.toLowerCase()}</td>
                  <td>{admin.email}</td>
                  <td>{admin.mobile}</td>
                  <td>{admin.location.description}</td>
                  <td><Link to={`/location-admin/detail?id=${admin.id}`}>View Details</Link></td>
                </tr>
              ))
            }
          </tbody>
        </table>
        : this.state.blockedLoading?
          <div className="container pt-5 mt-5 text-center">
              <APILoader/>
          </div>
        : 
        <div className="pt-5 text-center container">
          <NothingFound data="No Blocked Location Admins found"/>
        </div>
        }

      </div>
      </>
    );
    }

  }

export default BlockedAdmin;
