import React from "react"; 

export default class extends React.Component{
    constructor(props){
        super(props);
        this.state={
            type: true
        }
    }
    render(){
        return(
            <div className="d-flex ">
            <div className=" mx-3">
               <h6>{this.props.label}</h6> 
            </div>
         
          <div className="toggle-switch">
            <input type="checkbox" className="checkbox" checked={this.state.type}
                   name={this.props.label} id={this.props.label} onChange={ async ()=> { await this.setState({type: !this.state.type}); await this.props.handleChange(this.state.type) } }/>
            <label className="label" htmlFor={this.props.label}>
              <span className="inner" />
              <span className="switch" />
            </label>
          </div>
        </div>
        )
    }
}
