
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

/*Components*/
import NothingFound from '../../../components/other/nothing';
import APILoader from '../../../components/other/api-loader';
import ConfirmModal from '../../../components/modals/confirmModal'
import EditLocationAdmin from '../../../components/modals/editLocationAdmin';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';

/*Assets*/


class ActiveAdmin extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
  }

  // blockAdmin(){
  //   this.setState({ apiLoading: true, error: "" });
  //   let url = devconfig.localAdmin + "/locationadmin/block";
  //   let data = JSON.stringify({
  //     "admin_id" : this.state.blockAdmin? this.state.blockAdmin.id : null,
  //   });
  //   APIPost(url, data).then(function (response) {
  //     if (response.status === "ok") {
  //       this.setState({ activeLoading: false });
  //       this.props.toast("Location Admin Blocked!", "success");
  //     }
  //     else {
  //       this.setState({ activeLoading: false, error: response.message });
  //       this.props.toast(response.message, "fail");
  //     }
  //   }.bind(this));
  // }



  render() {

      return (
        <>
        <div className="w-100">
          {
            this.props.data.length?
            <table className="table e-location-admin-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email ID</th>
                <th>Phone Number</th>
                <th>Location</th>
                <th>Actions</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.data.map(admin=>(
                  <tr>
                    <td className="text-capitalize">{admin.name.toLowerCase()}</td>
                    <td>{admin.email}</td>
                    <td>{admin.mobile}</td>
                    <td>{admin.location.description}</td>
                    {/* <td><a onClick={()=>{this.setState({blockAdmin: admin}); $("#blockAdmin").modal("show")}}>Block Admin</a></td> */}
                    <td><a onClick={()=>{this.setState({editAdmin: {id:admin.id, name: admin.name, email: admin.email, mobile:admin.mobile, location: admin.location.description, place_id: admin.location.place_id}}); $("#editAdminModal").modal("show")}}>Edit</a></td>
                    <td><Link to={`/location-admin/detail?id=${admin.id}`}>View Details</Link></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          :
          <div className="pt-5 text-center container">
            <NothingFound data="No Active Location Admins found"/>
          </div>
          }

        </div>

        <EditLocationAdmin data={this.state.editAdmin} modalOn={this.state.editAdmin? true: false} updateData={(type, data)=>{let temp = this.state.editAdmin; temp[type]= data; this.setState({editAdmin: temp});}} modalClose={() => this.setState({ modalOn: false })} success={()=>this.props.success()}/>
        {/* <ConfirmModal id="blockAdmin" data={this.state.blockAdmin? `Block Location Admin ${this.state.blockAdmin.name}?`: null} success={()=>this.blockAdmin()}/> */}
        </>
      );
    }
  }

export default ActiveAdmin;
