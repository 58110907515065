
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

/*Components*/
import Toast from '../../../components/common/toast';
import UploadDummyAdsModal from '../../../components/modals/uploadDummyAdsModal';
import Input from '../../../components/formComponents/input';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
/*Assets*/


class Megamenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productBanner1: { content: "", link: "" },
            productBanner2: { content: "", link: "" },
            ads: {
                hero: { id: 16, page_id: 6, data: [{ content: null, link: null }] },
            },
            selectedBannerIndex: 0
        }
    }

    componentDidMount() {
        this.getProductPageAd();
        this.getData();
    }

    getData() {
        var url = devconfig.adminV1 + "/online/page/detail";
        var data = JSON.stringify({
            "layout_id": 156
        })
        APIPost(url, data).then(function (response) {
            if (response.status === "ok") {
                if (response.data && response.data.page_data
                    && response.data.page_data.ads && response.data.page_data.ads.hero
                    && response.data.page_data.ads.hero.data && response.data.page_data.ads.hero.data.length) {
                    this.setState({ productBanner1: response.data.page_data.ads.hero.data[0], ads: response.data.page_data.ads });
                }
            }
        }.bind(this))
    }

    updatePageLayout() {
        this.setState({ apiLoading: true });
        let ads = this.state.ads;
        ads.hero.data = [this.state.productBanner1];
        var tempArry = {
            layout: [],
            ads: ads
        }
        var url = devconfig.adminV1 + "/online/page/update";
        var data = JSON.stringify({
            "layout_id": 156,
            "page_data": tempArry
        })
        APIPost(url, data).then(function (response) {
            if (response.status === "ok") {
                this.triggerToast("Product banner updated!", "success");
                this.setState({ apiLoading: false });
            }
            else {
                this.triggerToast(response.message, "fail");
                this.setState({ apiLoading: false });
            }
        }.bind(this))
    }

    triggerToast(temp, type) {
        this.setState({ toast: temp, toastType: type })
        setTimeout(function () { this.setState({ toast: null }); }.bind(this), 4000);
    }

    adsDummySuccess(tempUrl, tempLink) {
        let key = this.state.selectedBannerIndex;
        let bannerData = { content: tempUrl, link: tempLink };
        switch (key) {
            case 1: {
                this.setState({ productBanner1: bannerData });
                break;
            }
            case 2: {
                this.setState({ productBanner2: bannerData });
                break;
            }
        }
    }

    async triggerUpload(key) {
        await this.setState({ uploadModal: true, selectedBannerIndex: key });
        $("#uploadDummyAdsModal").modal("show");
    }

    linkChange(key, val) {
        switch (key) {
            case 1: {
                let data = this.state.productBanner1;
                data.link = val;
                this.setState({ productBanner1: data });
                break;
            }
            case 2: {
                let data = this.state.productBanner2;
                data.link = val;
                this.setState({ productBanner2: data });
                break;
            }
        }
    }

    updateAd(key) {
        if (key === 1) this.updatePageLayout();
        else {
            this.setState({ apiLoading: true });
            let url = devconfig.adminV1 + "/productpagebanner/update";
            let data = JSON.stringify({
                "image": this.state.productBanner2 ? this.state.productBanner2.content : null,
                "redirection_url": this.state.productBanner2 ? this.state.productBanner2.link : null,
                "local": false,
                "banner_type": 2
            });
            APIPost(url, data).then(function (response) {
                if (response && response.status === "ok") {
                    this.triggerToast("Product banner updated!", "success");
                    this.setState({ apiLoading: false });
                }
                else {
                    this.triggerToast(response.message, "fail");
                    this.setState({ apiLoading: false });
                }
            }.bind(this));
        }
    }

    getProductPageAd() {
        let url = devconfig.adminV1 + "/productpagebanner/view";
        APIGet(url).then(function (response) {
            if (response.status === "ok" && response.data && response.data.online_data && response.data.online_data.length) {
                if (response.data.online_data[0] && response.data.online_data[0].image) {
                    let bannerData = { content: response.data.online_data[0].image, link: response.data.online_data[0].redirection_url };
                    // if(response.data.online_data[0].banner_type===1)this.setState({ productBanner1: bannerData });
                    if (response.data.online_data[0].banner_type === 2) this.setState({ productBanner2: bannerData });
                }
                if (response.data.online_data[1] && response.data.online_data[1].image) {
                    let bannerData = { content: response.data.online_data[1].image, link: response.data.online_data[1].redirection_url };
                    // if(response.data.online_data[1].banner_type===1)this.setState({ productBanner1: bannerData });
                    if (response.data.online_data[1].banner_type === 2) this.setState({ productBanner2: bannerData });
                }
            }
        }.bind(this));
    }

    removeContent(key) {
        switch (key) {
            case 1: {
                let data = this.state.productBanner1;
                data.content = "";
                this.setState({ productBanner1: data });
                break;
            }
            case 2: {
                let data = this.state.productBanner2;
                data.content = "";
                this.setState({ productBanner2: data });
                break;
            }
        }
    }

    render() {

        return (
            <>
                <div className="e-main-content e-vendor-table">
                    <h2 className="e-page-head">Other Ads</h2>
                    <div className="row mb-3">
                        <div className="col-lg-12 py-3">
                            <div className="e-layout-wrap p-4">
                                <div className="container-fluid px-0 e-megamenu-ad-container">
                                    <h4 className="mb-0 pb-4">Product Listing Hero Banner</h4>
                                    {
                                        this.state.productBanner1 && this.state.productBanner1.content ?
                                            <div className="position-relative e-megamenu-ad-wrapper">
                                                <span className="e-close-icon position-absolute" onClick={() => this.removeContent(1)}></span>
                                                <a href={this.state.productBanner1.link} target="_blank">
                                                    <img className="e-megamenu-ad" src={this.state.productBanner1.content} alt="Truekhoj highlight" />
                                                </a>
                                            </div>
                                            :
                                            <div className="e-upload-wrapper d-flex align-items-center justify-content-center" onClick={() => this.triggerUpload(1)}>
                                                <div className="e-ad-full-placeholder">
                                                    Upload Ad
                                                </div>
                                            </div>
                                    }
                                </div>
                                <div className="container-fluid px-0">
                                    <div className="row">
                                        <div className="col-lg-12 pt-4">
                                            <h6 className="e-career-header">Link*</h6>
                                            <Input id="e-megamenu-link" allowEdit={true} value={this.state.productBanner1 && this.state.productBanner1.link ? this.state.productBanner1.link : ""} type="text" className="e-add-career" valueChange={this.linkChange.bind(this, 1)} />
                                        </div>
                                        <div className="col-lg-12 pt-3">
                                            <div className="container-fluid px-0 d-flex justify-content-end">
                                                {
                                                    this.state.productBanner1 && this.state.productBanner1.content && this.state.productBanner1.link && !this.state.apiLoading ?
                                                        <button className="e-p-btn" onClick={this.updateAd.bind(this, 1)}>Update</button>
                                                        :
                                                        <button className="e-p-btn e-inactive">Update</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 py-3">
                            <div className="e-layout-wrap p-4">
                                <div className="container-fluid px-0 e-megamenu-ad-container">
                                    <h4 className="mb-0 pb-4">Product Listing Large Banner</h4>
                                    {
                                        this.state.productBanner2 && this.state.productBanner2.content ?
                                            <div className="position-relative e-megamenu-ad-wrapper">
                                                <span className="e-close-icon position-absolute" onClick={() => this.removeContent(2)}></span>
                                                <a href={this.state.productBanner2.link} target="_blank">
                                                    <img className="e-megamenu-ad" src={this.state.productBanner2.content} alt="Truekhoj highlight" />
                                                </a>
                                            </div>
                                            :
                                            <div className="e-upload-wrapper d-flex align-items-center justify-content-center" onClick={() => this.triggerUpload(2)}>
                                                <div className="e-ad-full-placeholder">
                                                    Upload Ad
                                                </div>
                                            </div>
                                    }
                                </div>
                                <div className="container-fluid px-0">
                                    <div className="row">
                                        <div className="col-lg-12 pt-4">
                                            <h6 className="e-career-header">Link*</h6>
                                            <Input id="e-megamenu-link" allowEdit={true} value={this.state.productBanner2 && this.state.productBanner2.link ? this.state.productBanner2.link : ""} type="text" className="e-add-career" valueChange={this.linkChange.bind(this, 2)} />
                                        </div>
                                        <div className="col-lg-12 pt-3">
                                            <div className="container-fluid px-0 d-flex justify-content-end">
                                                {
                                                    this.state.productBanner2 && this.state.productBanner2.content && this.state.productBanner2.link && !this.state.apiLoading ?
                                                        <button className="e-p-btn" onClick={this.updateAd.bind(this, 2)}>Update</button>
                                                        :
                                                        <button className="e-p-btn e-inactive">Update</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.uploadModal ?
                        <UploadDummyAdsModal success={this.adsDummySuccess.bind(this)} />
                        :
                        null
                }
                {
                    this.state.toast ?
                        <Toast data={this.state.toast} type={this.state.toastType} />
                        :
                        null
                }
            </>

        );
    }
}

export default Megamenu;
