
/*Package importing*/
import React, { Component } from 'react';
import VendorLocalAds from './vendor-local-ads';
import VendorOnlineAds from './vendor-online-ads';

class VendorAds extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: true,
      data: null,
      pageNumActive: true,
      pageNumLoader: false,
      pageNum: 1
    }
  }

  componentDidMount() {
   
  }

  render() {
    return(
      <>
      {
        this.props.local?
        <VendorLocalAds id={this.props.id}/>
        :
        <VendorOnlineAds id={this.props.id}/>
      }
      </>
    )
  }
}

export default VendorAds;
