/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class Input extends Component {
  constructor(props){
    super(props);
    this.state = {
    	value: ""
    }
  }

  componentDidMount(){
    if(this.props.defaultValue){
      console.log("inside");
      console.log(this.props.defaultValue);
      this.setState({value: this.props.defaultValue});
    }
  }

  valueChange(e) {
    this.setState({value: e.target.value});
    this.props.valueChange(e.target.value);
  }

  render() {
    return (
      <div className="e-input-wrap">
        <input
          id={this.props.id}
          value={this.props.allowEdit? this.props.value: this.state.value}
          type={this.props.type}
          onChange={this.valueChange.bind(this)}
          className={this.props.className}
          placeholder={this.props.placeholder}
          autoComplete={this.props.autoComplete}
          disabled={this.props.disabled}
          required
          // {...this.props}
          />
        <label htmlFor={this.props.id} className="label">{this.props.label}</label>
      </div>
    );
  }
}

export default Input;
