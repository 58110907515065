/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import SectionHeadingStyle from '../elementsPreview/sectionHeadingStyle';
import SelectBox from '../formComponents/selectBox';
import ColorPicker from '../common/colorPicker';
import ImageUploader from '../../services/upload/imageUploader';
import Input from '../../components/formComponents/input';

/*Assets*/
import logo from '../../assets/images/logo.svg';
import ImgPlaceholder from '../../assets/images/img-placeholder.svg';

class UploadDummyAdsModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      adImg: null,
      url: ""
    }
  }

  componentDidMount() {
      console.log("id --->", this.props.id)
  }

  imageUploadBtnClick(tempItem){
    $(tempItem).trigger('click');
  }

  submitClick(){
    this.props.success(this.state.adImg, this.state.url)
    this.setState({adImg: null, url: ""});
  }

  render() {

    return (
      <div className="modal fade e-dummy-ads-modal" id={this.props.id ? this.props.id : "uploadDummyAdsModal"} tabIndex="-1" role="dialog" aria-labelledby="uploadDummyAdsModalLabel" aria-hidden="true">
        <div className="modal-dialog"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="mb-3">Upload Dummy Ad's</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" >
              <div className="row">
                <div className="col-lg-5">
                  {
                    this.state.adImgLoading ?
                      <div className="e-upload-loader"></div>
                    :
                      <div>
                        {
                          this.state.adImg ?
                            <div className="e-img-upload-content e-img-edit" >
                              <span className="e-close" onClick={(tempVal) => this.setState({adImg: null})}></span>
                              {
                                this.state.adImg.indexOf(".mp4") > -1 ?
                                  <span className="e-video-upload-success">&#x2713; Video Uploaded Success</span>
                                :
                                  <img src={this.state.adImg} alt="banner"/>
                              }
                              
                            </div>
                          :
                            <span className="e-upload-btn mb-3" onClick={this.imageUploadBtnClick.bind(this, "#e-col-4-img-1")}>Upload image/video</span>
                        }
                      </div>
                  }
                </div>
                <ImageUploader id={"e-col-4-img-1"} accept={".jpg,.png,.mp4,.gif"} uploadSuccess={(tempUrl, tempData) => this.setState({adImg: tempUrl, adImgLoading: false})} uploadStart={(tempVal) => this.setState({adImgLoading: true})}/>
              </div>
              <div className="row">
                <div className="col-lg-12">
                <Input label="Add Redirection URL" id="banner-url" type="text" defaultValue={this.state.url} value={this.state.url} valueChange={(tempVal) => this.setState({url: tempVal})} />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              {
                this.state.adImg ?
                  <button type="submit" className="e-s-btn" value="Submit" data-dismiss="modal" onClick={this.submitClick.bind(this)}>Submit</button>
                :
                  <button type="submit" className="e-s-btn e-inactive" value="Submit" >Submit</button>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UploadDummyAdsModal;
