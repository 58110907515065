/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Pdf from "react-to-pdf";

/*Components*/
import SectionHeadingStyle from '../elementsPreview/sectionHeadingStyle';
import SelectBox from '../formComponents/selectBox';
import ColorPicker from '../common/colorPicker';
import ImageUploader from '../../services/upload/imageUploader';
import Input from '../../components/formComponents/input';

/*Assets*/
import logo from '../../assets/images/logo.svg';
import ImgPlaceholder from '../../assets/images/img-placeholder.svg';

class EditHeadingModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      heading: ""
    }
  }

  componentDidMount() {
    
  }

  imageUploadBtnClick(tempItem){
    $(tempItem).trigger('click');
  }

  submitClick(){
    this.props.success(this.state.heading);
    this.setState({heading: ""});
  }

  render() {

    return (
      <div className="modal fade e-dummy-ads-modal" id="editHeadingModal" tabIndex="-1" role="dialog" aria-labelledby="editHeadingModalLabel" aria-hidden="true">
        <div className="modal-dialog"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="mb-3">Edit heading text</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <Input label="Heading text" id="banner-url" type="text" defaultValue={this.state.heading} valueChange={(tempVal) => this.setState({heading: tempVal})} />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              {
                this.state.heading.length ?
                  <button type="submit" className="e-s-btn" value="Submit" data-dismiss="modal" onClick={this.submitClick.bind(this)}>Submit</button>
                :
                  <button type="submit" className="e-s-btn e-inactive" value="Submit" >Submit</button>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditHeadingModal;
