/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
// import Inputs from '../../components/formComponents/inputs.js';
import SelectBox from '../../components/formComponents/selectBox';
import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/textarea';
import ImageUploader from '../../services/upload/imageUploader';



/*Assets*/
import sampleBanner from '../../assets/images/sample-banner.jpg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';



class AddBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      apiLoading: false
    }
  }

  async addNewBrand(e) {
    e.preventDefault();
    this.setState({ apiLoading: true });
    var url = devconfig.admin + '/brands/create';
    if (this.props.edit) url = devconfig.admin + '/brands/update';
    var data = JSON.stringify({
      "name": this.state.category,
      "image": this.state.adImg
    });
    if (this.props.edit) {
      data = JSON.stringify({
        "name": this.props.brandName,
        "brand_id": this.props.brandId,
        "image": this.props.adImg
      });
    }
    await APIPost(url, data).then(function (response) {
      if (response.status == "ok") {
        window.location.reload();
      }
      else {
        this.setState({ apiLoading: false });
        $("#addBrandModal").modal("hide");
        this.props.showToast(response.message, "fail");
      }
    }.bind(this))
  }
  valueChangeCat(value) {
    this.setState({ category: value });
  }

  imageUploadBtnClick(tempItem) {
    $(tempItem).trigger('click');
  }


  render() {
    const categoryBy = [
      { value: 1, label: 'Last 1 week' },
      { value: 2, label: 'Last 1 month' },
      { value: 3, label: 'Last 3 months' },
    ]
    return (
      <div className="modal fade e-ad-detail-wrap e-ofer-modal" id="addBrandModal" tabIndex="-1" role="dialog" aria-labelledby="addBrandModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content e-career-modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h6 className="e-modal-header e-add-career-heading">{this.props.edit ? "Update Brand Name" : "Add New Brand"}</h6>
              </div>
            </div>
            <div className="row mt-3"><div className="col-lg-12">
                <h6 className="e-career-header">Brand image:</h6>
                <div className="col-lg-5 px-0 pt-2">
                  {
                    this.state.adImgLoading ?
                      <div className="e-upload-loader"></div>
                      :
                      this.props.edit ?
                        <div>
                          {
                            this.props.adImg ?
                              <div className="e-img-upload-content e-img-edit" >
                                <span className="e-close" onClick={(tempVal) => this.props.updateImage(null)}></span>
                                {
                                  this.props.adImg.indexOf(".mp4") > -1 ?
                                    <span className="e-video-upload-success">&#x2713; Video Uploaded Success</span>
                                    :
                                    <img src={this.props.adImg} alt="banner" />
                                }

                              </div>
                              :
                              <span className="e-upload-btn mb-3" onClick={this.imageUploadBtnClick.bind(this, "#e-brand-img-uploader")}>Upload image</span>
                          }
                        </div>
                        :
                        <div>
                          {
                            this.state.adImg ?
                              <div className="e-img-upload-content e-img-edit" >
                                <span className="e-close" onClick={(tempVal) => this.setState({ adImg: null })}></span>
                                {
                                  this.state.adImg.indexOf(".mp4") > -1 ?
                                    <span className="e-video-upload-success">&#x2713; Video Uploaded Success</span>
                                    :
                                    <img src={this.state.adImg} alt="banner" />
                                }

                              </div>
                              :
                              <span className="e-upload-btn mb-3" onClick={this.imageUploadBtnClick.bind(this, "#e-brand-img-uploader")}>Upload image</span>
                          }
                        </div>
                  }
                </div>
              </div>
              <ImageUploader id={"e-brand-img-uploader"} accept={".jpg,.png,.gif"} uploadSuccess={this.props.edit ? (tempUrl, tempData) => { this.setState({ adImg: tempUrl, adImgLoading: false }); this.props.updateImage(tempUrl) } : (tempUrl, tempData) => this.setState({ adImg: tempUrl, adImgLoading: false })} uploadStart={(tempVal) => this.setState({ adImgLoading: true })} />
              
              <div className="col-lg-12">
                <h6 className="e-career-header">Brand:</h6>
                <Input id={this.props.id} allowEdit={true} value={this.props.edit ? this.props.brandName : this.state.category} type="text" className="e-add-career" valueChange={this.props.edit ? (e) => this.props.update(e) : this.valueChangeCat.bind(this)} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9">
                <a href="#" className="float-right pt-4 e-ofer-cancel" data-dismiss="modal">Cancel</a>
              </div>
              {
                (this.state.category || (this.props.edit && this.props.brandName)) && (this.state.adImg || (this.props.edit && this.props.adImg)) && !this.state.apiLoading ?
                  <button className="e-s-btn e-new-ofer float-right mt-3 ml-5" type="submit" value="Submit" onClick={this.addNewBrand.bind(this)}>{this.props.edit ? "Update" : "Create"}</button>
                  :
                  <button className="e-s-btn e-inactive e-new-ofer float-right mt-3 ml-5" type="submit" value="Submit">{this.state.apiLoading ? "Loading.." : this.props.edit ? "Update" : "Create"}</button>
              }

            </div>

            <div className="modal-footer">


              {/*

              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              {
                this.state.category && this.state.styleType && this.state.colorOne.length && this.state.colorTwo.length  ?
                  <button type="submit" className="e-s-btn" value="Submit" data-dismiss="modal" onClick={this.submit.bind(this)}>Submit</button>
                :
                  <button type="submit" className="e-s-btn e-inactive" value="Submit" >Submit</button>
              }

            */}

            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default AddBrand;
