
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import APILoader from '../../../components/other/api-loader';
import NothingFound from '../../../components/other/nothing';
import DetailInvoice from '../../../components/modals/invoiceDetailModal';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';

/*Assets*/
import vendorLogo from '../../../assets/images/vendor-logo.jpg';

class VendorLocalAds extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: true,
      data: null,
      pageNumActive: true,
      pageNumLoader: false,
      pageNum: 1
    }
  }

  componentDidMount() {
    this.getData(this.state.pageNum);
  }

  getData(tempId){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1+"/locationadmin/vendor/ads";
    var data = JSON.stringify({
      "id" : parseInt(this.props.id),
      "page_num" : 1
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        console.log("Inside ads");
        console.log(response.data)

        if(response.data.length <= 39){
          this.setState({ pageNumActive: false});
        }
        else{
          this.setState({ pageNumActive: true});
        }

        if(this.state.data){
          var tempData = this.state.data;
          tempData.push(response.data);
          this.setState({ data: data, apiLoading: false, pageNumLoader: false});
        }
        else{
          this.setState({ data: response.data, apiLoading: false, pageNumLoader: false});
        }
        
      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }
  
  loadMore(){
    this.getData(this.state.pageNum + 1);
    this.setState({pageNum: this.state.pageNum + 1, pageNumLoader: true});
  }

  render() {
    var data = this.state.data;

    if(this.state.apiLoading){
      return(
        <APILoader/>
      );
    }
    else{
      return(
        <div className="row">
          {
            this.state.data && this.state.data.length ?
              <div className="col-lg-8 mt-4">
                <table className="table">
                  <thead>
                    <tr>  
                      <th>Ad type</th>
                      <th>Schedule</th>
                      <th>Status</th>
                      <th>Views</th>
                      <th>Clicks</th>
                      {/*<th>Total Amount</th>
                      <th>Pending Payment</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.map((item, index) => {
                        return(
                          <tr key={index}>
                            <td>{item.ads_location}</td>
                            <td>{item.booking_date}</td>
                            <td className="text-success">{item.status}</td>
                            <td>{item.views}</td>
                            <td>{item.clicks}</td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                {
                  this.state.pageNumActive ?
                    <div className="text-center">
                      {
                        this.state.pageNumLoader ?
                          <APILoader/>
                        :
                          <button className="e-s-btn-2" onClick={this.loadMore.bind(this)}>Load More</button>
                      }
                    </div>
                  :
                    null
                }
              </div>
            :
              <NothingFound data="No invoices found"/>
          }
          
        </div>
      );
    }
  }
}

export default VendorLocalAds;
