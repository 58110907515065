
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import APILoader from '../../../components/other/api-loader';
import NothingFound from '../../../components/other/nothing';
import VendorSelectedCategories from './vendor-selected-category';
import VendorInvoices from './vendor-invoices';
import VendorAds from './vendor-ads';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';

/*Assets*/
import vendorLogo from '../../../assets/images/vendor-logo.jpg';

class VendorDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      tabIndex: 1,
      apiLoading: true,
      id: null,
      data: {}
    }
  }

  componentDidMount() {
    GetURLVar().then(function(query){
      if(query){
        this.getData(query.id);
        this.setState({id: query.id});
        if(query.local&&query.local=="true") this.setState({localVendor: true});
      }
    }.bind(this))
  }

  getData(tempId){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1+"/vendor/detail";
    var data = JSON.stringify({
      "id" : tempId
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        if(response.data){
          this.setState({ data: response.data, apiLoading: false});
        }
        else{
          this.setState({ apiLoading: false});
        }
        
      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }

  vendorLogin(){
    this.setState({ loginLoading: true});
    var url = devconfig.localAdmin+"/vendor/login";
    var data = JSON.stringify({
      "vendor_id" : this.state.id
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({loginLoading: false});
        console.log(response.data);
        if(this.state.localVendor) window.open(`${devconfig.vendorAppUrl}/admin-login?vendor=${this.state.id}&token=${response.data.access_token}&type=local`, '_blank');
        else window.open(`${devconfig.vendorAppUrl}/admin-login?vendor=${this.state.id}&token=${response.data.access_token}`, '_blank');
      }
      else{
        this.setState({ loginLoading: false });
      }
    }.bind(this))
  }


  tabItemClick(tempItem){
    this.setState({tabIndex: tempItem});
  }
  

  render() {
    var data = this.state.data;

    if(this.state.apiLoading){
      return(
        <APILoader/>
      );
    }
    else{
      return (
        <div>
          {
            this.state.data && this.state.data.id ?
              <div className="e-main-content e-vendor-table">
                <h2 className="e-page-head">Vendor Detail</h2>
                
                <div className="row">
                  <div className="col-lg-12">

                    <div className="e-vendor-details-wrap">
                      <div className="media mb-4">
                        {
                          data.logo ?
                            <img src={data.logo} className="mr-3 e-vendor-logo" alt="Vendor Logo"/>
                          :
                            <span className="e-logo-placeholder mr-3">Logo</span>
                        }
                        
                        <div className="media-body">
                          <h5 className="mt-0">{data.company}</h5>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-4">
                          <h6>Contact Person:</h6>
                          <p>{data.name}</p>
                        </div>
                        <div className="col-lg-3">
                          <h6>Phone:</h6>
                          <p>{data.mobile}</p>
                        </div>
                        <div className="col-lg-5">
                          <h6>Email:</h6>
                          <p>{data.email}</p>
                        </div>
                        <div className="col-lg-4">
                          <h6>Seller Type:</h6>
                          <p>{data.seller_type}</p>
                        </div>
                        {
                          data.website ?
                            <div className="col-lg-4">
                              <h6>Website:</h6>
                              <p>{data.website}</p>
                            </div>
                          :
                            null
                        }
                        
                      </div>
                      <div className="e-vendor-more-details pt-3">
                        <div className="row">
                          <div className="col-lg-4">
                            <h6>Location:</h6>
                            <p>{data.location}</p>
                          </div>
                          <div className="col-lg-6">
                            <h6>Address:</h6>
                            <p>{data.address}</p>
                          </div>
                          <div className="col-lg-4">
                            <h6>GST:</h6>
                            <p>{data.gst_no}</p>
                          </div>
                          <div className="col-lg-4">
                            <h6>Documents:</h6>
                            <a target="_blank" href={data.document_urls}>View certificate of incorporation</a>
                          </div>
                          <div className="col-lg-4">
                          <button className="e-s-btn float-right" onClick={() => this.vendorLogin()}>{`Login as ${data.company}`}</button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                {/* <div className="row mt-4">
                  <div className="col-lg-6 e-vendor-tab-link-wrap">

                    {
                      this.state.tabIndex == 1 ?
                        <span className="e-active">Selected Category</span>
                      :
                        <span onClick={this.tabItemClick.bind(this, 1)}>Selected Category</span>
                    }

                    {
                      this.state.tabIndex == 2 ?
                        <span className="e-active">Invoices</span>
                      :
                        <span onClick={this.tabItemClick.bind(this, 2)}>Invoices</span>
                    }
                    
                    {
                      this.state.tabIndex == 3 ?
                        <span className="e-active">Ad's Created</span>
                      :
                        <span onClick={this.tabItemClick.bind(this, 3)}>Ad's Created</span>
                    }

                  </div>
                </div>

                {
                  this.state.tabIndex == 1 ?
                    <VendorSelectedCategories id={this.state.id} local={this.state.data.seller_type_id>1? true: false}/>
                  :
                    null
                }
                

                {
                  this.state.tabIndex == 2 ?
                    <VendorInvoices id={this.state.id} local={this.state.data.seller_type_id>1? true: false}/>
                  :
                    null
                }

                {
                  this.state.tabIndex == 3 ?
                    <VendorAds id={this.state.id} local={this.state.data.seller_type_id>1? true: false}/>
                  :
                    null
                } */}

              </div>
            :
              <NothingFound data={"Nothing found, try later."}/>
          }
          
        </div>
        
      );
    }
  }
}

export default VendorDetail;
