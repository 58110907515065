
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

/*Components*/
import NothingFound from '../../../components/other/nothing';
import APILoader from '../../../components/other/api-loader';
import Toast from '../../../components/common/toast';
import UploadDummyAdsModal from '../../../components/modals/uploadDummyAdsModal';
import Input from '../../../components/formComponents/input';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import AddPopup from '../../../components/modals/addPopup';
import NoDataFound from '../../../components/other/nothing';
/*Assets*/


class LocalPopups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: null,
            data: null,
            apiLoading: -1
        }
    }

    componentDidMount() {
        this.getPopups();
        this.getPageList();
    }

    getPageList() {
        var url = devconfig.admin + "/online/page/list";
        APIGet(url).then(async function (response) {
            if (response.status == "ok") {
                console.log(response);
                var options = {};
                response.data.category_data.map(function (item, index) {
                    options[item.id] = `Category: ${item.name}`;
                    item.subcategory_data.map(function (item, index) {
                        options[item.id] = `Subcategory: ${item.name}`;
                    });
                });
                response.data.home_data.map(function (item, index) {
                    options[item.id] = `Other: ${item.name}`;
                });
                await this.setState({ options });
            }
        }.bind(this))
    }

    triggerToast(temp, type) {
        this.setState({ toast: temp, toastType: type })
        setTimeout(function () { this.setState({ toast: null }); }.bind(this), 4000);
    }

    adsDummySuccess(tempUrl, tempLink) {
        let data = this.state.data;
        data[this.state.selectedBannerIndex].image = tempUrl;
        data[this.state.selectedBannerIndex].redirection_url = tempLink;
        this.setState({ data });
    }

    async triggerUpload(key) {
        await this.setState({ uploadModal: true, selectedBannerIndex: key });
        $("#uploadDummyAdsModal").modal("show");
    }

    linkChange(key, val) {
        let data = this.state.data;
        data[key].redirection_url = val;
        this.setState({ data });
    }

    deleteAd(key){
        this.setState({ apiLoading: key });
        let item = this.state.data[key];
        let url = devconfig.adminV1 + "/popupbanner/delete";
        let data = JSON.stringify({
            "id": item.id
        });
        APIPost(url, data).then(function (response) {
            if (response && response.status === "ok") {
                this.triggerToast("Popup deleted!", "success");
                this.setState({ apiLoading: -1 });
                this.getPopups();
            }
            else {
                this.triggerToast(response.message, "fail");
                this.setState({ apiLoading: -1 });
            }
        }.bind(this));
    }

    updateAd(key) {
        this.setState({ apiLoading: key });
        let item = this.state.data[key];
        let url = devconfig.adminV1 + "/popupbanner/update";
        let data = JSON.stringify({
            "image": item.image,
            "redirection_url": item.redirection_url,
            "local": false,
            "layout_id": item.layout_id

        });
        APIPost(url, data).then(function (response) {
            if (response && response.status === "ok") {
                this.triggerToast("Popup updated!", "success");
                this.setState({ apiLoading: -1 });
            }
            else {
                this.triggerToast(response.message, "fail");
                this.setState({ apiLoading: -1 });
            }
        }.bind(this));
    }

    getPopups() {
        this.setState({ loading: true });
        let url = devconfig.adminV1 + "/popupbanner/view";
        APIGet(url).then(function (response) {
            if (response && response.status === "ok") {
                if (response.data && response.data.online_data && response.data.online_data.length) {
                    this.setState({ data: response.data.online_data });
                }
            }
            this.setState({ loading: false });
        }.bind(this));
    }

    removeContent(key) {
        let data = this.state.data;
        data[key].image = "";
        this.setState({ data });
    }

    render() {

        return (
            <>
                <div className="e-main-content e-vendor-table">
                    <h2 className="e-page-head">Online Page Popups</h2>
                    <div className="row mb-3">
                        <div className="col-lg-12">
                            <div className="w-100 d-flex justify-content-end">
                                <button data-target="#addPopupModal" data-toggle="modal" className="e-p-btn">Add Popup</button>
                            </div>
                        </div>
                        {
                            this.state.loading ?
                                <div className="col-12 py-5 my-4">
                                    <APILoader />
                                </div>
                                : this.state.data && this.state.data.length ?
                                    <>
                                        {
                                            this.state.data.map((item, index) => {
                                                if (this.state.options && this.state.options[item.layout_id]) {
                                                    let name = this.state.options[item.layout_id];
                                                    return (
                                                        <div className="col-lg-4 py-3">
                                                            <div className="e-layout-wrap p-4">
                                                                <div className="container-fluid px-0 e-megamenu-ad-container">
                                                                    <h5 className="mb-0 pb-4">{name}</h5>
                                                                    {
                                                                        item && item.image ?
                                                                            <div className="position-relative e-megamenu-ad-wrapper">
                                                                                <span className="e-close-icon position-absolute" onClick={() => this.removeContent(index)}></span>
                                                                                <a href={item.redirection_url} target="_blank">
                                                                                    <img className="e-megamenu-ad" src={item.image} alt="Truekhoj highlight" />
                                                                                </a>
                                                                            </div>
                                                                            :
                                                                            <div className="e-upload-wrapper d-flex align-items-center justify-content-center" onClick={() => this.triggerUpload(index)}>
                                                                                <div className="e-ad-full-placeholder">
                                                                                    Upload Ad
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className="container-fluid px-0">
                                                                    <div className="row">
                                                                        <div className="col-lg-12 pt-4">
                                                                            <h6 className="e-career-header">Link*</h6>
                                                                            <Input id="e-megamenu-link" allowEdit={true} value={item && item.redirection_url ? item.redirection_url : ""} type="text" className="e-add-career" valueChange={this.linkChange.bind(this, index)} />
                                                                        </div>
                                                                        <div className="col-lg-12 pt-3">
                                                                            <div className="container-fluid px-0 d-flex justify-content-end">
                                                                                {
                                                                                    this.state.apiLoading != index ?
                                                                                    <button className="e-s-btn-2 e-btn-reverse mr-3" onClick={this.deleteAd.bind(this, index)}>Delete</button>
                                                                                        :
                                                                                        <button className="e-s-btn-2 e-btn-reverse mr-3 e-inactive">Delete</button>
                                                                                }
                                                                                {
                                                                                    item && item.image && item.redirection_url && this.state.apiLoading != index ?
                                                                                        <button className="e-p-btn" onClick={this.updateAd.bind(this, index)}>Update</button>
                                                                                        :
                                                                                        <button className="e-p-btn e-inactive">Update</button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </>
                                    :
                                    <div className="col-lg-12 py-5">
                                        <NoDataFound data="NO popups found!" />
                                    </div>
                        }
                    </div>
                </div>

                {
                    this.state.uploadModal ?
                        <UploadDummyAdsModal success={this.adsDummySuccess.bind(this)} />
                        :
                        null
                }
                {
                    this.state.toast ?
                        <Toast data={this.state.toast} type={this.state.toastType} />
                        :
                        null
                }
                <AddPopup showToast={(message, type) => this.triggerToast(message, type)} online={true} success={this.getPopups.bind(this)}/>
            </>

        );
    }
}

export default LocalPopups;
