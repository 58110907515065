/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class CheckBox extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  render() {
    return (
      <label className="e-checkbox-container">
        <input type="checkbox" name="editList" {...this.props}/>{this.props.label}
        <span className="checkmark"></span>
      </label>
    );
  }
}

export default CheckBox;
