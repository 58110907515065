/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import loader from '../../assets/images/loader/api-loading.gif';

class APILoader extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  render() {
    return (
      <div className="col-lg-12 pt-0 text-center mt-5 e-api-loader-wrap">
        <span className="e-api-loader"></span>
      </div>
    );
  }
}

export default APILoader;
