
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import NothingFound from '../../../components/other/nothing';
import APILoader from '../../../components/other/api-loader';

/*Services*/
import devconfig from '../../../config/config.js';
import APIGet from '../../../services/apiCall/get';

/*Assets*/


class PendingAdmin extends Component {
  constructor(props){
    super(props);
    this.state = {
        apiLoading: false,
        data: [],
    }
  }

  componentDidMount() {
      this.getData()
  }

  getData(){
    this.setState({ apiLoading: true, error: ""});
    let url = devconfig.localAdmin +"/locationadmin/invited-list";
    APIGet(url).then(function(response){
      if(response.status==="ok"){
        this.setState({data: response.data, apiLoading: false});
      }
      else{
        this.setState({apiLoading: false, error: response.message});
      }
    }.bind(this));
  }



  render() {

      return (

        <div className="w-100">
          {
              this.state.apiLoading?
              <div className="container pt-5 mt-5 text-center">
                  <APILoader/>
              </div>
              :
              <>
                {
                    this.state.data.length?
                    <table className="table e-location-admin-table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email ID</th>
                        <th>Phone Number</th>
                        <th>Location</th>
                        {/* <th>Actions</th>
                        <th></th> */}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.data.map(admin=>(
                        <tr>
                            <td className="text-capitalize">{admin.name.toLowerCase()}</td>
                            <td>{admin.email}</td>
                            <td>{admin.mobile}</td>
                            <td>{admin.location.description}</td>
                            {/* <td><a>Block Admin</a></td>
                            <td><a>Edit</a></td> */}
                        </tr>
                        ))
                    }
                    </tbody>
                </table>
                :
                <div className="pt-5 text-center container">
                    <NothingFound data="No Admin Invitations found"/>
                </div>
             }
              </>
          }

        </div>


      );
    }
  }

export default PendingAdmin;
