import React from 'react';

// import components
import APILoader from '../../components/other/api-loader';
import NothingFound from '../../components/other/nothing';

// import service
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';

export default class UserList extends React.Component{
    constructor(props){
        super(props);
        this.state={
            pageNum: 1,
        }
    }

    componentDidMount(){
        this.getUsers();
    }

    getUsers = () =>{
        this.setState({ loader: true});
        let url = devconfig.adminV2 +"/user/list";
        let data = JSON.stringify({
            "page_num" :  parseInt(this.state.pageNum)
        })
        APIPost(url, data).then((response)=>{
            if(response.status){
                this.setState({ loader: false, data: response.data})
            }
            else{
                this.setState({ loader:  false, data:null})
            }
        })
    }

    render(){
        return(
            <div className="e-main-content e-vendor-table">
                <h2 className="e-page-head">Joined users</h2>
                    <div className='row'>
                        {
                            this.state.loader ?
                                <div className='col-lg-12 text-center'>
                                    <APILoader/>
                                </div>
                            : !this.state.loader && !this.state.data ?
                                <div className='col-lg-12 text-center'>
                                    <NothingFound/>
                                </div>
                            :
                                <>
                                    <div className='col-lg-12'>
                                        <table className="table  e-enquire-table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.data.map(query => {

                                                        return (
                                                            <tr>
                                                                <td>{query.name}</td>
                                                                <td>{query.email}</td>
                                                                <td>{query.mobile}</td>
                                                                <td>{query.active ? <span className='e-active-user'>Active</span> : <span className='e-inactive-user'>Inactive</span>}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-lg-12 text-center'>
                                        <button onClick={ async ()=>{await  this.setState({ pageNum: (this.state.pageNum+1)}); await this.getUsers();}} >Load more</button>
                                    </div>
                                </>
                        }
                    </div>
            </div>
        )
    }
}