
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

/*Components*/
import APILoader from '../../../components/other/api-loader';
import NothingFound from '../../../components/other/nothing';
import ConfirmModal from '../../../components/modals/confirmModal';
import Toast from '../../../components/common/toast';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';

/*Assets*/
import vendorLogo from '../../../assets/images/vendor-logo.jpg';

class LocationAdminDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      tabIndex: 1,
      apiLoading: true,
      id: null,
      data: {}
    }
  }

  componentDidMount() {
    GetURLVar().then(function(query){
      if(query){
        this.getData(query.id);
        this.setState({id: query.id});
      }
    }.bind(this))
  }

  getData(tempId){
    this.setState({ apiLoading: true});
    var url = devconfig.localAdmin+"/locationadmin/detail";
    var data = JSON.stringify({
        "admin_id" : tempId
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        if(response.data){
          this.setState({apiLoading: false});
          this.setData(response.data);
        }
        else{
          this.setState({ apiLoading: false});
        }
        
      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }

  setData(data){
    let matches = ""; let res = "";
    if(data.name && data.name.length){
        matches = data.name.match(/\b(\w)/g);
        res = matches[0];
        if(matches.length>1){
          res= res+matches[matches.length-1]
        }
    }
    let admin = {...data, initials: res};
    this.setState({data: admin});
  }

  locationAdminLogin(){
    this.setState({ loginLoading: true});
    var url = devconfig.localAdmin+"/locationadmin/login";
    var data = JSON.stringify({
      "admin_id" : this.state.id
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({loginLoading: false});
        console.log(response.data);
        window.open(`${devconfig.locationAdminAppUrl}/admin-login?admin=${this.state.id}&token=${response.data.access_token}`, '_blank');
      }
      else{
        this.setState({ loginLoading: false });
      }
    }.bind(this))
  }

  blockAdmin(){
    this.setState({ activeLoading: true, error: "" });
    let url = devconfig.localAdmin + "/locationadmin/block";
    let data = JSON.stringify({
      "admin_id" : this.state.blockAdmin? this.state.blockAdmin.id : null,
      "block": this.state.blockAdmin? this.state.blockAdmin.active : null
    });
    APIPost(url, data).then(function (response) {
      if (response.status === "ok") {
        this.setState({ activeLoading: false });
        if(this.state.blockAdmin.active){
          this.triggerToast("Location Admin Blocked!", "success");
        }
        else{
          this.triggerToast("Location Admin Un-Blocked!", "success");
        }
        this.getData(this.state.id);
      }
      else {
        this.setState({ activeLoading: false, error: response.message });
        this.triggerToast(response.message, "fail");
      }
    }.bind(this));
  }

  triggerToast(temp, type) {
    this.setState({ toast: temp, toastType: type })
    setTimeout(function () { this.setState({ toast: null }); }.bind(this), 5000);
  }


//   tabItemClick(tempItem){
//     this.setState({tabIndex: tempItem});
//   }
  

  render() {
    var data = this.state.data;

    if(this.state.apiLoading){
      return(
        <APILoader/>
      );
    }
    else{
      return (
        <>
        <div>
          {
            this.state.data && this.state.data.id?
              <div className="e-main-content e-vendor-table e-location-admin">
                <h2 className="e-page-head">Location Admin Detail</h2>
                
                <div className="row">
                  <div className="col-lg-12">

                    <div className="e-vendor-details-wrap">
                      <div className="media mb-4">
                        {
                          data.logo ?
                            <img src={data.logo} className="mr-3 e-vendor-logo" alt="Vendor Logo"/>
                          :
                            <span className="e-logo-placeholder mr-3 p-3 d-flex align-items-center justify-content-center font-weight-bolder">{data.initials}</span>
                        }
                        
                        <div className="media-body">
                          <div className="container px-0">
                            <div className="row">
                              <div className="col-lg-6">
                                <h5 className="mt-0">{data.name}</h5>
                              </div>
                              <div className="col-lg-6">
                                <button className={data.active? "e-s-btn-2 float-right": "e-s-btn-2 e-btn-reverse float-right"} onClick={data.active?()=>{this.setState({blockAdmin: data}); $("#blockAdmin").modal("show")}: async ()=>{await this.setState({blockAdmin: data}); this.blockAdmin();}} >{data.active? "Block": "Unblock"}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row pb-3">
                        <div className="col-lg-3">
                          <h6>Phone:</h6>
                          <p>{data.mobile}</p>
                        </div>
                        <div className="col-lg-5">
                          <h6>Email:</h6>
                          <p>{data.email}</p>
                        </div>
                        <div className="col-lg-4">
                          {/* <h6>Seller Type:</h6>
                          <p>{data.seller_type}</p> */}
                        </div>
                        {
                          data.website ?
                            <div className="col-lg-4">
                              <h6>Website:</h6>
                              <p>{data.website}</p>
                            </div>
                          :
                            null
                        }
                        
                      </div>
                      <div className="e-vendor-more-details pt-4">
                        <div className="row">
                          <div className="col-lg-6">
                            <h6>Location:</h6>
                            <p>{data.location && data.location.description? data.location.description: ""}</p>
                          </div>
                          {/* <div className="col-lg-6">
                            <h6>Address:</h6>
                            <p>{data.address}</p>
                          </div>
                          <div className="col-lg-4">
                            <h6>GST:</h6>
                            <p>{data.gst_no}</p>
                          </div>
                          <div className="col-lg-4">
                            <h6>Documents:</h6>
                            <a target="_blank" href="/">View certificate of incorporation</a>
                          </div> */}
                          <div className="col-lg-6">
                          <button className="e-s-btn float-right" onClick={() => this.locationAdminLogin()}>Login as <span className="font-weight-bold">{data.name}</span></button>
                          </div>
                        </div>
                      </div>
                    
                    </div>

                  </div>
                </div>

              </div>
            :
              <NothingFound data={"Nothing found, try later."}/>
          }
          
        </div>
        <ConfirmModal id="blockAdmin" data={this.state.blockAdmin? `Block Location Admin ${this.state.blockAdmin.name}?`: null} success={()=>this.blockAdmin()}/>

        {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType} />
            :
            null
        }
        </>
        
      );
    }
  }
}

export default LocationAdminDetail;
