
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


/*Components*/ 
import Input from '../../../components/formComponents/input.js';
import TextButton from '../../../components/buttons/textButton.js';
import Textarea from '../../../components/formComponents/textarea';
import SelectBox from '../../../components/formComponents/selectBox';
import CheckBox from '../../../components/formComponents/checkbox';



/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';

/*Assets*/



class NewPlan extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: true,
      data: null,
      pageNumActive: true,
      pageNumLoader: false,
      pageNum: 1,
      adTypes: [
        {
          index: 0,
          adType: {value: undefined, label: "Choose type"},
          duration: undefined,
          durationType:  {value: 1, label: "Days"},
          images: false,
          gifs: true,
          videos: false
        }
      ],
      color: "#fff",
      features: [""],
      adType: {value: undefined, label: "Choose type"},
      durationType: {value: 1, label: "Days"}
    }
  }

  componentDidMount() {
    this.getAdTypes();
  }
  getAdTypes(){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1 + "/ads/location-dropdown";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({ adLocations: response.data, apiLoading: false});
      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }
  valueChangeTitle(value){
    this.setState({
      title: value
    })
  }
  colorChange(color){
    this.setState({
      color: color
    })
  }

  adTypeChange(index, adType){
    var adTypes = this.state.adTypes;
    var filteredItem = adTypes.filter(this.filterAdType.bind(this, index));
    if(filteredItem.length){
      filteredItem = filteredItem[0];
      filteredItem['adType'] = adType;
    }else{
      console.log("Invalid index.")
    }
    adTypes[index] = filteredItem;
    this.setState({adTypes: adTypes});
  }

  durationChange(index, duration){
    var adTypes = this.state.adTypes;
    var filteredItem = adTypes.filter(this.filterAdType.bind(this, index));
    if(filteredItem.length){
      filteredItem = filteredItem[0];
      filteredItem['duration'] = duration;
    }else{
      console.log("Invalid index.")
    }
    adTypes[index] = filteredItem;
    this.setState({adTypes: adTypes});
  }

  durationTypeChange(index, durationType){
    var adTypes = this.state.adTypes;
    var filteredItem = adTypes.filter(this.filterAdType.bind(this, index));
    if(filteredItem.length){
      filteredItem = filteredItem[0];
      filteredItem['durationType'] = durationType;
    }else{
      console.log("Invalid index.")
    }
    adTypes[index] = filteredItem;
    this.setState({adTypes: adTypes});
  }

  priceChange(price){
    this.setState({price: price})
  }

  emiChange(emi){
    this.setState({emi: emi})
  }

  noOfMonthsChange(noOfMonths){
    this.setState({noOfMonths: noOfMonths})
  }

  offerPriceChange(offerPrice){
    this.setState({offerPrice: offerPrice})
  }

  createPlan(){
    // console.log(this.state);
    this.setState({apiLoading: true});
    var url = devconfig.admin + '/plan/create';
    var data = JSON.stringify({
      "name": this.state.title,
      "description": this.state.description,
      "price": this.state.price,
      "offer_price": this.state.offerPrice,
      "type": 1,
      "emi_amount": this.state.emi,
      "no_of_pay": this.state.noOfMonths,
      "features": this.state.features,
      "emi_amount": this.state.emi,
      "color": this.state.color,
      "ad_types": this.state.adTypes
    });
    console.log(data)
    APIPost(url, data).then(function(response){
      if(response.status == "ok"){
        window.location = "membership-plans"
      }else{
        this.setState({errorMessage: response.message})
      }
    }.bind(this))
  }

  featureTextChange(index, featureText){
    var features = this.state.features;
    features[index] = featureText
    this.setState({features: features})
  }

  addFeature(){
    var features = this.state.features;
    // if(this.state.featureText){
    //   features.splice(features.length-1, 1);
    //   features.push({text: this.state.featureText, editable: false});
    //   features.push({text: "", editable: true});
    // }else{
    //   alert("Enter features to add more.")
    // }
    
    features.push("");
    this.setState({features: features, featureText: ""})
    // console.log(features)
  }

  filterAdType(index, item){
    // console.log(item, index)
    if(item.index===index){
      return item
    }
  }

  toggleCheckbox(index, type){
    var adTypes = this.state.adTypes;
    var filteredItem = adTypes.filter(this.filterAdType.bind(this, index));
    if(filteredItem.length){
      filteredItem = filteredItem[0];
      if(type==="images"){
        filteredItem.images = !filteredItem.images;
      }else if(type==="videos"){
        filteredItem.videos = !filteredItem.videos;
      }else if(type==="gifs"){
        filteredItem.gifs = !filteredItem.gifs;
      }
      adTypes[index] = filteredItem;
      this.setState({adTypes: adTypes});
    }else{
      console.log("Invalid index.")
    }
      
  }

  addAdType(){
    var adTypes = this.state.adTypes;
    adTypes.push({
      index: adTypes.length,
      adType: this.state.adLocations[0],
      duration: undefined,
      durationType:  {value: 1, label: "Days"},
      images: false,
      gifs: true,
      videos: false
    });
    this.setState({adTypes: adTypes});
  }


  render() {
    console.log(this.state.adTypes)
    var adLocations = this.state.adLocations 
    var membershipOptions = [
      {value: 1, label: "Days"},
      {value: 2, label: "Months"},
      {value: 3, label: "Years"}
    ] 
    var adOptions = [
      {value: 1, label: "Hours"}
    ] 
    return (
      <div className="e-main-content e-vendor-table e-new-plan">
        <h2 className="e-page-head">Create Membership Plan</h2>
        <div className="row">
          <h2 className="pl-3 mb-4"><Link to="./membership-plans">Membership Plans</Link> > <span>Add New Membership Plan</span></h2>
        </div>
        <div className="row mt-3">
          <div className="col-lg-6">
            <label>Plan Type</label>
            <Input  value="" type="text" className="e-plan-title" placeholder="Type the plan name" value={this.state.title} valueChange={this.valueChangeTitle.bind(this)}/>
          </div> 
        </div>
        <div className="row">
          <div className="col-lg-6">
            <label>Short Description</label>
            <Textarea placeholder="Short Description about plan, benefits" value={this.state.description} className="e-textarea" rows="4" cols="40" valueChange={(tempVal) => this.setState({description: tempVal})} required/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <label>
              Select Advertisement type/location
            </label>
          </div>
        </div>
        {
          this.state.adTypes.map((item, index)=>{
            return(
              <div className="row">

                <div className="col-lg-3">
                  <SelectBox placeholder="Ad Type" options={adLocations} isSearchable={true} selectchange={this.adTypeChange.bind(this, item.index)} required/>
                </div>
                <div className="col-lg-3">
                  <Input  value="" type="number" className="e-plan-title" placeholder={"No of days/months/year"} defaultValue={item.duration} valueChange={this.durationChange.bind(this, item.index)}/>
                </div>

                <div className="col-lg-2">
                  <SelectBox placeholder="Choose one" defaultValue={this.state.durationType} selectchange={this.durationTypeChange.bind(this, item.index)} options={membershipOptions} required/>
                </div>
                <div className="col-lg-1 e-check-box pt-2">
                  <CheckBox label="Images" checked={item.images} onClick={this.toggleCheckbox.bind(this, item.index, "images")}/>
                </div>
                <div className="col-lg-1 e-check-box pt-2">
                  <CheckBox label="GIFs" checked={item.gifs} onClick={this.toggleCheckbox.bind(this, item.index, "gifs")}/>
                </div>
                <div className="col-lg-1 e-check-box pt-2 pl-0">
                  <CheckBox label="Videos" checked={item.videos} onClick={this.toggleCheckbox.bind(this, item.index, "videos")}/>
                </div>
              </div>
            )
          })
        }
        <div className="row mb-4">
          <div className="col-lg-6">
            <TextButton className="e-t-btn" name="+ Add More Ad Types" handleClick={this.addAdType.bind(this)}/>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <label>
              Plan price details
            </label>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-3">
            <Input  value="" type="number" className="e-plan-title" placeholder="MRP (₹)" value={this.state.price} valueChange={this.priceChange.bind(this)}/>
          </div>
          <div className="col-lg-3">
            <Input  value="" type="number" className="e-plan-title" placeholder="Offer Price (₹)" value={this.state.offerPrice} valueChange={this.offerPriceChange.bind(this)}/>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-3">
            <Input  value="" type="number" className="e-plan-title" placeholder="EMI Amount(₹)" value={this.state.emi} valueChange={this.emiChange.bind(this)}/>
          </div>
          <div className="col-lg-3">
            <Input  value="" type="number" className="e-plan-title" placeholder="No of months" value={this.state.noOfMonths} valueChange={this.noOfMonthsChange.bind(this)}/>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <label>
              Plan Features
            </label>
          </div>
        </div>
        <div className="row">
        {
          this.state.features.map((feature, index)=>{
            return(
              <div className="col-lg-3">
                <Input  value="" type="text" className="e-plan-title" placeholder={"Extra Features " + String(Number(index)+1)} defaultValue={feature} valueChange={this.featureTextChange.bind(this, index)}/>
              </div>
            )
          })
        }
        </div>
        <div className="row mb-4">
          <div className="col-lg-6">
            <TextButton className="e-t-btn" handleClick={this.addFeature.bind(this)} name="+ Add Feature"/>
          </div>
        </div>


        <div className="row">
          <div className="col-lg-12">
            <label>
              Plan Uniq color code
            </label>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-3">
            <Input  value="" type="text" className="e-plan-title" placeholder="Enter a Color Hex Code ( Eg: #000000)" value={this.state.color} valueChange={this.colorChange.bind(this)}/>
          </div>
          <div className="col-lg-1">
           <div className="e-color" style={{backgroundColor: this.state.color}}></div>
          </div>
        </div>
        
        <div className="row mb-4">
          <div className="col-lg-6 pr-0">
          {
            this.state.title && this.state.description && this.state.adTypes.length && this.state.price && this.state.offerPrice &&
            this.state.emi && this.state.noOfMonths && this.state.features.length && this.state.color ?
              <button className="e-p-btn" type="submit" value="Submit" onClick={this.createPlan.bind(this)}>Save Plan</button>
            :
              <button className="e-p-btn" disabled type="submit" value="Submit">Save Plan</button>
          }
          </div>
        </div>
      </div>

    );
  }
}

export default NewPlan;
