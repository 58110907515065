/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
// import Inputs from '../../components/formComponents/inputs.js';
import SelectBox from '../../components/formComponents/selectBox';
import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/textarea';



/*Assets*/
import sampleBanner from '../../assets/images/sample-banner.jpg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import PhoneValidation from '../../services/validation/phone';
import EmailValidation from '../../services/validation/email';



class AddLocationAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      number: "",
      location: null,
      apiLoading: false,
      error: "",
      locationOptions: []
    }
  }

  async addAdmin() {
    this.setState({ apiLoading: true, error: "" });
    let verifyNumber = await PhoneValidation(this.state.number);
    let verifyEmail = await EmailValidation(this.state.email);
    if (verifyEmail && verifyNumber) {
      let url = devconfig.localAdmin + "/locationadmin/invite";
      let data = JSON.stringify({
        "name": this.state.name,
        "email": this.state.email,
        "mobile": this.state.number,
        "location_id": 1,
        "location": {
          "description":this.state.location && this.state.location.label? this.state.location.label: null,
          "place_id":this.state.location && this.state.location.value? this.state.location.value : null,
          "reference":this.state.location && this.state.location.value? this.state.location.value : null
        }
      });
      if(this.props.locationSelected){
        data = JSON.stringify({
          "name": this.state.name,
          "email": this.state.email,
          "mobile": this.state.number,
          "location_id": 1,
          "location": {
            "description": this.props.location.description,
            "place_id": this.props.location.place_id,
            "reference": this.props.location.place_id
          }
        });
      }
      APIPost(url, data).then(function (response) {
        if (response.status == "ok") {
          if(this.props.locationSelected){
            window.location.href="/location-admins"
          }
          else{
            this.modalClose();
            this.props.success();
            $("#addAdminModal").modal("hide");
          }
        }
        else {
          this.setState({ apiLoading: false, error: response.message })
        }
      }.bind(this))
    }
    else {
      if (!verifyNumber && !verifyEmail) {
        this.setState({ apiLoading: false, error: "Enter valid Number and E-mail" });
      }
      else if (verifyEmail) {
        this.setState({ apiLoading: false, error: "Enter a valid Number" });
      }
      else {
        this.setState({ apiLoading: false, error: "Enter a valid E-mail" });
      }
    }
  }

  modalClose() {
    this.setState({
      name: "",
      email: "",
      number: "",
      location: null,
      apiLoading: false,
      error: ""
    });
    this.props.modalClose();
  }

  async search(query){
    this.setState({locationSearch: query});
    if(query && query.length){
      let url = devconfig.localAdmin + "/location/search";
      let data = JSON.stringify({
        "text" : query
      })
      APIPost(url, data).then(function(response){
        if(response.status==="ok"){
          this.setState({locationOptions: response.data});
        }
        else{
          this.setState({ error: response.message});
        }
      }.bind(this));
    }
    else{
      this.setState({locationOptions: []});
    }
  }


  render() {
    return (
      <div className="modal fade e-ofer-modal" id="addAdminModal" tabIndex="-1" role="dialog" aria-labelledby="addCategoryModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content e-career-modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.modalClose()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {
              this.props.modalOn ?
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <h6 className="e-modal-header e-add-career-heading">Add New Location & Admin</h6>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <h6 className="e-career-header">Name</h6>
                      <Input id="e-input-name" defaultValue={this.state.name} type="text" className="e-add-career" valueChange={(tempVal) => this.setState({ name: tempVal })} autoComplete="nope" />
                    </div>
                    <div className="col-lg-12">
                      <h6 className="e-career-header">Email ID</h6>
                      <Input id="e-input-email" defaultValue={this.state.email} type="text" className="e-add-career" valueChange={(tempVal) => this.setState({ email: tempVal })} autoComplete="nope" />
                    </div>
                    <div className="col-lg-12">
                      <h6 className="e-career-header">Phone Number</h6>
                      <Input id="e-input-number" defaultValue={this.state.number} type="number" className="e-add-career" valueChange={(tempVal) => this.setState({ number: tempVal })} autoComplete="nope" />
                    </div>
                    <div className="col-lg-12 e-location-search">
                      <h6 className="e-career-header">Location</h6>
                      {
                        this.props.locationSelected?
                        <SelectBox options={this.state.locationOptions}
                        placeholder="Search for location"
                        value={{label: this.props.location.description, value: this.props.location.place_id}}
                        isDisabled={true}/>
                        :
                        <SelectBox options={this.state.locationOptions}
                        isSearchable={true}
                        inputValue={this.state.locationSearch}
                        onInputChange={(e) => this.search(e)}
                        placeholder="Search for location"
                        value={this.state.location}
                        selectchange={(val) => this.setState({location: val})}/>
                      }
                    </div>
                  </div>
                </>
                :
                null
            }
            <div className="row py-2">
              {
                this.state.error.length ?
                  <div className="col-lg-12 py-2">
                    <span className="text-danger">{this.state.error}</span>
                  </div>
                  :
                  null
              }
              <div className="col-lg-12">
                <div className="d-flex align-items-center float-right">
                  <a href="#" className="pt-1 e-ofer-cancel" data-dismiss="modal" onClick={() => this.modalClose()}>Cancel</a>
                  {
                    this.state.name && this.state.number && this.state.email && (this.state.location || this.props.locationSelected) && !this.state.apiLoading ?
                      <button className="e-s-btn ml-5" type="submit" value="Submit" onClick={() => this.addAdmin()}>Create</button>
                      :
                      <button className="e-s-btn ml-5 e-inactive" type="submit" value="Submit">Create</button>
                  }
                </div>
              </div>
            </div>

            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    );
  }
}

export default AddLocationAdmin;
