/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
// import Inputs from '../../components/formComponents/inputs.js';
import SelectBox from '../../components/formComponents/selectBox';
import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/textarea';



/*Assets*/
import sampleBanner from '../../assets/images/sample-banner.jpg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';



class AddProduct extends Component {
  constructor(props){
    super(props);
    this.state = {
      subCategory: "",
      apiLoading: false
    }
  }
   
 async createCareer(e){
   e.preventDefault();
    this.setState({apiLoading: true});
    var url = devconfig.admin+ '/product/create';
    var data = JSON.stringify({
      "name": this.state.subCategory,
      "sub_category_id" :this.props.sub_category,
    })
    if(JSON.parse(localStorage.getItem("trueKhoj_data")).type ===2){
      url = devconfig.admin+ '/subcategory/create';
      data = JSON.stringify({
        "name": this.state.subCategory,
        "category_id" :this.props.sub_category,
        "image" : null
      })
    }
    await APIPost(url,data).then(function(response){
      if(response.status == "ok"){
        window.location.reload();
      }
      else{
        this.setState({apiLoading: true, error: response.message});
      }
    }.bind(this))
  }

  async updateProductName(e){
    e.preventDefault();
    this.setState({apiLoading: true});
    var url = devconfig.admin+ '/subcategory/update';
    var data = JSON.stringify({
      "id" : this.props.productId,
      "category_id" : this.props.sub_category,
      "name" : this.props.name,
    })
    await APIPost(url,data).then(function(response){
      if(response.status == "ok"){
        window.location.reload();
      }
      else{
        this.setState({apiLoading: false});
        $(`#${this.props.id}`).modal("hide");
        this.props.showToast(response.message, "fail");
      }
    }.bind(this))
  }

  valueChangeCat(value){
    this.setState({subCategory: value});
  }


  render() {
     const categoryBy = [
      { value: 1, label: 'Last 1 week' },
      { value: 2, label: 'Last 1 month' },
      { value: 3, label: 'Last 3 months' },
    ]
    return (
      <div className="modal fade e-ad-detail-wrap e-ofer-modal" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="addSubCategoryModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content e-career-modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h6 className="e-modal-header e-add-career-heading">{this.props.edit? "Update Product": "Add Product"}</h6>
              </div>
            </div>
             <div className="row mt-3">
                <div className="col-lg-12">
                  <h6 className="e-career-header">Product Name:</h6>
                  <Input id={this.props.id} allowEdit={true} value={this.props.edit? this.props.name: this.state.subCategory} type="text" className="e-add-career" valueChange={this.props.edit?(e)=> this.props.update(e):this.valueChangeCat.bind(this)}/>
                </div>
              </div>
              <div className="row">
              <div className="col-lg-12 overflow-hidden">
              {this.state.error? <span className="text-danger">{this.state.error}</span>: null}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9">
                <a href="#" className="float-right pt-4 e-ofer-cancel" data-dismiss="modal">Cancel</a>
              </div>
              {
                (this.state.subCategory  || (this.props.edit &&this.props.name)) && !this.state.apiLoading ?
                  <button className="e-s-btn e-new-ofer float-right mt-3 ml-5" type="submit" value="Submit" onClick={this.props.edit? (e)=>this.updateProductName(e):this.createCareer.bind(this)}>{this.props.edit? "Update": "Create"}</button>
                :
                  <button className="e-s-btn e-inactive e-new-ofer float-right mt-3 ml-5" type="submit" value="Submit">{this.state.apiLoading ? "Loading.." :this.props.edit? "Update": "Create"}</button>
              }

            </div>

            <div className="modal-footer">


              {/*

              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              {
                this.state.category && this.state.styleType && this.state.colorOne.length && this.state.colorTwo.length  ?
                  <button type="submit" className="e-s-btn" value="Submit" data-dismiss="modal" onClick={this.submit.bind(this)}>Submit</button>
                :
                  <button type="submit" className="e-s-btn e-inactive" value="Submit" >Submit</button>
              }

            */}

            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default AddProduct;
