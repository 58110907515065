/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import SectionHeadingStyle from '../elementsPreview/sectionHeadingStyle';
import SelectBox from '../formComponents/selectBox';
import ColorPicker from '../common/colorPicker';
import Input from '../../components/formComponents/input';

/*Assets*/

class AddLink extends Component {
  constructor(props){
    super(props);
    this.state = {
      colorOne: "",
      colorTwo: "",
      pickerOneID: "colorPick1",
      pickerTwoID: "colorPick2",
      category: null,
      styleType: null,
      categoryOptions: [],
      textHeading: ""
    }
  }


  componentDidMount() {
    
  }



  triggerClick(tempItem, tempLoader){
    $(tempItem).trigger('click');
    this.setState({showUploadLoader: tempLoader});
  }


  submit(){
    this.props.success(this.state.url);
  }

  render() {




    return (
      <div className="modal fade" id="addLink" tabIndex="-1" role="dialog" aria-labelledby="addLink" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/*<div className="modal-header">
              <h5 className="modal-title">Choose header style & content</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>*/}
            <div className="modal-body">

              <div className="mb-4">
                <h6>Create Button Link</h6>
              </div>
              <div className="row ">
                <div className="col-lg-12">
                <Input label="Add Redirection URL*" id="banner-url" type="text" defaultValue={this.state.url} value={this.state.url} valueChange={(tempVal) => this.setState({url: tempVal})} />        
                </div>
              </div>
              

            </div>
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
                  <button type="submit" className="e-s-btn" value="Submit" data-dismiss="modal" onClick={this.submit.bind(this)}>Submit</button> 
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddLink;
