
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*Components*/
import HeadingSelectionModal from '../../../components/modals/headingSelectionModal';
import ListSelectionModal from '../../../components/modals/listSelectionModal';
import SectionHeadingStyle from '../../../components/elementsPreview/sectionHeadingStyle';
import Input from '../../../components/formComponents/input';
import CardFourColumn from '../../../components/cards/column-4.js';
import CardSixColumn from '../../../components/cards/column-6.js';
import UploadDummyAdsModal from '../../../components/modals/uploadDummyAdsModal';
import UploadDummyCatBannerModal from '../../../components/modals/uploadDummyCategorybanner';
import AddMenu from '../../../components/modals/addMenu';
import VideoPlayer from '../../../components/common/video';
import AddBg from '../../../components/modals/addBg';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';
import CardTwoColumn from '../../../components/cards/column-2';
import CardThreeColumn from '../../../components/cards/column-3';

/*Assets*/
 

class OnlineCategoryLayout extends Component {
  notify = e => toast(e);
  constructor(props){
    super(props);
    this.state = {
      layout: [],
      ads: {

        hero: { id: 4, page_id: 2, data: [{content: null, link: null}]},
        logoSlider: {
          id: 5, page_id: 2, data: [
            {content: null, link: null},
            {content: null, link: null},
            {content: null, link: null},
            {content: null, link: null},
            {content: null, link: null},
            {content: null, link: null},
          ]
        },
        buyOfMomentBanner: { id: 6, page_id: 2, data: [{content: null, link: null}]},
        buyOfMomentSubBanners: { 
          id: 7, page_id: 2, data: [
            {content: null, link: null},
            {content: null, link: null},
            {content: null, link: null},
          ]
        },
        brandLogo: { page_id: 2, data: [{content: null, link: null}]},
        brandAds1: { 
          id: 8, page_id: 2, data: [
            {content: null, link: null},
            {content: null, link: null},
            {content: null, link: null},
            {content: null, link: null},
            {content: null, link: null},
          ]
        },
      },
      id: null,
      selectItemIndex: null,
      categoryData: null,
      selectedSubCategories: null,
      data: null,
      name: null,
      adDummyType: null,
      swapIndex: "",
      catBannerAdIndex: null,
      categoryId: null,
      modalRefresh: false,
      menu: null,
      menuApiLoading: true,
    }
  }

  componentDidMount() {
    GetURLVar().then(function(query){
      if(query){
        this.getData(query.id);
        this.getMenu(query.id);
        this.setState({id: query.id});
      }
    }.bind(this))
  }

  getData(tempId){
    var url = devconfig.adminV1+"/online/page/detail";
    var data = JSON.stringify({
      "layout_id" : Number(tempId)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        if(response.data.page_data.layout){
          this.setState({layout: response.data.page_data.layout});
        }
        if(response.data.page_data.ads){
          this.setState({ads: response.data.page_data.ads});
        }
        this.setState({name: response.data.name});

        if(response.data.category_id){
          this.setState({categoryId: response.data.category_id});
          this.getSubCategoryData(response.data.category_id);
        }
      }
    }.bind(this))
  }

  getMenu(tempId){
    this.setState({menuApiLoading: true});
    var url = devconfig.adminV1+"/online/topmenu/list";
    var data = JSON.stringify({
      "layout_id" : Number(tempId)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        if(response.data.length){
          this.setState({menu: response.data[0], menuApiLoading: false});
        }
        else{
          this.setState({menu: [], menuApiLoading: false});
        }
        
      }
    }.bind(this))
  }

  addSection(){
    var tempArry =  {
      "header": { "content": null, "type": null, "font_color": null, "bg_color": null, category: null},
      "ads": { "content": null, "link": null},
      "logo_slider":{
        "page_id":2,
        "id": 10,
        "data":  [
          {content: null, link: null},
          {content: null, link: null},
          {content: null, link: null},
          {content: null, link: null},
          {content: null, link: null},
          {content: null, link: null},
        ]
      },
      "data": { "content": null , "image": null, "stlye": null},
      "id": 9,
      "page_id": 2,
    }

    var tempLayout = this.state.layout;
    tempLayout.push(tempArry);
    this.setState({layout: tempLayout});
  }

  removeSection(tempIndex){
    var tempLayout = this.state.layout;
    tempLayout.splice(tempIndex,1);
    this.setState({layout: tempLayout, selectedSubCategories: null});
  }

  headingSelectSuccess(tempItem){
    var tempArry = this.state.layout;
    tempArry[this.state.selectItemIndex].header = tempItem;
    this.setState({layout: tempArry});

    if(tempItem.category){
      this.getChildCategory(tempItem.category);
    }
    else{
      this.getChildCategory();
    }
  }

  bgColorSuccess(tempColor){
    var tempArry = this.state.layout;
    let ads = this.state.ads;
    if(this.state.selectItemIndex ==='buyOfMomentBanner'){
      ads.buyOfMomentBanner = {...ads.buyOfMomentBanner, bg_color: tempColor};
      this.setState({selectItemIndex: 0});
    }
    else{
      tempArry[this.state.selectItemIndex].bg_color = tempColor;
    }
    this.setState({layout: tempArry, ads: ads});
  }

  getChildCategory(tempItem){
    var url = devconfig.adminV2+"/product/list";
    var tempSubCat = [];
    if(tempItem){
      tempSubCat = [tempItem];
    }
    
    var data = JSON.stringify({
      "sub_category_ids" : tempSubCat,
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        var tempArry = [];
        response.data.map(function(item, index){
          var tempItem = {value: item.id, label: item.product}
          tempArry.push(tempItem);
        });

        this.setState({selectedSubCategories: tempArry, categoryData: response.data});
      }
    }.bind(this))
  }

  listSelectSuccess(tempItem){
    var tempArry = this.state.layout;
    if(tempArry[this.state.selectItemIndex].data && tempArry[this.state.selectItemIndex].data.content){
      var temp = tempArry[this.state.selectItemIndex].data.content.concat(tempItem.content);
      tempArry[this.state.selectItemIndex].data.content = temp;
      tempArry[this.state.selectItemIndex].data.style = tempItem.style;
      tempArry[this.state.selectItemIndex].data.type = tempItem.type;
    }
    else{
      tempArry[this.state.selectItemIndex].data = tempItem;
    }
    
    this.setState({layout: tempArry});
  }

  selectedIndex(tempindex){
    this.setState({selectItemIndex: tempindex});
  }

  sectionSwapInputChange(tempClass, e){
    $(".e-section-swap-btn").removeClass("e-active");
    $(tempClass).addClass("e-active");
  }
  
  sectionSwapInputReset(){
    $(".e-section-swap-btn").removeClass("e-active");
  }

  getCategoryData(){
    var url = devconfig.adminV2+"/subcategory/list";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        var tempArry = [];
        response.data.map(function(item, index){
          var tempItem = {value: item.id, label: item.category}
          tempArry.push(tempItem);
        });

        this.setState({categoryList: tempArry, categoryData: response.data});
      }
    }.bind(this))
  }

  getSubCategoryData(tempId){
    var url = devconfig.adminV2+"/subcategory/list";
    var data = JSON.stringify({
      "category_ids" : [tempId],
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        var tempArry = [];
        response.data.map(function(item, index){
          var tempItem = {value: item.id, label: item.sub_category}
          tempArry.push(tempItem);
        });

        this.setState({categoryList: tempArry, categoryData: response.data});
      }
    }.bind(this))
  }

  updatePageLayout(){
    this.setState({updateApiLoading: true});

    let subcategory_ids=[];
    let layout_ad_location_id = null;
    if(this.state.layout && this.state.layout.length){
      this.state.layout.forEach(section=>{
        if(section.header && section.header.category) subcategory_ids.push(section.header.category);
      });
      layout_ad_location_id = this.state.layout[0].id;
    }

    var tempArry = {
      layout: this.state.layout,
      ads: this.state.ads,
    }
    var url = devconfig.adminV1+"/online/page/update";
    var data = JSON.stringify({
      "layout_id" : this.state.id,
      "page_data" : tempArry,
      "layout_ad_location_id": layout_ad_location_id,
      "sub_category_ids": subcategory_ids
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({ updateApiLoading: false });
      }
    }.bind(this))
  }

  async adsDummySuccess(tempUrl, tempLink){
    var tempArry = this.state.ads;

    await this.setState({modalRefresh: true});

    switch (this.state.adDummyType) {
      case 1: {
        tempArry.hero.data[0] = {content:tempUrl, link: tempLink };
        tempArry.hero.page_id = 2;
        tempArry.hero.id = 4;
        break;
      }
      case 2: {
        tempArry.logoSlider.data[this.state.adIndex] = {content:tempUrl, link: tempLink};
        tempArry.logoSlider.id = 5;
        tempArry.logoSlider.page_id = 2;
        break;
      }
      case 3: {
        tempArry.buyOfMomentBanner.data[0] = {content:tempUrl, link: tempLink};
        tempArry.buyOfMomentBanner.id = 72;
        tempArry.buyOfMomentBanner.page_id = 2;
        break;
      }
      case 4: {
        tempArry.buyOfMomentSubBanners.data[this.state.adIndex] = {content:tempUrl, link: tempLink};
        tempArry.buyOfMomentSubBanners.id = 6;
        tempArry.buyOfMomentSubBanners.page_id = 2;
        break;
      }
      case 5: {
        tempArry.brandLogo.data[0] = {content: tempUrl, link: tempLink};
        break;
      }
      case 6: {
        tempArry.brandAds1.data[this.state.adIndex] = {content:tempUrl, link: tempLink};
        tempArry.brandAds1.id = 7;
        tempArry.brandAds1.page_id = 2;
        break;
      }
      case 7: {
        tempArry.logo6 = {content: tempUrl, link: tempLink};
        break;
      }
      case 8: {
        tempArry.buyOfMoment = {content: tempUrl, link: tempLink};
        break;
      }
      case 9: {
        tempArry.buyOfMomentSubOne = {content: tempUrl, link: tempLink};
        break;
      }
      case 10: {
        tempArry.buyOfMomentSubTwo = {content: tempUrl, link: tempLink};
        break;
      }
      case 11: {
        tempArry.buyOfMomentSubThree = {content: tempUrl, link: tempLink};
        break;
      }
      case 12: {
        tempArry.brandAds1 = {content: tempUrl, link: tempLink};
        break;
      }
      case 13: {
        tempArry.brandAdOne = {content: tempUrl, link: tempLink};
        break;
      }
      case 14: {
        tempArry.brandAdTwo = {content: tempUrl, link: tempLink};
        break;
      }
      case 15: {
        tempArry.brandAdThree = {content: tempUrl, link: tempLink};
        break;
      }
      case 16: {
        tempArry.brandAdFour = {content: tempUrl, link: tempLink};
        break;
      }
      case 17: {
        tempArry.brandAdFive = {content: tempUrl, link: tempLink};
        break;
      }
    }

    this.setState({ads: tempArry, modalRefresh: false});
  }

  adDummyTypeClick(tempItem, tempIndex){
    this.setState({adDummyType: tempItem, adIndex: tempIndex});
  }

  removeDummyAds(tempItem, tempIndex){
    var tempArry = this.state.ads;

    switch (tempItem) {
      case 1: {
        tempArry.hero.data[0] = {content: null, link: null};
        break;
      }
      case 2: {
        tempArry.logoSlider.data[tempIndex] = {content:null, link: null};
        break;
      }
      case 3: {
        tempArry.buyOfMomentBanner.data[0] = {content: null, link: null};
        break;
      }
      case 4: {
        tempArry.buyOfMomentSubBanners.data[tempIndex] = {content: null, link: null};
        break;
      }
      case 5: {
        tempArry.brandLogo.data[0] = {content: null, link: null};
        break;
      }
      case 6: {
        tempArry.logo5 = {content: null, link: null};
        tempArry.brandAds1.data[tempIndex] = {content: null, link: null};

        break;
      }
      case 7: {
        tempArry.logo6 = {content: null, link: null};
        break;
      }
      case 8: {
        tempArry.buyOfMoment = {content: null, link: null};
        break;
      }
      case 9: {
        tempArry.buyOfMomentSubOne = {content: null, link: null};
        break;
      }
      case 10: {
        tempArry.buyOfMomentSubTwo = {content: null, link: null};
        break;
      }
      case 11: {
        tempArry.buyOfMomentSubThree = {content: null, link: null};
        break;
      }
      case 12: {
        tempArry.brandLogo = {content: null, link: null};
        break;
      }
      case 13: {
        tempArry.brandAdOne = {content: null, link: null};
        break;
      }
      case 14: {
        tempArry.brandAdTwo = {content: null, link: null};
        break;
      }
      case 15: {
        tempArry.brandAdThree = {content: null, link: null};
        break;
      }
      case 16: {
        tempArry.brandAdFour = {content: null, link: null};
        break;
      }
      case 17: {
        tempArry.brandAdFive = {content: null, link: null};
        break;
      }
    }

    this.setState({ads: tempArry});
  }

  sectionSwap(tempCurrectIndex, tempClass){
    var tempArry = this.state.layout;
    var tempSwapValue = $(tempClass).val();

    if(tempSwapValue > tempArry.length || tempSwapValue <= 0){
      this.notify("Swap index must be between 0 to "+tempArry.length)
    }
    else{
      if(tempArry[tempSwapValue-1]){
        var b = tempArry[tempCurrectIndex-1];
        tempArry[tempCurrectIndex-1] = tempArry[tempSwapValue-1];
        tempArry[tempSwapValue-1] = b;
        $(".e-section-swap-btn").removeClass("e-active");
        $(tempClass).val("")
      }
      else{
        this.notify("Please verify your input data")
      }
      this.setState({layout: tempArry});
    }
  }

  addLogoSlider(tempIndex){
    var tempArry = this.state.layout;
    if(!tempArry[tempIndex].logo_slider || !tempArry[tempIndex].logo_slider.data||!tempArry[tempIndex].logo_slider.data.length){
      tempArry[tempIndex] = {...tempArry[tempIndex], logo_slider: {
        "page_id":2,
        "id": 10,
        "data":[
          {content: null, link: null},
          {content: null, link: null},
          {content: null, link: null},
          {content: null, link: null},
          {content: null, link: null},
          {content: null, link: null},
        ]
      }}
    }
    this.setState({layout: tempArry});
  }

  catBannerIndexClick(tempIndex, sliderIndex, logoSlider){
    this.setState({catBannerAdIndex: tempIndex});
    if(logoSlider) this.setState({sliderLogoIndex: sliderIndex, setCatLogoSlider: true});
    else this.setState({sliderLogoIndex: null, setCatLogoSlider: null});
  }

  catAdsBannerSuccess(tempImg, tempLink){
    var tempArry = this.state.layout;
    var tempItem = { "content": tempImg, "link": tempLink};
    if(this.state.setCatLogoSlider) tempArry[this.state.catBannerAdIndex].logo_slider.data[this.state.sliderLogoIndex] = tempItem;
    else tempArry[this.state.catBannerAdIndex].ads = { "content": tempImg, "link": tempLink};
    this.setState({layout: tempArry});
  }

  removeCatAds(tempIndex, sliderIndex, logoSlider){
    var tempArry = this.state.layout;
    if(logoSlider) tempArry[tempIndex].logo_slider.data[sliderIndex] = { "content": null, "link": null}
    else tempArry[tempIndex].ads = { "content": null, "link": null};
    this.setState({layout: tempArry});
  }

  render() {
    console.log("this.state.ads");
    console.log(this.state.ads);

    return (
      <div className="e-main-content e-driver-detail-wrap e-dash-wrap">
        <h2 className="e-page-head">{this.state.name} Page Settings</h2>

        {
          this.state.updateApiLoading ?
            <button className="e-p-btn e-nav-button e-inactive e-loading e-page-layout-btn"></button>
          :
            <span>
              {
                this.state.layout && this.state.layout.length ?
                  <button className="e-p-btn e-nav-button" onClick={this.updatePageLayout.bind(this)}>Update page</button>
                :
                  <button className="e-p-btn e-nav-button e-inactive">Update page</button>
              }
            </span>
        }


        <div className="row e-menu-setting-wrap">
          <div className="col-lg-12">
            <h6>Menu Settings {
              !this.state.menu || (this.state.menu.sub_page_data && this.state.menu.sub_page_data.length) || !this.state.menu.sub_page_data <= 7 ? 
                <span data-toggle="modal" data-target="#addMenuModal">Edit/Add</span> 
              : 
                null
            }</h6>
          </div>
          <div className="col-lg-12">
            {
              this.state.menuApiLoading ?
                null
              :
                <React.Fragment>
                  {
                    this.state.menu ?
                      <AddMenu data={this.state.menu.sub_page_data} id={this.state.id} online={true}/>
                    :
                      <AddMenu id={this.state.id} online={true}/>
                  }
                </React.Fragment>
            }
          </div>

          <div className="col-lg-12">
            {
              this.state.menu && this.state.menu.sub_page_data  ?
                <React.Fragment>
                  {
                      this.state.menu.sub_page_data.map( (data, index) => {
                        return(
                          <span className="e-menu-item-tag mr-3">{data.name}</span>
                        );
                      })
                  }
                </React.Fragment>
              :
                <React.Fragment>
                  {
                    this.state.menuApiLoading ?
                      null
                    :
                      <span className='e-no-menu-item'>No menu item found. Click Edit/Add to update menu.</span>
                  }
                </React.Fragment>
            }
          </div>
          {/*
            this.state.menu && this.state.menu.sub_page_data ?
              <AddMenu data={this.state.menu.sub_page_data} id={this.state.id}/>
            :
              null
          */}
          
        </div>

        <div className="row mt-5">
          <div className="col-lg-12">
            <h6>Layout Settings</h6>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="e-layout-wrap mb-3">

              <div className="row">
                <div className="col-lg-12">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads &&  this.state.ads.hero.data[0] && this.state.ads.hero.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 1)}></span>
                          <a href={this.state.ads.hero.data[0].link} target="_blank">
                            {
                              this.state.ads.hero.data[0].content && this.state.ads.hero.data[0].content.indexOf(".mp4") > -1 ?
                                <VideoPlayer url={this.state.ads.hero.data[0].content} />
                              :
                                <img className="e-hero-dummy-img" src={this.state.ads.hero.data[0].content} alt="Truekhoj hero"/>
                            }
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 1)}></span>
                          Hero banner advertisement section
                        </div>
                    }
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                {
                  this.state.ads && this.state.ads.logoSlider && this.state.ads.logoSlider.data.length ?
                    <React.Fragment>
                      {
                        this.state.ads.logoSlider.data.map((item, index) => {
                          return( 
                            <div className="col-lg-2">
                              <div className="e-layout-row e-ad-row">
                                {
                                  this.state.ads && item && item.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 2, index)}></span>
                                      <a href={item.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight"/>
                                      </a>
                                    </div>
                                  :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 2, index)}></span>
                                      Logo {index + 1}
                                    </div>
                                }
                              </div>
                            </div>

                          );
                        })
                      }
                    </React.Fragment>
                  :
                    null
                }

                
                {/*<div className="col-lg-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads.logo2 && this.state.ads.logo2.content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 3)}></span>
                          <a href={this.state.ads.logo2.link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.logo2.content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 3)}></span>
                          Logo two
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads.logo3 && this.state.ads.logo3.content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 4)}></span>
                          <a href={this.state.ads.logo3.link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.logo3.content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 4)}></span>
                          Logo three
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads.logo4 && this.state.ads.logo4.content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 5)}></span>
                          <a href={this.state.ads.logo4.link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.logo4.content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 5)}></span>
                          Logo four
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads.logo5 && this.state.ads.logo5.content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 6)}></span>
                          <a href={this.state.ads.logo5.link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.logo5.content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 6)}></span>
                          Logo Five
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads.logo6 && this.state.ads.logo6.content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 7)}></span>
                          <a href={this.state.ads.logo6.link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.logo6.content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 7)}></span>
                          Logo Six
                        </div>
                    }
                  </div>
                </div>*/}


              </div>
            </div>

            <div className="e-layout-wrap mb-3">
              
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center my-3">
                    <h6>Buy of the moment special</h6>
                  </div>
                </div>

                <div className="col-lg-12 mb-4">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.buyOfMomentBanner && this.state.ads.buyOfMomentBanner.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 3)}></span>
                          <a href={this.state.ads.buyOfMomentBanner.data[0].link} target="_blank">
                            {
                              this.state.ads.buyOfMomentBanner.data[0].content.indexOf(".mp4") > -1 ?
                                <VideoPlayer url={this.state.ads.buyOfMomentBanner.data[0].content} />
                              :
                                <img className="e-hero-dummy-img" src={this.state.ads.buyOfMomentBanner.data[0].content} alt="Truekhoj hero"/>
                            }
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder position-relative">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 3)}></span>
                          Buy of the moment special banner
                        </div>
                    }
                  </div>
                </div>
                {
                  this.state.ads && this.state.ads.buyOfMomentSubBanners && this.state.ads.buyOfMomentSubBanners.data.length ?
                    <React.Fragment>
                      {
                        this.state.ads.buyOfMomentSubBanners.data.map((item, index) => {
                          return( 
                            <div className="col-lg-4">
                              <div className="e-layout-row e-ad-row">
                                {
                                  this.state.ads && item && item.content ?
                                    <div className="position-relative e-hero-dummy-img-wrap">
                                      <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 4, index)}></span>
                                      <a href={item.link} target="_blank">
                                        <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight"/>
                                      </a>
                                    </div>
                                  :
                                    <div className="e-ad-full-placeholder">
                                      <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 4, index)}></span>
                                      Sub banner
                                    </div>
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </React.Fragment>
                  :
                    null
                }
                {/*<div className="col-lg-4 mt-4">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.buyOfMomentSubOne && this.state.ads.buyOfMomentSubOne.content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 9)}></span>
                          <a href={this.state.ads.buyOfMomentSubOne.link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.buyOfMomentSubOne.content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 9)}></span>
                          Sub banner
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-4 mt-4">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.buyOfMomentSubTwo && this.state.ads.buyOfMomentSubTwo.content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 10)}></span>
                          <a href={this.state.ads.buyOfMomentSubTwo.link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.buyOfMomentSubTwo.content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 10)}></span>
                          Sub banner
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-4 mt-4">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.buyOfMomentSubThree && this.state.ads.buyOfMomentSubThree.content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 11)}></span>
                          <a href={this.state.ads.buyOfMomentSubThree.link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.buyOfMomentSubThree.content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 11)}></span>
                          Sub banner
                        </div>
                    }
                  </div>
                </div>*/}
                <div className="col-lg-12 d-flex justify-content-end">
                  <div className="pt-3 d-flex align-items-center">
                    <span className="e-link" data-toggle="modal" data-target="#addBg" onClick={()=>this.setState({selectItemIndex: 'buyOfMomentBanner'})}>{this.state.ads.buyOfMomentBanner.bg_color? "Change selected bg color:": "+ Add bg color"}</span>
                    {
                      this.state.ads.buyOfMomentBanner.bg_color?
                      <span className="px-2 py-2 ml-2 d-block" style={{backgroundColor: this.state.ads.buyOfMomentBanner.bg_color}}></span>
                      :
                      null
                    }
                  </div>
                </div>
              </div>


              <div className="row mt-4">
                <div className="offset-5 col-lg-2">
                  <div className="e-layout-row e-ad-row">
                    {
                      this.state.ads && this.state.ads.brandLogo && this.state.ads.brandLogo.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 5)}></span>
                          <a href={this.state.ads.brandLogo.data[0].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.brandLogo.data[0].content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder position-relative">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 5)}></span>
                          Brand Logo
                        </div>
                    }
                  </div>
                </div>
              </div>


              <div className="row mt-4">
                <div className="col-lg-4">
                  <div className="e-layout-row e-ad-row position-relative">
                    {
                      this.state.ads && this.state.ads.brandAds1 && this.state.ads.brandAds1.data[0].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 6, 0)}></span>
                          <a href={this.state.ads.brandAds1.data[0].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.brandAds1.data[0].content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 6, 0)}></span>
                          Brand banner one 
                        </div>
                    }
                  </div>
                  <div className="e-layout-row e-ad-row position-relative mt-3">
                    {
                      this.state.ads && this.state.ads.brandAds1 && this.state.ads.brandAds1.data[1].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 6, 1)}></span>
                          <a href={this.state.ads.brandAds1.data[1].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.brandAds1.data[1].content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 6, 1)}></span>
                          Brand banner two
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="e-layout-row e-ad-row position-relative">
                    {
                      this.state.ads && this.state.ads.brandAds1 && this.state.ads.brandAds1.data[2].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 6, 2)}></span>
                          <a href={this.state.ads.brandAds1.data[2].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.brandAds1.data[2].content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder e-l-banner">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 6, 2)}></span>
                          Brand banner three 
                        </div>
                    }
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="e-layout-row e-ad-row position-relative">
                    {
                      this.state.ads && this.state.ads.brandAds1 && this.state.ads.brandAds1.data[3].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 6, 3)}></span>
                          <a href={this.state.ads.brandAds1.data[3].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.brandAds1.data[3].content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 6, 3)}></span>
                          Brand banner four 
                        </div>
                    }
                  </div>
                  <div className="e-layout-row e-ad-row position-relative mt-3">
                    {
                      this.state.ads && this.state.ads.brandAds1 && this.state.ads.brandAds1.data[4].content ?
                        <div className="position-relative e-hero-dummy-img-wrap">
                          <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 6, 4)}></span>
                          <a href={this.state.ads.brandAds1.data[4].link} target="_blank">
                            <img className="e-hero-dummy-img" src={this.state.ads.brandAds1.data[4].content} alt="Truekhoj highlight"/>
                          </a>
                        </div>
                      :
                        <div className="e-ad-full-placeholder">
                          <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 6, 4)}></span>
                          Brand banner five 
                        </div>
                    }
                  </div>
                </div>
              </div>

            </div>

            <div className="e-layout-wrap mb-3">
              { 
                this.state.layout && this.state.layout.map( (data, index) => {
                  var tempClass = "e-section-swap-btn e-section-swap-"+index;
                  var tempClassSelect = ".e-section-swap-"+index;

                  var tempInputClass = "e-swap-input"+index;
                  var tempInputClassSelect = ".e-swap-input"+index;


                  var tempStyle = null;
                  if(data.bg_color){
                    var tempStyle = {background: data.bg_color};
                  }


                  return( 
                    <div className="row mt-4" key={index} style={tempStyle}>
                      <div className="col-lg-12">
                        <div className="e-row-input-wrap">
                          <label className="label">Section - {index + 1}: </label>
                          <input className={tempInputClass} onChange={this.sectionSwapInputChange.bind(this, tempClassSelect)}  type="text" required/>
                          <span className={tempClass} onClick={this.sectionSwap.bind(this, index + 1, tempInputClassSelect)}></span>
                        </div>
                        <div className="e-layout-row e-edit-section">
                          <span className="e-close-icon" onClick={this.removeSection.bind(this, index)}></span>
                          {
                            data.header.content ?
                              <div className="bg-white e-demo-element-wrap">
                                <SectionHeadingStyle data={data.header}/>
                              </div>
                            :
                              <div className="e-section-head-placeholder" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#headerModal">
                                + Add Section Header
                              </div>
                          }


                          {
                            data.ads && data.ads.content ?
                              <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index)}></span>
                                {
                                  data.ads.content.indexOf(".mp4") > -1 ?
                                    <VideoPlayer url={data.ads.content} />
                                  :
                                    <img className="e-hero-dummy-img" src={data.ads.content} alt="Truekhoj hero"/>
                                }
                              </div>
                            :
                              <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index)}></span>
                                Section banner advertisement
                              </div>
                          }
                          {
                            data.logo_slider &&  data.logo_slider.data&& data.logo_slider.data.length?
                            <div>
                            <div className="mt-4 mb-3 e-no-p w-100">
                            <div className="row">
                              {
                                data.logo_slider.data.map((item, sliderIndex) => {
                                  return( 
                                    <div className="col-lg-2">
                                      <div className="e-layout-row e-ad-row">
                                        {
                                          this.state.ads && item && item.content ?
                                            <div className="position-relative e-hero-dummy-img-wrap">
                                              <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, sliderIndex, true)}></span>
                                              <a href={item.link} target="_blank">
                                                <img className="e-hero-dummy-img" src={item.content} alt="Truekhoj highlight"/>
                                              </a>
                                            </div>
                                          :
                                            <div className="e-ad-full-placeholder">
                                              <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, sliderIndex, true)}></span>
                                              Logo {sliderIndex + 1}
                                            </div>
                                        }
                                      </div>
                                    </div>

                                  );
                                })
                              }
                              </div>
                              </div>
                            </div>
                            :
                            this.addLogoSlider(index)
                          }
                          {
                            data.data.content ?
                              <div>
                                <div className="mt-3 e-no-p">
                                {
                                    data.data.content && data.data.type === 1?
                                      <CardFourColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color}/>
                                    : data.data.content && data.data.type === 2?
                                      <CardSixColumn data={data.data.content} type={data.data.style.value}/>
                                    : data.data.content && data.data.type === 3?
                                      <CardTwoColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color}/>
                                    : data.data.content && data.data.type === 4?
                                      <CardThreeColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color}/>
                                    :
                                    null
                                  }
                                </div>

                                {
                                  this.state.layout.length == index + 1  && data.data.content.length != 8 && data.data.content.length != 12 && data.data.content.length != 10 ?
                                    <div className="e-section-head-placeholder mt-3" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#listModal">
                                      + Add Category List
                                    </div>
                                  :
                                    null

                                }
                                
                              </div>
                            :
                              <div className="e-section-head-placeholder mt-3" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#listModal">
                                + Add Category List
                              </div>
                          }

                          <div className="text-right">
                            <span className="e-link" data-toggle="modal" data-target="#addBg" onClick={this.selectedIndex.bind(this, index)}>+ Add bg color</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              }

              <div className="row my-4">
                <div className="col-lg-12 text-center">
                  <button className="e-s-btn" type="submit" value="Submit" onClick={this.addSection.bind(this)}>+ Add New Section & Elements</button>
                </div>
              </div>


            </div>
          </div>
        </div>

      {/*Modal*/}
      {
        this.state.categoryList ?
          <HeadingSelectionModal success={this.headingSelectSuccess.bind(this)} data={this.state.categoryList} type={2}/>
        :
          null
      }
      
      {
        this.state.selectedSubCategories ?
          <ListSelectionModal success={this.listSelectSuccess.bind(this)} data={this.state.selectedSubCategories}/>
        :
          null
      }
      
      {
        !this.state.modalRefresh ?
          <UploadDummyAdsModal success={this.adsDummySuccess.bind(this)}/>
        :
          null
      }
      

      <UploadDummyCatBannerModal success={this.catAdsBannerSuccess.bind(this)}/>

      <AddBg success={this.bgColorSuccess.bind(this)}/>

      </div>
      
    );
  }
}

export default OnlineCategoryLayout;
