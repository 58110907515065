/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/

/*Assets*/
import logo from '../../assets/images/logo.svg';
// import profile from '../../assets/images/profile-placeholder.jpg';
import $ from 'jquery';



class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeItem: "",
			adminData: {},
			adminType: 1
		}
	}

	componentWillMount() {
		this.findActiveItem();
		let temp = JSON.parse(localStorage.getItem("trueKhoj_data"))
		this.setState({ adminType: temp.type ? temp.type : 1 });
		// this.setState({adminData: $.parseJSON(localStorage.getItem("u_data"))});
	}

	findActiveItem() {
		var tempUrl = [];
		tempUrl = window.location.href.split("/");
		this.setState({ activeItem: tempUrl[tempUrl.length - 1] });
	}

	navItemClick(item) {
		this.setState({ activeItem: item });
	}

	render() {
		return (
			<div className="e-sidebar">
				<div className="e-sidebar-logo px-4">
					<img src={logo} alt="Truekhoj logo" />
					<div className="d-flex pl-4" style={{ opacity: "0.6" }}>
						<h6 className="pl-4 text-muted font-weight-bold">Super Admin</h6>
					</div>
				</div>
				{
					localStorage.getItem("truekhoj_admin") ?
						<>
							<div className="e-sidebar-list-wrap p-4">

								<span className="d-flex py-3 e-sidebar-header">Local</span>
								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "local-layouts" ?
											<Link className="e-sidebar-layouts e-active" onClick={this.navItemClick.bind(this, "local-layouts")} to={"/local-layouts"}>Page Layouts</Link>
											:
											<Link className="e-sidebar-layouts" onClick={this.navItemClick.bind(this, "local-layouts")} to={"/local-layouts"}>Page Layouts</Link>
									}
								</div>
								{/*<div className="e-sidebar-item pb-4">
              {
                this.state.activeItem === "users" ?
                  <Link className="e-sidebar-users e-active" onClick={this.navItemClick.bind(this, "users")} to="/users">Users</Link>
                :
                  <Link className="e-sidebar-users" onClick={this.navItemClick.bind(this, "users")} to="/users">Users</Link>
              }
            </div>*/}
								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "local-vendors" ?
											<Link className="e-sidebar-users e-active" onClick={this.navItemClick.bind(this, "local-vendors")} to="/local-vendors">Vendors</Link>
											:
											<Link className="e-sidebar-users" onClick={this.navItemClick.bind(this, "local-vendors")} to="/local-vendors">Vendors</Link>
									}
								</div>
								{
									this.state.adminType === 1 ?
										<>
											<div className="e-sidebar-item pb-4">
												{
													this.state.activeItem === "scheduled-ads" ?
														<Link className="e-sidebar-discount e-active" onClick={this.navItemClick.bind(this, "scheduled-ads")} to="/scheduled-ads">Scheduled ads</Link>
														:
														<Link className="e-sidebar-discount" onClick={this.navItemClick.bind(this, "scheduled-ads")} to="/scheduled-ads">Scheduled ads</Link>
												}
											</div>

											<div className="e-sidebar-item pb-4">
												{
													this.state.activeItem === "invoices" ?
														<Link className="e-sidebar-payouts e-active" onClick={this.navItemClick.bind(this, "invoices")} to="/invoices">Invoices</Link>
														:
														<Link className="e-sidebar-payouts" onClick={this.navItemClick.bind(this, "invoices")} to="/invoices">Invoices</Link>
												}
											</div>
										</>
										:
										null
								}


								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "push-notification" ?
											<Link className="e-sidebar-notification e-active" onClick={this.navItemClick.bind(this, "push-notification")} to="/push-notification">Push Notification</Link>
											:
											<Link className="e-sidebar-notification" onClick={this.navItemClick.bind(this, "push-notification")} to="/push-notification">Push Notification</Link>
									}
								</div>

								{/*<div className="e-sidebar-item pb-4">
              {
                this.state.activeItem === "faq-settings" ?
                  <Link className="e-sidebar-faq e-active" onClick={this.navItemClick.bind(this, "faq-settings")} to="/faq-settings">Ads Settings</Link>
                :
                  <Link className="e-sidebar-faq" onClick={this.navItemClick.bind(this, "faq-settings")} to="/faq-settings">Ads Settings</Link>
              }
            </div>*/}
								{
									this.state.adminType === 1 ?
										<>
											<div className="e-sidebar-item pb-4">
												{
													this.state.activeItem === "membership-plans" ?
														<Link className="e-sidebar-plan e-active" onClick={this.navItemClick.bind(this, "membership-plans")} to="/membership-plans">Membership Plans</Link>
														:
														<Link className="e-sidebar-plan" onClick={this.navItemClick.bind(this, "membership-plans")} to="/membership-plans">Membership Plans</Link>
												}
											</div>
											<div className="e-sidebar-item pb-4">
												{
													this.state.activeItem === "ad-plans" ?
														<Link className="e-sidebar-ad-plans e-active" onClick={this.navItemClick.bind(this, "ad-plans")} to="/ad-plans">Advertisement Plans</Link>
														:
														<Link className="e-sidebar-ad-plans" onClick={this.navItemClick.bind(this, "ad-plans")} to="/ad-plans">Advertisement Plans</Link>
												}
											</div>
										</>
										:
										null
								}

								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "category-settings" ?
											<Link className="e-sidebar-payments e-active" onClick={this.navItemClick.bind(this, "category-settings")} to="/category-settings">Category Settings</Link>
											:
											<Link className="e-sidebar-payments" onClick={this.navItemClick.bind(this, "category-settings")} to="/category-settings">Category Settings</Link>
									}
								</div>

								{this.state.adminType != 1 ?
									<>
										<div className="e-sidebar-item pb-4">
											{
												this.state.activeItem === "location-admins" ?
													<Link className="e-sidebar-location-admins e-active" onClick={this.navItemClick.bind(this, "location-admins")} to="/location-admins">Location Admins</Link>
													:
													<Link className="e-sidebar-location-admins" onClick={this.navItemClick.bind(this, "location-admins")} to="/location-admins">Location Admins</Link>
											}
										</div>
										<div className="e-sidebar-item pb-4">
											{
												this.state.activeItem === "page-builder" ?
													<Link className="e-sidebar-layouts e-active" onClick={this.navItemClick.bind(this, "page-builder")} to="/page-builder">Page-builder</Link>
													:
													<Link className="e-sidebar-layouts" onClick={this.navItemClick.bind(this, "page-builder")} to="/page-builder">Page-builder</Link>
											}
										</div>
										<div className="e-sidebar-item pb-4">
											{
												this.state.activeItem === "quotations" ?
													<Link className="e-sidebar-quotation e-active" onClick={this.navItemClick.bind(this, "quotations")} to="/quotations">Quotations</Link>
													:
													<Link className="e-sidebar-quotation" onClick={this.navItemClick.bind(this, "quotations")} to="/quotations">Quotations</Link>
											}
										</div>
									</>
									:
									null
								}

								{
									this.state.adminType === 2 ?
										<div className="e-sidebar-item pb-4">
											{
												this.state.activeItem === "career" ?
													<Link className="e-sidebar-career e-active" onClick={this.navItemClick.bind(this, "career")} to="/career">Career</Link>
													:
													<Link className="e-sidebar-career" onClick={this.navItemClick.bind(this, "career")} to="/career">Career</Link>
											}
										</div>
										:
										null
								}
								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "local-ads" ?
											<Link className="e-sidebar-ad-plans e-active" onClick={this.navItemClick.bind(this, "local-ads")} to="/local-ads">Other Ads</Link>
											:
											<Link className="e-sidebar-ad-plans" onClick={this.navItemClick.bind(this, "local-ads")} to="/local-ads">Other Ads</Link>
									}
								</div>
								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "local-membership-plans" ?
											<Link className="e-sidebar-plan e-active" onClick={this.navItemClick.bind(this, "local-membership-plans")} to="/local-membership-plans">Membership Plans</Link>
											:
											<Link className="e-sidebar-plan" onClick={this.navItemClick.bind(this, "local-membership-plans")} to="/local-membership-plans">Membership Plans</Link>
									}
								</div>
								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "local-popup" ?
											<Link className="e-sidebar-discount e-active" onClick={this.navItemClick.bind(this, "local-popup")} to="/local-popup">Offer Popups</Link>
											:
											<Link className="e-sidebar-discount" onClick={this.navItemClick.bind(this, "local-popup")} to="/local-popup">Offer Popups</Link>
									}
								</div>
								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "online-popup" ?
											<Link className="e-sidebar-discount e-active" onClick={this.navItemClick.bind(this, "user-list")} to="/user-list">Users</Link>
											:
											<Link className="e-sidebar-discount" onClick={this.navItemClick.bind(this, "user-list")} to="/user-list">Users</Link>
									}
								</div>

								<span className="d-flex py-3 e-sidebar-header">Online</span>
								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "online-admins" ?
											<Link className="e-sidebar-location-admins e-active" onClick={this.navItemClick.bind(this, "online-admins")} to="/online-admins">Online Admins</Link>
											:
											<Link className="e-sidebar-location-admins" onClick={this.navItemClick.bind(this, "online-admins")} to="/online-admins">Online Admins</Link>
									}
								</div>
								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "online-layouts" ?
											<Link className="e-sidebar-layouts e-active" onClick={this.navItemClick.bind(this, "online-layouts")} to={"/online-layouts"}>Page Layouts</Link>
											:
											<Link className="e-sidebar-layouts" onClick={this.navItemClick.bind(this, "online-layouts")} to={"/online-layouts"}>Page Layouts</Link>
									}
								</div>
								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "online-vendors" ?
											<Link className="e-sidebar-users e-active" onClick={this.navItemClick.bind(this, "online-vendors")} to="/online-vendors">Vendors</Link>
											:
											<Link className="e-sidebar-users" onClick={this.navItemClick.bind(this, "online-vendors")} to="/online-vendors">Vendors</Link>
									}
								</div>
								{/* <div className="e-sidebar-item pb-4">
              {
                this.state.activeItem === "other-ads" ?
                  <Link className="e-sidebar-ad-plans e-active" onClick={this.navItemClick.bind(this, "other-ads")} to="/other-ads">Other Ads</Link>
                :
                  <Link className="e-sidebar-ad-plans" onClick={this.navItemClick.bind(this, "other-ads")} to="/other-ads">Other Ads</Link>
              }
            </div> */}
								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "online-membership-plans" ?
											<Link className="e-sidebar-plan e-active" onClick={this.navItemClick.bind(this, "online-membership-plans")} to="/online-membership-plans">Membership Plans</Link>
											:
											<Link className="e-sidebar-plan" onClick={this.navItemClick.bind(this, "online-membership-plans")} to="/online-membership-plans">Membership Plans</Link>
									}
								</div>
								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "online-popup" ?
											<Link className="e-sidebar-discount e-active" onClick={this.navItemClick.bind(this, "online-popup")} to="/online-popup">Offer Popups</Link>
											:
											<Link className="e-sidebar-discount" onClick={this.navItemClick.bind(this, "online-popup")} to="/online-popup">Offer Popups</Link>
									}
								</div>
								<span className="d-flex py-3 e-sidebar-header">Support Desk</span>
								<div className="e-sidebar-item pb-4">
									{
										this.state.activeItem === "support" ?
											<Link className="e-sidebar-support e-active" onClick={this.navItemClick.bind(this, "support")} to="/support">Support </Link>
											:
											<Link className="e-sidebar-support" onClick={this.navItemClick.bind(this, "support")} to="/support">Support </Link>
									}
								</div>
							</div>
							
						</>
						: localStorage.getItem("truekhoj_locationadmin") ?
							<>
								<div className="e-sidebar-list-wrap p-4">
									<div className="e-sidebar-item">
									</div>
									<div className="e-sidebar-item pb-4">
										{
											this.state.activeItem === "local-layouts" || this.state.activeItem === "online-layouts" ?
												<Link className="e-sidebar-layouts e-active" onClick={this.navItemClick.bind(this, this.state.adminType === 2 ? "local-layouts" : "online-layouts")} to={this.state.adminType === 2 ? "/local-layouts" : "/online-layouts"}>Page Layouts</Link>
												:
												<Link className="e-sidebar-layouts" onClick={this.navItemClick.bind(this, this.state.adminType === 2 ? "local-layouts" : "online-layouts")} to={this.state.adminType === 2 ? "/local-layouts" : "/online-layouts"}>Page Layouts</Link>
										}
									</div>
									<div className="e-sidebar-item pb-4">
										{
											this.state.activeItem === "vendors" ?
												<Link className="e-sidebar-users e-active" onClick={this.navItemClick.bind(this, "vendors")} to="/vendors">Vendors</Link>
												:
												<Link className="e-sidebar-users" onClick={this.navItemClick.bind(this, "vendors")} to="/vendors">Vendors</Link>
										}
									</div>

									<div className="e-sidebar-item pb-4">
										{
											this.state.activeItem === "scheduled-ads" ?
												<Link className="e-sidebar-discount e-active" onClick={this.navItemClick.bind(this, "scheduled-ads")} to="/scheduled-ads">Scheduled ads</Link>
												:
												<Link className="e-sidebar-discount" onClick={this.navItemClick.bind(this, "scheduled-ads")} to="/scheduled-ads">Scheduled ads</Link>
										}
									</div>

									<div className="e-sidebar-item pb-4">
										{
											this.state.activeItem === "invoices" ?
												<Link className="e-sidebar-payouts e-active" onClick={this.navItemClick.bind(this, "invoices")} to="/invoices">Invoices</Link>
												:
												<Link className="e-sidebar-payouts" onClick={this.navItemClick.bind(this, "invoices")} to="/invoices">Invoices</Link>
										}
									</div>

									<div className="e-sidebar-item pb-4">
										{
											this.state.activeItem === "push-notification" ?
												<Link className="e-sidebar-notification e-active" onClick={this.navItemClick.bind(this, "push-notification")} to="/push-notification">Push Notification</Link>
												:
												<Link className="e-sidebar-notification" onClick={this.navItemClick.bind(this, "push-notification")} to="/push-notification">Push Notification</Link>
										}
									</div>

									<div className="e-sidebar-item pb-4">
										{
											this.state.activeItem === "membership-plans" ?
												<Link className="e-sidebar-plan e-active" onClick={this.navItemClick.bind(this, "membership-plans")} to="/membership-plans">Membership Plans</Link>
												:
												<Link className="e-sidebar-plan" onClick={this.navItemClick.bind(this, "membership-plans")} to="/membership-plans">Membership Plans</Link>
										}
									</div>
									<div className="e-sidebar-item pb-4">
										{
											this.state.activeItem === "ad-plans" ?
												<Link className="e-sidebar-ad-plans e-active" onClick={this.navItemClick.bind(this, "ad-plans")} to="/ad-plans">Advertisement Plans</Link>
												:
												<Link className="e-sidebar-ad-plans" onClick={this.navItemClick.bind(this, "ad-plans")} to="/ad-plans">Advertisement Plans</Link>
										}
									</div>
									<div className="e-sidebar-item pb-4">
										{
											this.state.activeItem === "quotations" ?
												<Link className="e-sidebar-discount e-active" onClick={this.navItemClick.bind(this, "quotations")} to="/quotations">Quotations</Link>
												:
												<Link className="e-sidebar-discount" onClick={this.navItemClick.bind(this, "quotations")} to="/quotations">Quotations</Link>
										}
									</div>

	
									<div className="e-sidebar-item pb-4">
										{
											this.state.activeItem === "career" ?
												<Link className="e-sidebar-career e-active" onClick={this.navItemClick.bind(this, "career")} to="/career">Career</Link>
												:
												<Link className="e-sidebar-career" onClick={this.navItemClick.bind(this, "career")} to="/career">Career</Link>
										}
									</div>

								</div>
							</>
							:
							null
							
				}
			
			</div>
		);
	}
}

export default Sidebar;
