
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import NothingFound from '../../components/other/nothing';
import APILoader from '../../components/other/api-loader';
import Toast from '../../components/common/toast';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
/*Assets*/


class Quotations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 1,
            data: [],
            data: null,
            activeLoading: false,
            error: "",
            modalOn: false
        }
    }

    componentDidMount() {
        this.getQuotations();
        if (localStorage.getItem("truekhoj_locationadmin")) {
            this.setState({ locationAdmin: true });
        }
    }

    getQuotations() {
        this.setState({ apiLoading: true, error: "" });
        if (!localStorage.getItem("truekhoj_locationadmin")) {
            let url = devconfig.admin + "/quotation/list";
            let data = JSON.stringify({
                "location_id": null
            });
            APIPost(url, data).then(function (response) {
                if (response.status === "ok") {
                    this.setState({ data: response.data, apiLoading: false });
                }
                else {
                    this.setState({ apiLoading: false, error: response.message });
                }
            }.bind(this));
        }
        else {
            let url = devconfig.admin + "/quotation/list";
            APIGet(url).then(function (response) {
                if (response.status === "ok") {
                    this.setState({ data: response.data, apiLoading: false });
                }
                else {
                    this.setState({ apiLoading: false, error: response.message });
                }
            }.bind(this));
        }
    }

    getQueries() {
        this.setState({ apiLoading: true, error: "" });
        let url = devconfig.admin + "/enquiry/list";
        APIGet(url).then(function (response) {
            if (response.status === "ok") {
                console.log(response.data);
                this.setState({ queries: response.data, apiLoading: false });
            }
            else {
                this.setState({ apiLoading: false, error: response.message });
            }
        }.bind(this));
    }

    getFeedbacks() {
        this.setState({ apiLoading: true, error: "" });
        let url = devconfig.localAdmin + "/feedback/list";
        APIGet(url).then(function (response) {
            if (response.status === "ok") {
                // console.log(response.data);
                this.setState({ feedbacks: response.data, apiLoading: false });
            }
            else {
                this.setState({ apiLoading: false, error: response.message });
            }
        }.bind(this));
    }

    triggerToast(temp, type) {
        this.setState({ toast: temp, toastType: type })
        setTimeout(function () { this.setState({ toast: null }); }.bind(this), 4000);
    }


    tabItemClick(tempItem) {
        this.setState({ tabIndex: tempItem });
        if (tempItem == 2 && this.state.locationAdmin) {
            this.getQueries();
        }
        else if(tempItem == 2 && !this.state.locationAdmin){
           this.getFeedbacks();
        }
    }

    render() {

        return (
            <>
                <div className="e-main-content e-vendor-table">
                    <h2 className="e-page-head">{this.state.locationAdmin? "Quotations and Queries": "Quotations and Feedbacks"}</h2>
                    <div className="row mb-3">
                        {
                            this.state.locationAdmin ?
                                <div className="col-lg-6 e-vendor-tab-link-wrap pt-4">

                                    {
                                        this.state.tabIndex === 1 ?
                                            <span className="e-active">Quotations</span>
                                            :
                                            <span onClick={this.tabItemClick.bind(this, 1)}>Quotations</span>
                                    }
                                    {
                                        this.state.tabIndex === 2 ?
                                            <span className="e-active">Queries</span>
                                            :
                                            <span onClick={this.tabItemClick.bind(this, 2)}>Queries</span>
                                    }
                                </div>
                                :
                                <div className="col-lg-6 e-vendor-tab-link-wrap pt-4">

                                    {
                                        this.state.tabIndex === 1 ?
                                            <span className="e-active">Quotations</span>
                                            :
                                            <span onClick={this.tabItemClick.bind(this, 1)}>Quotations</span>
                                    }
                                    {
                                        this.state.tabIndex === 2 ?
                                            <span className="e-active">Feedbacks</span>
                                            :
                                            <span onClick={this.tabItemClick.bind(this, 2)}>Feedbacks</span>
                                    }
                                </div>
                        }
                    </div>

                    <div className="row pt-3">
                        {
                            this.state.tabIndex === 1 ?
                                <div className="col-lg-12">
                                    {
                                        this.state.apiLoading ?
                                            <div className="container pt-5 mt-5 text-center">
                                                <APILoader />
                                            </div>
                                            : this.state.data && this.state.data.length ?
                                                <table className="table  e-enquire-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Name</th>
                                                            <th>Phone Number</th>
                                                            <th>Product</th>
                                                            <th>Quantity</th>
                                                            {!this.state.locationAdmin ? <th>Location</th> : null}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.data.map(query => {

                                                                return (
                                                                    <tr>
                                                                        <td>{query.date}</td>
                                                                        <td>{query.name}</td>
                                                                        <td>{query.mobile}</td>
                                                                        <td>{query.item}</td>
                                                                        <td>{query.quantity}</td>
                                                                        {!this.state.locationAdmin ? <td>{query.location? query.location: "--"}</td> : null}
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <div className="pt-5 text-center container">
                                                    <NothingFound data="No Quotations found" />
                                                </div>
                                    }
                                </div>
                                :
                                null
                        }
                        {
                            this.state.tabIndex === 2 && this.state.locationAdmin?
                                <div className="col-lg-12">
                                    {
                                        this.state.apiLoading ?
                                            <div className="container pt-5 mt-5 text-center">
                                                <APILoader />
                                            </div>
                                            : this.state.queries && this.state.queries.length ?
                                                <table className="table  e-enquire-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Name</th>
                                                            <th>Email ID</th>
                                                            <th>Phone Number</th>
                                                            <th>Product</th>
                                                            <th>Vendor</th>
                                                            <th>Varients</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.queries.map(query => {

                                                                return (
                                                                    <tr>
                                                                        <td>{query.date}</td>
                                                                        <td>{query.name}</td>
                                                                        <td>{query.email}</td>
                                                                        <td>{query.mobile}</td>
                                                                        <td>{query.product}</td>
                                                                        <td>{query.vendor_name}</td>
                                                                        <td><span className="e-varient-wrap">{query.variant}</span><span className="e-varient-wrap ml-3">{query.choice}</span></td>
                                                                        <td>{query.status}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <div className="pt-5 text-center container">
                                                    <NothingFound data="No Queries found" />
                                                </div>
                                    }
                                </div>
                                :this.state.tabIndex === 2 && !this.state.locationAdmin?
                                    <div className="col-lg-12">
                                    {
                                        this.state.apiLoading ?
                                            <div className="container pt-5 mt-5 text-center">
                                                <APILoader />
                                            </div>
                                            : this.state.feedbacks && this.state.feedbacks.length ?
                                                <table className="table  e-enquire-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Email ID</th>
                                                            <th>Phone Number</th>
                                                            <th>Subject</th>
                                                            <th>Message</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.feedbacks.map(query => {

                                                                return (
                                                                    <tr>
                                                                        <td>{query.name}</td>
                                                                        <td>{query.email}</td>
                                                                        <td>{query.mobile}</td>
                                                                        <td>{query.subject}</td>
                                                                        <td>{query.message}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <div className="pt-5 text-center container">
                                                    <NothingFound data="No feedbacks found" />
                                                </div>
                                    }
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </>

        );
    }
}

export default Quotations;
