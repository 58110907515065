
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


/*Components*/
import DetailInvoice from '../../../components/modals/invoiceDetailModal';
import APILoader from '../../../components/other/api-loader';
import NothingFound from '../../../components/other/nothing';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';

/*Assets*/



class InvoiceList extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: true,
      data: null,
      pageNumActive: true,
      pageNumLoader: false,
      pageNum: 1
    }
  }

  componentDidMount() {
    this.getData(this.state.pageNum);
  }


  getData(tempId){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1+"/invoice/list";
    var data = JSON.stringify({
      "page_num" : 1
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        if(response.data.length <= 39){
          this.setState({ pageNumActive: false});
        }
        else{
          this.setState({ pageNumActive: true});
        }

        if(this.state.data){
          var tempData = this.state.data;
          tempData.push(response.data);
          this.setState({ data: data, apiLoading: false, pageNumLoader: false});
        }
        else{
          this.setState({ data: response.data, apiLoading: false, pageNumLoader: false});
        }
        
      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }

  loadMore(){
    this.getData(this.state.pageNum + 1);
    this.setState({pageNum: this.state.pageNum + 1, pageNumLoader: true});
  }
  

  render() {
    var data = this.state.data;

    if(this.state.apiLoading){
      return(
        <APILoader/>
      );
    }
    else{
      return(
        <div className="e-main-content e-vendor-table">
          <h2 className="e-page-head">Invoices</h2>
          <div className="row">
            {
              this.state.data && this.state.data.length ?
                <div className="col-lg-12 mt-4">
                  <table className="table">
                    <thead>
                      <tr>  
                        <th>Company</th>
                        <th>Vendor</th>
                        <th>Plan type</th>
                        <th>Plan name</th>
                        <th>Purchased on</th>
                        <th>Total amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data.map((item, index) => {
                          var tempId = "e-invoice-modal-"+index;
                          var tempSelector = "#e-invoice-modal-"+index;
                          return(
                            <tr key={index}>
                              <td>{item.company}</td>
                              <td>{item.vendor_name}</td>
                              <td>{item.type}</td>
                              <td>{item.plan_name}</td>
                              <td>{item.purchased_on}</td>
                              <td>INR {item.total}/-</td>
                              <td><span className="e-link" data-toggle="modal" data-target={tempSelector}>View detail</span></td>
                              <DetailInvoice id={item.id} selector={tempId}/>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                  {
                    this.state.pageNumActive ?
                      <div className="text-center">
                        {
                          this.state.pageNumLoader ?
                            <APILoader/>
                          :
                            <button className="e-s-btn-2" onClick={this.loadMore.bind(this)}>Load More</button>
                        }
                      </div>
                    :
                      null
                  }
                </div>
              :
                <NothingFound data="No invoices found"/>
            }
            
          </div>
        </div>
      );
    }
    // return (
    //   <div className="e-main-content e-vendor-table">
    //     <h2 className="e-page-head">Invoices</h2>
        
    //     <table className="table">
    //       <thead>
    //         <tr>
    //           <th>Vendor</th>
    //           <th>Company</th>
    //           <th>Plan type</th>
    //           <th>Plan mame</th>
    //           <th>Purchased on</th>
    //           <th>Total amount</th>
    //           <th>Action</th>
    //           {/*<th>Total Amount</th>
    //           <th>Pending Payment</th>*/}
    //         </tr>
    //       </thead>
    //       <tbody>
    //         <tr>
    //           <td>Basil PV</td>
    //           <td>Reebok Bangalore Pvt Ltd</td>
    //           <td>Advertisement</td>
    //           <td>Sliver</td>
    //           <td>14-04-2020</td>
    //           <td>INR 499/-</td>
    //           <td><Link data-toggle="modal" data-target="#invoiceDetailModal">View detail</Link></td>
    //         </tr>
    //         <tr>
    //           <td>Sachin Rex</td>
    //           <td>Pulikot credits</td>
    //           <td>Advertisement</td>
    //           <td>Sliver</td>
    //           <td>14-04-2020</td>
    //           <td>INR 499/-</td>
    //           <td><Link data-toggle="modal" data-target="#invoiceDetailModal">View detail</Link></td>
    //         </tr>
    //       </tbody>

    //     </table>

    //   {/*Modals*/}
    //   <DetailInvoice/>

    //   </div>
      
    // );
  }
}

export default InvoiceList;
