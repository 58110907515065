/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../components/formComponents/input';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';



class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            confirmPassword: "",
            errorMessage: "",
            apiLoading: false,
        }
    }

    async signup(e){
        e.preventDefault()
        this.setState({ apiLoading: true, error: ""});
        if(this.state.password===this.state.confirmPassword){
            let url = devconfig.localAdmin +"/auth/signup";
            if(this.props.reset){
                url = devconfig.localAdmin +"/auth/reset-password";
            }
            let data = JSON.stringify({
                "email" : this.state.username.length? this.state.username: this.props.signupData[0],
                "key" : this.props.signupData[1],
                "password" : this.state.confirmPassword
            });
            await APIPost(url, data).then(function(response){
            if(response.status==="ok"){
                window.location.href="/?success=true"
            }
            else{
                this.setState({apiLoading: false, errorMessage: response.message});
            }
            }.bind(this));
        }
        else{
            this.setState({ apiLoading: true, error: "Passwords don't match"});
        }
    }


    render() {
        return (
            <form id="e-login-form">
                <div className="pt-4">
                    <Input label="Username" id="u-name" type="email" defaultValue={this.props.signupData[0]} valueChange={(tempVal) => this.setState({ username: tempVal })} />
                    <Input label="Password" id="u-password" type="password" defaultValue={this.state.password} valueChange={(tempVal) => this.setState({ password: tempVal })} />
                    <Input label="Confirm Password" id="u-confirm" type="password" defaultValue={this.state.confirmPassword} valueChange={(tempVal) => this.setState({ confirmPassword: tempVal })} />
                </div>
                <span className="text-danger">{this.state.errorMessage}</span>
                <div className="d-flex pt-3 justify-content-end align-items-center">
                    {
                        (this.state.username.length || this.props.signupData[0].length) && this.state.password && this.state.confirmPassword && !this.state.apiLoading ?
                            <button className="e-s-btn ml-5" type="submit" form="e-login-form" value="Submit" onClick={this.signup.bind(this)}>{this.props.reset? "Reset Password":"Signup"}</button>
                            :
                            <button className="e-inactive e-s-btn ml-5" type="submit" form="e-login-form" value="Submit" >{this.props.reset? "Reset Password":"Signup"}</button>
                    }
                </div>
            </form>
        );
    }
}

export default SignUp;
