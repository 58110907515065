  
/*Package importing*/
import React, { Component } from 'react';
import {Link} from 'react-router-dom';

/*Components*/
import APILoader from '../../../components/other/api-loader';
import NothingFound from '../../../components/other/nothing';
import AddCategory from '../../../components/modals/addCategoryModal';
import AddSubCategory from '../../../components/modals/addSubCategoryModal';
import AddProduct from '../../../components/modals/AddProduct';
import Toast from '../../../components/common/toast';
import ConfirmModal from '../../../components/modals/confirmModal';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';

/*Assets*/




class CategorySettings extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: false,
      data: null,
      dropdown: 0
    }
  }

  componentDidMount() {
    this.getData();
  }

  addCategory(){

  }


  searchChange(){

  }

  getData(){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1+"/category/list";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({ data: response.data, apiLoading: false});
      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }

  dropdownOpen(tempId){
    if(this.state.dropdown == tempId){
      this.setState({ dropdown: 0 });
    }
    else{
      this.setState({ dropdown: tempId });
    }
    
  }

  removeProduct(tempId){
    var url = devconfig.admin+ '/product/delete';
    var data = JSON.stringify({
      "id": tempId
    })
    APIPost(url,data).then(function(response){
      if(response.status == "ok"){
        window.location.reload();
      }
      else{
        this.triggerToast(response.message, "fail")
      }
    }.bind(this))
  }

  removeSubcategory(tempId){
    var url = devconfig.admin+ '/subcategory/delete';
    var data = JSON.stringify({
      "id": tempId
    })
    APIPost(url,data).then(function(response){
      if(response.status == "ok"){
        window.location.reload();
      }
      else{
        this.triggerToast(response.message, "fail")
      }
    }.bind(this))
  }

  triggerToast(temp, type){
    this.setState({toast: temp , toastType:type})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
  }

  deletePlan(tempId){
    var url = devconfig.admin+ '/category/delete';
    var data = JSON.stringify({
      "id": tempId
    })
    APIPost(url,data).then(function(response){
      if(response.status == "ok"){
        window.location.reload();
      }
      else{
        this.triggerToast(response.message, "fail")
      }
    }.bind(this))
  }

  render() {
    var data = this.state.data;
    if(this.state.apiLoading){
      return(
        <APILoader/>
      );
    }
    else{
      return (

        <div>
          {
            this.state.data ?
              <div className="e-main-content e-category-wrap e-dash-wrap">
                <h2 className="e-page-head">Category Settings</h2>
                <div className="row mb-3">

                  <div className="col-lg-6 offset-6 text-right">
                    <button className="e-s-btn" type="submit" value="Submit" data-toggle="modal" data-target="#addCategoryModal">Add New Category</button> 
                  </div>
                  {/*<div className="col-lg-6">
                    <div className="e-search-wrap text-right">
                      <input className="e-search-input" placeholder="Search with category name" value={this.state.value} type="text" onChange={this.searchChange.bind(this)} required/>
                    </div>
                  </div>*/}
                </div>
                <div className="row">
                  {
                    data.map((item, index) => {
                      var tempId = "e-invoice-modal-"+index;
                      var tempSelector = "#e-invoice-modal-"+index;
                      return(
                        <div className="col-lg-12">
                          <div className="e-list-card mb-3">
                            <div className="row">
                              <div className="col-lg-10">
                                <h5 className="mb-3">{item.category}</h5>
                              </div>
                              <div className="col-lg-2">
                                <span className="e-delete" data-toggle="modal" data-target={"#e-delete-category-modal"+index}></span>
                              </div>
                            </div>
                            

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="e-subcategory-head d-flex justify-content-between">
                                  <h6>Subcategories</h6>
                                  <span className="e-link" data-toggle="modal" data-target={"#addSubCategoryModal"+index}>Add subcategory</span>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              {
                                item.sub_category.map((item1, index) => {
                                  return(
                                    <div className="col-lg-3 mb-3">
                                      <div className="e-subcategory-wrap position-relative">
                                        {
                                          this.state.dropdown == item1.id ?
                                            <span className="e-close" onClick={this.dropdownOpen.bind(this, item1.id)}></span>
                                          :
                                            <span className="e-more" onClick={this.dropdownOpen.bind(this, item1.id)}></span>
                                        }
                                        

                                        {
                                          this.state.dropdown == item1.id ?
                                            <div className="e-more-dropdown">
                                              <span data-toggle="modal"  onClick={(e)=>{ this.setState({ dropdown: 0 }); }} data-target={"#e-add-product"+item1.id}>Add Product</span>
                                              <span onClick={this.removeSubcategory.bind(this, item1.id)}>Remove</span>
                                            </div>
                                          :
                                            null
                                        }
                                        

                                        <h5>{item1.sub_category}</h5>
                                        <h6>Items:</h6>
                                        <div className="e-subcategory-item-wrap">
                                          {
                                            item1.product.map((item2, index) => {
                                              return(
                                                <span className="position-relative e-product-tag">
                                                  {item2.product}
                                                  <span className="e-close" onClick={this.removeProduct.bind(this, item2.id)}></span>
                                                </span>
                                              );
                                            })
                                          }
                                        </div>
                                      </div>
                                      <AddProduct id={"e-add-product"+item1.id} sub_category={item1.id}/>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </div>
                          <AddSubCategory id={"addSubCategoryModal"+index} category_id={item.id}/>
                          <ConfirmModal data="Are you sure want to delete this category? Click confirm to proceed." id={"e-delete-category-modal"+index} success={this.deletePlan.bind(this, item.id)}/>
                        </div>
                      );
                    })
                  }
                </div>

                {/*<div className="e-overlay-category">

                </div>*/}
              </div>
            :
              <div className="e-main-content">
                <h2 className="e-page-head">Category Settings</h2>
                <div className="col-lg-12 text-right">
                  <button className="e-s-btn" type="submit" value="Submit" data-toggle="modal" data-target="#addCategoryModal">Add New Category</button>
                </div>
                <NothingFound data="No categories found"/>
              </div>
          }

          

          <AddCategory/>

          {
            this.state.toast ?
              <Toast data={this.state.toast} type={this.state.toastType}/>
           :
              null
          }
        </div>
      );
    }
  }
}

export default CategorySettings;
