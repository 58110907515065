  
/*Package importing*/
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
/*Components*/
import APILoader from '../../../components/other/api-loader';
import NothingFound from '../../../components/other/nothing';
import AddCategory from '../../../components/modals/addCategoryModal';
import AddSubCategory from '../../../components/modals/addSubCategoryModal';
import AddProduct from '../../../components/modals/AddProduct';
import Toast from '../../../components/common/toast';
import ConfirmModal from '../../../components/modals/confirmModal';
import AddBrand from '../../../components/modals/addBrandModal';
/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';

/*Assets*/




class CategorySettings extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: false,
      data: null,
      dropdown: 0,
      tabIndex: 1,
      query: ''
    }
  }

  componentDidMount() {
    this.getData();
    this.getBrands();
  }

  addCategory(){

  }


  searchChange(){

  }

  getData(){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1+"/department/list";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({data: response.data, apiLoading: false, baseData: [...response.data]});
      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }

  getBrands(){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1+"/brands/list";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({brands: response.data, apiLoading: false});
      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }

  dropdownOpen(tempId){
    if(this.state.dropdown == tempId){
      this.setState({ dropdown: 0 });
    }
    else{
      this.setState({ dropdown: tempId });
    }
    
  }

  removeProduct(tempId){
    var url = devconfig.admin+ '/subcategory/delete';
    var data = JSON.stringify({
      "id": tempId
    })
    APIPost(url,data).then(function(response){
      if(response.status == "ok"){
        window.location.reload();
      }
      else{
        this.triggerToast(response.message, "fail")
      }
    }.bind(this))
  }

  removeSubcategory(tempId){
    var url = devconfig.admin+ '/category/delete';
    var data = JSON.stringify({
      "id": tempId
    })
    APIPost(url,data).then(function(response){
      if(response.status == "ok"){
        window.location.reload();
      }
      else{
        this.triggerToast(response.message, "fail")
      }
    }.bind(this))
  }

  triggerToast(temp, type){
    this.setState({toast: temp , toastType:type})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
  }

  deletePlan(tempId){
    var url = devconfig.admin+ '/department/delete';
    var data = JSON.stringify({
      "id": tempId
    })
    APIPost(url,data).then(function(response){
      if(response.status == "ok"){
        window.location.reload();
      }
      else{
        this.triggerToast(response.message, "fail")
      }
    }.bind(this))
  }

  tabItemClick(tempItem) {
    this.setState({ tabIndex: tempItem });
  }

  keyDownHandler(e){
    if(e.keyCode == 13) this.submitSearch();
  }

  submitSearch(){
    this.setState({apiLoading: true});
    if(this.state.query && this.state.baseData && this.state.baseData.length){
      let query = this.state.query.toLowerCase();
      let base = this.state.baseData;
      let out=[];
      base.forEach(item=>{
        if(item.name && item.name.toLowerCase().includes(query)) out.push(item);
        else if(item.category&& item.category.length){
          item.category.map(element=>{
            if(element.name && element.name.toLowerCase().includes(query)) out.push(item);
          })
        }
      });
      this.setState({data: out});
    }
    else if(this.state.baseData && this.state.baseData.length){
      let base = [...this.state.baseData];
      this.setState({data: base});
    }
    this.setState({apiLoading: false});
  }

  render() {
    var data = this.state.data;
    if(this.state.apiLoading){
      return(
        <APILoader/>
      );
    }
    else{
      return (

        <div>
          <div className="e-main-content e-category-wrap e-dash-wrap">
                <h2 className="e-page-head">Category Settings</h2>
                <div className="row mb-3">
                  <div className="col-lg-12 e-vendor-tab-link-wrap">
                  {
                      this.state.tabIndex === 1 ?
                          <span className="e-active">Categories</span>
                          :
                          <span onClick={this.tabItemClick.bind(this, 1)}>Categories</span>
                  }
                  {
                      this.state.tabIndex === 2 ?
                          <span className="e-active">Brands</span>
                          :
                          <span onClick={this.tabItemClick.bind(this, 2)}>Brands</span>
                  }
                  </div>
                  <div className="col-lg-5 pt-4">
                   {
                     this.state.tabIndex===1?
                     <div className="e-search-wrap e-category-search w-100 position-relative">
                     <input className="e-search-input w-100" placeholder="Search with category or subcategory name" value={this.state.query} type="text" onChange={(e)=>this.setState({query: e.target.value})} onKeyDown={this.keyDownHandler.bind(this)} required/>
                     {
                       this.state.query && this.state.query.length?
                       <div className="e-search-btn position-absolute" onClick={this.submitSearch.bind(this)}></div>
                       :
                       null
                     }
                   </div>
                   :
                   null
                   }
                  </div>
                  <div className="col-lg-6 offset-1 text-right">
                    <button className="e-s-btn" type="submit" value="Submit" onClick={async()=>{if(this.state.tabIndex===2){await this.setState({updateBrand: null}); $("#addBrandModal").modal('show');}else{await this.setState({updateCategory: null, catImg: null}); $("#addCategoryModal").modal('show');}}}>{this.state.tabIndex===1? "Add New Category": "Add New Brand"}</button> 
                  </div>
                  {/*<div className="col-lg-6">
                    <div className="e-search-wrap text-right">
                      <input className="e-search-input" placeholder="Search with category name" value={this.state.value} type="text" onChange={this.searchChange.bind(this)} required/>
                    </div>
                  </div>*/}
                </div>
          {
            this.state.tabIndex===1?
            <>
            {
            this.state.data && this.state.data.length ?
              
                <div className="row">
                  {
                    data.map((item, index) => {
                      var tempId = "e-invoice-modal-"+index;
                      var tempSelector = "#e-invoice-modal-"+index;
                      return(
                        <div className="col-lg-12">
                          <div className="e-list-card mb-3">
                            <div className="row">
                              <div className="col-lg-8">
                                <h5 className="mb-3">{item.name}</h5>
                              </div>
                              <div className="col-lg-4">
                                <div className="container d-flex align-items-center justify-content-end">
                                <span className="e-edit-icon d-flex mr-4" onClick={async()=>{
                                      await this.setState({updateCategory: true, updateCategoryName: item.name, updateCategoryId: item.id, catImg: item.image});
                                      $("#addCategoryModal").modal("show");
                                    }}></span>
                                <span className="e-delete" data-toggle="modal" data-target={"#e-delete-category-modal"+index}></span>
                                </div>
                              </div>
                            </div>
                            

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="e-subcategory-head d-flex justify-content-between">
                                  <h6>Subcategories</h6>
                                  <span className="e-link"
                                   onClick={async()=>{
                                    await this.setState({dropdown: 0, updateSub: null});
                                    $(`#addSubCategoryModal${index}`).modal("show");
                                  }}>Add subcategory</span>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              {
                                item.category.map((item1) => {
                                  return(
                                    <div className="col-lg-3 mb-3">
                                      <div className="e-subcategory-wrap position-relative">
                                        {
                                          this.state.dropdown == item1.id ?
                                            <span className="e-close" onClick={this.dropdownOpen.bind(this, item1.id)}></span>
                                          :
                                            <span className="e-more" onClick={this.dropdownOpen.bind(this, item1.id)}></span>
                                        }
                                        

                                        {
                                          this.state.dropdown == item1.id ?
                                            <div className="e-more-dropdown">
                                              <span
                                              onClick={async()=>{
                                                await this.setState({dropdown: 0, updateSub: true, updateSubName: item1.name, updateSubId: item1.id});
                                                $(`#addSubCategoryModal${index}`).modal("show");
                                              }}>Update</span>
                                              <span onClick={async()=>{
                                                    await this.setState({dropdown: 0, updateProduct: false});
                                                    $(`#e-add-product${item1.id}`).modal("show");
                                                  }}>Add Product</span>
                                              <span onClick={this.removeSubcategory.bind(this, item1.id)}>Remove</span>
                                            </div>
                                          :
                                            null
                                        }
                                        

                                        <h5>{item1.name}</h5>
                                        <h6>Items:</h6>
                                        <div className="e-subcategory-item-wrap">
                                          {
                                            item1.subcategory.map((item2) => {
                                              return(
                                                <span className="position-relative e-product-tag">
                                                  <div className="d-flex align-items-center">
                                                  {item2.name}
                                                  <div className="e-edit-icon e-icon-small mb-1 d-inline-block ml-3 mr-2" onClick={async()=>{
                                                    await this.setState({updateProduct: true, updateProductName: item2.name, updateProductId: item2.id});
                                                    $(`#e-add-product${item1.id}`).modal("show");
                                                  }}></div> 
                                                  </div>
                                                  <span className="e-close" onClick={this.removeProduct.bind(this, item2.id)}></span>
                                                </span>
                                              );
                                            })
                                          }
                                        </div>
                                      </div>
                                      <AddProduct id={"e-add-product"+item1.id} sub_category={item1.id} edit={this.state.updateProduct} name={this.state.updateProductName} productId={this.state.updateProductId} update={(val)=>this.setState({updateProductName: val})} showToast={(message, type)=>this.triggerToast(message, type)}/>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </div>
                          <AddSubCategory id={"addSubCategoryModal"+index} category_id={item.id} edit={this.state.updateSub} name={this.state.updateSubName} subId={this.state.updateSubId} update={(val)=>this.setState({updateSubName: val})} showToast={(message, type)=>this.triggerToast(message, type)}/>
                          <ConfirmModal data="Are you sure want to delete this category? Click confirm to proceed." id={"e-delete-category-modal"+index} success={this.deletePlan.bind(this, item.id)}/>
                        </div>
                      );
                    })
                  }
                </div>
              
            :
            <NothingFound data="No categories found"/>
          }
            </>
            :this.state.tabIndex===2?
            <>
            {
            this.state.brands&&this.state.brands.length ?
              
                <div className="row my-n3 pt-3">
                    
                      {
                            this.state.brands.map(brand=>{

                              return(
                                <div className="col-lg-4 py-2">
                                  <div className="e-list-card">
                                  <div className="d-flex container justify-content-between p-3">
                                    <h5 className="mb-0">{brand.name}</h5>
                                    <span className="e-edit-icon" onClick={async()=>{
                                      await this.setState({updateBrand: true, updateBrandName: brand.name, updateId: brand.id, brandImg: brand.image});
                                      $("#addBrandModal").modal("show");
                                    }}></span>
                                  </div>
                                </div>
                                </div>
                              )
                            })
                          }
          
                </div>
              
            :
            <NothingFound data="No categories found"/>
          }
            </>
            :
            null
          }
          </div>
          

          <AddCategory edit={this.state.updateCategory} 
          categoryName={this.state.updateCategoryName} 
          categoryId={this.state.updateCategoryId} 
          update={(val)=>this.setState({updateCategoryName: val})} 
          showToast={(message, type)=>this.triggerToast(message, type)}
          adImg={this.state.catImg}
          updateImage={val=>this.setState({catImg: val})}
          />
          <AddBrand edit={this.state.updateBrand} 
          brandName={this.state.updateBrandName}
          brandId={this.state.updateId} 
          update={(val)=>this.setState({updateBrandName: val})} 
          showToast={(message, type)=>this.triggerToast(message, type)}
          adImg={this.state.brandImg}
          updateImage={val=>this.setState({brandImg: val})}/>
          {
            this.state.toast ?
              <Toast data={this.state.toast} type={this.state.toastType}/>
           :
              null
          }
        </div>
      );
    }
  }
}

export default CategorySettings;
