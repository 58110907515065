
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/

/*Services*/
import devconfig from '../../../config/config.js';
import APIGet from '../../../services/apiCall/get';
import APIPost from '../../../services/apiCall/post';

/*Assets*/
import HideIcon from '../../../assets/images/hide.png';

class LocalLayoutsList extends Component {
  constructor(props){
    super(props);
    this.state = {
      mainData: null,
      categoryData: null,
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData(){
    var url = devconfig.admin+"/local/page/list";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({mainData: response.data.home_data,categoryData: response.data.category_data, customLayouts: response.data.custom_page_layouts});
      }
    }.bind(this))
  }

  async unhide(id){
    let url = devconfig.admin+"/local/page/hide";
    let data = JSON.stringify({
      "layout_id" : id
    });
    let response = await APIPost(url, data);
    if(response&& response.status==="ok"){
      this.getData();
    }
  }

  render() {
    return (
      <div className="e-main-content e-driver-detail-wrap e-layout-list e-dash-wrap">
        <h2 className="e-page-head">Local Page Layouts</h2>

        {
          this.state.mainData ?
            <div className="row pb-4">
              <div className="col-lg-6">
                <h3 className="mb-4">Main pages</h3>
                {
                  this.state.mainData.map((item, index) => {
                    if(item.name == "Home Page" ){
                      return(
                        <Link className="e-list-card e-card-transition mb-3 " to={"/local-layout-home?id="+item.id} key={index}>
                          {item.name} 
                          {
                            item.hide ?
                            <img src={HideIcon} className='e-hide-icon'/>
                            :
                            null
                          }
                          
                        </Link>
                      );
                    }
                  })
                }

                {
                  this.state.mainData.map((item, index) => {
                    if(item.name == "Product listing page"|| item.id===203 ){
                      return(
                        <Link className="e-list-card e-card-transition mb-3" to={"/local-product?id="+item.id+"hide="+item.hide} key={index}>
                          {"Product listing"}
                          {
                            item.hide ?
                            <img src={HideIcon} className='e-hide-icon'/>
                            :
                            null
                          }
                          
                        </Link>
                      );
                    }
                  })
                }

                {
                  this.state.mainData.map((item, index) => {
                    if(item.id!=203 && item.name != "Home Page"){
                      return(
                        <Link className="e-list-card e-card-transition mb-3" to={"/local-deals?id="+item.id} key={index}>
                          {item.name}
                          {
                            item.hide ?
                            <img src={HideIcon} className='e-hide-icon'/>
                            :
                            null
                          }
                        </Link>
                      );
                    }
                  })
                }


              </div>
            </div>
          :
            null
        }
        
        {
          this.state.categoryData ?
            <div>
              <div className="row">
                <div className="col-lg-6">
                  <h3 className="mb-4">Category</h3>
                </div>
                <div className="col-lg-6">
                  <h3 className="mb-4">Sub category</h3>
                </div>
              </div>

              {
                this.state.categoryData.map((item, index) => {
                  return(
                    <div className="row e-cat-layout-list-wrap" key={index}>
                      <div className="col-lg-6">
                        <Link className="e-list-card e-card-transition mb-3" to={"/local-layout-category?id="+item.id} >
                          {item.name}
                          {
                            item.hide ?
                            <img src={HideIcon} className='e-hide-icon'/>
                            :
                            null
                          }
                        </Link>
                       {
                         item.wholesale_data.active?
                         <Link className="e-list-card e-card-transition mb-3" to={`/local-deals?id=${item.wholesale_data.id}&wholesale=true`}>
                         Wholesale Page
                         {
                            item.hide ?
                            <img src={HideIcon} className='e-hide-icon'/>
                            :
                            null
                          }
                       </Link>
                       :
                       <div className="e-list-card mb-3 e-item-inactive d-flex align-items-center justify-content-between">
                       Wholesale Page
                       <span className="e-unhide-btn" onClick={()=>this.unhide(item.wholesale_data.id)}>Unhide</span>
                     </div>
                       }
                        {
                          item.super_value_store_data.active?
                          <Link className="e-list-card e-card-transition mb-3" to={`/super-value-store?id=${item.super_value_store_data.id}`}>
                          Super value store
                        </Link>
                        :
                        <div className="e-list-card mb-3 e-item-inactive d-flex align-items-center justify-content-between">
                          Super value store
                       <span className="e-unhide-btn" onClick={()=>this.unhide(item.super_value_store_data.id)}>Unhide</span>
                        </div>
                        }
                      </div>

                      <div className="col-lg-6">
                        <div className="row">
                          {
                            item.categorydata.map((subItem, index) => {
                              return(
                                <div className="col-lg-6" key={index}>
                                  <Link className="e-list-card e-card-transition mb-3" to={"/local-layout-subcategory?id="+subItem.id} >
                                    {subItem.name}
                                    {
                                      subItem.hide ?
                                      <img src={HideIcon} className='e-hide-icon'/>
                                      :
                                      null
                                    }
                                  </Link>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                  );
                })
              }

            </div>
          :
            null
        }
        
        {
          this.state.customLayouts && this.state.customLayouts.length?
          <div className="row pt-4">
              <div className="col-lg-6">
                <h3 className="mb-4">Custom Pages</h3>
                {
                  this.state.customLayouts.map((item, index) => {
                      return(
                        <Link className="e-list-card e-card-transition mb-3" to={"/page-builder?id="+item.id} key={index}>
                          {item.name}
                        </Link>
                      );
                  })
                }


              </div>
            </div>
            :
            null
        }
      </div>
      
    );
  }
}

export default LocalLayoutsList;
