/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../components/formComponents/input';
import SignUp from './signup';

/*Services*/
import devconfig from '../../config/config.js';



class LocationAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            errorMessage: "",
            apiLoading: false,
            locationAdmin: false
        }
    }

    buttonClick(e) {
        e.preventDefault();

        this.setState({ apiLoading: true });
        var tempEncodeData = this.state.username.concat(":").concat(this.state.password);
        var encodedData = btoa(tempEncodeData);
        $.ajax({
            type: "GET",
            url: devconfig.locationAdmin + '/auth/login',
            dataType: 'json',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Basic " + encodedData,
            }
        })
            .then(function (response) {
                if (response.status === "ok") {
                    let info = {...response.data, type: 2}
                    localStorage.setItem("trueKhoj_data", JSON.stringify(info));
                    localStorage.setItem("trueKhoj_token", response.access_token);
                    localStorage.setItem("truekhoj_locationadmin", true);

                    window.location.href = '/local-layouts';
                }
            }.bind(this))
            .catch(function (response) {
                this.setState({ errorMessage: response.responseJSON.message, apiLoading: false });
            }.bind(this))

    }

    render() {
        return (
            <>
                {
                    this.props.signup ?
                        <SignUp signupData={this.props.signupData} login={()=>this.props.login()} reset={this.props.reset}/>
                        :
                        <form id="e-login-form">
                            <div className="pt-4">
                                <Input label="Username" id="u-name" type="email" defaultValue={this.state.username} valueChange={(tempVal) => this.setState({ username: tempVal })} />
                                <Input label="Password" id="u-name" type="password" defaultValue={this.state.password} valueChange={(tempVal) => this.setState({ password: tempVal })} />
                            </div>
                            <span className="text-danger">{this.state.errorMessage}</span>
                            <div className="d-flex pt-3 justify-content-end align-items-center">
                                <a className="pt-1 e-login-admin" onClick={() => this.props.admin()}>Admin?</a>
                                {
                                    this.state.username.length && this.state.password && !this.state.apiLoading ?
                                        <button className="e-s-btn ml-5" type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Login</button>
                                        :
                                        <button className="e-inactive e-s-btn ml-5" type="submit" form="e-login-form" value="Submit" >Login</button>
                                }
                            </div>
                        </form>
                }
            </>
        );
    }
}

export default LocationAdmin;
