
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


/*Components*/ 
import Input from '../../../components/formComponents/input.js';
import TextButton from '../../../components/buttons/textButton.js';
import Textarea from '../../../components/formComponents/textarea';
import SelectBox from '../../../components/formComponents/selectBox';
import CheckBox from '../../../components/formComponents/checkbox';
import ConfirmModal from '../../../components/modals/confirmModal';
import Toast from '../../../components/common/toast';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';

/*Assets*/

var durationTypes = {
    1: "Days",
    2: "Months",
    3: "Years"
}


class EditPlan extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: true,
      data: null,
      pageNumActive: true,
      pageNumLoader: false,
      pageNum: 1,
      adTypes: [
        {
          index: 0,
          adType: {value: undefined, label: "Choose type"},
          duration: undefined,
          durationType:  {value: 1, label: "Days"},
          images: false,
          gifs: true,
          videos: false
        }
      ],
      color: "#fff",
      features: [""],
      adType: {value: undefined, label: "Choose type"},
      durationType: {value: 1, label: "Days"}
    }
  }

  getUrlVars(){
      var vars = [], hash;
      var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
      for(var i = 0; i < hashes.length; i++){
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
      }
      return vars;
  }

  getPlanDetails(plan_id){
    var url = devconfig.adminV1+ '/plan/detail';
    var data = JSON.stringify({plan_id: plan_id});
    APIPost(url, data).then(function(response){
      if(response.status == "ok"){
        var data = response.data;
        var adTypes = [];
        var features = data.features
        for (var i = features.length - 1; i >= 0; i--) {
          var adType = features[i];
          var tempType = {
            index: i,
            adType: {value: adType.ads_location_id, label: adType.ads_location},
            duration: adType.validity,
            durationType:  {value: adType.validity_type, label: durationTypes[adType.validity_type]},
            images: adType.ads_content_ids.includes(1),
            gifs: adType.ads_content_ids.includes(2),
            videos: adType.ads_content_ids.includes(3)
          }
          adTypes.push(tempType)
        }


        this.setState({ 
          id: data.id,
          plan_type: data.type_id,
          title: data.name,
          description: data.description,
          price: data.price,
          offerPrice: data.offer_price,
          noOfMonths: data.no_of_pay,
          emi: data.emi_amount,
          color: data.color,
          features: data.additional_info ? data.additional_info : [],
          adTypes: adTypes,

          apiLoading: false,
        });
      }
    }.bind(this))
  }

  componentDidMount() {
    var params = this.getUrlVars();
    if(params.id){
      this.getPlanDetails(params.id); 
    }else{
      window.history.back()
    }
    this.getAdTypes();
  }
  getAdTypes(){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1 + "/ads/location-dropdown";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({ adLocations: response.data});
      }
      else{ 
      }
    }.bind(this))
  }
  valueChangeTitle(value){
    this.setState({
      title: value
    })
  }
  colorChange(color){
    this.setState({
      color: color
    })
  }

  adTypeChange(index, adType){
    var adTypes = this.state.adTypes;
    var filteredItem = adTypes.filter(this.filterAdType.bind(this, index));
    if(filteredItem.length){
      filteredItem = filteredItem[0];
      filteredItem['adType'] = adType;
    }else{
      console.log("Invalid index.")
    }
    adTypes[index] = filteredItem;
    this.setState({adTypes: adTypes});
  }

  durationChange(index, duration){
    var adTypes = this.state.adTypes;
    var filteredItem = adTypes.filter(this.filterAdType.bind(this, index));
    if(filteredItem.length){
      filteredItem = filteredItem[0];
      filteredItem['duration'] = duration;
    }else{
      console.log("Invalid index.")
    }
    adTypes[index] = filteredItem;
    this.setState({adTypes: adTypes});
  }

  durationTypeChange(index, durationType){
    var adTypes = this.state.adTypes;
    var filteredItem = adTypes.filter(this.filterAdType.bind(this, index));
    if(filteredItem.length){
      filteredItem = filteredItem[0];
      filteredItem['durationType'] = durationType;
    }else{
      console.log("Invalid index.")
    }
    adTypes[index] = filteredItem;
    this.setState({adTypes: adTypes});
  }

  priceChange(price){
    this.setState({price: price})
  }

  emiChange(emi){
    this.setState({emi: emi})
  }

  noOfMonthsChange(noOfMonths){
    this.setState({noOfMonths: noOfMonths})
  }

  offerPriceChange(offerPrice){
    this.setState({offerPrice: offerPrice})
  }

  updatePlan(){
    // console.log(this.state);
    this.setState({apiLoading: true});
    var url = devconfig.admin + '/plan/update';
    var data = JSON.stringify({
      "plan_id": this.state.id,
      "name": this.state.title,
      "description": this.state.description,
      "price": this.state.price,
      "offer_price": this.state.offerPrice,
      "type": this.state.plan_type,
      "emi_amount": this.state.emi,
      "no_of_pay": this.state.noOfMonths,
      "features": this.state.features,
      "emi_amount": this.state.emi,
      "color": this.state.color,
      "ad_types": this.state.adTypes
    });
    console.log(data)
    APIPost(url, data).then(function(response){
      if(response.status == "ok"){
        if(this.state.plan_type==2){
          window.location = "/ad-plans"
        }else{
          window.location = "/membership-plans";  
        }
        
      }else{
        this.setState({errorMessage: response.message})
      }
    }.bind(this))
  }

  featureTextChange(index, featureText){
    var features = this.state.features;
    features[index] = featureText
    this.setState({features: features})
  }

  addFeature(){
    var features = this.state.features;
    // if(this.state.featureText){
    //   features.splice(features.length-1, 1);
    //   features.push({text: this.state.featureText, editable: false});
    //   features.push({text: "", editable: true});
    // }else{
    //   alert("Enter features to add more.")
    // }
    
    features.push("");
    this.setState({features: features, featureText: ""})
    // console.log(features)
  }

  filterAdType(index, item){
    // console.log(item, index)
    if(item.index===index){
      return item
    }
  }

  toggleCheckbox(index, type){
    var adTypes = this.state.adTypes;
    var filteredItem = adTypes.filter(this.filterAdType.bind(this, index));
    if(filteredItem.length){
      filteredItem = filteredItem[0];
      if(type==="images"){
        filteredItem.images = !filteredItem.images;
      }else if(type==="videos"){
        filteredItem.videos = !filteredItem.videos;
      }else if(type==="gifs"){
        filteredItem.gifs = !filteredItem.gifs;
      }
      adTypes[index] = filteredItem;
      this.setState({adTypes: adTypes});
    }else{
      console.log("Invalid index.")
    }
      
  }

  addAdType(){
    var adTypes = this.state.adTypes;
    adTypes.push({
      index: adTypes.length,
      adType: this.state.adLocations[0],
      duration: undefined,
      durationType:  {value: 1, label: "Days"},
      images: false,
      gifs: true,
      videos: false
    });
    this.setState({adTypes: adTypes});
  }

  deletePlan(){
    var url = devconfig.admin + '/plan/delete';
    var data = JSON.stringify({
      "plan_id": this.state.id,
    });
    APIPost(url, data).then(function(response){
      if(response.status == "ok"){
        if(this.state.plan_type == 2){
          window.location = "ad-plans"
        }
        else{
          window.location = "membership-plans"
        }
        
      }
      else{
        this.triggerToast(response.message, "fail")
      }
    }.bind(this))
  }

  triggerToast(temp, type){
    this.setState({toast: temp , toastType:type})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
  }


  render() {
    console.log(this.state.adTypes)
    var adLocations = this.state.adLocations 
    var membershipOptions = [
      {value: 1, label: "Days"},
      {value: 2, label: "Months"},
      {value: 3, label: "Years"}
    ] 
    var adOptions = [
      {value: 1, label: "Hours"}
    ] 
    if(this.state.apiLoading){
      return null
    }
    return (
      <div className="e-main-content e-vendor-table e-new-plan">
        <h2 className="e-page-head">Edit {this.state.plan_type == 2 ? "Advertisement" : "Membership"} Plan</h2>
        <div className="row">
          <h2 className="pl-3 mb-4"><Link to="./membership-plans">{this.state.plan_type== 2? "Advertisement" : "Membership"} Plans</Link> > <span>Edit {this.state.plan_type== 2? "Advertisement" : "Membership"} Plan</span></h2>
        </div>
        <div className="row mt-3">
          <div className="col-lg-6">
            <span className="e-delete" data-toggle="modal" data-target="#e-delete-plan"></span>
            <label>Plan Type</label>
            <Input  type="text" className="e-plan-title" placeholder="Type the plan name" defaultValue={this.state.title} value={this.state.title} valueChange={this.valueChangeTitle.bind(this)}/>
          </div> 
        </div>
        <div className="row">
          <div className="col-lg-6">
            <label>Short Description</label>
            <Textarea placeholder="Short Description about plan, benefits" defaultValue={this.state.description} value={this.state.description} className="e-textarea" rows="4" cols="40" valueChange={(tempVal) => this.setState({description: tempVal})} required/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <label>
              Select Advertisement type/location
            </label>
          </div>
        </div>
        {
          this.state.adTypes.map((item, index)=>{
            return(
              <div className="row">

                <div className="col-lg-3">
                  <SelectBox placeholder="Ad Type" defaultValue={item.adType} options={adLocations} isSearchable={true} selectchange={this.adTypeChange.bind(this, item.index)} required/>
                </div>
                <div className="col-lg-3">
                  <Input type="number" className="e-plan-title" placeholder={"No of days/months/year"} defaultValue={item.duration} valueChange={this.durationChange.bind(this, item.index)}/>
                </div>

                <div className="col-lg-2">
                  <SelectBox placeholder="Choose one" defaultValue={this.state.durationType} selectchange={this.durationTypeChange.bind(this, item.index)} options={membershipOptions} required/>
                </div>
                <div className="col-lg-1 e-check-box pt-2">
                  <CheckBox label="Images" checked={item.images} onClick={this.toggleCheckbox.bind(this, item.index, "images")}/>
                </div>
                <div className="col-lg-1 e-check-box pt-2">
                  <CheckBox label="GIFs" checked={item.gifs} onClick={this.toggleCheckbox.bind(this, item.index, "gifs")}/>
                </div>
                <div className="col-lg-1 e-check-box pt-2 pl-0">
                  <CheckBox label="Videos" checked={item.videos} onClick={this.toggleCheckbox.bind(this, item.index, "videos")}/>
                </div>
              </div>
            )
          })
        }
        <div className="row mb-4">
          <div className="col-lg-6">
            <TextButton className="e-t-btn" name="+ Add More Ad Types" handleClick={this.addAdType.bind(this)}/>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <label>
              Plan price details
            </label>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-3">
            <Input  type="number" className="e-plan-title" placeholder="MRP (₹)" defaultValue={this.state.price} value={this.state.price} valueChange={this.priceChange.bind(this)}/>
          </div>
          <div className="col-lg-3">
            <Input  type="number" className="e-plan-title" placeholder="Offer Price (₹)" defaultValue={this.state.offerPrice} value={this.state.offerPrice} valueChange={this.offerPriceChange.bind(this)}/>
          </div>
        </div>
        {
          this.state.plan_type==2? null
          :
            <div className="row ">
              <div className="col-lg-3">
                <Input  type="number" className="e-plan-title" placeholder="EMI Amount(₹)" defaultValue={this.state.emi} value={this.state.emi} valueChange={this.emiChange.bind(this)}/>
              </div>
              <div className="col-lg-3">
                <Input  type="number" className="e-plan-title" placeholder="No of months" defaultValue={this.state.noOfMonths} value={this.state.noOfMonths} valueChange={this.noOfMonthsChange.bind(this)}/>
              </div>
            </div>
        }

        <div className="row">
          <div className="col-lg-12">
            <label>
              Plan Features
            </label>
          </div>
        </div>
        <div className="row">
        {
          this.state.features.map((feature, index)=>{
            return(
              <div className="col-lg-3">
                <Input  type="text" className="e-plan-title" placeholder={"Extra Features " + String(Number(index)+1)} defaultValue={feature} valueChange={this.featureTextChange.bind(this, index)}/>
              </div>
            )
          })
        }
        </div>
        <div className="row mb-4">
          <div className="col-lg-6">
            <TextButton className="e-t-btn" handleClick={this.addFeature.bind(this)} name="+ Add Feature"/>
          </div>
        </div>


        <div className="row">
          <div className="col-lg-12">
            <label>
              Plan Uniq color code
            </label>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-3">
            <Input  type="text" className="e-plan-title" placeholder="Enter a Color Hex Code ( Eg: #000000)" defaultValue={this.state.color} value={this.state.color} valueChange={this.colorChange.bind(this)}/>
          </div>
          <div className="col-lg-1">
           <div className="e-color" style={{backgroundColor: this.state.color}}></div>
          </div>
        </div>
        
        <div className="row mb-4">
          <div className="col-lg-6 pr-0">
          {
            this.state.title && this.state.description && this.state.adTypes.length && this.state.price && this.state.offerPrice &&
            ((this.state.emi && this.state.noOfMonths)||this.state.plan_type==2) && this.state.features.length && this.state.color ?
              <button className="e-p-btn" type="submit" value="Submit" onClick={this.updatePlan.bind(this)}>Update Plan</button>
            :
              <button className="e-p-btn e-inactive" disabled type="submit" value="Submit">Update Plan</button>
          }
          </div>
          
        </div>


        <ConfirmModal data="Are you sure want to delete this plan? Click confirm to proceed." id="e-delete-plan" success={this.deletePlan.bind(this)}/>
        {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
         :
            null
        }
      </div>

    );
  }
}

export default EditPlan;
