/*Package importing*/
import React, { Component } from 'react';
import $, { timers } from 'jquery';

/*Components*/
import SectionHeadingStyle from '../elementsPreview/sectionHeadingStyle';
import SelectBox from '../formComponents/selectBox';
import ColorPicker from '../common/colorPicker';
import ImageUploader from '../../services/upload/imageUploader';
import ToggleSwitch from '../buttons/toggle';
import UploadDummyAdsModal from '../../components/modals/uploadDummyAdsModal';
import RadioButton from '../formComponents/radioButton';

import devconfig from '../../config/config.js';
import APIGet from '../../services/apiCall/get';

/*Assets*/
import ImgPlaceholder from '../../assets/images/img-placeholder.svg';

class ListSelectionModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      colorOne: "",
      colorTwo: "",
      pickerOneID: "colorPick1",
      pickerTwoID: "colorPick2",
      category: null,
      subcategoryOptions: [],
      type: null,
      listType: null,
      images: [],
      imageLoader: [],
      label: [],
      listStyle: {},
      error: "", 
      catType: 2,
      catList: 2,
	//   vendorOptions: [];
	  favStores: {
		data: [
		  { content: null, link: null, vendor: null },
		  { content: null, link: null, vendor: null },
		],
		vendors: [ ]
	  },
    }
  }

  componentDidMount() {
	this.getVendorList();
    if(this.props.data){
      this.setState({subcategoryOptions: this.props.data});
    }
  }

  getVendorList(){
    var url = devconfig.localAdmin+"/vendor/names";
    APIGet(url).then(function(response){
      if(response.status === "ok"){
        if(response.data && response.data.length){
          let temp = []
          const vendors = response.data;
          vendors.forEach(vendor=>{
            temp.push({value: vendor.id, label: vendor.name});
          });
          this.setState({vendorOptions: temp});
        }
      }
    }.bind(this))
  }

  setList(i){
    let j=0
    let temp1 = [];
    let temp2 = [];
    let temp3 = [];
    let type = 0;
    while(j<i){
      temp1.push("");
      temp3.push("");
      temp2.push(false);
      j++;
    }
    if(i==4) type= 1;
    else if(i==6) type= 2;
    else if(i==2) type = 3;
    else if(i==3) type = 4;
    this.setState({images: temp1, imageLoader: temp2, listType: i, label: temp3, type: type, vendorList: false});
  }

  removeImage(i){
    let temp = this.state.images;
    temp[i] = "";
    this.setState({images: temp});
  }

  setImage(i, url){
    console.log(url)
    let temp1 = this.state.images;
    let temp = this.state.imageLoader;
    temp1[i] = url;
    temp[i] = false; 
    this.setState({images: temp1, imageLoader: temp});
  }

  startLoading(i){
    let temp = this.state.imageLoader;
    temp[i] = true; 
    this.setState({imageLoader: temp});
  } 

  setLabel(i, val){
    let temp = this.state.label;
    temp[i] = val;
    this.setState({label: temp});
  }

  // selectListType(tempType, tempClass){
  //   $(".e-demo-list-wrap").removeClass("e-active");
  //   $(tempClass).addClass("e-active");

  //   this.setState({listType: tempType});
  // }

  pickerOneColorChange(tempColor){
    this.setState({colorOne: tempColor});
  }

  pickerTwoColorChange(tempColor){
    this.setState({colorTwo: tempColor});
  }

  // triggerClick(tempItem, tempLoader){
  //   $(tempItem).trigger('click');
  //   this.setState({showUploadLoader: tempLoader});
  // }

  // selectChange = async (subcategory) => {
  //   await this.setState({subcategory: subcategory});
  // }

  selectStyleChange = async (item) => {
    await this.setState({listStyle: item});
  }

  // selectContentOneChange = async (item) => {
  //   await this.setState({subcategoryOne: item});
  // }

  // selectContentTwoChange = async (item) => {
  //   await this.setState({subcategoryTwo: item});
  // }

  // selectContentThreeChange = async (item) => {
  //   await this.setState({subcategoryThree: item});
  // }

  // selectContentFourChange = async (item) => {
  //   await this.setState({subcategoryFour: item});
  // }

  // selectContent2OneChange = async (item) => {
  //   await this.setState({subcategory2One: item});
  // }

  // selectContent2TwoChange = async (item) => {
  //   await this.setState({subcategory2Two: item});
  // }

  // selectContent2ThreeChange = async (item) => {
  //   await this.setState({subcategory2Three: item});
  // }

  // selectContent2FourChange = async (item) => {
  //   await this.setState({subcategory2Four: item});
  // }

  // selectContent2FiveChange = async (item) => {
  //   await this.setState({subcategory2Five: item});
  // }

  // selectContent2SixChange = async (item) => {
  //   await this.setState({subcategory2Six: item});
  // }


  

  submitClick(){
    let tempContent = [];
    this.state.images.forEach((image, i)=>{
      tempContent.push({img: image, title: this.state.label[i].label, id: this.state.label[i].value,category_type: this.state.catType, type: this.state.listType});
    });
   
    console.log(tempArry);
	if(this.state.vendorList) {

		var tempArry = { 
			"vendors": this.state.favStores, 
		  };
	}
	else{
		var tempArry = { 
			"content": tempContent, 
			"type": this.state.type, 
			"style": this.state.listStyle,	
		  };
	}
  console.log('vendor list' , this.state.vendorList);
  console.log('temp type--->', tempArry);
    this.props.success(tempArry, this.state.vendorList);
  }

  // imageUploadBtnClick(tempItem){
  //   $(tempItem).trigger('click');
  // }

  render() {

    const styles = [
      { value: 1, label: 'Style - 1' },
      { value: 2, label: 'Style - 2' },
      { value: 3, label: 'Style - 3' },
      { value: 4, label: 'Style - 4' },
      { value: 5, label: 'Style - 5' },
      { value: 6, label: 'Style - 6' },
      { value: 7, label: 'Style - 7' },
      { value: 8, label: 'Style - 8' },
      { value: 9, label: 'Style - 9' },
      { value: 10, label: 'Style - 10' },
      { value: 11, label: 'Style - 11' },
      { value: 12, label: 'Style - 12' },
      { value: 13, label: 'Style - 13' },
      { value: 14, label: 'Style - 14' },
      { value: 15, label: 'Style - 15' },
      { value: 16, label: 'Style - 16' },
      { value: 17, label: 'Style - 17' },
      { value: 18, label: 'Style - 18' },
      { value: 19, label: 'Style - 19' },
      { value: 20, label: 'Style - 20' },
      { value: 21, label: 'Style - 21' },
      { value: 22, label: 'Style - 22' },
      { value: 23, label: 'Style - 23' },
      { value: 24, label: 'Style - 24' },
      { value: 25, label: 'Style - 25' },
      { value: 26, label: 'Style - 26' },
      { value: 27, label: 'Style - 27' },
      { value: 28, label: 'Style - 28' },
      { value: 29, label: 'Style - 29' },
      { value: 30, label: 'Style - 30' },
      { value: 31, label: 'Style - 31' },
      { value: 32, label: 'Style - 32' },
      { value: 33, label: 'Style - 33' },
      { value: 34, label: 'Style - 34' },
      { value: 35, label: 'Style - 35' },
      { value: 36, label: 'Style - 36' },
      { value: 37, label: 'Style - 37' },
      { value: 38, label: 'Style - 38' },
      { value: 39, label: 'Style - 39' },
    ]

    const styles2 = [...styles];


    var tempIDSelectOne = "#"+this.state.pickerOneID;
    var tempIDSelectTwo = "#"+this.state.pickerTwoID;
    var tempColorOne = { background: this.state.colorOne };
    var tempColorTwo = { background: this.state.colorTwo };


    return (
      <div className="modal fade" id="listModal" tabIndex="-1" role="dialog" aria-labelledby="listModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/*<div className="modal-header">
              <h5 className="modal-title">Choose list type</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>*/}
            <div className="modal-body mt-3">
              <div>
                <h6>Choose the list type</h6>
              </div>
              <div className="e-demo-list-style">
              <div className="row">
                  <div className="col-lg-3">
                    <div className={this.state.listType==2? "e-card-transition e-demo-two-list text-center e-demo-list-wrap e-active": "e-card-transition e-demo-two-list text-center e-demo-list-wrap"} onClick={()=>{ this.setList(2); this.setState({ vendorList: false})}}>
                      <img className="mr-2" src={ImgPlaceholder} alt="Demo slider image"/>
                      <img src={ImgPlaceholder} alt="Demo slider image"/>
                      <p className="mb-0">Two items list</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className={this.state.listType==3? "e-card-transition e-demo-three-list text-center e-demo-list-wrap e-active": "e-card-transition e-demo-three-list text-center e-demo-list-wrap"} onClick={()=>{this.setList(3); this.setState({ vendorList: false}) }}>
                      <img className="mr-2" src={ImgPlaceholder} alt="Demo slider image"/>
                      <img className="mr-2" src={ImgPlaceholder} alt="Demo slider image"/>
                      <img src={ImgPlaceholder} alt="Demo slider image"/>
                      <p className="mb-0">Three items list</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className={this.state.listType==4?"e-card-transition e-demo-four-list text-center e-demo-list-wrap e-active" : "e-card-transition e-demo-four-list text-center e-demo-list-wrap"} onClick={()=> {this.setList(4); this.setState({ vendorList: false}) }}>
                      <img className="mr-2" src={ImgPlaceholder} alt="Demo slider image"/>
                      <img className="mr-2" src={ImgPlaceholder} alt="Demo slider image"/>
                      <img className="mr-2" src={ImgPlaceholder} alt="Demo slider image"/>
                      <img src={ImgPlaceholder} alt="Demo slider image"/>
                      <p className="mb-0">Four items list</p>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <div className={this.state.listType==6? "e-card-transition e-demo-six-list text-center e-demo-list-wrap e-active": "e-card-transition e-demo-six-list text-center e-demo-list-wrap"} onClick={()=>{this.setList(6); this.setState({ vendorList: false})}}>
                      <img className="mr-2" src={ImgPlaceholder} alt="Demo slider image"/>
                      <img className="mr-2" src={ImgPlaceholder} alt="Demo slider image"/>
                      <img className="mr-2" src={ImgPlaceholder} alt="Demo slider image"/>
                      <img className="mr-2" src={ImgPlaceholder} alt="Demo slider image"/>
                      <img className="mr-2" src={ImgPlaceholder} alt="Demo slider image"/>
                      <img src={ImgPlaceholder} alt="Demo slider image"/>
                      <p className="mb-0">Six items list</p>
                    </div>
                  </div>   
				  <div className="col-lg-3 mt-3">
                    <div className={this.state.vendorList ? "e-card-transition e-demo-two-list text-center e-demo-list-wrap e-active": "e-card-transition e-demo-two-list text-center e-demo-list-wrap"} onClick={()=>this.setState({ vendorList: true, listType: null })}>
                      <img className="mr-2" src={ImgPlaceholder} alt="Demo slider image"/>
                      <img src={ImgPlaceholder} alt="Demo slider image"/>
                      <p className="mb-0">Vendor list</p>
                    </div>
                  </div>
                </div>
              </div>
			  {
				this.state.vendorList  ?
				<div className='mt-4 '>
				<h6>Upload product</h6>
			  <div className='row'>
				{
					this.state.favStores.data.map((item, index) => {
					  return (
						  
						  <>
						  {console.log('rendered')}
						  <div className='col-lg-4 e-layout-row e-vendor-placeholder'>
						  <div className="e-layout-row e-ad-row h-100">
							{
							 item.content ?
								<div className="position-relative e-hero-dummy-img-wrap e-vendor-img-wrap"> {console.log('has content')}
								  <span className="e-close-icon"  onClick={()=> { var temp = this.state.favStores; temp.data[index] =  { content: null, link: null, vendor: null} ; this.setState({favStores: temp })  }}></span>
									  <img className="e-hero-dummy-img e-vendor-img" src={item.content} alt="Truekhoj highlight" />
								</div>
								:
								<div className="e-ad-full-placeholder"> {console.log('with out image')}
									  <span className="e-upload" onClick={()=> $('#uploadImage-'+index).trigger('click')}></span>
									  Your favorite {index+1}
								  </div>
							}
							{
							 item.content?
							 <div className="w-100 pt-2 e-vendor-selection">
							 <SelectBox options={this.state.vendorOptions} selectchange={ async (value)=>{var temp = this.state.favStores;  temp.data[index] =  { content: item.content, link: item.link, vendor: value}; temp.vendors.push(value.value); this.setState({ favStores: temp });  await console.log(this.state.favStores) }}/>
						   </div>
						   :
						   null
							}
						  </div>
						  <ImageUploader id={ "uploadImage-"+index } accept={".jpg,.png,.pdf"} index={index} tempKey={`list-image-${index}`} uploadSuccess= { async (tempUrl, tempData) =>{ var temp = this.state.favStores; temp.data[index] =  { content: tempUrl, link: tempUrl, vendor: null} ;  this.setState({ favStores: temp,favImage: tempUrl })  }} uploadStart={() => this.startLoading(index)}/>
						 </div>   
						
						 </>
					  );
					})
				  }
				</div>
					  
					 
		</div>
		:
				  null
			  }
              

              <div className="mt-4">
                <h6>Choose the list style</h6>
              </div>
              <div className="row">
                <div className="e-filter-wrap col-lg-3">
                  {
                    this.state.listType != 6 ?
                      <SelectBox defaultValue={this.state.filterDefault} options={styles} selectchange={this.selectStyleChange} placeholder="Select list style"/>

                    :
                      <SelectBox defaultValue={this.state.filterDefault} options={styles2} selectchange={this.selectStyleChange} placeholder="Select list style"/>
                  }
                </div>
                <div className="col-lg-6 text-left">
                  <a className="e-view-style-link" href="https://vendor.truekhoj.com/assets/images/listing-styles.png" target="_blank">View the styles</a>
                </div>
              </div>

              {
                this.state.listType ?
                  <div>
                    <div className="mt-4 d-flex justify-content-between">
                      <h6>Upload content</h6>
                      {
                        this.props.type === 1 ?
                          <div className='d-flex'>
                              <RadioButton label="Product" checked={this.state.catType === 3 ? true: false} onClick={()=> this.setState({ catType : 3})}/>
                              <RadioButton label="Category" checked={this.state.catType === 2 ? true: false} onClick={()=> this.setState({ catType : 2})}/>
                              <RadioButton label="Sub category" checked={this.state.catType === 1 ? true: false} onClick={()=> this.setState({ catType : 1})}/>
                            </div>
                          :
                          <>
                            {
                              this.props.include_category ?
                              <ToggleSwitch label="Include Category" handleChange={ async (temp)=>{ await this.setState({ catType : temp == false ? 1 : 2}); console.log(this.state.catType)  }}/>
                              :
                                null
                            }
                          </>
                      }
                        
                      
                    </div>
                    <div className="row">
                      {
                        this.state.images.map((image, i)=>(
                          <div className="col-lg-3">
                            <div className="e-subcategory-content text-center">
                              {
                                this.state.imageLoader[i] ?
                                  <div className="e-upload-loader"></div>
                                :
                                  <div>
                                    {
                                      image.length ?
                                        <div className="e-img-upload-content e-img-edit">
                                          <span className="e-close" onClick={() => this.removeImage(i)}></span>
                                          <img src={image} alt="banner"/>
                                        </div>
                                      :
                                        <span className="e-upload-btn mb-3" onClick={()=>$(`#image-uploader-${i}`).trigger("click")}>Upload image</span>
                                    }
                                  </div>

                              }
                              {
                                this.state.catType === 1 ?
                                <SelectBox options={this.props.data? this.props.data: this.state.subcategoryOptions} selectchange={(val)=>{let temp = this.state.label; temp[i] = val; this.setState({label:temp})}} placeholder={this.props.vendors? "Vendor": this.props.explore?"Category" :"Subcategory"}/>
                              : this.state.catType === 2 ?
                                <SelectBox options={this.props.catList? this.props.catList: this.state.subcategoryOptions} selectchange={(val)=>{let temp = this.state.label; temp[i] = val; this.setState({label:temp})}} placeholder={this.props.vendors? "Vendor": this.props.explore?"Category" :"Category"}/>
                              : 
                                <SelectBox options={this.props.productList? this.props.productList: this.state.subcategoryOptions} selectchange={(val)=>{let temp = this.state.label; temp[i] = val; this.setState({label:temp})}} placeholder={this.props.vendors? "Vendor": this.props.explore?"Category" :"Product"}/>
                              }
                            </div>

                            <ImageUploader id={`image-uploader-${i}`} accept={".jpg,.png,.pdf"} index={i} tempKey={`list-image-${i}`} uploadSuccess={(tempUrl, tempData) => this.setImage(i, tempUrl)} uploadStart={() => this.startLoading(i)}/>
                          </div>
                        ))
                      }
                    </div>

                  </div>
                :
                  null
              }
              
              

            </div>
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              {
				
                ((this.state.listType===2 && this.state.images[0] && this.state.images[1] && this.state.label[0] && this.state.label[1])||
                (this.state.listType===3 && this.state.images[0] && this.state.images[1] && this.state.images[2] && this.state.label[0] && this.state.label[1] && this.state.label[2])||
                (this.state.listType===4 && this.state.images[0] && this.state.images[1] && this.state.images[2] && this.state.images[3] && this.state.label[0] && this.state.label[1] && this.state.label[2] && this.state.label[3])||
                (this.state.listType===6 && this.state.images[0] && this.state.images[1] && this.state.images[2] && this.state.label[0] && this.state.label[1] && this.state.label[2] && this.state.images[3] && this.state.images[4] && this.state.images[5] && this.state.label[3] && this.state.label[4] && this.state.label[5]))&&
                this.state.listStyle && this.state.listStyle.value 
				|| this.state.vendorList 
				?
                  <button type="submit" className="e-s-btn" value="Submit" data-dismiss="modal" onClick={this.submitClick.bind(this)}>Submit</button>
                :
                  <button type="submit" className="e-s-btn e-inactive" value="Submit" >Submit</button>
              }
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ListSelectionModal;
