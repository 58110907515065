/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Assets*/

import Sample from '../../assets/images/sample/Banners.jpg';

import banner1 from '../../assets/images/sample/placeholder-1.png';
import banner2 from '../../assets/images/sample/placeholder-2.png';
import banner3 from '../../assets/images/sample/placeholder-3.png';
import banner4 from '../../assets/images/sample/placeholder-4.png';
import banner5 from '../../assets/images/sample/placeholder-5.png';
import banner6 from '../../assets/images/sample/placeholder-6.png';
import banner7 from '../../assets/images/sample/placeholder-7.png';
import banner9 from '../../assets/images/sample/placeholder-9.png';
import noBanner from '../../assets/images/sample/noBanner.png';

class ChooseBannerStyle extends Component {
  constructor(props){
    super(props);
    this.state = {
        chooseStyle: true,
      data: {bannerStyle: {}},
      bannerStyle: {},
      bannerStyles: [
        { id: 10, noOfFiles: 0, name: "No Banner", image: noBanner},
        { id: 1, noOfFiles: 1, name: "Single Column", image: banner1 },
        { id: 2, noOfFiles: 2, name: "Two Column", image: banner2 },
        { id: 3, noOfFiles: 3, name: "Three Column", image: banner3 },
        { id: 4, noOfFiles: 4, name: "Four Column", image: banner4 },
        { id: 5, noOfFiles: 4, name: "Four Images", image: banner5 },
        { id: 6, noOfFiles: 5, name: "Five Images Banner", image: banner6 },
        { id: 7, noOfFiles: 4, name: "Image slider (Fullwidth)", image: banner7 },
        { id: 8, noOfFiles: 6, name: "Logo slider", image: banner4 },
        { id: 9, noOfFiles: 6, name: "2-Column 3-Row", image: banner9 },
      ]
    }
  }
  

  componentDidMount(){
  }


  nextStepClick(){
    const data = this.state.bannerStyle
    this.props.next(data);
    this.modalClose();
  }

  selectStyle(bannerStyle){
    var data = this.state.data;
    data['bannerStyle'] = bannerStyle;
    this.setState({bannerStyle: bannerStyle, data: data})
  }

  modalClose(){
    this.setState({data: {bannerStyle: {}},bannerStyle: {}, chooseStyle: true});
    $("#bannerStyleModal").modal("hide");
  }

  render() {

    return (
        <div className="modal fade e-banner-modal" id="bannerStyleModal" tabIndex="-1" role="dialog" aria-labelledby="bannerStyleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered"  role="document">
            <div className="modal-content e-ofer">
              <div className="modal-header">
                <h6>{this.state.chooseStyle? "Choose banner style": "Add Content"}</h6>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.setState({data: {bannerStyle: {}},bannerStyle: {}, chooseStyle: true})}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                    <div className="e-edit-ad">
                        <div className="row">
                        {
                        this.state.bannerStyles.map((bannerStyle, index)=>(
                            <div className="col-lg-4 pt-3" onClick={this.selectStyle.bind(this, bannerStyle)}>
                                <div className={bannerStyle.id===this.state.bannerStyle.id ? "e-bannerwrap-active text-center" : "e-bannerwrap text-center"}>
                                <img src={bannerStyle.image} className="e-bannerstyle1" alt="Offer Banner"/>
                                <p className="pt-3">{bannerStyle.name}</p>
                                </div>
                            </div>
                            ))
                        }
                        
                        <div className="col-lg-12 mt-3 e-sample-link">
                        Click to view <a href={Sample} target="_blank">Banners Style</a>
                        </div> 
                        </div>   
                        <div className="row pt-4">
                        <div className="col-lg-12">
                            {
                            this.state.bannerStyle.id?
                                <button className="e-s-btn" onClick={this.nextStepClick.bind(this)} type="submit" value="Submit">Next</button>
                            :
                                <button className="e-s-btn e-inactive" type="submit" value="Submit">Next</button>
                            }
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                </div>
  
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      );
  }
}

export default ChooseBannerStyle;
