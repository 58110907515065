/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import SectionHeadingStyle from '../elementsPreview/sectionHeadingStyle';
import SelectBox from '../formComponents/selectBox';
import ColorPicker from '../common/colorPicker';
import Input from '../../components/formComponents/input';

/*Assets*/

class AddBg extends Component {
  constructor(props){
    super(props);
    this.state = {
      colorOne: "",
      colorTwo: "",
      pickerOneID: "colorPick1",
      pickerTwoID: "colorPick2",
      category: null,
      styleType: null,
      categoryOptions: [],
      textHeading: ""
    }
  }


  componentDidMount() {
    
  }
  pickerOneColorChange(tempColor){
    this.setState({colorOne: tempColor});
  }


  triggerClick(tempItem, tempLoader){
    $(tempItem).trigger('click');
    this.setState({showUploadLoader: tempLoader});
  }


  submit(){
    this.props.success(this.state.colorOne);
  }

  render() {

    var tempColorOne = { background: this.state.colorOne };


    return (
      <div className="modal fade" id="addBg" tabIndex="-1" role="dialog" aria-labelledby="addBgLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/*<div className="modal-header">
              <h5 className="modal-title">Choose header style & content</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>*/}
            <div className="modal-body">

              <div className="mb-4">
                <h6>Choose the colors</h6>
              </div>
              <div className="row e-upload-wrap">
                <div className="col-lg-4">
                  <div className="e-color-picker-wrap e-card-transition" onClick={this.triggerClick.bind(this, "#e-bg-colorPick1-section")}>
                    {
                      this.state.colorOne.length ?
                        <span className="e-color-preview-wrap">
                          <span className="e-color-preview" style={tempColorOne}></span><br/>
                          {this.state.colorOne}
                        </span>
                      :
                        <span>Choose heading font color</span>
                    }
                    <ColorPicker id={"e-bg-colorPick1-section"} colorChange={this.pickerOneColorChange.bind(this)}/> 
                  </div>
                </div>
              </div>
              

            </div>
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              {
                this.state.colorOne.length  ?
                  <button type="submit" className="e-s-btn" value="Submit" data-dismiss="modal" onClick={this.submit.bind(this)}>Submit</button>
                :
                  <button type="submit" className="e-s-btn e-inactive" value="Submit" >Submit</button>
              }
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddBg;
