/*Package importing*/
/*Package importing*/
import React, { Component } from 'react';
import ReactPlayer from 'react-player';

class VideoPlayer extends Component {
  constructor(props){
    super(props);
    this.state = {
      value: "",
      playing: false,
    }
  }

  
  
  handleOnReady = () => setTimeout(() => this.setState({ playing: true }), 100);



  render() {
    return (
      <React.Fragment>
        <ReactPlayer className="e-video-player" volume={0} loop={true} onReady={this.handleOnReady} playing={this.state.playing} url={this.props.url} />
      </React.Fragment>
    );
  }
}

export default VideoPlayer;
 