
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import NothingFound from '../../../components/other/nothing';
import APILoader from '../../../components/other/api-loader';
import ActiveVendor from './active-vendor-list';
import PendingVendor from './pending-list';
import BlockedVendor from './blocekd-list';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';

/*Assets*/


class VendorsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 1,
      apiLoading: false,
      pageNum: 1,
      data: null
    }
  }

  componentDidMount() {
    this.findAdminType();
  }

  findAdminType() {
    let temp = JSON.parse(localStorage.getItem("trueKhoj_data"))
    this.setState({ adminType: temp.type ? temp.type : 1 });
  }

  tabItemClick(tempItem) {
    this.setState({ tabIndex: tempItem });
  }

  render() {

    return (
      <div className="e-main-content e-vendor-table">
        <h2 className="e-page-head">Vendors</h2>
        {
          !this.props.online ?
            <div className="row mb-4">
              <div className="col-lg-6 e-vendor-tab-link-wrap">

                {
                  this.state.tabIndex === 1 ?
                    <span className="e-active">Active Vendors</span>
                    :
                    <span onClick={this.tabItemClick.bind(this, 1)}>Active Vendors</span>
                }

                {
                  this.state.tabIndex === 2 ?
                    <span className="e-active">Pending Request</span>
                    :
                    <span onClick={this.tabItemClick.bind(this, 2)}>Pending Request</span>
                }

                {
                  this.state.adminType > 1 && localStorage.getItem("truekhoj_locationadmin") ?
                    <>
                      {
                        this.state.tabIndex === 3 ?
                          <span className="e-active">Blocked Vendors</span>
                          :
                          <span onClick={this.tabItemClick.bind(this, 3)}>Blocked Vendors</span>
                      }
                    </>
                    :
                    null
                }

              </div>
            </div>

            :
            null
        }
        <div>
          {
            this.state.dataLoading ?
              <div>
                <APILoader />
              </div>
              :
              <div>
                {
                  this.state.tabIndex === 1 ?
                    <ActiveVendor adminType={this.state.adminType} online={this.props.online}/>
                    :
                    null
                }

                {
                  this.state.tabIndex === 2 ?
                    <PendingVendor adminType={this.state.adminType} />
                    :
                    null
                }

                {
                  this.state.tabIndex === 3 ?
                    <BlockedVendor />
                    :
                    null
                }
              </div>
          }
        </div>


      </div>

    );
  }
}

export default VendorsList;
