/*Package importing*/
import React, { Component } from 'react';

/*Components*/


/*Assets*/
import sampleBanner from '../../assets/images/sample-banner.jpg';


class ViewAdContentModal extends Component {
  constructor(props){
    super(props);
    this.state = {
       
    }
  }

  componentDidMount() {
    
  }

  


  render() {

    return (
      <div className="modal fade e-ad-detail-wrap" id="adDetailModal" tabIndex="-1" role="dialog" aria-labelledby="adDetailModalLabel" aria-hidden="true">
        <div className="modal-dialog"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-1">
              <h5 className="mb-3">Advertisement content</h5>
              
              <div className="row">
              {
                this.props.data.map((item, index)=>{
                  if(item.image){
                    return(
                      <div className="col-lg-6">
                        <div className="e-ads-content-wrap">
                          <img src={item.image} alt="ads banner"/>
                          <p className="mb-0 mt-3"><b>Redirect URL:</b></p>
                          <p className="mb-0">{item.website_link}</p>
                        </div>
                      </div>
                    );
                  }
                  else{
                    return(
                      null
                    );  
                  }
                })
              } 
              </div>

            </div>
            <div className="modal-footer">
            
              {/*

              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              {
                this.state.category && this.state.styleType && this.state.colorOne.length && this.state.colorTwo.length  ?
                  <button type="submit" className="e-s-btn" value="Submit" data-dismiss="modal" onClick={this.submit.bind(this)}>Submit</button>
                :
                  <button type="submit" className="e-s-btn e-inactive" value="Submit" >Submit</button>
              }

            */}
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewAdContentModal;
