
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


/*Components*/
// import DetailInvoice from '../../../components/modals/invoiceDetailModal';
// import CareerDetail from '../../../components/modals/careerDetailModal';
// import APILoader from '../../../components/other/api-loader';
// import NothingFound from '../../../components/other/nothing';
// import ConfirmationModal from '../../../components/modals/confirmationModal';
// import CareerModal from '../../../components/modals/newCareerModal';
import PlanList from '../../../components/plan/planList.js';


/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';

/*Assets*/



class MembershipList extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: true,
      data: [],
      pageNumActive: true,
      pageNumLoader: false,
      pageNum: 1
    }
  }

  getPlans(){
    this.setState({ apiLoading: true});
    var url = devconfig.adminV1 + "/plan/list";
    var data = JSON.stringify({
      type: 2
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        console.log("Category list");
        console.log(response.data)
        this.setState({ data: response.data, apiLoading: false});
      }
      else{
        this.setState({ apiLoading: false });
      }
    }.bind(this))
  }

  componentDidMount() {
    this.getPlans();
  }




  render() {

    return (
      <div className="e-main-content e-vendor-table">
        <h2 className="e-page-head">Advertisement Plans</h2>
        <div className="row mb-4">
          <div className="col-lg-6 offset-6 text-right">
            <Link className="" to="/new-ad-plan">
              <button className="e-p-btn" type="submit" value="Submit">Add New Plan</button>
            </Link>

          </div>
        </div>
        <PlanList data={this.state.data} type={2}/>
      </div>

    );
  }
}

export default MembershipList;
