import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*Components*/
import HeadingSelectionModal from '../../../components/modals/headingSelectionModal';
import ListSelectionModal from '../../../components/modals/listSelectionModal';
import EditHeadingModal from '../../../components/modals/editHeadingModal';

import SectionHeadingStyle from '../../../components/elementsPreview/sectionHeadingStyle';
import Input from '../../../components/formComponents/input';
import CardFourColumn from '../../../components/cards/column-4.js';
import CardSixColumn from '../../../components/cards/column-6.js';
import CardTwoColumn from '../../../components/cards/column-2';
import CardThreeColumn from '../../../components/cards/column-3';
import UploadDummyAdsModal from '../../../components/modals/uploadDummyAdsModal';
import UploadDummyCatBannerModal from '../../../components/modals/uploadDummyCategorybanner';
import AddMenu from '../../../components/modals/addMenu';
import AddBg from '../../../components/modals/addBg';
import VideoPlayer from '../../../components/common/video';
import SelectBox from '../../../components/formComponents/selectBox';
import ChooseComponent from '../../../components/modals/chooseComponent';
import ChooseBannerStyle from '../../../components/modals/chooseComponent';
import AddPagename from '../../../components/modals/addPagename';
import CopyBtn from '../../../assets/images/copyIcon.png';

/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import GetURLVar from '../../../services/getParams/get-url-var';

class PageBuilder extends Component {
  notify = e => toast(e);
    constructor(props) {
        super(props);
        this.state = {
            layout: [
               { "header": { "content": null, "type": null, "font_color": null, "bg_color": null, category: null},
                "ads": {banner_type: 1, banners:[{ "content": null , "image": null, "stlye": null}]},
                "favStore":  { },
                "data": { "content": null , "image": null, "stlye": null},
                "page_id": 1,
                "id": null}
            ],
            page_id: 1,
            deletedAds: []
        }
    }

    componentDidMount(){
      this.getProductList();
        this.getCategoryData();
        GetURLVar().then(function(query){
          if(query.id){
            this.setState({id: query.id});
            this.getData(query.id);
          }
        }.bind(this))
    }
    getProductList = () =>{
      var tempArry = [];
      let url = devconfig.adminV2+'/product/list';
      let data = JSON.stringify({
        "sub_category_ids" : [],
      })
      APIPost(url, data).then((response)=>{
        response.data.map(function(item, index){
          var tempItem = {value: item.id, label: item.product}
          tempArry.push(tempItem);
          });
       this.setState({ productList: tempArry});
      })
    }

    getData(tempId){
      var url = devconfig.adminV1+"/local/page/detail";
      var data = JSON.stringify({
        "layout_id" : tempId
      })
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          if(response.data.page_data.layout){
            this.setState({layout: response.data.page_data.layout, pageName: response.data.name});
          }
          else if(response.data.current_page_data.layout){
            this.setState({layout: response.data.current_page_data.layout, pageName: response.data.name});
          }
        }
      }.bind(this))
    }

    updatePageLayout(){
      this.setState({apiLoading: true}); 
      let pageName = this.state.pageName 
      let temp = [];
      this.state.layout.forEach((element,i)=>{
        if(element.name) {
          let x = element;
          x.name =`${pageName}-Section-${i+1}`;
          temp.push(x);
        }
        else temp.push({...element, name: `${pageName}-Section-${i+1}`});
      })
      var tempArry = {
        layout: temp
      }
      var url = devconfig.adminV1+"/local/page/update";
      var data = JSON.stringify({
        "layout_id" : this.state.id,
        "page_data" : tempArry,
        "deleted_ids": this.state.deletedAds && this.state.deletedAds.length? this.state.deletedAds: null
      })
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          this.setState({apiLoading: false});
        }
      }.bind(this))
    }

    adDummyTypeClick(tempItem, tempIndex){
        this.setState({adDummyType: tempItem, adIndex: tempIndex});
    }

    addSection(bannerStyle){
        let tempId = null;
        let tempArry=[];
        if(bannerStyle.id<10){
          let ad = null;
          ad = {banner_type: bannerStyle.id, banners:[]}
          for(let i=0;i<bannerStyle.noOfFiles;i++){
              ad.banners.push({ "content": null, "link": null})
          }
          tempArry =  {
            "header": { "content": null, "type": null, "font_color": null, "bg_color": null, category: null},
            "ads": ad,
            "data": { "content": null , "image": null, "stlye": null},
            "page_id": this.state.page_id,
            "id": tempId
          }
        }
        else{
          tempArry =  {
            "header": { "content": null, "type": null, "font_color": null, "bg_color": null, category: null},
            "data": { "content": null , "image": null, "stlye": null},
            "page_id": this.state.page_id
          }
        }
    
        var tempLayout = this.state.layout;
        tempLayout.push(tempArry);
        this.setState({layout: tempLayout});
      }
    
      removeSection(tempIndex){
        var tempLayout = this.state.layout;
        let deleted = this.state.deletedAds;
        if(tempLayout[tempIndex].ads && tempLayout[tempIndex].id) {
          deleted.push(tempLayout[tempIndex].id)
        }
        tempLayout.splice(tempIndex,1);
        this.setState({layout: tempLayout, selectedSubCategories: null, deletedAds: deleted});
      }
    
      headingSelectSuccess(tempItem){
        var tempArry = this.state.layout;
        tempArry[this.state.selectItemIndex].header = tempItem;
        this.setState({layout: tempArry});
    
        if(tempItem.category){
          this.getChildCategory(tempItem.category);
        }
        else{
          this.getChildCategory();
        }
      }

      getChildCategory(tempItem){
        var url = devconfig.localAdmin + "/category/list";
        var tempSubCat = [];
        if (tempItem) {
          tempSubCat = [tempItem];
        }
    
        var data = JSON.stringify({
          "department_ids": tempSubCat,
        })
        APIPost(url, data).then(function (response) {
          if (response.status === "ok") {
            var tempArry = [];
            response.data.map(function (item, index) {
              var tempItem = { value: item.id, label: item.name }
              tempArry.push(tempItem);
            });
    
            this.setState({ selectedSubCategories: tempArry, categoryData: response.data });
          }
        }.bind(this))
      }

      async listSelectSuccess(tempItem , tempType){
        console.log('tempItem', tempItem);
        console.log('temptype', tempType);
        var tempArry = this.state.layout;
        console.log(tempArry);
        if(tempType){
          if(tempArry[this.state.selectItemIndex].favStore && tempArry[this.state.selectItemIndex].favStore.data){
            var temp = tempArry[this.state.selectItemIndex].favStore.data.concat(tempItem.vendors.data);
            var tVendor =  tempArry[this.state.selectItemIndex].favStore.vendors.concat(tempItem.vendors.vendors);
            tempArry[this.state.selectItemIndex].favStore.data = temp;
            tempArry[this.state.selectItemIndex].favStore.vendors = tVendor;
            console.log('temp data ->', temp);
            console.log('temp vendor-->', tVendor);
            console.log('favStoer-->', tempArry);
          }else{
            tempArry[this.state.selectItemIndex].favStore = tempItem.vendors ;
          }
        }
        else{
          if(tempArry[this.state.selectItemIndex].data && tempArry[this.state.selectItemIndex].data.content){
            var temp = tempArry[this.state.selectItemIndex].data.content.concat(tempItem.content);
            tempArry[this.state.selectItemIndex].data.content = temp;
            tempArry[this.state.selectItemIndex].data.style = tempItem.style;
            tempArry[this.state.selectItemIndex].data.type = tempItem.type;
          }
          else{
            tempArry[this.state.selectItemIndex].data = tempItem;
          }
        }
       
     
        
     await    this.setState({layout: tempArry});
     await console.log(this.state.layout);
      }
    
      selectedIndex(tempindex){
        this.setState({selectItemIndex: tempindex});
      }
    
    
      sectionSwapInputChange(tempClass, e){
        $(".e-section-swap-btn").removeClass("e-active");
        $(tempClass).addClass("e-active");
      }
      
      sectionSwapInputReset(){
        $(".e-section-swap-btn").removeClass("e-active");
      }
    
      getCategoryData(){
        var url = devconfig.adminV1+"/department/list";
        APIGet(url).then(function(response){
          if(response.status === "ok"){
            var tempArry = [];
            var subcat = [];
            response.data.map(function(item, index){
              var tempItem = {value: item.id, label: item.name}
              tempArry.push(tempItem);
              item.category.forEach(function (sub, index) {
                var tempItem = { value: sub.id, label: sub.name }
                subcat.push(tempItem);
              });
            });
    
            this.setState({categoryList: tempArry, categoryData: response.data, selectedSubCategories: subcat});
          }
        }.bind(this))
      }
    
      setVendor(vendor, i){
        let temp = this.state.ads;
        if(temp.ad_section_1.vendors){
          temp.ad_section_1.vendors[i] = vendor;
        }
        else{
          temp.ad_section_1 = {...temp.ad_section_1, vendors: [null, null, null, null]};
          temp.ad_section_1.vendors[i] = vendor;
        }
        this.setState({ads: temp});
      }

      sectionSwap(tempCurrectIndex, tempClass){
        var tempArry = this.state.layout;
        var tempSwapValue = $(tempClass).val();
    
        if(tempSwapValue > tempArry.length || tempSwapValue <= 0){
          this.notify("Swap index must be between 0 to "+tempArry.length)
        }
        else{
          if(tempArry[tempSwapValue-1]){
            var b = tempArry[tempCurrectIndex-1];
            tempArry[tempCurrectIndex-1] = tempArry[tempSwapValue-1];
            tempArry[tempSwapValue-1] = b;
            $(".e-section-swap-btn").removeClass("e-active");
            $(tempClass).val("")
          }
          else{
            this.notify("Please verify your input data")
          }
          this.setState({layout: tempArry});
        }
      }
      
      catBannerIndexClick(tempIndex, bannerIndex){
        this.setState({catBannerAdIndex: tempIndex, bannerIndex: bannerIndex});
      }
    
      catAdsBannerSuccess(tempImg, tempLink){
        var tempArry = this.state.layout;
        var tempItem = { "content": tempImg, "link": tempLink};
    
        tempArry[this.state.catBannerAdIndex].ads.banners[this.state.bannerIndex] = { "content": tempImg, "link": tempLink};
        this.setState({layout: tempArry});
      }
    
      removeCatAds(tempIndex, bannerIndex){
        var tempArry = this.state.layout;
        tempArry[tempIndex].ads.banners[bannerIndex] = { "content": null, "link": null};
        this.setState({layout: tempArry});
      }

      async adsDummySuccess(tempUrl, tempLink){
        let layout = this.state.layout;
    
        await this.setState({modalRefresh: true});
    
        switch (this.state.adDummyType) {
          case 10: {
            layout[this.state.selectItemIndex].header = { content: tempUrl, link: tempLink };
            this.setState({ layout: layout });
            break;
          }
        }
    
        this.setState({ modalRefresh: false});
      }
    
      adDummyTypeClick(tempItem, tempIndex){
        this.setState({adDummyType: tempItem, adIndex: tempIndex});
      }
    
      removeDummyAds(tempItem, tempIndex){
        let layout = this.state.layout;
    
        switch (tempItem) {
          case 10:{
            layout[tempIndex].header = { "content": null, "type": null, "font_color": null, "bg_color": null, category: null };
            this.setState({ layout: layout });
            break;
          }
        }
      }

    createPage(pageName){
      this.setState({apiLoading: true});
      let temp = [];
      this.state.layout.forEach((element, i)=>{
        temp.push({...element, name: `${pageName}-Section-${i+1}`});
      })
      let data = JSON.stringify({
        "page_name" : pageName,
        "page_data": {
          layout: temp,
          name: pageName
        }
      });
      let url = devconfig.admin+"/local/page/create";
      APIPost(url, data).then(function(response){
        if(response.status === "ok"){
          this.setState({apiLoading: false});
          window.location.href="/local-layouts"
        }
      }.bind(this))  
    }
	bgColorSuccess(tempColor) {
		if (this.state.adBackground) {
		  let ad = this.state.ads;
		  if (ad.summerCollection.bg_color) ad.summerCollection.bg_color = tempColor;
		  else ad.summerCollection = { ...this.state.ads.summerCollection, bg_color: tempColor };
		  this.setState({ ads: ad });
		}
		else {
		  var tempArry = this.state.layout;
		  tempArry[this.state.selectItemIndex].bg_color = tempColor;
		  this.setState({ layout: tempArry });
		}
	  }

    render() {
        return (
            <>
            <div className="e-main-content e-driver-detail-wrap e-dash-wrap">
                <h2 className="e-page-head">Page Builder</h2>
                {
                    this.state.updateApiLoading ?
                        <button className="e-p-btn e-nav-button e-inactive e-loading e-page-layout-btn"></button>
                        :
                        <span>
                            {
                                this.state.layout && this.state.layout.length && !this.state.apiLoading ?
                                    <button className="e-p-btn e-nav-button" onClick={()=>{if(this.state.id){this.updatePageLayout();} else{$("#addPageName").modal("show");}}}>{this.state.id? "Update":"Create Page"}</button>
                                    :
                                    <button className="e-p-btn e-nav-button e-inactive">{this.state.id? "Update":"Create Page"}</button>
                            }
                        </span>
                }
                {
                  this.state.id ?
                  <div className='row'>
                  <div className='col-lg-12 '>
                  <div className="e-store-link-wrapper d-flex align-items-center mr-auto">
                       <p className="mb-0 pr-2">{`https://truekhoj.com/local/custom?id=${this.state.id}`}</p>
                      <img src={CopyBtn} alt="copy-icon" className="e-link-copy-icon" onClick={()=>{ navigator.clipboard.writeText("https://truekhoj.com/local/custom?id="+this.state.id); this.setState({ copied: true}); setTimeout(()=>{ this.setState({ copied: false}) },2000)}}/>
					  {
                    this.state.copied ?
                      <span className='e-copy-text-alert'> copied</span>
                    :
                      null
                  }
                   </div>
                 
                  </div>
                </div>
                :
                null
                }
                
                <div className="row mt-3">
                    <div className="col-lg-12">
                        <div className="e-layout-wrap my-3">
                        { 
                            this.state.layout && this.state.layout.map( (data, index) => {
                              {console.log(data)}
                            var tempClass = "e-section-swap-btn e-section-swap-"+index;
                            var tempClassSelect = ".e-section-swap-"+index;

                            var tempInputClass = "e-swap-input"+index;
                            var tempInputClassSelect = ".e-swap-input"+index;

                            var tempStyle = null;
                            if(data.bg_color){
                                var tempStyle = {background: data.bg_color};
                            }
                            

                            return( 
                                <div className="row mt-4" key={index} style={tempStyle}>
                                <div className="col-lg-12">
                                    <div className="e-row-input-wrap">
                                    <label className="label">Section - {index + 1}: </label>
                                    <input className={tempInputClass} onChange={this.sectionSwapInputChange.bind(this, tempClassSelect)}  type="text" required/>
                                    <span className={tempClass} onClick={this.sectionSwap.bind(this, index + 1, tempInputClassSelect)}></span>
                                    </div>
                                    <div className="e-layout-row e-edit-section">
                                    <span className="e-close-icon" onClick={this.removeSection.bind(this, index)}></span>
                                    {
                                        data.header.content && !data.header.link ?
                                        <div className="bg-white e-demo-element-wrap">
                                            <SectionHeadingStyle data={data.header} />
                                        </div>
                                        : data.header.content && data.header.link ?
                                        <div className="row">
                                            <div className="col-lg-5"></div>
                                            <div className="col-lg-2">
                                            <div className="position-relative e-hero-dummy-img-wrap">
                                                <span className="e-close-icon" onClick={this.removeDummyAds.bind(this, 10, index)}></span>
                                                <a href={data.header.link} target="_blank">
                                                <img className="e-hero-dummy-img" src={data.header.content} alt="Truekhoj highlight" />
                                                </a>
                                            </div>
                                            </div>
                                            <div className="col-lg-5"></div>
                                        </div>
                                        : this.state.selectItemIndex === index ?
                                        <div className="row">
                                            <div className="col-lg-5">
                                            <div className="e-upload-layout-header h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6", cursor: "pointer" }} data-toggle="modal" data-target="#headerModal">
                                                Choose Header style and content
                                            </div>
                                            </div>
                                            <div className="col-lg-2">
                                            <span className="e-upload-layout-header border-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ opacity: ".6" }}>OR</span>
                                            </div>
                                            <div className="col-lg-5">
                                            <div className="e-upload-layout-header">
                                                <div className="e-ad-full-placeholder position-relative">
                                                <span className="e-upload" data-toggle="modal" data-target="#uploadDummyAdsModal" onClick={this.adDummyTypeClick.bind(this, 10)}></span>
                                                Upload Header Image
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="e-section-head-placeholder" onClick={this.selectedIndex.bind(this, index)} >
                                            + Add Section Header
                                        </div>
                                    }

                                    
                                    {
                                        data.ads && data.ads.banner_type===1?
                                        <>
                                        {data.ads && data.ads.banners && data.ads.banners[0].content ?
                                        <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                            <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, 0)}></span>
                                            <img className="e-hero-dummy-img" src={data.ads.banners[0].content} alt="Truekhoj highlight"/>
                                        </div>
                                        :
                                        <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                            <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, 0)}></span>
                                            Section banner advertisement
                                        </div>}
                                        </>
                                        :
                                        data.ads && data.ads.banner_type===2?
                                        <div className="row">
                                        {
                                          data.ads.banners.map((banner, i)=>(
                                              <div className="col-lg-6">
                                              {banner.content ?
                                                <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                                    <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, i)}></span>
                                                    <img className="e-hero-dummy-img" src={banner.content} alt="Truekhoj highlight"/>
                                                </div>
                                                :
                                                <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                                    <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, i)}></span>
                                                    Section banner advertisement
                                                </div>}
                                                </div>
                                          ))  
                                        }
                                        </div>
                                        :
                                        data.ads && data.ads.banner_type===3?
                                        <div className="row">
                                        {
                                          data.ads.banners.map((banner, i)=>(
                                              <div className="col-lg-4">
                                              {banner.content ?
                                                <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                                    <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, i)}></span>
                                                    <img className="e-hero-dummy-img" src={banner.content} alt="Truekhoj highlight"/>
                                                </div>
                                                :
                                                <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                                    <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, i)}></span>
                                                    Section banner advertisement
                                                </div>}
                                                </div>
                                          ))  
                                        }
                                        </div>
                                        :
                                        data.ads && data.ads.banner_type===4?
                                        <div className="row">
                                        {
                                          data.ads.banners.map((banner, i)=>(
                                              <div className="col-lg-3">
                                              {banner.content ?
                                                <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                                    <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, i)}></span>
                                                    <img className="e-hero-dummy-img" src={banner.content} alt="Truekhoj highlight"/>
                                                </div>
                                                :
                                                <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                                    <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, i)}></span>
                                                    Section banner advertisement
                                                </div>}
                                                </div>
                                          ))  
                                        }
                                        </div>
                                        :
                                        data.ads && data.ads.banner_type===5?
                                        <div className="row">
                                        <div className="col-lg-6 d-flex">
                                            {data.ads.banners && data.ads.banners[0].content ?
                                            <div className="mt-3 position-relative e-hero-dummy-img-wrap d-flex w-100 align-items-center justify-content-center" style={{backgroundColor:"#ffffff"}}>
                                                <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, 0)}></span>
                                                <img className="e-hero-dummy-img e-large" src={data.ads.banners[0].content} alt="Truekhoj highlight"/>
                                            </div>
                                            :
                                            <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative d-flex w-100 align-items-center justify-content-center" style={{backgroundPositionY:" 110px"}}>
                                                <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, 0)}></span>
                                                Section banner advertisement
                                            </div>}
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row">
                                            {
                                                data.ads.banners.map((banner, i)=>{ 
                                                    if(i!=0){
                                                        return(
                                                            <div className={i>1?"col-lg-12 pt-3": "col-lg-12"}>
                                                            {banner.content ?
                                                            <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                                                <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, i)}></span>
                                                                <img className="e-hero-dummy-img e-small" src={banner.content} alt="Truekhoj highlight"/>
                                                            </div>
                                                            :
                                                            <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                                                <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, i)}></span>
                                                                Section banner advertisement
                                                            </div>}
                                                            </div>
                                                        )
                                                    }
                                                })  
                                                }
                                            </div>
                                        </div>
                                        </div>
                                        :
                                        data.ads && data.ads.banner_type===6?
                                        <div className="row">
                                        <div className="col-lg-4">
                                            <div className="row">
                                            {
                                            data.ads.banners.map((banner, i)=>{
                                                if(i<2){
                                                    return(
                                                        <div className={i>0?"col-lg-12 pt-3": "col-lg-12"}>
                                                        {banner.content ?
                                                        <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                                            <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, i)}></span>
                                                            <img className="e-hero-dummy-img" src={banner.content} alt="Truekhoj highlight"/>
                                                        </div>
                                                        :
                                                        <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                                            <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, i)}></span>
                                                            Section banner advertisement
                                                        </div>}
                                                        </div>
                                                    )
                                                }
                                            })  
                                            }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 d-flex">
                                        {data.ads.banners && data.ads.banners[2].content ?
                                            <div className="mt-3 position-relative e-hero-dummy-img-wrap d-flex w-100 align-items-center justify-content-center" style={{backgroundColor:"#ffffff"}}>
                                                <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, 2)}></span>
                                                <img className="e-hero-dummy-img e-large" src={data.ads.banners[2].content} alt="Truekhoj highlight"/>
                                            </div>
                                            :
                                            <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative d-flex w-100 align-items-center justify-content-center" style={{backgroundPositionY:"70px"}}>
                                                <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, 2)}></span>
                                                Section banner advertisement
                                            </div>}
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="row">
                                            {
                                            data.ads.banners.map((banner, i)=>{
                                                if(i>2){
                                                    return(
                                                        <div className={i>3?"col-lg-12 pt-3": "col-lg-12"}>
                                                        {banner.content ?
                                                        <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                                            <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, i)}></span>
                                                            <img className="e-hero-dummy-img " src={banner.content} alt="Truekhoj highlight"/>
                                                        </div>
                                                        :
                                                        <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                                            <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, i)}></span>
                                                            Section banner advertisement
                                                        </div>}
                                                        </div>
                                                    )
                                                }
                                            })  
                                            }
                                            </div>
                                        </div>
                                        </div>
                                        :
                                        data.ads && data.ads.banner_type===7?
                                        <div className="row">
                                        {
                                          data.ads.banners.map((banner, i)=>(
                                              <div className="col-lg-6">
                                              {banner.content ?
                                                <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                                    <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, i)}></span>
                                                    <img className="e-hero-dummy-img" src={banner.content} alt="Truekhoj highlight"/>
                                                </div>
                                                :
                                                <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                                    <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, i)}></span>
                                                    Section banner advertisement
                                                </div>}
                                                </div>
                                          ))  
                                        }
                                        </div>
                                        :
                                        data.ads && data.ads.banner_type===8?
                                        <div className="row">
                                        {
                                          data.ads.banners.map((banner, i)=>(
                                              <div className="col-lg-2">
                                              {banner.content ?
                                                <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                                    <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, i)}></span>
                                                    <img className="e-hero-dummy-img" src={banner.content} alt="Truekhoj highlight"/>
                                                </div>
                                                :
                                                <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                                    <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, i)}></span>
                                                    Logo-{i+1}
                                                </div>}
                                                </div>
                                          ))  
                                        }
                                        </div>
                                        :
                                        data.ads && data.ads.banner_type===9?
                                        <div className="row">
                                        {
                                          data.ads.banners.map((banner, i)=>(
                                              <div className="col-lg-6 py-2">
                                              {banner.content ?
                                                <div className="mt-3 position-relative e-hero-dummy-img-wrap">
                                                    <span className="e-close-icon" onClick={this.removeCatAds.bind(this, index, i)}></span>
                                                    <img className="e-hero-dummy-img" src={banner.content} alt="Truekhoj highlight"/>
                                                </div>
                                                :
                                                <div className="mt-3 e-ad-section-full-placeholder e-cat-banner-placeholder position-relative" >
                                                    <span className="e-upload" data-toggle="modal" data-target="#uploadDummyCatBannerModal" onClick={this.catBannerIndexClick.bind(this, index, i)}></span>
                                                    Section banner advertisement
                                                </div>}
                                                </div>
                                          ))  
                                        }
                                        </div>
                                        :
                                        null
                                    }

                                  {
                                      data.favStore && data.favStore.data && data.favStore.data.length?
                                      <div className='row'>
                                      
                                      {
                                        data.favStore.data.map((item, key)=>{
                                          
                                        return(
                                          <div className='col-lg-6 mt-3'>
                                            <div className='card e-favitem-card'>
                                                <img  src={item.content}/>
                                                <p className='mt-3 text-center '>Vendor - {item.vendor.label}</p>
                                            </div>
                                          </div>
                                         )
                                      })
                                    }
                                      </div>
                                      :
                                      null
                                    }
                                    {
                                        data.data.content ?
                                        <div>
                                            <div className="mt-3 e-no-p">
                                            {
                                                data.data.content && data.data.type === 1?
                                                <CardFourColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color}/>
                                                : data.data.content && data.data.type === 2?
                                                <CardSixColumn data={data.data.content} type={data.data.style.value}/>
                                                : data.data.content && data.data.type === 3?
                                                <CardTwoColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color}/>
                                                : data.data.content && data.data.type === 4?
                                                <CardThreeColumn data={data.data.content} type={data.data.style.value} bg_color={data.header.bg_color}/>
                                                :
                                                null
                                            }
                                            </div>

                                            {
                                            this.state.layout.length == index + 1  && data.data.content.length != 8 && data.data.content.length != 12 && data.data.content.length != 10 ?
                                                <div className="e-section-head-placeholder mt-3" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#listModal">
                                                + Add Category List
                                                </div>
                                            :
                                                null

                                            }
                                            
                                        </div>
                                        :
                                        <div className="e-section-head-placeholder mt-3" onClick={this.selectedIndex.bind(this, index)} data-toggle="modal" data-target="#listModal">
                                            + Add Category List
                                        </div>
                                    }
                                    <div className="text-right">
                                        <span className="e-link" data-toggle="modal" data-target="#addBg" onClick={this.selectedIndex.bind(this, index)}>+ Add bg color</span>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            );
                            })
                        }
                            <div className="row my-4">
                                {
                                    this.state.layout.length <= 200 ?
                                        <div className="col-lg-12 text-center">
                                            <button className="e-s-btn" type="submit" value="Submit" data-toggle="modal" data-target="#bannerStyleModal">+ Add New Section & Elements</button>
                                        </div>
                                        :
                                        null
                                }
                            </div>

                        </div>
                    </div>
                </div>
                {
                    this.state.layout.length>2?
                    <div className="row mt-4">
                        <div className="col-lg-12">
                        {
                        this.state.updateApiLoading ?
                            <button className="e-p-btn e-nav-button e-inactive e-loading e-page-layout-btn"></button>
                            :
                            <>
                                {
                                    this.state.layout && this.state.layout.length &&!this.state.apiLoading?
                                        <button className="e-p-btn" onClick={()=>{if(this.state.id){this.updatePageLayout();} else{$("#addPageName").modal("show");}}}>{this.state.id? "Update":"Create Page"}</button>
                                        :
                                        <button className="e-p-btn e-inactive">{this.state.id? "Update":"Create Page"}</button>
                                }
                            </>
                        }
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            <ChooseBannerStyle next={(bannerStyle)=>this.addSection(bannerStyle)}/>
            <UploadDummyCatBannerModal success={this.catAdsBannerSuccess.bind(this)}/>
            <AddPagename createPage={(pageName)=>this.createPage(pageName)}/>
            {
                this.state.categoryList ?
                <HeadingSelectionModal success={this.headingSelectSuccess.bind(this)} data={this.state.categoryList} type={2}/>
                :
                null
            }
            
            {
          this.state.selectedSubCategories  && this.state.categoryList && this.state.productList?
            <ListSelectionModal success={this.listSelectSuccess.bind(this)} productList={this.state.productList} catList={this.state.categoryList} include_category={true} data={this.state.selectedSubCategories} type={1} />
            :
            null
        }
            {
                !this.state.modalRefresh ?
                <UploadDummyAdsModal success={this.state.headerImage?this.sectionHeadingChange.bind(this): this.adsDummySuccess.bind(this)}/>
                :
                null
            }
			 <AddBg success={this.bgColorSuccess.bind(this)} />
            </>
        )
    }
}

export default PageBuilder;