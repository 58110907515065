
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Assets*/

class NewCardFourColumnTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 1,

        }
    }

    componentDidMount() {
        this.setState({ type: this.props.type });
    }

    render() {



        return (
            <div className="w-100">
                {
                    this.props.data && this.props.data.length ?
                        <div className="row">
                            <div className="col-8">
                                <div className="row my-n2">
                                    {
                                        this.props.data.map((data, index) => {
                                            if (index < 3) {
                                                return (
                                                    <div className={index===0? "col-12 py-2 h-100": "col-6 py-2 h-100"}>
                                                        <div className={index===0? "e-new-col-img-wrapper-5 e-new-col-wrapper-small-5 position-relative":"e-new-col-img-wrapper-5 e-new-col-wrapper-med-5 position-relative"}>
                                                            <img className="e-new-col-img position-absolute" src={data.img} alt="ads banner" />
                                                            <div className="position-absolute e-new-col-label">{data.title}</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="row my-n2">
                                    {
                                        this.props.data.map((data, index) => {
                                            if (index > 2) {
                                                return (
                                                    <div className="col-12 py-2 h-100">
                                                        <div className="e-new-col-img-wrapper-5 position-relative">
                                                            <img className="e-new-col-img position-absolute" src={data.img} alt="ads banner" />
                                                            <div className="position-absolute e-new-col-label">{data.title}</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        );
    }
}

export default NewCardFourColumnTwo;
