
/*Package importing*/
import React, { Component } from 'react';
import VendorLocalInvoices from './vendor-local-invoice';
import VendorOnlineInvoices from './vendor-online-invoice';

class VendorInvoices extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: true,
      data: null,
      pageNumActive: true,
      pageNumLoader: false,
      pageNum: 1
    }
  }

  componentDidMount() {
  }


  render() {
    return(
      <>
      {
        this.props.local?
        <VendorLocalInvoices id={this.props.id}/>
        :
        <VendorOnlineInvoices id={this.props.id}/>
      }
      </>
    )
  }
}

export default VendorInvoices;
