/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../../components/formComponents/input';
import Textarea from '../../../components/formComponents/textarea';
import SelectBox from '../../../components/formComponents/selectBox';
import APIPost from '../../../services/apiCall/post';
import devconfig from '../../../config/config.js';
import LazyLoaderGif from '../../../components/common/lazyloader';
import ImageUploader from '../../../services/upload/imageUploader';

/*Assets*/
import Delete from '../../../assets/images/delete-icon.svg';
class SendNotification extends Component {
  constructor(props){
    super(props);
    this.state = {
      title: "",
      message: "",
      apiLoading: false,
      errorMessage: "",
      success: false,
    }
  }

  componentDidMount() {

  }

  sendClick(){
    this.setState({apiLoading: true});
    var url = devconfig.adminV1+"/notification/sent-all";
    var data = JSON.stringify({
      "title" : this.state.title,
      "message" : this.state.message,
      "user_type" : this.state.user_type,
     "image_url" : this.state.image
    })
    APIPost(url, data).then(function(response){
      if(response.status == "ok"){
        this.setState({
          apiLoading: false, 
          success: true,
          title: "",
          message: "",
          image: null,
          user_type: null
        });
        setTimeout(function() {
            this.setState({ success: false });
          }.bind(this), 1000
        );
      }
      else{
        this.setState({errorMessage: response.message, apiLoading: false});
      }
    }.bind(this))
  }

  render() {
  
    const options = [
      {value: 1, label : 'User'},
      {value: 2, label: 'Vendor'}
    ]
    return (
      <div className="e-main-content e-invites-wrap">
        <h2 className="e-page-head">Send Notification</h2>
        <div className="row">
          <div className="col-lg-6">
            <div className="bg-white p-3">
              <div className="e-notify-wrap">
                {
                  this.state.image  ?
                      <div  className='e-img-preview'>
                        <img src={this.state.image}/>
                       <span className='e-delete position-absolute' onClick={()=> this.setState({ image: null })}></span> 
                      </div>
                  :
                    null
                }
                {
                  this.state.uploading ?
                  <div className='e-img-preview'>
                    <div className="e-upload-loader"></div>
                  </div>
                  :
                    null
                }
                {
                  !this.state.uploading && !this.state.image ?
                    <div className='mt-5 pb-5'>
                    <span className='e-image-push-image' onClick={()=> $('#push-image').trigger('click')}>
                    </span>
                    <ImageUploader id="push-image" accept={".jpg,.png,.pdf"}  uploadSuccess= { async (tempUrl, tempData) =>{ this.setState({ image: tempUrl, uploading: false })  }} uploadStart={() => this.setState({ uploading:  true})}/>
                    </div>
                  :
                    null
                }
                
                  <SelectBox  options={options} className="mt-5" selectchange={(temp)=> this.setState({ user_type: temp.value})} placeholder="Select user type"/>
         
                {
                  this.state.success ?
                    <Input label="Title " id="n-title" type="text" defaultValue={this.state.title} valueChange={(tempVal) => this.setState({title: tempVal})} />
                  :
                    <Input label="Title" id="n-title" type="text" defaultValue={this.state.title} valueChange={(tempVal) => this.setState({title: tempVal})} />
                }
                
                {
                  this.state.success ?
                    <Textarea label="Message" placeholder="Message" id="n-mesage" defaultValue={this.state.message} valueChange={(tempVal) => this.setState({message: tempVal})} />
                  :
                    <Textarea label="Message" placeholder="Message" id="n-mesage" defaultValue={this.state.message} valueChange={(tempVal) => this.setState({message: tempVal})} />
                }
                
                
                {
                  this.state.errorMessage.length ?
                    <div className="text-danger pb-4">{this.state.errorMessage}</div>
                  :
                    null
                }

                {
                  this.state.success ?
                    <div className="text-success pb-4">Notification has been sent successfully</div>
                  :
                    null
                }

                {
                  this.state.apiLoading ?
                    <span className="e-api-loader"></span>
                  :
                    <span>
                      {
                        this.state.message.length && this.state.title.length && !this.state.apiLoading && this.state.user_type && this.state.image ?
                          <button className="e-s-btn" type="submit" form="e-login-form" value="Submit" onClick={this.sendClick.bind(this)}>Send</button>
                        :
                          <button className="e-inactive e-s-btn" type="submit" form="e-login-form" value="Submit">Send</button>
                      }
                    </span>
                }  
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SendNotification;
