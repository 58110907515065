import React from 'react';

import UserList from './userList';
import VendorList from './vendorList';

export default class extends React.Component{
    constructor(props){
        super(props);
        this.state={
            tabIndex: 1,
        }
    }

    render(){
        return(
            <div className="e-main-content e-driver-detail-wrap e-dash-wrap">
                <h2 className="e-page-head">Support </h2>
                <div className="row mb-4">
                    <div className="col-lg-6 e-vendor-tab-link-wrap pt-4">
                        {
                        this.state.tabIndex === 1 ?
                            <span className="e-active">User</span>
                            :
                            <span onClick={()=> this.setState({tabIndex: 1 })}>User</span>
                        }
                        {
                        this.state.tabIndex === 2 ?
                            <span className="e-active">Vendor</span>
                            :
                            <span onClick={()=> this.setState({ tabIndex: 2 })}>Vendor</span>
                        }
                    </div>
                </div>
                {
                    this.state.tabIndex === 1  ?
                        <UserList/>
                    :
                        <VendorList/>
                }
            </div>
        )
    }
}