/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

/*Components*/
// import Inputs from '../../components/formComponents/inputs.js';
import SelectBox from '../../components/formComponents/selectBox';
import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/textarea';



/*Assets*/
import sampleBanner from '../../assets/images/sample-banner.jpg';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';



class CareerModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      designation: "",
      location: "",
      description: "",
      vacancy: ""

    }
  }
   selectChange = async (category) => {
    await this.setState({category: category});
  }

   componentDidMount() {
    // this.getData();
  }
 createCareer(e){
   e.preventDefault();
    this.setState({apiLoading: true});
    var url = devconfig.adminV2+ '/career/create';
    var data = JSON.stringify({
      "designation": this.state.designation,
      "location": this.state.location,
      "description": this.state.description,
      "vacancy": this.state.vacancy
    })
    APIPost(url,data).then(function(response){
      if(response.status == "ok"){
        // console.log(response.data);
        this.props.getData();
        $("#newCareerModal").modal('hide');
        // this.setState({ data: response.data,apiLoading: false});
      }
    }.bind(this))
  }
  valueChangeDesignation(value){
    this.setState({designation: value});
  }
  valueChangeLocation(value){
    this.setState({location: value});
  }
   valueChangeDescription(value){
    this.setState({description: value});
  }
   valueChangeVacancy(value){
    this.setState({Vacancy: value});
  }


  render() {
     const categoryBy = [
      { value: 1, label: 'Last 1 week' },
      { value: 2, label: 'Last 1 month' },
      { value: 3, label: 'Last 3 months' },
    ]
    return (
      <div className="modal fade e-ad-detail-wrap e-ofer-modal" id="newCareerModal" tabIndex="-1" role="dialog" aria-labelledby="newCareerModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content e-career-modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h6 className="e-modal-header e-add-career-heading">Add Career</h6>
              </div>
            </div>
             <div className="row mt-3">
                <div className="col-lg-12">
                  <h6 className="e-career-header">Designation:</h6>
                  <Input id={this.props.id} value="" type="text" className="e-add-career" valueChange={this.valueChangeDesignation.bind(this)}/>
                  {/*<Input id="e-name" tsype="text" defaultValue={this.state.name} valueChange={(tempVal) => this.setState({name: tempVal})} />*/}
                </div>

                <div className="col-lg-12">
                  <h6 className="e-career-header">Location:</h6>
                  <Input id={this.props.id} value="" type="text" className="e-add-career" valueChange={this.valueChangeLocation.bind(this)}/>
                </div>

                <div className="col-lg-12">
                  <h6 className="e-career-header">No. of Vacancy:</h6>
                  <Input id={this.props.id} value="" type="number" className="e-add-career" valueChange={this.valueChangeVacancy.bind(this)}/>
                </div>

                 <div className="col-lg-12">
                  <h6 className="e-career-header">Description:</h6>
                  <CKEditor
                    editor={ ClassicEditor }
                    // data={this.state.introduction}
                    onChange={(evt , editor) =>{
                    this.setState({description: editor.getData()})
                  }}
                />
                  {/* <Textarea rows="4" id={this.props.id} value="" type="text" className="e-add-career" valueChange={this.valueChangeDescription.bind(this)}/> */}
                </div>
              </div>
            <div className="row">
              <div className="col-lg-9">
                <a href="#" className="float-right pt-4 e-ofer-cancel" data-dismiss="modal">Cancel</a>
              </div>
              {
                this.state.designation ?
                  <button className="e-s-btn e-new-ofer float-right mt-3 ml-5" type="submit" value="Submit" onClick={this.createCareer.bind(this)}>Create</button>
                :
                  <button className="e-s-btn e-inactive e-new-ofer float-right mt-3 ml-5" type="submit" value="Submit">Create</button>
              }

            </div>

            <div className="modal-footer">


              {/*

              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              {
                this.state.category && this.state.styleType && this.state.colorOne.length && this.state.colorTwo.length  ?
                  <button type="submit" className="e-s-btn" value="Submit" data-dismiss="modal" onClick={this.submit.bind(this)}>Submit</button>
                :
                  <button type="submit" className="e-s-btn e-inactive" value="Submit" >Submit</button>
              }

            */}

            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default CareerModal;
