/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../components/formComponents/input';

/*Assets*/

class AddPagename extends Component {
  constructor(props){
    super(props);
    this.state = {
        pageName: ""
    }
  }

  componentDidMount() {
    
  }

  submitClick(e){
      e.preventDefault();
      this.props.createPage(this.state.pageName);
      $("#addPageName").modal("hide");
  }

  render() {

    return (
      <div className="modal fade e-dummy-ads-modal" id="addPageName" tabIndex="-1" role="dialog" aria-labelledby="addPageNameLabel" aria-hidden="true">
        <div className="modal-dialog"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="mb-3">Add Page Name</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <Input label="Page Name" id="e-page-name" type="text" defaultValue={this.state.pageName} valueChange={(tempVal) => this.setState({pageName: tempVal})} />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              {
                this.state.pageName.length ?
                  <button className="e-s-btn" type="submit" onClick={this.submitClick.bind(this)}>Create Page</button>
                :
                  <button className="e-s-btn e-inactive" >Create Page</button>
              }
            </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AddPagename;
