
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


/*Components*/
import DetailInvoice from '../../../components/modals/invoiceDetailModal';
import CareerDetail from '../../../components/modals/careerDetailModal';
import APILoader from '../../../components/other/api-loader';
import NothingFound from '../../../components/other/nothing';
import ConfirmationModal from '../../../components/modals/confirmationModal';
import CareerModal from '../../../components/modals/newCareerModal';


/*Services*/
import devconfig from '../../../config/config.js';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';

/*Assets*/



class CareerList extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiLoading: true,
      data: null,
      pageNumActive: true,
      pageNumLoader: false,
      pageNum: 1
    }
  }

  componentDidMount() {
    this.getData();
  }
  getData(){
    this.setState({apiLoading: true});
    var url = devconfig.adminV2+'/career/list-all';
    var data = JSON.stringify({
      "page_num": 1
    })
    APIGet(url, data).then(function(response){
      if(response.status === "ok"){
        console.log(response.data);
        this.setState({ data: response.data});
      }
    }.bind(this))
  }


  // loadMore(){
  //   this.getData(this.state.pageNum + 1);
  //   this.setState({pageNum: this.state.pageNum + 1, pageNumLoader: true});
  // }
  viewDetails(item){
    this.setState({description: item.description,designation: item.designation})
  }
  confirmation(id){
    this.setState({id:id})
  }

  render() {

    return (
      <div className="e-main-content e-vendor-table">
        <h2 className="e-page-head">Career</h2>
        <div className="mb-3">
          <button className="e-s-btn" data-toggle="modal" data-target="#newCareerModal">Add New Career</button>
        </div>
        {
          this.state.data ?
            <table className="table">
              <thead>
                <tr>
                  <th>Designation</th>
                  <th>Location</th>
                  <th>No. of vacancy</th>
                  <th>Action</th>
                  {/*<th>Total Amount</th>
                  <th>Pending Payment</th>*/}
                </tr>
              </thead>
              <tbody>
                {
                  this.state.data.map((item,index) => {
                    return(
                      <tr key={index}>
                        <td>{item.designation}</td>
                        <td>{item.location}</td>
                        <td>{item.vacancy}</td>
                        <td><span className="e-link" data-toggle="modal" data-target="#careerDetailModal" onClick={this.viewDetails.bind(this,item)}>View details</span></td>
                        <td><span className="e-remove" data-toggle="modal" data-target="#confirmModal" onClick={this.confirmation.bind(this,item.id)}></span></td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          :
            null
          }

      {/*Modals*/}
      <CareerDetail description={this.state.description} designation={this.state.designation}/>
      <ConfirmationModal id={this.state.id} getData={this.getData.bind(this)}/>
      <CareerModal getData={this.getData.bind(this)}/>

      </div>

    );
  }
}

export default CareerList;
