
/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $, { data } from 'jquery';

/*Components*/
import NothingFound from '../../components/other/nothing';
import APILoader from '../../components/other/api-loader';
import Toast from '../../components/common/toast';
import UploadDummyAdsModal from '../../components/modals/uploadDummyAdsModal';
import Input from '../../components/formComponents/input';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
/*Assets*/


class Megamenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: {content: "", link: ""},
            productBanner1: {content: "", link: ""},
            productBanner2: {content: "", link: ""},
            selectedBannerIndex: 0,
            ads: {
              megamenu: { page_id: 1, id: 187,data: [{ content: null, link: null }] },
            },
            layout_id: 202
        }
    }

    componentDidMount() {
        this.getMegaMenuAd(this.state.layout_id);
        // this.getProductPageAd();
    }

    triggerToast(temp, type) {
        this.setState({ toast: temp, toastType: type })
        setTimeout(function () { this.setState({ toast: null }); }.bind(this), 4000);
    }

    adsDummySuccess(tempUrl, tempLink) {
        let key = this.state.selectedBannerIndex;
        let bannerData = { content: tempUrl, link: tempLink };
        switch (key) {
            case 1:{
                this.setState({ productBanner1: bannerData });
                break;
            }
            case 2:{
                this.setState({ productBanner2: bannerData });
                break;
            }
            case 3:{
                this.setState({ banner: bannerData });
                break;
            }
        }
    }

    async triggerUpload(key) {
        await this.setState({ uploadModal: true, selectedBannerIndex: key});
        $("#uploadDummyAdsModal").modal("show");
    }

    linkChange(key, val) {
        switch (key) {
            case 1:{
                let data = this.state.productBanner1;
                data.link = val;
                this.setState({ productBanner1: data });
                break;
            }
            case 2:{
                let data = this.state.productBanner2;
                data.link = val;
                this.setState({ productBanner2: data });
                break;
            }
            case 3:{
                let data = this.state.banner;
                data.link = val;
                this.setState({ banner: data });
                break;
            }
        }
    }

    updateAd(key) {
        this.setState({apiLoading: true});
        let ads = this.state.ads;
        ads.megamenu.data = [this.state.banner];
        // let url = devconfig.adminV1 + "/productpagebanner/update";
        // if(key===3) url = devconfig.adminV1 + "/megamenubanner/update";
        // let data = JSON.stringify({
        //     "image" : this.state.productBanner1? this.state.productBanner1.content : null,
        //     "redirection_url" : this.state.productBanner1? this.state.productBanner1.link: null,
        //     "local" : true,
        //     "banner_type" : 1

        // });
        // if(key===2){
        //     data = JSON.stringify({
        //         "image" : this.state.productBanner2? this.state.productBanner2.content : null,
        //         "redirection_url" : this.state.productBanner2? this.state.productBanner2.link: null,
        //         "local" : true,
        //         "banner_type" : 2
        //     });
        // }
        // else if(key===3){
        //     data = JSON.stringify({
        //         "image" : this.state.banner? this.state.banner.content : null,
        //         "redirection_url" : this.state.banner? this.state.banner.link: null
        //     });
        // }
        // APIPost(url, data).then(function (response) {
        // if (response && response.status==="ok") {
        //     if(key===1 || key===2) this.triggerToast("Product banner updated!", "success");
        //     else if(key===3) this.triggerToast("Mega Menu banner updated!", "success");
        //     this.setState({apiLoading: false});
        // }
        // else{
        //     this.triggerToast(response.message, "fail");
        //     this.setState({apiLoading: false});
        // }
        // }.bind(this));
        var tempArry = {
            ads: ads,
            layout: []
          }
          var url = devconfig.adminV1 + "/local/page/update";
          var data = JSON.stringify({
            "layout_id": this.state.layout_id,
            "page_data": tempArry
          })
          APIPost(url, data).then(function (response) {
            if (response.status === "ok") {
                this.setState({apiLoading: false});
            }
          }.bind(this))
    }

    getMegaMenuAd(tempId) {
        var url = devconfig.adminV1 + "/local/page/detail";
        var data = JSON.stringify({
          "layout_id": tempId
        })
        APIPost(url, data).then(function (response) {
          if (response.status === "ok") {
            if (response.data.page_data.ads) {
                let ads = response.data.page_data.ads
              this.setState({ ads: ads, banner: ads.megamenu.data[0] });
            }
          }
        }.bind(this))
        // let url = devconfig.adminV1 + "/megamenubanner/view";
        // APIGet(url).then(function (response) {
        // if (response.status === "ok") {
        //     if(response.data && response.data.image){
        //         let bannerData = { content: response.data.image, link: response.data.redirection_url };
        //         this.setState({ banner: bannerData });
        //     }
        // }
        // }.bind(this));
    }

    getProductPageAd() {
        let url = devconfig.adminV1 + "/productpagebanner/view";
        APIGet(url).then(function (response) {
        if (response.status === "ok"&& response.data && response.data.local_data && response.data.local_data.length) {
            if(response.data.local_data[0] && response.data.local_data[0].image){
                let bannerData = { content: response.data.local_data[0].image, link: response.data.local_data[0].redirection_url };
                if(response.data.local_data[0].banner_type===1)this.setState({ productBanner1: bannerData });
                else if(response.data.local_data[0].banner_type===2)this.setState({ productBanner2: bannerData });
            }
            if(response.data.local_data[1] && response.data.local_data[1].image){
                let bannerData = { content: response.data.local_data[1].image, link: response.data.local_data[1].redirection_url };
                if(response.data.local_data[1].banner_type===1)this.setState({ productBanner1: bannerData });
                else if(response.data.local_data[1].banner_type===2)this.setState({ productBanner2: bannerData });
            }
        }
        }.bind(this));
    }

    removeContent(key){
        switch (key) {
            case 1:{
                let data = this.state.productBanner1;
                data.content = "";
                this.setState({ productBanner1: data });
                break;
            }
            case 2:{
                let data = this.state.productBanner2;
                data.content = "";
                this.setState({ productBanner2: data });
                break;
            }
            case 3:{
                let data = this.state.banner;
                data.content = "";
                this.setState({ banner: data });
                break;
            }
        }
    }

    render() {

        return (
            <>
                <div className="e-main-content e-vendor-table">
                    <h2 className="e-page-head">Other Ads</h2>
                    <div className="row mb-3">
                        <div className="col-lg-4 py-3">
                            <div className="e-layout-wrap p-4">
                                <div className="container-fluid px-0 e-megamenu-ad-container">
                                    <h5 className="mb-0 pb-4">Mega menu</h5>
                                    {
                                        this.state.banner && this.state.banner.content ?
                                            <div className="position-relative e-megamenu-ad-wrapper">
                                                <span className="e-close-icon position-absolute" onClick={() =>this.removeContent(3)}></span>
                                                <a href={this.state.banner.link} target="_blank">
                                                    <img className="e-megamenu-ad" src={this.state.banner.content} alt="Truekhoj highlight" />
                                                </a>
                                            </div>
                                            :
                                            <div className="e-upload-wrapper d-flex align-items-center justify-content-center" onClick={() => this.triggerUpload(3)}>
                                                <div className="e-ad-full-placeholder">
                                                    Upload Ad
                                                </div>
                                            </div>
                                    }
                                </div>
                                <div className="container-fluid px-0">
                                    <div className="row">
                                        <div className="col-lg-12 pt-4">
                                            <h6 className="e-career-header">Link*</h6>
                                            <Input id="e-megamenu-link" allowEdit={true} value={this.state.banner && this.state.banner.link ? this.state.banner.link : ""} type="text" className="e-add-career" valueChange={this.linkChange.bind(this, 3)} />
                                        </div>
                                        <div className="col-lg-12 pt-3">
                                            <div className="container-fluid px-0 d-flex justify-content-end">
                                                {
                                                    this.state.banner && this.state.banner.content && this.state.banner.link &&!this.state.apiLoading?
                                                        <button className="e-p-btn" onClick={this.updateAd.bind(this, 3)}>Update</button>
                                                        :
                                                        <button className="e-p-btn e-inactive">Update</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.uploadModal ?
                        <UploadDummyAdsModal success={this.adsDummySuccess.bind(this)} />
                        :
                        null
                }
                {
                    this.state.toast?
                    <Toast data={this.state.toast} type={this.state.toastType}/>
                    :
                    null
                }
            </>

        );
    }
}

export default Megamenu;
